import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import { pdsTv } from '@purinanbm/pds-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { getFocalPoint } from 'src/utils/mediaHelpers';
import { CMS_OVERLAY, CMS_THEME } from '../../common/enums';
import { ButtonLink } from '../../components/button/Button';
import { remCalc, theme } from '../../theme/theme';

type AppBannerPlatform = 'ios' | 'android' | null;

interface AppStoreBannerState {
  showQRCode: Boolean;
  platform: AppBannerPlatform;
}

const useMobileDevice = (): AppStoreBannerState => {
  const [appBanner, setAppBanner] = useState<AppStoreBannerState>({
    showQRCode: true,
    platform: null,
  });

  useEffect(() => {
    const isiOS = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    const isAndroid = () => {
      return /Android/.test(navigator.userAgent);
    };

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

    let platform: AppBannerPlatform = null;
    if (isiOS()) {
      platform = 'ios';
    } else if (isAndroid()) {
      platform = 'android';
    }

    if (!isMobile) {
      setAppBanner({ showQRCode: true, platform: null });
      return;
    }

    setAppBanner({
      showQRCode: false,
      platform,
    });
  }, []);

  return appBanner;
};

const HeroContainer = styled(Container)`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.alternate};

  img {
    max-width: 100%;
  }

  .hero-card {
    background-color: ${({ cmsTheme }) => theme[cmsTheme].background.alternate};
  }

  &.skrim {
    .hero-card {
      background-color: transparent;
    }

    text-shadow: ${({ cmsOverlayTheme }) => theme.common.overlay[cmsOverlayTheme].shadow};
  }

  .gatsby-image-wrapper {
    height: 575px;

    &.tall {
      height: 400px;
    }
  }

  .hero-content {
    position: relative;
  }

  .hero-card {
    border-top-left-radius: unset !important;
    border-top-right-radius: unset !important;
  }

  .body {
    font-size: ${() => remCalc(14)};
  }

  // Tablet.
  @media (min-width: ${theme.media.sm}) and (max-width: ${theme.media.lg}) {
    .gatsby-image-wrapper {
      height: 384px;

      &.tall {
        height: 576px;
      }
    }
  }

  // Desktop.
  @media (min-width: ${theme.media.lg}) {
    .hero-content.overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    .hero-card {
      border-top-left-radius: 0.5rem !important;
      border-top-right-radius: 0.5rem !important;
    }

    .gatsby-image-wrapper {
      height: 240px;

      &.tall {
        height: 480px;
      }
    }
  }
  // Large Desktop.
  @media (min-width: ${theme.media.xl}) {
    .gatsby-image-wrapper {
      height: 400px;

      &.tall {
        height: 600px;
      }
    }
  }

  &.wide {
    padding-top: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;

    > .container-lg {
      max-width: unset;
    }

    .gatsby-image-wrapper {
      border-radius: unset !important;
    }

    .hero-media {
      padding-left: 0;
      padding-right: 0;
    }

    // Small up.
    @media (max-width: ${theme.media.lg}) {
      .hero-content {
        padding: 0;
      }
    }
    // Large up.
    @media (min-width: ${theme.media.lg}) {
      .gatsby-image-wrapper {
        height: 248px;

        &.tall {
          height: 496px;
        }
      }
    }
    // X-large up.
    @media (min-width: ${theme.media.xl}) {
      .gatsby-image-wrapper {
        height: 400px;

        &.tall {
          height: 600px;
        }
      }
    }
  }
`;

interface StorageCardProps {
  node: IStorageHeroCard;
}

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    hero_variant_type: 'tall',
    content_position: 'left',
    content_card: false,
    fluid: true,
    theme: CMS_THEME.LIGHT,
    overlay: CMS_OVERLAY.DARK,
  },
  entity_buttons: {
    primary_button: 'btn',
  },
};
const styles = pdsTv({
  slots: {
    foreground: '!pds-max-h-[250px]',
    bgImg: '',
    imgContentContainer:
      'pds-flex pds-flex-1 pds-items-center pds-justify-normal pds-gap-5 pds-bg-transparent lg-bt:pds-px-4 lg:pds-px-6',
    textContainer: '',
  },
  variants: {
    theme: {
      light: {
        textContainer: 'pds-text-paper-text',
      },
      dark: {
        textContainer: 'pds-text-white',
      },
      neutral: {
        textContainer: 'pds-text-paper-text',
      },
    },
    textDirection: {
      right: {
        imgContentContainer: 'lg-bt:pds-justify-end',
      },
      left: {
        imgContentContainer: '',
      },
    },
    foregroundAlignment: {
      left: {},
      right: {},
    },
    hasForeground: {
      true: {},
      false: '',
      null: '',
    },
    hasSkrim: {
      true: {
        bgImg:
          'after:pds-hidden after:pds-rounded-[inherit] after:pds-opacity-subtle after:pds-content-empty after:lg-bt:pds-block',
      },
      false: {},
    },
    overlayTheme: {
      light: {},
      dark: {},
    },
  },
  compoundVariants: [
    {
      hasForeground: true,
      textDirection: 'right',
      foregroundAlignment: 'right',
      class: { imgContentContainer: 'lg-bt:pds-flex-row lg-bt:pds-justify-end lg-bt:pds-p-4' },
    },
    {
      hasForeground: true,
      foregroundAlignment: 'right',
      textDirection: 'left',
      class: {
        imgContentContainer:
          'lg-bt:pds-flex-row-reverse lg-bt:pds-justify-between lg-bt:pds-pr-4 lg:pds-pr-7',
      },
    },
    {
      hasForeground: true,
      foregroundAlignment: 'left',
      textDirection: 'right',
      class: {
        imgContentContainer: 'lg-bt:pds-justify-between lg-bt:pds-pl-4 lg:pds-pl-7',
      },
    },
    {
      hasSkrim: true,
      overlayTheme: 'light',
      class: {
        bgImg: 'after:pds-bg-white',
      },
    },
    {
      hasSkrim: true,
      overlayTheme: 'dark',
      class: {
        bgImg: 'after:pds-bg-paper-text',
      },
    },
  ],
});

// eslint-disable-next-line complexity
const StorageHeroCard: React.FC<StorageCardProps> = function ({ node: heroCard }) {
  const { showQRCode } = useMobileDevice();
  const settings = Object.assign(DefaultSettings, heroCard?.behaviors || {});
  const direction = settings?.entity_variant?.content_position ?? 'left';
  const hasCard = settings?.entity_variant?.content_card;
  const isFullWidth = settings?.entity_variant?.fluid;
  const imageHeight = settings?.entity_variant?.hero_variant_type;
  const cmsTheme = heroCard.behaviors?.entity_variant?.theme || CMS_THEME.LIGHT;
  const cmsOverlayTheme = heroCard.behaviors?.entity_variant?.overlay || CMS_OVERLAY.LIGHT;
  const foregroundImageAlignment = settings?.entity_variant?.foreground_image_alignment ?? 'left';
  const primaryButtonVariation = settings.entity_buttons.primary_button;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { element: SubheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const hero_image = heroCard.relationships.image?.relationships?.field_media_image?.gatsbyImage;
  const foregroundImage =
    heroCard?.relationships?.secondary_image?.relationships?.field_media_image.gatsbyImage;

  const hasForegroundImage = !!heroCard.relationships.secondary_image;
  const { foreground, imgContentContainer, bgImg, textContainer } = styles({
    theme: cmsTheme,
    overlayTheme: cmsOverlayTheme,
    textDirection: direction,
    hasForeground: hasForegroundImage,
    foregroundAlignment: foregroundImageAlignment,
    hasSkrim: !hasCard,
  });

  const { indexOnPage } = heroCard;
  return (
    <HeroContainer
      id={heroCard.component_id}
      data-id={heroCard.drupal_id}
      className={cx(
        'px-0',
        isFullWidth && 'wide',
        !settings?.entity_variant?.content_card && 'skrim',
        `${isFullWidth ? 'py-0' : 'py-3 py-lg-5'}`,
      )}
      fluid
      cmsTheme={cmsTheme}
      cmsOverlayTheme={cmsOverlayTheme}
    >
      <Container fluid="lg">
        <Row
          className={cx(
            settings?.entity_variant?.content_position === 'right' ? 'flex-row-reverse' : null,
            'position-relative',
          )}
        >
          <Col xs={12} className="hero-media pds-relative">
            { !!heroCard.relationships.image && (
              <GatsbyImage
                alt={heroCard.relationships.image.field_media_image.alt}
                image={hero_image}
                className={bgImg({ className: ['rounded-2', imageHeight] })}
                objectFit="cover"
                objectPosition={getFocalPoint(heroCard.relationships.image)}
                loading={indexOnPage !== 0 ? 'lazy' : 'eager'}
              />
            )}
            {hasForegroundImage && showQRCode && (
              <div className="pds-absolute pds-left-0 pds-top-0 pds-flex pds-h-full pds-w-full pds-items-center pds-justify-center lg-bt:!pds-hidden">
                <GatsbyImage
                  alt={heroCard.relationships.secondary_image.field_media_image.alt}
                  image={foregroundImage}
                  className={cx('rounded-2', foreground())}
                  imgClassName="pds-object-contain"
                  objectFit="cover"
                  loading={indexOnPage !== 0 ? 'lazy' : 'eager'}
                />
              </div>
            )}
          </Col>
          <div
            className={cx(
              'hero-content overlay d-flex justify-content-center overflow-hidden pds-items-center pds-gap-4',
              direction === 'right' ? 'pe-lg-5' : 'ps-lg-5',
              hasCard && 'as-card',
            )}
          >
            <div className={imgContentContainer()}>
              {hasForegroundImage && showQRCode && (
                <GatsbyImage
                  alt={heroCard.relationships.secondary_image.field_media_image.alt}
                  image={foregroundImage}
                  className={foreground({ className: '!pds-hidden pds-rounded lg-bt:!pds-block' })}
                  imgClassName="pds-object-contain"
                  objectFit="contain"
                  loading={indexOnPage !== 0 ? 'lazy' : 'eager'}
                />
              )}
              <div className="hero-card pds-rounded pds-p-4 md-bt:pds-max-w-2xl">
                <div className={textContainer()}>
                  <HeadingElement className={headingStyle}>{heroCard.title}</HeadingElement>
                  {heroCard?.subtitle && (
                    <SubheadingElement className={subheadingStyle}>
                      {heroCard.subtitle}
                    </SubheadingElement>
                  )}
                  {heroCard?.body?.processed && (
                    <div
                      className="mt-3"
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{ __html: heroCard.body.processed }}
                    />
                  )}
                </div>
                {!showQRCode && (
                  <ButtonDiadWrapper>
                    {heroCard.links.map((linkObject, index) => {
                      return (
                        <AnalyticsPoint
                          type="component"
                          node={{
                            title: linkObject.title,
                            name: linkObject.title,
                            type: 'hero_link',
                            drupal_id: '',
                          }}
                          as={ButtonLink}
                          key={linkObject.url}
                          variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                          to={linkObject.url}
                          cmsTheme={cmsTheme}
                          {...linkObject?.options?.attributes}
                        >
                          {linkObject.title}
                        </AnalyticsPoint>
                      );
                    })}
                  </ButtonDiadWrapper>
                )}
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </HeroContainer>
  );
};

export default StorageHeroCard;
