import Icon from '@mdi/react';
import { ISelect, Label, Select, pdsTv } from '@purinanbm/pds-ui';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { mdiInformationOutline } from 'src/assets/icons/mdiIcons';

type Props = Omit<ISelect, 'name' | 'data-error'> & {
  required?: boolean;
  name: string;
  control?: Control<any>;
  // !TODO: Add tooltip tooltip?: React.ReactElement;
  maxLength?: number;
  autoComplete?: string;
  children: React.ReactNode;
};
const styles = pdsTv({
  // TODO: Check after [p] to see if needed.
  base: 'pds-flex pds-h-[24px] pds-items-center',
  variants: {
    error: {
      true: 'pds-text-error',
    },
    required: {
      true: 'after:pds-content-required',
    },
  },
});

const SelectField = ({ required = false, label, name, control, ...rest }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  return (
    <div className="pds-flex pds-flex-col">
      <Label htmlFor={`${name}-trigger`} className={styles({ error: !!error, required })}>
        {label}
      </Label>
      <Select
        id={name}
        {...field}
        onValueChange={value => field.onChange(value)}
        className="pds-peer"
        aria-invalid={!!error}
        data-error={!!error}
        aria-required={required}
        aria-describedby={`${name}-trigger-message`}
        {...rest}
      />
      {error && (
        <span className="pds-mt-2 pds-block pds-text-error" id={`${name}-trigger-message`}>
          <Icon title="Error" className="pds-rotate-180" path={mdiInformationOutline} size={1} />{' '}
          {error?.message?.toString()}
        </span>
      )}
    </div>
  );
};

export default SelectField;
