import { useEffect, useState } from 'react';

// It checks if the OneTrust object is available in the window object and allow for a callback to be executed when it is ready
export const useOneTrustReady = (callback: () => void) => {
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (window?.OneTrust) {
        setReady(true);
        clearInterval(interval);
      }
    }, 100); // checks every 100 milliseconds

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isReady) {
      callback();
    }
  }, [isReady, callback]);
};
