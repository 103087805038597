import { Button } from '@purinanbm/pds-ui';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import errorSvg from '../../assets/icons/error.svg';

type OfferErrorServerProps = {
  errorTitle: string;
  errorDescription: string;
};

const OfferErrorServer: React.FC<OfferErrorServerProps> = ({ errorTitle, errorDescription }) => {
  const { t } = useTranslation();
  return (
    <div className="pds-mt-5 pds-flex pds-flex-col pds-items-center md:pds-my-6">
      <div className="pds-mb-5">
        <img src={errorSvg} alt="" />
      </div>

      <div className="text-center pds-mb-2 pds-flex pds-flex-col pds-flex-wrap pds-justify-center pds-gap-x-2 pds-text-title-sm pds-font-extrabold md:pds-flex-row">
        <div>{errorTitle}</div>
      </div>

      <div className="text-center pds-mb-5">{errorDescription}</div>

      <Button
        buttonColor="neutral"
        // TS error is because "GatsbyLink" is not a defined option for the the "as" property
        // @ts-ignore - Fix in PDS-UI
        as={GatsbyLink}
        to="/rebates"
        className="pds-no-underline"
      >
        {t('Back to your offers')}
      </Button>
    </div>
  );
};

export default OfferErrorServer;
