import { css } from '@linaria/core';
import React from 'react';
import { FormControl, FormControlProps, FormGroup, FormLabel } from 'react-bootstrap';
import { FieldValues, Path, useFormContext } from 'react-hook-form';
import { theme } from 'src/theme/theme';
import PurinaToggletip from '../../toggletip/Toggletip';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../ContactUsForm';
import { OfferFormTypes } from '../utils/offer';
import { ProfileFormTypes } from '../utils/profile';

type Props<T extends FieldValues> = Omit<FormControlProps, 'name'> & {
  label: string;
  required?: boolean;
  name: Path<T>;
  tooltip?: React.ReactElement;
  maxLength?: number;
  autoComplete?: string;
};

export const requiredClass = css`
  &:after {
    content: ' *';
    color: ${theme.common.error.dark};
  }
`;

const InputField: React.FC<Props<IContactUsFormData | OfferFormTypes | ProfileFormTypes>> = ({
  required = false,
  label,
  name,
  tooltip,
  ...rest
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <FormGroup controlId={name}>
      <div className="pds-flex pds-items-baseline pds-gap-1">
        <FormLabel className={required ? requiredClass : ''}>{label}</FormLabel>
        {tooltip && <PurinaToggletip>{tooltip}</PurinaToggletip>}
      </div>
      <FormControl
        isInvalid={!!errors[name]}
        aria-invalid={!!errors[name]}
        required={required}
        {...register(name)}
        type="text"
        {...rest}
        aria-describedby={`${name}-message`}
      />
      {errors[name] && (
        <FormControl.Feedback className="d-block" type="invalid" id={`${name}-message`}>
          {errors[name]?.message?.toString()}
        </FormControl.Feedback>
      )}
    </FormGroup>
  );
};

export default InputField;
