import { Pet } from '@purinanbm/pup-script/dist/api/data-contracts';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { FieldError, FieldErrors } from 'react-hook-form';
import { AnsiraOfferProps, AnsiraResponse } from 'src/utils/clientRequests';
import { removeKeyAndStringify } from 'src/utils/logging';
import * as Yup from 'yup';
import { TranslateFunctionType } from '../../../utils/TranslateFunctionType';
import { textPattern } from './contactUs';

const createOfferSchemaLong = (t: TranslateFunctionType) =>
  Yup.object({
    hasActivePets: Yup.boolean(),
    firstName: Yup.string()
      .matches(textPattern, t('First name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .required(t('Please fill out a First Name.')),
    lastName: Yup.string()
      .matches(textPattern, t('Last name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .required(t('Please fill out a Last Name.')),
    referrer: Yup.string(),
    postalCode: Yup.string()
      .min(5, t('Provide a 5 digit zip code.'))
      .max(5, t('Provide a 5 digit zip code.'))
      .transform((o, c) => (o === '' ? null : c))
      .nullable()
      .required(t('Please fill out a Zip Code.')),
    email: Yup.string()
      .email(t('Provide a valid email address.'))
      .required(t('Please fill out an Email.')),
    dogCount: Yup.string().when('catCount', {
      is: (catCount: string) => Number(catCount) === 0,
      then: Yup.string()
        .required(t('Please fill out an animal'))
        .oneOf(['1', '2', '3', '4', '5'], t('Please fill out an animal')),
    }),
    catCount: Yup.string().oneOf(['0', '1', '2', '3', '4', '5']).default('0'),
    catPets: Yup.array().when('hasActivePets', {
      is: true,
      then:
        // Allow existing pets to flow through
        Yup.array().of(
          Yup.object({
            // Ensure empty string for size is not sent to API
            size: Yup.string().transform(size => (size === '' ? undefined : size)),
          }),
        ),
      otherwise: Yup.array().of(
        Yup.object({
          name: Yup.string().required(t('Please fill out a name for the cat')),
          gender: Yup.string()
            .oneOf(['male', 'female'], t('Please fill out a valid option'))
            .required(t('Please fill out a valid option')),
          birthDate: Yup.date()
            .max(new Date(), t('Can not pick a date in the future'))
            .required(t('Please fill out a birth date'))
            .typeError(t('Please fill out a birth date')),
          size:
            // Ensure empty string for size is not sent to API
            Yup.string().transform(size => (size === '' ? undefined : size)),
        }),
      ),
    }),
    dogPets: Yup.array().when('hasActivePets', {
      is: true,
      then:
        // Allow existing pets to flow through
        Yup.array().of(
          Yup.object({
            // Ensure empty string for size is not sent to API
            size: Yup.string().transform(size => (size === '' ? undefined : size)),
          }),
        ),
      otherwise:
        // Validate new pets
        Yup.array().of(
          Yup.object({
            name: Yup.string().required(t('Please fill out a name for the dog')),
            gender: Yup.string()
              .oneOf(['male', 'female'], t('Please fill out a valid option'))
              .required(t('Please fill out a valid option')),
            birthDate: Yup.date()
              .max(new Date(), t('Can not pick a date in the future'))
              .required(t('Please fill out a birth date'))
              .typeError(t('Please fill out a birth date')),
            primaryBreed: Yup.object().nullable(),
            size: Yup.string().when('primaryBreed', {
              is: undefined,
              then: Yup.string()
                .required(t('Please fill out a size'))
                .lowercase()
                .oneOf(['toy', 'small', 'medium', 'large', 'giant']),
              otherwise: Yup.string()
                // Ensure empty string for size is not sent to API
                .transform(size => (size === '' ? undefined : size))
                .notRequired(),
            }),
          }),
        ),
    }),
    optIn: Yup.bool()
      .oneOf([true], t('Please fill out an Email Consent.'))
      .required(t('Please fill out an Email Consent.')),
  }).required();

const createOfferSchemaLongWithAddress = (t: TranslateFunctionType) => {
  const offerSchemaLong = createOfferSchemaLong(t);

  const addressSchema = Yup.object({
    city: Yup.string().required(t('Please fill out a City.')),
    state: Yup.string().required(t('Please fill out a State.')),
    phoneNumber: Yup.string().when('callFeedbackConsent', {
      is: 1,
      then: Yup.string().required(t('Please fill out a Phone Number.')),
    }),
    address1: Yup.string().required(t('Please fill out an Address 1.')),
    address2: Yup.string().default(''),
  }).required();

  return offerSchemaLong.concat(addressSchema);
};

const createOfferSchemaShort = (t: TranslateFunctionType) =>
  Yup.object({
    firstName: Yup.string()
      .matches(textPattern, t('First name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .required(t('Please fill out a First Name.')),
    lastName: Yup.string()
      .matches(textPattern, t('Last name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .required(t('Please fill out a Last Name.')),
    referrer: Yup.string(),
    postalCode: Yup.string()
      .min(5, t('Provide a 5 digit zip code.'))
      .max(5, t('Provide a 5 digit zip code.'))
      .transform((o, c) => (o === '' ? null : c))
      .nullable()
      .required(t('Please fill out a Zip Code.')),
    email: Yup.string()
      .email(t('Provide a valid email address.'))
      .required(t('Please fill out an Email.')),
    dogCount: Yup.string().when('catCount', {
      is: (catCount: string) => Number(catCount) === 0,
      then: Yup.string()
        .required(t('Please fill out an animal'))
        .oneOf(['1', '2', '3', '4', '5'], t('Please fill out an animal')),
    }),
    catCount: Yup.string().oneOf(['0', '1', '2', '3', '4', '5']).default('0'),
    optIn: Yup.bool()
      .oneOf([true], t('Please fill out an Email Consent.'))
      .required(t('Please fill out an Email Consent.')),
  }).required();

export const createOfferSchema = (
  t: TranslateFunctionType,
  formType: 'short' | 'long' | 'long_address',
) => {
  switch (formType) {
    case 'short':
      return createOfferSchemaShort(t);
    case 'long':
      return createOfferSchemaLong(t);
    case 'long_address':
      return createOfferSchemaLongWithAddress(t);
    default:
      throw new Error('Invalid formType');
  }
};

export type OfferFormTypes = Yup.InferType<
  ReturnType<
    | typeof createOfferSchemaLong
    | typeof createOfferSchemaLongWithAddress
    | typeof createOfferSchemaShort
  >
>;

export const calculateAge = (birthdate: string) => {
  const birthDate = new Date(birthdate);
  const today = new Date();

  let years = today.getFullYear() - birthDate.getFullYear();
  let months = today.getMonth() - birthDate.getMonth();

  if (months < 0) {
    years--;
    months += 12;
  }

  if (years > 0) {
    return `${years} year${years > 1 ? 's' : ''}`;
  }
  return `${months} month${months > 1 ? 's' : ''}`;
};

export const submitAnalyticsForIncompleteResponse = ({
  response,
}: {
  response: AnsiraResponse;
}) => {
  const uuid = response.response.user?.uuid;

  const analyticsEvent = {
    event: 'error_occurred',
    eventCategory: 'ansira_coupon_form_error',
    eventParams: {
      error_field: 'submit',
      error_code: 'coupon_request_error',
      error_name: 'coupon_request_error',
      error_feature: "API did not return 'coupon_request'",
    },
    up: uuid ? { user_id: uuid } : undefined,
  };

  sendIt(analyticsEvent);
};

export const postOfferForm = (
  response: AnsiraResponse,
  {
    formData,
    activePets,
    referrer,
    title,
    brand,
  }: {
    formData: AnsiraOfferProps;
    activePets: any;
    referrer: string;
    title: string;
    brand?: string;
  },
) => {
  if (navigator.onLine) {
    //  GA Event ---------------------------------
    const processPetData = (pet: Pet, index: number, species: 'dog' | 'cat') => {
      const dateObject = new Date(pet.birthDate?.toString() ?? '');
      const year = dateObject.getFullYear();
      const month =
        dateObject.getMonth() + 1 < 10
          ? `0${dateObject.getMonth() + 1}`
          : dateObject.getMonth() + 1;
      const age = calculateAge(pet.birthDate?.toString() ?? '');

      // !IMPORTANT: This event is responsible for triggering down streams
      // that grant users loyalty points
      const submitAnalytics = {
        event: 'add_pet_completed',
        eventParams: {
          pet_species: species,
          pet_gender: pet.gender as string,
          pet_age: age,
          pet_birth_year: year.toString(),
          pet_birth_month: month.toString(),
          pet_name: pet.name,
          pet_size: pet.size as string,
        },
        up: {
          pet_number: index + 1,
        },
      };
      sendIt(submitAnalytics);
    };

    // TODO: Somehow figure these types out.
    if (!activePets?.length) {
      formData.data.dogPets?.forEach((dog: Pet, index: number) => {
        processPetData(dog, index, 'dog');
      });

      formData.data.catPets?.forEach((cat: Pet, index: number) => {
        processPetData(cat, index, 'cat');
      });
    }

    // !IMPORTANT: This event is responsible for triggering down streams
    // that grant users loyalty points
    const uuid = response?.response?.user?.uuid;
    const submitAnalyticsForm = {
      event: 'form_complete',
      eventParams: {
        form_id: referrer,
        form_name: `offers ${title}`,
        form_brand: brand ?? '',
        form_type: 'offer_form',
        form_version: 'v2',
        form_parent: 'purina',
      },
      up: uuid ? { user_id: uuid } : undefined,
    };
    sendIt(submitAnalyticsForm);
  }
};

export const getMessage = (data: AnsiraResponse, tr: TranslateFunctionType) => {
  if (data?.response?.error_code === '1007') {
    return {
      heading: tr('Duplicate Email'),
      text: tr(
        "This email is already registered for this offer. Look for the coupon in your inbox, and don't forget to check your spam or junk folders.",
      ),
    };
  }
  if (data?.response?.error_code === '1012') {
    return {
      heading: tr('No Offers'),
      text: tr(
        'There are currently no offers available for this product. Please check back later for new offers.',
      ),
    };
  }

  if (!data?.response?.error_code) {
    return {
      heading: tr('Error'),
      text: tr(
        "Something went wrong. Please resubmit your information, and we'll try again. If you continue to experience issues, please contact us.",
      ),
    };
  }

  return {
    heading: tr('Error'),
    text: data?.response.message,
  };
};

export const onOfferError = (validationErrors: FieldErrors<OfferFormTypes>) => {
  const errorsArray = Object.entries(validationErrors);
  (errorsArray as [string, FieldError][]).forEach(([key, err]) => {
    const sendErrorToGA = ([errorKey, fieldError]: [
      string,
      FieldError | { string: FieldError }[],
    ]) => {
      let errorCode = 'ERROR';
      let errorFeature = '';

      if (Array.isArray(fieldError)) {
        errorFeature = removeKeyAndStringify(fieldError, 'ref');
      } else {
        errorCode = fieldError.type;
        errorFeature = fieldError.message || '';
      }

      const submitAnalyticsErrorForm = {
        event: 'error_occurred',
        eventCategory: 'ansira_coupon_form_error',
        eventParams: {
          error_field: errorKey,
          error_code: errorCode,
          error_name: errorKey,
          error_feature: errorFeature,
        },
      };
      sendIt(submitAnalyticsErrorForm);
    };
    sendErrorToGA([key, err]);
  });
};
