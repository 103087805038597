export const getPageTypeFromDom = () => {
  if (typeof document !== 'undefined') {
    const main = document.getElementById('mainContent');
    const pageType = main?.getAttribute('data-page-type');

    return pageType?.replace('node__', '');
  }

  return '[not set]';
};
