import { UseQueryOptions } from '@tanstack/react-query';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import { useAuth } from 'src/hooks/useAuth';
import { LOYALTY_QUERIES, useLoyaltyQuery } from 'src/hooks/useLoyaltyService';
import { Customers as LoyaltyService } from 'src/services/loyalty/Customers';
import { Reward } from '../../../services/loyalty/types';

type SelectOption<T extends LOYALTY_QUERIES> = UseQueryOptions<
  Awaited<ReturnType<LoyaltyService[T]>>['data']
>['select'];

export const useRewardsCatalog = (select?: SelectOption<LOYALTY_QUERIES.GET_REWARDS_CATALOG>) => {
  const { user, error: errorUser, isLoading: isLoadingUser } = useAuth();
  const { enableCatalogOmitFreeSamples } = useFlags();

  const isLoadingFlags = enableCatalogOmitFreeSamples === undefined;
  const userAnsiraId = user?.ansiraUuid || '';

  const {
    data: customerDetailResponse,
    error: errorCustomerDetail,
    isLoading: isLoadingCustomerDetail,
  } = useLoyaltyQuery(
    LOYALTY_QUERIES.GET_CUSTOMERS_DETAIL,
    {
      customerId: userAnsiraId,
    },
    {
      enabled: Boolean(user),
    },
  );

  const {
    data: rewardsCatalogueResponse,
    error: errorRewardsCatalog,
    isLoading: isLoadingRewardsCatalog,
  } = useLoyaltyQuery(
    LOYALTY_QUERIES.GET_REWARDS_CATALOG,
    {
      customerId: userAnsiraId,
    },
    {
      enabled: Boolean(user) && !isLoadingFlags,
      select,
    },
  );

  const userPoints = customerDetailResponse?.customer?.loyaltyPoints || 0;
  const isLoading = [
    isLoadingCustomerDetail,
    isLoadingRewardsCatalog,
    isLoadingUser,
    isLoadingFlags,
  ].some(Boolean);
  const hasErrored = [errorCustomerDetail, errorUser, errorRewardsCatalog].some(Boolean);

  const rewardItems: Reward[] = ((rewardsCatalogueResponse?.rewards as Reward[]) ?? [])
    .filter(reward => reward.type !== 'DIGITAL')
    .filter(reward => (enableCatalogOmitFreeSamples ? reward.points !== 0 : true));

  return {
    hasErrored,
    userPoints,
    rewardItems,
    isLoading,
  };
};
