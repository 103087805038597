import { yupResolver } from '@hookform/resolvers/yup';
import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { mdiMailOutline, mdiPackage2Outline } from 'src/assets/icons/mdiIcons';
import { RewardCard } from 'src/components/card/RewardCard';
import { useAuth } from 'src/hooks/useAuth';
import {
  LOYALTY_MUTATIONS,
  LOYALTY_QUERIES,
  useLoyaltyMutation,
  useLoyaltyQuery,
} from 'src/hooks/useLoyaltyService';
import { PostRewardsCheckoutRequestBody } from 'src/services/loyalty/data-contracts';
import { Reward } from '../../../services/loyalty/types';
import PurinaPerksFooter from '../PurinaPerksFooter';
import { PURINA_PERKS_ROUTES } from '../PurinaPerksLayout';
import { onCheckoutError } from '../helpers';
import RewardCheckoutFields from './RewardCheckoutFields';
import RewardCheckoutSummary from './RewardCheckoutSummary';
import { RewardDivider } from './RewardDivider';
import {
  RewardDigitalFormType,
  RewardPhysicalFormType,
  rewardDigitalCheckoutSchema,
  rewardPhysicalCheckoutSchema,
} from './types';

const RewardCheckout: React.FC<{ item: Reward }> = ({ item }) => {
  const { user } = useAuth();
  const userAnsiraId = user?.ansiraUuid || '';
  const { data: customer } = useLoyaltyQuery(
    LOYALTY_QUERIES.GET_CUSTOMERS_DETAIL,
    {
      customerId: userAnsiraId,
    },
    { enabled: Boolean(userAnsiraId) },
  );

  const [error, setError] = useState<Error | undefined>();
  const { handleSubmit, control } = useForm<RewardPhysicalFormType | RewardDigitalFormType>({
    resolver: yupResolver(
      item.type === 'PHYSICAL' ? rewardPhysicalCheckoutSchema : rewardDigitalCheckoutSchema,
    ),
    // TODO: figure out why the yup infer types is causing type errors when this isn't asserted as undefined.
    defaultValues: { email: user?.email as undefined },
  });

  const mutation = useLoyaltyMutation(LOYALTY_MUTATIONS.POST_REWARDS_CHECKOUT, {
    rewardId: String(item.id),
    customerId: user?.ansiraUuid || '',
    data: {} as PostRewardsCheckoutRequestBody,
    params: {},
  });
  const currentPoints = customer?.customer?.loyaltyPoints;

  const onSubmit = async (data: RewardPhysicalFormType | RewardDigitalFormType) => {
    try {
      if (!user?.ansiraUuid) throw new Error('Unable to find user');
      const currentBalance =
        typeof currentPoints !== 'undefined' ? String(currentPoints - item.points) : '[not set]';

      if (item.type === 'DIGITAL') {
        mutation.mutate(
          {
            email: data.email,
            pid: item.pid,
            firstName: user.name,
            lastName: user.lastName,
            transactionNumber: crypto.randomUUID(),
          },
          {
            onSuccess: () => {
              sendIt({
                event: 'spend_virtual_currency',
                eventParams: {
                  item_id: item.name,
                  item_code: item.pid,
                  current_balance: currentBalance,
                  value: String(item.points),
                  method: 'redeem reward',
                  item_category: 'digital reward',
                },
              });
              navigate(PURINA_PERKS_ROUTES.CheckoutSuccess, { state: { reward: item } });
            },
            onError: (e: any) => {
              setError(new Error(e.error.detail));
              // setError(new Error('Unable to complete purchase please try again later.'));
            },
          },
        );

        return;
      }

      mutation.mutate(
        {
          email: data.email,
          pid: item.pid,
          firstName: data.first,
          lastName: data.last,
          transactionNumber: crypto.randomUUID(),
          state: data.state,
          city: data.city,
          address1: data.street1,
          address2: data.street2,
          postalCode: data.zip,
        },
        {
          onSuccess: () => {
            sendIt({
              event: 'spend_virtual_currency',
              eventParams: {
                item_id: item.name,
                item_code: item.pid,
                current_balance: currentBalance,
                value: String(item.points),
                method: 'redeem reward',
                item_category: 'physical reward',
              },
            });
            navigate(PURINA_PERKS_ROUTES.CheckoutSuccess, { state: { reward: item } });
          },
          onError: (e: any) => {
            setError(new Error(e.error.detail));
            sendIt({
              event: 'error_occurred',
              eventParams: {
                error_field: 'reward submission',
                error_code: 'capilary error',
                error_name: e.error.detail,
                error_feature: 'reward-checkout',
              },
            });
            // setError(new Error('Unable to complete purchase please try again later.'));
          },
        },
      );
    } catch (e: any) {
      setError(e);
    }
  };

  return (
    <form
      className="pds-flex pds-flex-col 3xl:pds-grid 3xl:pds-grid-cols-12 3xl:pds-gap-4"
      onSubmit={handleSubmit(onSubmit, onCheckoutError)}
    >
      <div className="pds-col-span-8">
        <RewardCard iconPath={mdiMailOutline} title="Send Confirmation Email to:">
          <RewardCheckoutFields item={item} control={control} />
          <RewardDivider title="Shipping Method" icon={mdiPackage2Outline} />
          {item.type === 'PHYSICAL' ? (
            <div className="pds-flex pds-flex-col pds-rounded pds-border pds-border-surface-line pds-p-4">
              <div className="pds-flex pds-justify-between">
                <h3 className="pds-text-body-md pds-font-semibold">Standard Shipping</h3>
                <span className="pds-font-semibold pds-text-primary">FREE</span>
              </div>
              Arrives in 10-14 Business Days
            </div>
          ) : (
            <div className="pds-flex pds-flex-col pds-rounded pds-border pds-border-surface-line pds-p-4">
              <div className="pds-flex pds-justify-between">
                <h3 className="pds-text-title-sm pds-font-semibold">Digital Delivery</h3>
                <span className="pds-font-semibold pds-text-primary">FREE</span>
              </div>
              If you selected a digital gift (like a gift card), it may take up to two business days
              for your reward to reach you.
            </div>
          )}
        </RewardCard>

        <PurinaPerksFooter />
      </div>
      <RewardCheckoutSummary submissionIsLoading={mutation.isLoading} error={error} item={item} />
    </form>
  );
};

export default RewardCheckout;
