import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FieldArrayWithId, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ProfileFormTypes } from '../utils/profile';
import { requiredClass } from './InputField';

const ControlledPets = ({
  petType,
  fields,
  remove,
}: {
  petType: 'cat' | 'dog';
  fields: FieldArrayWithId<ProfileFormTypes, 'catPets' | 'dogPets'>[];
  remove: (index: number) => void;
}) => {
  const { t } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext<ProfileFormTypes>();

  return (
    <>
      {fields.map((pet, index: number) => {
        const petName = `${petType}Pets` as const;
        const petNameId = `${petName}.${index}.name` as const;
        const petBirthDateId = `${petName}.${index}.birthDate` as const;
        const petsError = errors?.[petName]?.[index];

        /* i18next-extract-mark-context-next-line ["cat", "dog"] */
        const nameLabel = t('petType', {
          context: petType,
        });

        const handleRemovePet = () => {
          remove(index);
        };
        return (
          <fieldset key={pet.id}>
            <legend className="typography_h4">
              {nameLabel} #{index + 1}
            </legend>
            <Row key={index} className="py-2 gy-3 gx-2">
              <Col xs={12} md={6}>
                <input
                  type="hidden"
                  value={petType.toUpperCase()}
                  {...register(`${petName}.${index}.petType.keyName` as const)}
                />
                <Form.Group controlId={`${petName}-${index}-name`}>
                  <Form.Label className={requiredClass}>{t('Name')}</Form.Label>
                  <Form.Control
                    {...register(petNameId)}
                    type="text"
                    isInvalid={petsError && !!petsError?.name}
                    aria-invalid={petsError && !!petsError?.name}
                    // i18next-extract-mark-context-next-line ["cat", "dog"]
                    placeholder={t('petTypeName', { context: petType })}
                    maxLength={255}
                    required
                    aria-describedby={`${petNameId}-message`}
                  />
                  <Form.Control.Feedback type="invalid" id={`${petNameId}-message`}>
                    {petsError && petsError?.name && petsError?.name.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId={`pets-${index}-birthDate`}>
                  <Form.Label className={requiredClass}>{t('Birthdate')}</Form.Label>
                  <Form.Control
                    {...register(petBirthDateId)}
                    type="date"
                    isInvalid={petsError && !!petsError?.birthDate}
                    aria-invalid={petsError && !!petsError?.birthDate}
                    placeholder="mm/dd/yyyy"
                    required
                    aria-describedby={`${petBirthDateId}-message`}
                  />
                  <Form.Control.Feedback type="invalid" id={`${petBirthDateId}-message`}>
                    {petsError && petsError?.birthDate && petsError?.birthDate.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={2} className="d-inline-flex align-items-center">
                <Button
                  key={`${pet.id}-button`}
                  variant="outline-dark"
                  onClick={handleRemovePet}
                  className="mt-2"
                >
                  {t('Remove')}
                </Button>
              </Col>
            </Row>
            <hr aria-hidden="true" />
          </fieldset>
        );
      })}
    </>
  );
};

export default ControlledPets;
