import { styled } from '@linaria/react';
import * as React from 'react';
import { theme } from '../../theme/theme';

export interface IAlertProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: 'success' | 'warning' | 'error' | 'info';
}

const StyledAlert: StyledComponent<IAlertProps> = styled.div<IAlertProps>`
  background-color: ${({ variant }) => theme.common[variant].light};
  border-radius: ${theme.common.shape.rounded1};
  color: ${({ variant }) => theme.common[variant].dark};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: ${theme.common.spacing[1]};

  a {
    color: ${({ variant }) => theme.common[variant].dark};
  }
`;

const Alert = React.forwardRef<HTMLDivElement, IAlertProps>((props: IAlertProps, ref) => {
  const { children, className, variant, ...rest } = props;
  const dataType = {
    success: 'status',
    info: 'status',
    warning: 'alert',
    error: 'alert',
  } as const;

  return (
    <StyledAlert ref={ref} variant={variant} role={props.role ?? dataType[variant]}  className={className} {...rest}>
      {children}
    </StyledAlert>
  );
});

export default Alert;
