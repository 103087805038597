import Icon from '@mdi/react';
import AppStoreBanner from 'gatsby-plugin-app-banner/AppStoreBanner';
import { StaticImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFlags } from 'gatsby-plugin-launchdarkly';

import { theme } from 'src/theme/theme';
import Link from '../Link';
import { mdiClose } from 'src/assets/icons/mdiIcons';

const APP_STORE_ID = '1658539065';
const PLAY_STORE_ID = 'com.nestle.us.purina.fellowshipapp';

const StyledBanner = styled.section`
  display: flex;
  padding: 1rem;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  line-height: 1.125rem;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
`;

const AppStoreButton = styled(Link)`
  all: unset;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-flex;
  padding: 0.25rem 1rem;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  transition: all 255ms cubic-bezier(0.29, 0.19, 0.405, 1);

  text-transform: uppercase;
  border-radius: 2.5rem;
  background-color: #0071e3;
  color: #fff;
  font-size: 14px;
  font-family:
    system-ui,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    'Open Sans',
    'Helvetica Neue',
    sans-serif;
  justify-self: right;
  margin-left: auto;
  align-self: flex-end;
  text-align: right;

  &:focus-visible {
    box-shadow:
      0 0 0 3px #fff,
      0 0 0 5px #0071e3;
    outline: none;
  }
  &:hover,
  &:focus {
    background: #0077ed;
    color: #fff;
    border-color: transparent;
  }
`;

const DismissButton = styled.button`
  padding: 0.25rem 0.625rem;
  border: none;
  background-color: transparent;
`;

const StyledLabel = styled.span`
  font-size: 12px;
  color: ${theme.common.gray[500]};
  text-transform: capitalize;
`;

const sendIt = (flagValue: boolean) => {
  window.purData = window.purData || [];
  window.purData.push(
    {
      event: 'launch_darkly',
      event_params: {
        feature_name: 'smartAppBannerCopy',
        index: flagValue ? 1 : 0,
      },
    },
    { event_params: undefined },
  );
};

const SmartAppBanner: React.FC = () => {
  const { t } = useTranslation();
  const { smartAppBannerCopy } = useFlags();

  return (
    <AppStoreBanner
      appStoreId={APP_STORE_ID}
      playStoreId={PLAY_STORE_ID}
      dismissDuration={3 * 60000} // 3 minutes in milliseconds
    >
      {(platform, _viewAppStore, handleDismiss) => (
        <StyledBanner
          aria-label="myPurina app banner"
          className="bg-white border-top align-items-center pds-w-100 pds-absolute pds-left-0 pds-right-0"
        >
          <div className="d-flex align-items-center">
            <DismissButton type="button" aria-label={t('Close')} onClick={handleDismiss}>
              <Icon path={mdiClose} size={0.6} color={theme.common.gray[500]} />
            </DismissButton>
            <StaticImage
              src="../../assets/media/images/mypurina-app-logo.jpg"
              alt="myPurina app logo"
              height={60}
              width={60}
              loading="eager"
            />
            <div className="ms-2">
              <span className="">myPurina - Pet</span>
              <p className="mb-0">Food Rewards</p>
              <StyledLabel>{smartAppBannerCopy}</StyledLabel>
            </div>
          </div>

          <AnalyticsPoint
            type="component"
            category="banner click"
            action="mypurina app"
            typeLabel="[not set]"
            label="[not set]"
          >
            <AppStoreButton
              to="https://mypurina.page.link/gnDq"
              aria-label={
                platform === 'android' || platform === 'ios'
                  ? t('Get MyPurina App')
                  : t('Learn About MyPurina')
              }
              className="js-track"
            >
              {t('Get')}
            </AppStoreButton>
          </AnalyticsPoint>
        </StyledBanner>
      )}
    </AppStoreBanner>
  );
};

export default SmartAppBanner;
