/* eslint no-bitwise: 0 */
import { common } from './themes/common';
import { dark } from './themes/dark';
import { light } from './themes/light';
import { neutral } from './themes/neutral';
import { IThemeMain } from './themes/types';

const media = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

export const theme: IThemeMain = {
  dark,
  light,
  neutral,
  common,
  media,
};

// TODO: example of theme object use delete once implemented
// export const TestThemeExplicitComponent = styled.div`
//   width: 100px;
//   height: 100px;
//   background: ${theme.dark.primary.main};
// `;

// interface ITestPassthroug {
//   cmsTheme: CMS_THEME;
// }

// export const TestThemeFromCMSComponent = styled.div<ITestPassthroug>`
//   width: 100px;
//   height: 100px;
//   background: ${({ cmsTheme }) => theme[cmsTheme].primary.main};
// `;

const baseFontSize = 16;

export const remCalc = (pixelSize: number): string => {
  return `${pixelSize / baseFontSize}rem`;
};

export const colorModify = (color: string, amount: number) => {
  const num = parseInt(color.replace('#', ''), 16);
  let r = (num >> 16) + amount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  let b = ((num >> 8) & 0x00ff) + amount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  let g = (num & 0x0000ff) + amount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
};
