import { pdsTv } from '@purinanbm/pds-ui';
import { Link } from 'gatsby';
import React, { FC, ReactElement } from 'react';

export interface Props {
  url: string;
  name: string;
  className?: string;
  onClick?: () => void;
}

const categoryTag = pdsTv({
  base: [
    'pds-bg-surface-foreground',
    'pds-rounded',
    'pds-text-body-md',
    'pds-font-medium',
    'pds-tracking-widest',
    'pds-text-left',
    'pds-px-4',
    'pds-py-2',
    'pds-w-fit',
    'pds-cursor-pointer',
    'pds-no-underline',
    'pds-text-paper-text',
    'pds-uppercase',
    'pds-max-w-full',
  ],
});

export const CategoryTag: FC<Props> = ({ url, name, className = '', onClick }) => (
  <Link to={url} className={categoryTag({ class: className })} onClick={onClick}>
    <span dangerouslySetInnerHTML={{ __html: name }} />
  </Link>
);
