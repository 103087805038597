import Icon from '@mdi/react';
import { UserResponse } from '@purinanbm/pup-script';
import React from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { mdiEdit } from 'src/assets/icons/mdiIcons';
import { PROFILE_CONFIG } from 'src/common/constants';
import Link from 'src/components/Link';
import { User } from 'src/hooks/useAuth';

const UserInfo: React.FC<{
  profile: UserResponse | undefined;
  user: User | undefined;
  isLoadingSst: boolean;
}> = ({ profile, isLoadingSst, user }) => {
  const { t } = useTranslation();
  return (
    <div className="pds-mb-2 pds-mt-4 pds-flex pds-flex-col pds-gap-4">
      <div className="pds-flex pds-items-center pds-justify-between">
        <h2 className="pds-text-title-md">{t('My Information')}</h2>
        <span className="pds-sr-only">{t('My Information')}</span>
      </div>
      <div className="pds-flex pds-flex-col pds-space-y-2">
        <span className="pds-text-body-lg pds-font-light">{user?.fullName}</span>
        <span className="pds-text-body-lg pds-font-light">{user?.email}</span>
        {isLoadingSst && <Spinner />}
        {!isLoadingSst && profile?.data?.address?.postalCode && (
          <span className="pds-text-body-lg pds-font-light">
            {profile?.data?.address?.postalCode}
          </span>
        )}
      </div>
      <Link
        to={`${PROFILE_CONFIG.pup.url}/account?origin=${window.location.href}`}
        className="pds-flex pds-items-center pds-font-semibold pds-text-surface-text pds-underline-offset-2"
      >
        <div className="pds-mr-4 pds-flex pds-h-[45px] pds-w-[45px] pds-items-center pds-justify-center pds-rounded-circle pds-bg-surface-text">
          <Icon path={mdiEdit} className="pds-text-surface" size={1} />
        </div>
        <span className="pds-text-surface-text">{t('Edit My Information')}</span>
      </Link>
    </div>
  );
};

export default UserInfo;
