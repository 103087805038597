import React from 'react';
import RewardCatalogListSkeleton from 'src/components/purina_perks/reward_catalog/RewardCatalogListSkeleton';
import RewardCatalogGate from './RewardCatalogGate';
import RewardCatalogList from './RewardCatalogList';
import RewardError from './RewardError';
import { useRewardsCatalog } from './useRewardsCatalog';

const RewardCatalogContent: React.FC = () => {
  const { isLoading, hasErrored, rewardItems, userPoints } = useRewardsCatalog();

  if (isLoading) {
    return <RewardCatalogListSkeleton />;
  }

  if (hasErrored) {
    return <RewardError />;
  }

  return <RewardCatalogList rewards={rewardItems} userLoyaltyPoints={userPoints} />;
};

const RewardCatalog = () => {
  return (
    <div className="pds-rounded-[24px] pds-border pds-border-solid pds-border-surface-line pds-bg-surface pds-px-4.5 pds-pt-4.5 md-bt:pds-px-5 md-bt:pds-pt-5">
      <RewardCatalogGate>
        <RewardCatalogContent />
      </RewardCatalogGate>
    </div>
  );
};

export default RewardCatalog;
