/* eslint-disable react/no-danger */
import { pdsTv } from '@purinanbm/pds-ui';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import Card from 'react-bootstrap/Card';
import Stack from 'react-bootstrap/Stack';
import { useTranslation } from 'react-i18next';
import { themeStyles } from 'src/theme/basic-styles';
import { CMS_THEME } from '../common/enums';
import Link from '../components/Link';
import { Button } from '../components/button/Button';
import Filmstrip from '../components/filmstrip/Filmstrip';
import RichText from '../components/rich_text/RichText';
import Typography from '../components/typography/Typography';
import { theme } from '../theme/theme';
import { getMedia } from '../utils/mediaHelpers';

const styles = pdsTv({
  slots: {
    grid: 'pds-grid pds-grid-cols-2 pds-place-items-center pds-gap-4 lg:pds-grid-cols-4 [&_a]:pds-no-underline',
    carousel: '[&_a]:pds-no-underline',
    stack: [
      'pds-gap-[3rem] pds-py-[3rem] pds-pl-4 [@media(min-width:450px)]:pds-pr-4',
      // bootstrap classes
      'container-xxl',
    ],
    card: 'pds-flex !pds-h-[14.125rem] !pds-w-full pds-content-center pds-items-center pds-rounded-xl !pds-border-[0] !pds-p-4',
  },
  variants: {
    theme: {
      light: {
        grid: 'pds-text-paper-text [&_a]:pds-text-paper-text',
        carousel: '[&_a]:pds-text-paper-text',
        card: '!pds-bg-paper !pds-text-paper-text',
      },
      dark: {
        grid: 'pds-text-white [&_a]:pds-text-white',
        carousel: '[&_a]:pds-text-white',
        card: '!pds-bg-paper-700 !pds-text-white',
      },
      neutral: {
        grid: 'pds-text-paper-text [&_a]:pds-text-paper-text',
        carousel: '[&_a]:pds-text-paper-text',
        card: '!pds-bg-white !pds-text-paper-text',
      },
    },
    textAlignment: {
      left: {
        stack: '',
      },
      center: {
        stack: 'pds-text-center',
      },
      right: {
        stack: 'pds-text-right',
      },
    },
  },
  defaultVariants: {
    theme: 'light',
    textAlignment: 'center',
  },
});

const BrandCardItem = (
  el: IStorageBrandCard & { mode?: string; cmsTheme: CMS_THEME },
): JSX.Element => {
  const { card } = styles({ theme: el.cmsTheme });
  const handleGA = () => {
    sendIt({
      event: 'module_interaction',
      eventParams: {
        component_id: `primary_cta: ${el.link.title}`,
        component_type: 'brand_card',
        content_name: el.title,
      },
    });
  };
  return (
    <Stack
      style={{
        color: theme[el.cmsTheme].text.default,
      }}
      className="pds-grid pds-w-full pds-min-w-[15rem] pds-max-w-[19.5rem]"
      gap={3}
    >
      <Link
        className="pds-flex pds-flex-col pds-justify-center [&_span]:pds-mt-[20px]"
        id={el.component_id}
        to={el.link.url}
        onClick={handleGA}
        analytics={false}
        {...el.link.options?.attributes}
      >
        <Card className={card()}>
          {el.relationships?.field_media &&
            getMedia({
              media: el.relationships?.field_media,
              objectFit: 'contain',
              style: { width: '100%', height: '100%' },
            })}
        </Card>
        <Typography className="fs-5 pds-text-center pds-font-light pds-no-underline">
          {el.text}
        </Typography>
      </Link>
    </Stack>
  );
};

type Props = {
  node: IStorageBrandGrid;
};

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    text_align: 'left',
    load_more: false,
    theme: CMS_THEME.DARK,
  },
};

const StorageBrandGrid = ({ node: brandGrid }: Props) => {
  const breakpoint = useBreakpoint();
  const [showAll, setShowAll] = React.useState(false);
  const settings = Object.assign(defaultSettings, brandGrid?.behaviors || {});
  const cmsTheme: CMS_THEME = settings.entity_variant.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const textAlign = settings.entity_variant.text_align;
  const {
    relationships: { cards },
  } = brandGrid;
  const { t } = useTranslation();

  const condensed = settings.entity_variant.load_more && cards.length > 4;
  const isMobile = breakpoint.xs;
  const renderCardItem = (element: IStorageBrandCard) => (
    <BrandCardItem {...element} cmsTheme={cmsTheme} key={element.id} mode={cmsTheme} />
  );
  const firstFourBrandElements = condensed ? cards.slice(0, 4) : cards;
  const restBrandElements = condensed && showAll ? cards.slice(4) : [];

  // If all cards are draft, render nothing.
  if (!cards || cards.length === 0) {
    return null;
  }

  const { grid, carousel, stack } = styles({ theme: cmsTheme, textAlignment: textAlign });
  return (
    <Stack className={themeStyles({ theme: cmsTheme })} data-id={brandGrid.drupal_id}>
      <Stack className={stack()}>
        <div>
          {brandGrid?.title && (
            <HeadingElement className={headingStyle}>{brandGrid.title}</HeadingElement>
          )}
          {brandGrid?.body?.processed && (
            <RichText cmsTheme={cmsTheme} className="pds-mt-4.5" body={brandGrid.body.processed} />
          )}
        </div>
        {!isMobile && (
          <div className="pds-flex pds-flex-col pds-gap-[3rem]">
            <div className={grid()} key="brand-grid-desktop">
              {[...firstFourBrandElements, ...restBrandElements].map(renderCardItem)}
            </div>
            {condensed && (
              <div className="pds-flex pds-justify-center [&_button]:pds-w-full">
                <Button
                  cmsTheme={cmsTheme}
                  shape="square"
                  className="pds-max-w-[453px] pds-justify-center pds-self-center pds-text-center pds-capitalize"
                  onClick={() => setShowAll(prevState => !prevState)}
                >
                  {showAll ? t('view less') : t('view all')}
                </Button>
              </div>
            )}
          </div>
        )}
        {isMobile && (
          <div
            key="brand-grid-mobile"
            role="region"
            aria-label="Subcategories"
            className="pds-flex"
          >
            <Filmstrip className={carousel({ className: 'fs-no-offset' })}>
              {cards.map(renderCardItem)}
            </Filmstrip>
          </div>
        )}
      </Stack>
    </Stack>
  );
};

export default StorageBrandGrid;
