import Axios from 'axios';
import { format } from 'date-fns';
import { OfferFormTypes } from 'src/components/forms/utils/offer';

const drupal = Axios.create({
  baseURL: `${process.env.GATSBY_DRUPAL_ENDPOINT}`,
});

interface AnsiraPetType {
  petType: {
    keyName: 'CAT' | 'DOG';
  };
  name: string;
  birthDate: string;
}

interface AnsiraBaseData {
  referrer: string;
  optIn: boolean;
  firstName: string;
  lastName: string;
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  postalCode: string;
  dogCount: string;
  catCount: string;
  RecaptchaToken: string;
}

export interface AnsiraContactUs {
  Email: string;
  Message: string;
  ContactViaEmail: number;
  ContactViaPhone: number;
  Phone: string;
  UPCCode: string;
  ManufacturerCode: string;
  FirstName: string;
  LastName: string;
  NumberOfDogs: number;
  NumberOfCats: number;
  Address1: string;
  Address2: string;
  City: string;
  State: string;
  Zip: string;
  PurinaOptin: number;
  PhotoAttached?: number;
  Terms: number;
  ContactReason: string;
  ReasonSpecifications: string;
  UserExperience?: string;
  brand: string;
  sourceCode: string;
  Attachment: string;
  AttachmentContentType: string;
  AttachmentData: string;
  RecaptchaToken: string;
}

export interface AnsiraData extends AnsiraBaseData {
  [key: string]: string | boolean;
}
export interface AnsiraOfferProps {
  data: OfferFormTypes & { RecaptchaToken: string };
  offerId: number;
}

export interface AnsiraUserProps {
  petOwnershipPlan: {
    keyName: string;
  };
  address: {
    postalCode: string;
  };
  optIn: boolean;
  sourceCode: {
    keyName: string;
  };
  firstName: string;
  lastName: string;
  email: string;
  pets: AnsiraPetType[];
  RecaptchaToken: string;
}

export interface PetType {
  name: string;
  key_name: string;
}

export interface Referrer {
  code: string;
}

export interface CouponRequest {
  id: number;
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  address1?: any;
  address2?: any;
  city?: any;
  state?: any;
  postal_code: number;
  phone?: any;
  opt_in: boolean;
  created_at: Date;
  pet_type: AnsiraPetType;
  purpose?: any;
  referrer: Referrer;
}

export interface User {
  id: number;
  uuid: string;
}

export interface AnsiraResponse {
  response: {
    coupon_request?: CouponRequest;
    remaining?: number;
    quantity?: number;
    offer_status?: string;
    email_uri?: any;
    profiles_user_id?: number;
    user?: User;
    error_code?: string;
    message?: string;
    referrer?: string;
    success: boolean;
  };
  status: number;
}

interface Pet {
  name: string;
  gender: string;
  pet_type: {
    keyName: 'DOG' | 'CAT';
  };
  birthDate: string;
}

interface AnsiraRequest {
  firstName: string;
  lastName: string;
  optIn: boolean;
  email: string;
  catCount: number;
  dogCount: number;
  referrer: string;
  pets?: Pet[] | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode: string | null;
  RecaptchaToken: string;
}

const mapPetType =
  (keyName: 'DOG' | 'CAT') =>
  ({ birthDate, ...pet }: OfferFormTypes['dogPets'][number]) => {
    return {
      ...pet,
      birthDate: format(new Date(birthDate), 'yyyy-MM-dd'),
      pet_type: { keyName },
    };
  };

export const createAnsiraRequest = (
  data: OfferFormTypes & { RecaptchaToken: string },
): AnsiraRequest => {
  const pets: Pet[] = [
    ...(data.dogPets || []).filter(Boolean).map(mapPetType('DOG')),
    ...(data.catPets || []).filter(Boolean).map(mapPetType('CAT')),
  ];

  if (!pets[0]?.name) {
    return {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      catCount: Number(data.catCount),
      dogCount: Number(data.dogCount),
      postalCode: data.postalCode || null,
      optIn: data.optIn,
      referrer: data.referrer ?? '',
      RecaptchaToken: data.RecaptchaToken,
    };
  }

  return {
    firstName: data.firstName,
    lastName: data.lastName,
    optIn: data.optIn,
    email: data.email,
    catCount: Number(data.catCount),
    dogCount: Number(data.dogCount),
    referrer: data.referrer ?? '',
    pets: pets[0].name ? pets : null,
    address1: data.address1 || null,
    address2: data.address2 || null,
    city: data.city || null,
    state: data.state || null,
    postalCode: data.postalCode || null,
    RecaptchaToken: data.RecaptchaToken,
  };
};

export const ansiraOfferPost = async ({ data, offerId }: AnsiraOfferProps) => {
  const transformedData = createAnsiraRequest(data);
  try {
    const response: AnsiraResponse = (
      await drupal.post('api/ansira/offer', transformedData, {
        params: {
          offerId,
          _format: 'json',
        },
      })
    ).data;

    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const ansiraUserPost = async ({ data }: { data: AnsiraUserProps }) => {
  let user = null;
  try {
    const response = await drupal.post('api/ansira/user', data, {
      params: {
        _format: 'json',
      },
    });
    if (response.status === 200) {
      user = response.data;
    }
  } catch (error) {
    return error;
  }
  return user;
};

export const ansiraContactUsPost = async (data: AnsiraContactUs) => {
  let result = null;
  try {
    const response = await drupal.post('api/ansira/contactus', data, {
      params: {
        _format: 'json',
      },
    });
    if (response.status === 200) {
      result = response.data as AnsiraResponse;
    }
  } catch (error) {
    console.error(error);
  }
  return result;
};
