import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Filmstrip from 'src/components/filmstrip/Filmstrip';
import ProductCard from 'src/components/product_card/ProductCard';
import { formatBazaarVoiceId, formatSku } from 'src/utils/helpers';
import { NodeProductProps } from '../types';

interface Props {
  relatedProducts: {
    edges: [
      {
        node: NodeProductProps;
      }
    ];
  };
}

const RelatedProducts: React.FC<Props> = ({ relatedProducts }) => {
  const { t } = useTranslation();

  return (
    <AnalyticsPoint
      as={Container}
      className="py-5"
      type="module"
      typeLabel="product_related_products"
      label="related products"
    >
      <Row>
        <Col>
          <h2 className="h4 mb-3 text-capitalize">{t('Discover similar products')}</h2>
        </Col>
      </Row>
      <Filmstrip className="pb-4">
        {relatedProducts.edges.map(product => {
          const { node: productNode } = product;
          const productSkus = productNode?.relationships?.skus;
          const productSku = productSkus && productSkus.length > 0 ? productSkus[0].upc : '';
          const bvID = formatBazaarVoiceId(productNode?.field_bazaarvoice_id);
          const relatedCount = relatedProducts.edges.length;
          const extraClasses = [];
          const url = productNode.field_alternate_url?.uri
            ? productNode.field_alternate_url.uri
            : productNode.path.alias;

          if (relatedCount > 1) {
            extraClasses.push('h-scroll-sm h-scroll-md-4');
            if (relatedCount > 4) {
              extraClasses.push('h-scroll-lg-3');
            }
          }

          return (
            <div key={productNode.path.alias} className={`pds-h-full ${extraClasses.join(' ')} pds-flex pds-flex-row`}>
              <AnalyticsPoint
                type="component"
                node={{
                  ...productNode,
                  name: productNode.title,
                  type: productNode.internal?.type || '',
                }}
                action="view similar products"
                category="product details"
                eventLabel={productNode.title}
                typeLabel="product_related_product"
                className="pds-h-full"
              >
                <ProductCard
                  type="products"
                  title={productNode.title}
                  image={productNode?.relationships?.image?.gatsbyImage}
                  link={url}
                  bvID={bvID}
                  sku={(productSku && formatSku(productSku)) || ''}
                />
              </AnalyticsPoint>
            </div>
          );
        })}
      </Filmstrip>
    </AnalyticsPoint>
  );
};

export default RelatedProducts;
