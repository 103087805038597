import { useEffect, useState } from 'react';

export type AppBannerPlatform = 'ios' | 'android' | null;

interface AppStoreBannerState {
  showCustomBanner: Boolean;
  platform: AppBannerPlatform;
}

const useAppStoreBanner = ({ alwaysCustom }: { alwaysCustom: boolean }): AppStoreBannerState => {
  const [appBanner, setAppBanner] = useState<AppStoreBannerState>({
    showCustomBanner: false,
    platform: null,
  });

  useEffect(() => {
    const isiOS = () => {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    };

    const isAndroid = () => {
      return /Android/.test(navigator.userAgent);
    };

    const supportsNativeBanner = () => {
      // Check if the browser is Safari on iOS (which generally supports native app banners).
      const isSafariOniOS =
        isiOS() && /Safari/.test(navigator.userAgent) && !/CriOS/.test(navigator.userAgent);

      return isSafariOniOS;
    };

    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    let platform: AppBannerPlatform = null;
    if (isiOS()) {
      platform = 'ios';
    } else if (isAndroid()) {
      platform = 'android';
    }

    if (!isMobile) {
      setAppBanner({ showCustomBanner: false, platform: null });
      return;
    }

    if (supportsNativeBanner()) {
      setAppBanner({
        showCustomBanner: alwaysCustom,
        platform,
      });
    } else {
      setAppBanner({
        showCustomBanner: true,
        platform,
      });
    }
  }, [alwaysCustom]);

  return appBanner;
};

export default useAppStoreBanner;
