import React from 'react';
import ContentLoader from 'react-content-loader';

import ProductCardSkeleton from '../../page_card/PageCardSkeleton';

const RewardCatalogListSkeleton: React.FC = () => {
  return (
    <>
      <span className="pds-block pds-pl-4 pds-pt-4 lg:pds-p-4.5">
        <ContentLoader
          speed={1.5}
          width={160}
          height={37}
          viewBox="0 0 160 37"
          backgroundColor="#EDF0F8"
          foregroundColor="#e2e7f3"
        >
          <rect x="25" y="15" width="220" height="10" />
        </ContentLoader>
      </span>
      <h2 className="pds-mb-4 pds-pl-4 pds-pt-4 pds-text-left pds-text-title-md pds-font-semibold lg:pds-pt-4 lg:pds-text-center">
        Redeem Rewards
      </h2>
      <div className="pds-grid pds-grid-cols-2 pds-place-items-center pds-gap-4 pds-px-5 pds-pb-5 md:pds-grid-cols-3 lg:pds-grid-cols-4 lg:pds-px-6">
        {Array.from({ length: 6 }).map((_, index) => (
          <ProductCardSkeleton key={index} />
        ))}
      </div>
    </>
  );
};

export default RewardCatalogListSkeleton;
