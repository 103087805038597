/* eslint-disable complexity */
import Icon from '@mdi/react';
import { pdsTv } from '@purinanbm/pds-ui';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { themeVarient, themeVarientText } from 'src/theme/basic-styles';
import { CMS_THEME } from '../../common/enums';
import { StarEmpty, StarFilled } from '../../theme/icons';
import { ButtonLink } from '../button/Button';
import PricespiderWidget from '../pricespider/PricespiderWidget';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  cmsTheme: CMS_THEME;
  title: string;
  review: string;
  author: string;
  rating: number;
  alignment?: 'left' | 'right';
  isVertical: boolean;
  imgComponent?: React.ReactNode;
  imgCover: boolean;
  sku?: string;
  reviewLink?: string;
  product: IStorageReview['relationships']['product'][0];
}

const RatingStars = ({ rating }: { rating: number }) => {
  const stars = [];

  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      stars.push(<Icon key={i} path={StarFilled} size={1.5} style={{ color: '#E7BF2E' }} />);
    } else {
      stars.push(
        <Icon
          key={i}
          path={StarEmpty}
          size={1.5}
          style={{ stroke: '#E4E9F4', color: '#E4E9F4' }}
        />,
      );
    }
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{stars}</>;
};

const imageContainer = pdsTv({
  base: 'pds-flex pds-h-auto pds-w-[100%]  pds-justify-center pds-overflow-hidden pds-rounded-xl md:pds-h-[318px] 2xl:pds-min-w-[636px]',
  variants: {
    theme: themeVarient,
    direction: {
      vertical: 'pds-w-full pds-min-w-full pds-rounded-bl-none pds-rounded-br-none',
      horizontal: 'pds-min-w-[473px] pds-rounded-bl-xl pds-rounded-br-xl',
    },
    addPadding: {
      true: 'pds-p-4',
    },
    align: {
      left: 'pds-flex-row-reverse',
      right: '',
      center: '',
    },
  },
  compoundVariants: [
    {
      direction: 'vertical',
      align: 'left',
      class: 'pds-flex-col',
    },
    {
      direction: 'vertical',
      align: 'right',
      class: 'pds-flex-col',
    },
  ],
});

const card = pdsTv({
  base: 'vstack rounded-bottom',
  variants: {
    theme: themeVarient,
    direction: {
      horizontal: 'pds-flex-grow-0 pds-bg-transparent',
      vertical: 'pds-flex-grow pds-p-4 sm:pds-p-5',
    },
    textCenter: {
      true: 'pds-text-center',
    },
  },
  compoundVariants: [
    {
      direction: 'vertical',
      theme: 'light',
      class: 'pds-bg-paper',
    },
    {
      direction: 'vertical',
      theme: 'dark',
      class: 'pds-bg-[#464646]',
    },
  ],
});

const wrapper = pdsTv({
  base: 'pds-flex pds-h-full',
  variants: {
    theme: themeVarientText,
    direction: {
      vertical: 'pds-flex-col',
      horizontal: 'pds-flex-row pds-items-center pds-gap-[3rem]',
    },
  },
});

const buttonWrapper = pdsTv({
  base: 'pds-mt-auto pds-flex pds-flex-col pds-gap-4 md:pds-flex-row',
  variants: {
    buttonWidth: {
      full: '[&>*]:pds-flex-grow [&>*]:pds-basis-[45%]',
      auto: '',
    },
  },
});

const ProductReview = ({
  cmsTheme = CMS_THEME.LIGHT,
  title,
  review,
  author,
  rating,
  alignment = 'left',
  isVertical = false,
  imgComponent,
  imgCover = false,
  sku,
  reviewLink,
  product,
  ...props
}: Props) => {
  const breakpoint = useBreakpoint();
  const isMobile = !breakpoint.lg;
  const starRating = Math.min(Math.max(rating, 0), 5);
  const direction = isVertical || isMobile ? 'vertical' : 'horizontal';
  const contentAlignment = !imgComponent || isVertical ? 'center' : alignment;
  const { t } = useTranslation();

  return (
    <div {...props} className={wrapper({ theme: cmsTheme, direction, className: props.className })}>
      {imgComponent && (
        <div
          className={imageContainer({
            theme: cmsTheme,
            direction,
            addPadding: imgCover,
            align: contentAlignment,
          })}
        >
          {imgComponent}
        </div>
      )}
      <figure className={card({ theme: cmsTheme, direction, textCenter: Boolean(!imgComponent) })}>
        <div>
          <RatingStars rating={starRating} />
          &nbsp;
          <span aria-hidden className="text-nowrap">
            {t('{{starRating}} out of 5 stars', { starRating })}
          </span>
        </div>
        <span className="typography_h3 pds-my-4">
          {title}
          <span className="sr-only">{t(': {{starRating}} out of 5', { starRating })}</span>
        </span>
        <q
          className="typography_body pds-my-4 !pds-font-extralight"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: review }}
        />
        <figcaption className={`typography_body ${sku || reviewLink ? 'mb-4' : null}`}>
          &mdash;&nbsp;{author}
        </figcaption>
        {(sku || reviewLink) && (
          <div
            className={buttonWrapper({
              buttonWidth: (sku && reviewLink) || direction === 'vertical' ? 'full' : 'auto',
            })}
          >
            {sku && (
              <div>
                <div id={sku} hidden>
                  {product.title}
                </div>

                <PricespiderWidget title={title} sku={sku} cmsTheme={cmsTheme} />
              </div>
            )}
            {Boolean(reviewLink) && (
              <AnalyticsPoint type="component" typeLabel="product_review" label="read more reviews">
                <ButtonLink
                  className="!pds-w-full"
                  variant="btn-outline"
                  to={`${reviewLink}#reviews`}
                  cmsTheme={cmsTheme}
                >
                  {t('Read more reviews')}
                  <span className="pds-sr-only">
                    {t('About')} {product.title}
                  </span>
                </ButtonLink>
              </AnalyticsPoint>
            )}
          </div>
        )}
      </figure>
    </div>
  );
};

export default ProductReview;
