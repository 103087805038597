import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import ProfileForm from 'src/components/forms/ProfileForm';

interface StorageAnsiraProfileFormProps {
  node: IStorageAnsiraProfileForm;
}

const StorageAnsiraProfileForm: React.FC<StorageAnsiraProfileFormProps> = function ({
  node: ansiraProfileForm,
}) {
  const { t } = useTranslation();
  const title = ansiraProfileForm.title || '';
  const body = ansiraProfileForm.body?.processed || '';
  return (
    <Container id={ansiraProfileForm.component_id} data-id={ansiraProfileForm.drupal_id} className="py-5">
      <Row className="justify-content-md-center">
        <Col md={8}>
          <h2 className="text-center">{title}</h2>
          {/* eslint-disable-next-line react/no-danger */}
          {body && <div className="text-center mb-3" dangerouslySetInnerHTML={{ __html: body }} />}
          <div className="mb-3">
            <span className="text-danger">*{t('Required Field')}</span>
          </div>
          <ProfileForm
            indexOfModule={ansiraProfileForm.indexOnPage}
            sourceCode={ansiraProfileForm.source_code}
            settings={ansiraProfileForm.settings}
            language={ansiraProfileForm.langcode}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StorageAnsiraProfileForm;
