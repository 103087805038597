import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { getAddressStates } from '../../../../utils/formHelpers';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../../ContactUsForm';
import { contactReasonAnswers, moreSpecificReasonAnswers } from '../../utils/contactUs';
// eslint-disable-next-line import/no-cycle
import InputField, { requiredClass } from '../InputField';

const PersonalInformationSection: React.FC = () => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext<IContactUsFormData>();

  const { t } = useTranslation();
  const stateOptions = Object.entries(getAddressStates());

  const contactReason = watch('contactReason') as
    | typeof contactReasonAnswers['COMMENT']
    | typeof contactReasonAnswers['COMPLAINT']
    | typeof contactReasonAnswers['QUESTION'];

  const specificReason = watch('moreSpecificReason');
  // conditions for conditionally rendering 3 fields about upc code
  const showSpecificQuestionsForComplaint =
    contactReason === contactReasonAnswers.COMPLAINT &&
    specificReason === moreSpecificReasonAnswers?.Complaint?.PRODUCT_PACKAGING?.value;

  // when complaint is selected and either product packaging or e-commerce is selected every field that can be required is required

  const selectedEcommerce =
    specificReason === moreSpecificReasonAnswers?.Complaint?.ECOMMERSE?.value;

  // when complaint is selected and either product packaging or e-commerce is selected every field that can be required is required
  const standardRequired = showSpecificQuestionsForComplaint || selectedEcommerce;
  const zipSpecialRequired =
    specificReason === moreSpecificReasonAnswers?.Question?.WHERE_TO_BUY?.value || standardRequired;

  const nameRequired =
    showSpecificQuestionsForComplaint ||
    (contactReason === contactReasonAnswers.COMPLAINT && selectedEcommerce);

  return (
    <>
      <Col xs={12} md={6}>
        <InputField
          name="firstName"
          autoComplete="given-name"
          label={t('First Name')}
          required={nameRequired}
        />
      </Col>
      <Col xs={12} md={6}>
        <InputField
          name="lastName"
          autoComplete="family-name"
          label={t('Last Name')}
          required={nameRequired}
        />
      </Col>
      <Col md={12}>
        <InputField
          name="address1"
          autoComplete="address-line1"
          label={t('Address 1')}
          required={standardRequired}
        />
      </Col>
      <Col md={12}>
        <InputField name="address2" autoComplete="address-line2" label={t('Address 2')} />
      </Col>
      <Col xs={12} md={3}>
        <InputField
          name="city"
          autoComplete="address-level2"
          label={t('City')}
          required={standardRequired}
        />
      </Col>
      <Col xs={12} md={3}>
        <Form.Group controlId="state">
          <Form.Label className={standardRequired ? requiredClass : ''}>{t('State')}</Form.Label>
          <Form.Select
            {...register('state')}
            isInvalid={!!errors.state}
            aria-invalid={!!errors.state}
            autoComplete="address-level1"
            aria-describedby="state-message"
          >
            <option key="state-default" value="">
              {t('Select a state')}
            </option>
            {stateOptions.map(([abbreviation, name]) => (
              <option key={abbreviation} value={abbreviation}>
                {name}
              </option>
            ))}
          </Form.Select>
          {errors?.state && (
            <Form.Control.Feedback className="d-block" type="invalid" id="state-message">
              {errors.state?.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} md={3}>
        <InputField
          name="zip"
          placeholder={t('Enter zip (ex. 63102)')}
          label={t('Zip Code')}
          autoComplete="postal-code"
          required={zipSpecialRequired}
        />
      </Col>
      <Col md={10}>
        <InputField
          name="email"
          label={t('Email Address')}
          placeholder={t('example@mail.com')}
          autoComplete="email"
          required
        />
      </Col>
    </>
  );
};

export default PersonalInformationSection;
