import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Stack } from 'react-bootstrap';
import { CMS_THEME } from '../../common/enums';
import { getMedia } from '../../utils/mediaHelpers';
import RichText from '../rich_text/RichText';

type Props = {
  node: IStorageHighlights;
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_buttons: {
    primary_button: 'btn-link',
  },
  entity_rows_columns: {
    columns: 3,
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const HighlightList = styled.div`
  max-width: 500px;
`;

const IconItem = styled.div`
  max-width: 56px;
  max-height: 56px;
`;

// this component is a specific version of the highlights, but for the contact us page
const StorageHighlights = ({ node: highlights }: Props) => {
  const settings = Object.assign(DefaultSettings, highlights?.behaviors || {});
  const { element: HeadingElement } = settings.entity_heading;
  // @ts-ignore Always a heading element.
  const HighlightHeading: keyof JSX.IntrinsicElements =
    parseInt(HeadingElement.replace(/\D/g, ''), 10) < 5
      ? `h${parseInt(HeadingElement.replace(/\D/g, ''), 10) + 1}`
      : 'div';

  // If all highlights are draft, don't render.
  if (!highlights?.relationships?.highlights || highlights.relationships.highlights.length === 0) {
    return null;
  }

  return (
    <div>
      {highlights?.relationships?.highlights.map(highlight => {
        const media = highlight.relationships?.media;
        return (
          <HighlightList className="d-flex gap-3" key={highlight.id}>
            <IconItem className="rounded-pill">
              {media && getMedia({ media, style: { width: '56px' } })}
            </IconItem>
            <Stack gap={2}>
              {highlight?.title && (
                <HighlightHeading className={cx('typography_h5 fw-semibold')}>
                  {highlight.title}
                </HighlightHeading>
              )}
              {highlight?.body?.processed && (
                <RichText className={cx('fw-light')} body={highlight.body.processed} />
              )}
            </Stack>
          </HighlightList>
        );
      })}
    </div>
  );
};

export default StorageHighlights;
