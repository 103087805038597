import Icon from '@mdi/react';
import { Button, DropZone, FilePreview, Paper, Spinner } from '@purinanbm/pds-ui';
import React, { useEffect, useRef } from 'react';

import { mdiAddImage, mdiCheck } from 'src/assets/icons/mdiIcons';
import { MimeType } from 'src/common/enums';
import { getFocusableChildren } from 'src/utils/domHelpers';
import { getListStyleIcon } from 'src/utils/getListStyleIcon';

const listStyleIcon = getListStyleIcon(
  <Icon
    path={mdiCheck}
    style={{ color: '#ec1c24' }}
    // @ts-ignore Need to pass valid SVG attributes through to the SVG element.
    xmlns="http://www.w3.org/2000/svg"
    height={18}
    width={18}
  />,
);

type UploadReceiptInputProps = {
  isSubmitting: boolean;
  value: File[];
  allowedMimeTypes: MimeType[];
  onRemoveClick: (event: React.MouseEvent, index: number) => void;
  onDrop: (event: React.DragEvent<HTMLDivElement>) => void;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const UploadReceiptInput: React.FC<UploadReceiptInputProps> = ({
  value: files,
  onChange,
  onDrop,
  onRemoveClick,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const filePreviewContainerRef = useRef<HTMLDivElement>(null);
  const uploadImageButtonRef = useRef<HTMLButtonElement>(null);

  const fileCount = useRef(0);
  const removedFileIndex = useRef<number | null>(null);

  // Set focus in upload container when remove button is clicked
  // Need to wait for the files to be re-processed and updated before setting focus
  useEffect(() => {
    const areFilesRemoved = fileCount.current > files.length;
    const indexRemoved = removedFileIndex.current;

    fileCount.current = files.length;
    removedFileIndex.current = null;

    if (!areFilesRemoved || indexRemoved === null) return;

    const focusableFilePreviewElements = getFocusableChildren(filePreviewContainerRef.current);

    if (focusableFilePreviewElements.length > 0) {
      const lastIndex = files.length - 1;
      const indexToFocus = indexRemoved > lastIndex ? lastIndex : indexRemoved;
      focusableFilePreviewElements[indexToFocus]?.focus();
    } else {
      uploadImageButtonRef.current?.focus();
    }
  }, [files]);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
      inputRef.current.value = ''; // Allow the same file to trigger onChange event
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    onDrop?.(event);
  };

  const handleRemoveClick = (event: React.MouseEvent, index: number) => {
    onRemoveClick(event, index);
    removedFileIndex.current = index;
  };

  return (
    <>
      {/* Place the description first to ensure it is announced by SRs first */}
      <div className="pds-surface-text pds-flex-[1_0_250px]">
        <div>
          <h2 className="pds-text-body-md pds-font-semibold pds-leading-6">
            To earn points on your purchase, your receipt image(s) must clearly show:
          </h2>
          <Paper
            as="ul"
            className="pds-flex pds-flex-col pds-gap-3 pds-border-surface-layer pds-bg-surface-layer pds-py-4 pds-pl-[2.5rem] pds-pr-4 marker:pds-translate-y-[5px]"
            style={{
              listStyleImage: `url(${listStyleIcon} )`,
            }}
          >
            <li>Retailer Name</li>
            <li>Date of Purchase (valid up to 1 month)</li>
            <li>Purina Item(s) Purchased</li>
            <li>Price of Purina Item(s)</li>
          </Paper>
        </div>
        <div>
          <div className="pds-mb-4">
            Accepted file formats: <strong>png or jpg</strong>
          </div>

          <div>
            Size limit: <strong>6MB</strong>
          </div>
        </div>
      </div>

      <input
        type="file"
        className="pds-hidden"
        accept={props.allowedMimeTypes.join(', ')}
        multiple
        onChange={handleChange}
        ref={inputRef}
      />

      <div className="pds-flex pds-flex-[1_1_400px] pds-flex-col pds-items-center pds-gap-4">
        <DropZone onDrop={handleDrop}>
          {() => {
            return (
              <div className="pds-align-center pds-flex pds-w-full pds-flex-wrap pds-justify-center pds-p-2 md:pds-p-5">
                <p className="pds-max-w-[370px] pds-text-center pds-text-body-md pds-text-surface-text">
                  Choose file(s) or <b>drag here.</b> You can upload up to three images to capture
                  your entire receipt.
                </p>

                <div className="pds-flex pds-flex-col pds-gap-4">
                  <div
                    ref={filePreviewContainerRef}
                    className="pds-mt-5 pds-flex pds-flex-wrap pds-justify-center pds-gap-4"
                  >
                    {Array.from({ length: 3 }).map((_, index) => {
                      return (
                        <div
                          className="pds-aspect-[5/6] pds-h-full pds-w-[75px] md:pds-w-[90px]"
                          key={`${files[index] ? files[index].name : index}`}
                        >
                          <FilePreview
                            file={files[index]}
                            className="pds-aspect-[5/6]"
                            altText={`Receipt image ${index + 1}`}
                            close={
                              !props.isSubmitting
                                ? {
                                    onClose: event => {
                                      handleRemoveClick(event, index);
                                    },
                                    label: `Remove receipt image ${index + 1}`,
                                  }
                                : undefined
                            }
                          >
                            <Icon path={mdiAddImage} size={1} className="pds-text-primary" />
                          </FilePreview>
                        </div>
                      );
                    })}
                  </div>

                  <Button
                    ref={uploadImageButtonRef}
                    buttonColor="neutral"
                    size="medium"
                    buttonStyle="outlined"
                    width="full"
                    className="pds-max-w-[304px]"
                    onClick={handleClick}
                    disabled={props.isSubmitting}
                  >
                    Upload Image
                  </Button>
                </div>
              </div>
            );
          }}
        </DropZone>

        <Button
          aria-live="assertive"
          buttonColor="neutral"
          size="medium"
          buttonStyle="solid"
          width="full"
          className="pds-flex pds-max-w-[304px] pds-flex-row pds-justify-center pds-gap-4 pds-border-none"
          type="submit"
          disabled={props.isSubmitting}
        >
          {props.isSubmitting && (
            <Spinner
              className={props.isSubmitting ? 'pds-block' : 'pds-hidden'}
              color="currentColor"
            />
          )}
          Submit this Receipt
        </Button>
      </div>
    </>
  );
};
