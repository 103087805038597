import React from 'react';
import ContentLoader from 'react-content-loader';

import pointsBadgeSvg from 'src/assets/icons/pointsBadge.svg';
import shippingSvg from 'src/assets/icons/shipping.svg';

const RewardDetailsSkeleton: React.FC = () => {
  return (
    <>
      <div className="pds-grid pds-gap-4 md:pds-grid-cols-2 md:pds-gap-5">
        <div className="pds-rounded-xl pds-bg-[#F8F9F9] pds-p-4 md:pds-max-h-[332px] md:pds-max-w-[378px]">
          <ContentLoader viewBox="0 0 378 332">
            <rect x="0" y="0" rx="20" ry="20" width="378" height="332" />
          </ContentLoader>
        </div>
        <div className="pds-grid pds-gap-5 md:pds-max-w-[349px]">
          <div className="pds-grid pds-content-baseline pds-gap-4 pds-pt-5">
            <ContentLoader viewBox="0 0 349 120">
              <rect x="0" y="0" rx="0" ry="0" width="349" height="30" />
              <rect x="0" y="35" rx="0" ry="0" width="349" height="30" />
              <rect x="0" y="70" rx="0" ry="0" width="349" height="30" />
            </ContentLoader>
            <div className="pds-relative pds-flex pds-w-fit pds-items-center pds-gap-2 pds-self-baseline pds-rounded-pill pds-border pds-bg-surface-heavy pds-px-[0.75rem] pds-text-body-sm pds-font-semibold">
              <img src={pointsBadgeSvg} className="pds-scale-125" alt="Perks points" />
              Points
            </div>
            <div className="pds-text-body-md pds-font-semibold">
              <img
                src={shippingSvg}
                className="pds-mr-3 pds-inline-block pds-pl-3 pds-align-middle"
                alt=""
              />
              Free Shipping
            </div>
          </div>
          <ContentLoader viewBox="0 0 349 56">
            <rect x="0" y="0" rx="0" ry="0" width="349" height="56" />
          </ContentLoader>
        </div>
      </div>
      <div className="pds-grid pds-p-4.5">
        <h2 className="pds-text-body-lg pds-font-semibold">Product Description:</h2>
        <ContentLoader viewBox="0 0 349 120">
          <rect x="0" y="0" rx="0" ry="0" width="349" height="15" />
          <rect x="0" y="20" rx="0" ry="0" width="349" height="15" />
          <rect x="0" y="40" rx="0" ry="0" width="349" height="15" />
          <rect x="0" y="60" rx="0" ry="0" width="349" height="15" />
          <rect x="0" y="80" rx="0" ry="0" width="349" height="15" />
        </ContentLoader>
      </div>
    </>
  );
};

export default RewardDetailsSkeleton;
