import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  ISlide,
  SlideShow,
} from '@purinanbm/pds-ui';
import React from 'react';

import tipsImage1 from './assets/tipsImage1.png';
import tipsImage2 from './assets/tipsImage2.png';
import tipsImage3 from './assets/tipsImage3.png';

const slideShowContents: ISlide['contents'] = [
  {
    title: 'Is the retailer name visible?',
    body: 'Make sure the retailer or site name is clearly visible on at least one image.',
    image: {
      src: tipsImage1,
      alt: 'Slide 1: Snapshot of Receipt highlighting the store name',
    },
  },
  {
    title: 'When did you purchase?',
    body: 'Be sure the date of purchase is on the receipt and captured in your image (valid up to 1 month).',
    image: {
      src: tipsImage2,
      alt: 'Slide 2: Snapshot of Receipt highlighting the purchase date',
    },
  },
  {
    title: 'Purina Products & Price',
    body: 'The Purina items, their price and any item codes on the receipt need to be clearly shown in your image.',
    image: {
      src: tipsImage3,
      alt: 'Slide 3: Snapshot of Receipt highlighting the Purina product, quantity, and price',
    },
  },
];

const ReceiptUploadTips = () => {
  return (
    <div className="pds-my-4 pds-rounded-xl pds-border pds-border-solid pds-border-surface-line pds-bg-surface pds-px-4 lg:pds-px-0">
      <Accordion
        type="single"
        collapsible
        className="pds-w-full pds-rounded-xl pds-border-solid pds-border-surface-line pds-outline-none [&_h2]:pds-m-0"
        defaultValue="tips-slide"
        options={{ headingLevel: 'h2' }}
      >
        <AccordionItem value="tips-slide" className="pds-border-none">
          <AccordionTrigger
            iconSize="md"
            className="pds-rounded-xl pds-border-none pds-bg-surface !pds-no-underline lg:pds-px-4 lg:data-[state='open']:pds-mb-4"
            iconBtnClass="pds-mr-2 pds-rotate-180 [&_svg]:pds-w-[41px]"
          >
            <span className="pds-pl-4 pds-text-title-sm pds-font-semibold">
              Receipt Upload Tips
            </span>
          </AccordionTrigger>
          <AccordionContent className="pds-pb-0">
            <SlideShow contents={slideShowContents} />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ReceiptUploadTips;
