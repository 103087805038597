import { styled } from '@linaria/react';
import { IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import ProductCardBody from './ProductCardBody';

interface Props {
  drupalId?: string;
  image: IGatsbyImageData;
  link: string;
  title: string;
  sku: string;
  bvID: string;
  cmsTheme?: CMS_THEME;
  brand?: string;
  type?: 'products' | 'offers';
  onClick?: () => void;
  outlinedCta?: boolean;
  isDirect?: boolean;
  ctaText?: string;
}

const StyledProductCard = styled(Card)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].text.default};
  background: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].background.default};
  border: 0px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ProductCard: React.FC<Props> = function ({
  title,
  link,
  image,
  bvID,
  sku,
  brand,
  drupalId,
  cmsTheme = CMS_THEME.LIGHT,
  type,
  onClick = () => {},
  outlinedCta = false,
  isDirect = false,
  ctaText = undefined,
}: Props) {
  // TODO: figure out another way to have this analytics point not triggered rather then just creating a ternary.
  // There should be a way to manually skip a anlytics point
  return (
    <StyledProductCard data-id={drupalId} cmsTheme={cmsTheme}>
      <ProductCardBody
        image={image}
        link={link}
        title={title}
        sku={sku}
        bvID={bvID}
        brand={brand}
        cardType={type}
        onClick={onClick}
        cmsTheme={cmsTheme}
        outlinedCta={outlinedCta}
        isDirect={isDirect}
        ctaText={ctaText}
      />
    </StyledProductCard>
  );
};
export default ProductCard;
