import { useLocation } from '@reach/router';
import { RefObject, useEffect, useState } from 'react';

const DEFAULT_ITEMS_PER_PAGE = 10;

const calculatePageIndex = (
  queryParams: string,
  itemsLength: number,
  itemsPerPage: number,
): number => {
  const page = new URLSearchParams(queryParams).get('page');
  const pageIndex = page ? parseInt(page, 10) - 1 : 0;
  const isIndexOutOfBounds = pageIndex * itemsPerPage > itemsLength;
  return isIndexOutOfBounds ? 0 : pageIndex;
};

const sliceItems = <T,>(queryParams: string, items: T[], perPage: number) => {
  const pageIndex = calculatePageIndex(queryParams, items.length, perPage);
  return items.slice(pageIndex * perPage, (pageIndex + 1) * perPage);
};

const usePager = <T,>(
  items: T[],
  containerRef?: RefObject<HTMLElement>,
  itemsPerPage = DEFAULT_ITEMS_PER_PAGE,
) => {
  const location = useLocation();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [itemsSlice, setItemsSlice] = useState<T[]>(
    sliceItems(location.search, items, itemsPerPage),
  );

  const pager: SearchPager = {
    current_page: currentPageIndex,
    total_items: items.length,
    total_pages: Math.ceil(items.length / itemsPerPage),
    items_per_page: itemsPerPage,
    container_ref: containerRef,
  };

  useEffect(() => {
    if (items.length === 0) return;

    const pageIndex = calculatePageIndex(location.search, items.length, itemsPerPage);
    const newItemsSlice = sliceItems(location.search, items, itemsPerPage);
    setCurrentPageIndex(pageIndex);
    setItemsSlice(newItemsSlice);
  }, [location.search, items, itemsPerPage]);

  return {
    itemsSlice,
    pager,
  };
};

export default usePager;
