import React from 'react';
import { Button, Col, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RichText from '../../../rich_text/RichText';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../../ContactUsForm';
import { contactReasonAnswers, moreSpecificReasonAnswers } from '../../utils/contactUs';
// eslint-disable-next-line import/no-cycle
import InputField, { requiredClass } from '../InputField';

interface IContactReasonFormProps {
  manufacturerTooltip?: string;
  upcTooltip?: string;
}

const ContactReason: React.FC<IContactReasonFormProps> = ({ manufacturerTooltip, upcTooltip }) => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext<IContactUsFormData>();
  const { t } = useTranslation();

  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const fileName = watch('file')?.name;

  const contactReason = watch('contactReason') as
    | (typeof contactReasonAnswers)['COMMENT']
    | (typeof contactReasonAnswers)['COMPLAINT']
    | (typeof contactReasonAnswers)['QUESTION'];

  const specificReason = watch('moreSpecificReason');
  // conditions for conditionally rendering 3 fields about upc code
  const showSpecificQuestionsForComplaint =
    contactReason === contactReasonAnswers.COMPLAINT &&
    specificReason === moreSpecificReasonAnswers?.Complaint?.PRODUCT_PACKAGING?.value;

  // when complaint is selected and either product packaging or e-commerce is selected every field that can be required is required
  const requiredUPCCode = (watch('specificPurchaseReason') as unknown as string) === '1';

  const specificReasonOptions = moreSpecificReasonAnswers[contactReason];

  const handleButtonClick = () => {
    hiddenFileInput?.current?.click();
  };
  return (
    <>
      <Col xs={12} md={6}>
        <Form.Group controlId="contactReason">
          <Form.Label className={requiredClass}>{t('Select a contact reason')}</Form.Label>
          <Form.Select
            {...register('contactReason')}
            isInvalid={!!errors.contactReason}
            aria-invalid={!!errors.contactReason}
            aria-describedby="contactReason-message"
            required
          >
            <option key="cr-default" value="">
              {t('Select')}
            </option>
            <option key={contactReasonAnswers.QUESTION} value={contactReasonAnswers.QUESTION}>
              {t('Question')}
            </option>
            <option key={contactReasonAnswers.COMPLAINT} value={contactReasonAnswers.COMPLAINT}>
              {t('Complaint')}
            </option>
            <option key={contactReasonAnswers.COMMENT} value={contactReasonAnswers.COMMENT}>
              {t('Comment')}
            </option>
          </Form.Select>
          {errors.contactReason && (
            <Form.Control.Feedback type="invalid" id="contactReason-message">
              {errors.contactReason?.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group controlId="moreSpecificReason">
          <Form.Label className={requiredClass}>{t('More Specifically About')}</Form.Label>
          <Form.Select
            {...register('moreSpecificReason')}
            isInvalid={!!errors.moreSpecificReason}
            aria-invalid={!!errors.moreSpecificReason}
            aria-describedby="moreSpecificReason-message"
            required
          >
            <option key="sr-default" value="">
              {t('Select')}
            </option>
            {contactReason &&
              Object.entries(specificReasonOptions).map(([key, { label, value }]) => (
                <option key={key} value={value}>
                  {/* i18next-extract-mark-context-next-line ["Product & Packaging", "Where to Buy", "Donations", "Promotions & Special Offers", "General Questions", "Product Availability", "Ecommerce (Just Right Purina Store Only, Vet Direct, P4P)", "General Comment"] */}
                  {t('specificReasonOptionsLabels', { context: label })}
                </option>
              ))}
          </Form.Select>
          {errors.moreSpecificReason && (
            <Form.Control.Feedback type="invalid" id="moreSpecificReason-message">
              {errors.moreSpecificReason?.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col xs={12}>
        <InputField
          name="message"
          as="textarea"
          className="col-lg-12"
          placeholder={t('Enter message')}
          label={t('Enter your Message')}
          required
        />
      </Col>
      {showSpecificQuestionsForComplaint && (
        <>
          <Col xs={12}>
            <Form.Group controlId="specificPurchaseReason">
              <fieldset>
                <legend className={`form-label ${requiredClass}`}>
                  {t('Was this regarding a specific purchase?')}
                </legend>
                <div className="d-flex gap-2">
                  <Form.Check
                    {...register('specificPurchaseReason')}
                    type="radio"
                    value={1}
                    id="specificPurchaseReason-yes"
                    label={t('Yes')}
                    aria-describedby="specificPurchaseReason-message"
                  />
                  <Form.Check
                    type="radio"
                    value={0}
                    {...register('specificPurchaseReason')}
                    id="specificPurchaseReason-no"
                    label={t('No')}
                    aria-describedby="specificPurchaseReason-message"
                  />
                </div>
                {errors?.specificPurchaseReason && (
                  <Form.Control.Feedback
                    type="invalid"
                    className="d-block"
                    id="specificPurchaseReason-message"
                  >
                    {errors.specificPurchaseReason.message}
                  </Form.Control.Feedback>
                )}
              </fieldset>
            </Form.Group>
          </Col>
          <Col md={6} xs={12}>
            <InputField
              name="upcCode"
              label={t('UPC Code')}
              required={requiredUPCCode}
              tooltip={upcTooltip ? <RichText className="pds-p-3" body={upcTooltip} /> : undefined}
            />
            <Form.Text className="text-muted d-flex">
              {t('Please enter a valid 6- or 10-digit UPC (no characters or spaces)')}.
            </Form.Text>
          </Col>
          <Col md={6} xs={12}>
            <InputField
              name="manufactureCode"
              label={t('Manufacturer Code')}
              required={requiredUPCCode}
              tooltip={
                manufacturerTooltip ? (
                  <RichText className="pds-p-3" body={manufacturerTooltip} />
                ) : undefined
              }
            />
          </Col>
        </>
      )}
      <Col xs={12}>
        <Form.Group controlId="file">
          <Col md={12} className="d-flex gap-3 align-items-center">
            <Controller
              control={control}
              name="file"
              render={({ field: { onChange } }) => (
                <Form.Control
                  type="file"
                  className="d-none"
                  accept="image/png, image/jpeg"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e.target.files?.[0] ?? undefined);
                  }}
                  ref={hiddenFileInput}
                />
              )}
            />
            <Button
              variant="outline-dark"
              onClick={handleButtonClick}
              className="rounded-pill"
              aria-describedby="file file-message"
              type="button"
            >
              {fileName ?? t('Choose File')}
            </Button>
            <Form.Label>{t('8mb limit Accepted Filetypes: png, jpg')}</Form.Label>
          </Col>
          {errors?.file && (
            <Form.Control.Feedback className="d-block" type="invalid" id="file-message">
              {errors.file.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col xs={12}>
        <hr className="mt-3" aria-hidden="true" />
      </Col>
    </>
  );
};

export default ContactReason;
