type SizeInBytes = number;
type Base64Image = string;

/**
 * Reduces the size of an image to a target file size
 *
 * @param image The image to compress
 * @param targetFileSize The target file size in bytes
 * @returns A promise that resolves with the compressed image as a Base64 data URL
 */
export const compressImage = async (
  image: File | Base64Image,
  targetFileSize: SizeInBytes,
): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async e => {
      const img = new Image();
      img.src = typeof image === 'string' ? image : (e.target?.result as string);

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) return;

        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let quality = 1;
        let dataURL = canvas.toDataURL('image/jpeg', quality);

        while (dataURL.length > targetFileSize) {
          quality -= 0.1;
          dataURL = canvas.toDataURL('image/jpeg', quality);
        }

        canvas.remove();

        resolve(dataURL);
      };

      img.onerror = error => reject(error);
    };

    reader.onerror = error => reject(error);

    if (typeof image !== 'string') {
      reader.readAsDataURL(image);
    }
  });
};

export const hashImage = async (file: File): Promise<string> => {
  if (TextDecoder && crypto.subtle.digest) {
    const decoder = new TextDecoder();
    const digest = await crypto.subtle.digest('SHA-1', await file.arrayBuffer());
    return decoder.decode(digest);
  }

  // Simple hash for older browsers
  return file.lastModified + file.size + file.type;
};
