import { pdsTv } from '@purinanbm/pds-ui';
import React from 'react';
import { CMS_THEME } from '../../common/enums';

const styles = pdsTv({
  base: [
    'pds-prose pds-max-w-none',
    'prose-ol:pds-ml-4 prose-ul:pds-ml-4 md:prose-ol:pds-ml-5 md:prose-ul:pds-ml-5',
    // anchor tag
    'prose-a:pds-text-action-500 prose-a:hover:pds-text-action-500 prose-a:focus:pds-text-action-500 prose-a:active:pds-text-action-500',
    // blockquote
    'prose-blockquote:pds-border-[0px] prose-blockquote:pds-border-l-thick prose-blockquote:pds-border-solid prose-blockquote:pds-border-l-primary prose-blockquote:pds-pl-4.5 prose-blockquote:pds-font-normal [&_>_*_+_*]:pds-mb-1 prose-blockquote:[&last-child]:pds-mb-0',
    // headings
    'prose-headings:pds-font-normal prose-headings:pds-leading-loose',
    // custom alignment classes
    '[&_.text-align-center]:pds-text-center [&_.text-align-right]:pds-text-right',
    // tables
    'prose-table:pds-table-auto prose-table:pds-overflow-hidden prose-table:pds-rounded prose-th:pds-bg-surface-foreground prose-th:pds-pl-3 prose-td:pds-py-3',
    // images
    'prose-img:pds-mx-auto prose-img:pds-block prose-img:pds-rounded-xl',
  ],
  variants: {
    theme: {
      dark: 'prose-a:pds-text-action-700 prose-a:hover:pds-text-action-700 prose-a:focus:pds-text-action-700 prose-a:active:pds-text-action-700 pds-prose-invert prose-th:pds-bg-paper-text',
      light: '',
      neutral: '',
    },
    isArticle: {
      true: 'rich-article [&_p:first-of-type]:pds-text-title-sm',
    },
  },
});

export type RichTextProps = {
  body?: string;
  isArticle?: boolean;
  className?: string;
  cmsTheme?: CMS_THEME;
  children?: React.ReactNode;
} & ({ body: string } | { children: React.ReactNode });

const RichText = ({
  body,
  isArticle,
  className,
  cmsTheme = CMS_THEME.LIGHT,
  children,
}: RichTextProps) => {
  const wysiwyg = styles({
    theme: cmsTheme,
    isArticle,
    className,
  });

  if (children) {
    return <div className={wysiwyg}>{children}</div>;
  }

  if (body) {
    return (
      <div
        // cmsTheme={cmsTheme}
        className={wysiwyg}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: body }}
      />
    );
  }

  return null;
};
export default RichText;
