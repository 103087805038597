import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { styled } from '@linaria/react';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { theme } from '../../theme/theme';
import Link from '../Link';

export interface BreedCardProps {
  image: IGatsbyImageData;
  link: string;
  title: string;
  onClick?: () => void;
}

const StyledBreedCard = styled(Card)`
  display: flex;
  border: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const StyledTitleLink = styled(Link)`
  color: ${theme.light.text.default};
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: ${theme.light.text.default};
    text-decoration: underline;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
`;

const BreedCard: React.FC<BreedCardProps> = function ({ title, link, image, onClick = () => {} }) {
  return (
    <StyledBreedCard>
      <GatsbyImage image={image} alt="" className="rounded-3" />
      <StyledTitleLink to={link} onClick={onClick}>
        <span className="text-lg-center fw-light">{title}</span>
      </StyledTitleLink>
    </StyledBreedCard>
  );
};

export default BreedCard;
