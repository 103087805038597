import { Link as GatsbyLink } from 'gatsby';
import React from 'react';

const PurinaPerksFooter: React.FC = () => {
  return (
    <div className="pds-mt-4 pds-flex pds-flex-col pds-items-center pds-rounded-xl pds-border pds-border-surface-line pds-bg-white pds-py-4.5">
      <span>Questions or comments?</span>
      <span>
        Visit our{' '}
        <GatsbyLink to="/faq#purina-perks" className="pds-font-semibold pds-text-paper-text">
          FAQs
        </GatsbyLink>{' '}
        or{' '}
        <GatsbyLink to="/contact-us" className="pds-font-semibold pds-text-paper-text">
          Contact Us
        </GatsbyLink>{' '}
        pages.
      </span>
    </div>
  );
};

export default PurinaPerksFooter;
