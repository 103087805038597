import { styled } from '@linaria/react';
import { pdsTv } from '@purinanbm/pds-ui';
import { AxiosError } from 'axios';
import { PageProps, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { CategoryTag } from 'src/components/category_tag/CategoryTag';
import { getArticleSearchData } from 'src/ssr/getSearchData';
import { theme } from 'src/theme/theme';
import Layout from '../../components/Layout';
import Link from '../../components/Link';
import Seo from '../../components/Seo';
import Card from '../../components/card/Card';
import RichText from '../../components/rich_text/RichText';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';
import ArticleListingBody from '../components/ArticleListingBody';
import { ArticleSD } from './types/types';

interface DataProps {
  node: IArticleListing;
  translations: Translations;
}

const categoryTag = pdsTv({
  base: [
    'pds-bg-surface-foreground',
    'pds-rounded',
    'pds-text-body-md',
    'pds-font-[500]',
    'pds-tracking-widest',
    'pds-flex',
    '!pds-text-center',
    'pds-box-border',
    'pds-h-full',
    'pds-items-center',
    'pds-content-center',
    'pds-px-4',
    'pds-py-2',
    'pds-gap-y-3',
    'pds-w-full',
    'pds-w-fit',
    'pds-cursor-pointer',
    'pds-no-underline',
    'pds-text-paper-text',
  ],
});

const CardContainer = styled.div`
  text-decoration: none;
  color: ${theme.light.text.default};

  a {
    color: ${theme.light.text.default};
    text-decoration: none;
  }

  :hover {
    color: ${theme.light.text.default};
    text-decoration: underline;
  }
`;

const StyledLink = styled(Link)`
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

interface FeaturedCardProps {
  node: NodeContent;
}

const FeaturedCard = ({ node }: FeaturedCardProps) => {
  // eslint-disable-next-line no-underscore-dangle
  const media = node?.relationships?.field_image?.relationships?.field_media_image?.gatsbyImage;
  const alt = node?.relationships?.field_image?.field_media_image.alt || '';

  return (
    <CardContainer>
      <Card
        containment="implicit"
        media={media ? <GatsbyImage image={media} alt={alt} /> : undefined}
      >
        <div className="mt-2">
          <h3 className="h3 fw-bold">
            <StyledLink to={node.path.alias}>{node.title}</StyledLink>
          </h3>
          {node?.body?.summary && <p className="typography_body fw-light">{node.body.summary}</p>}
        </div>
      </Card>
    </CardContainer>
  );
};

const ArticleListingTemplate: React.FC<PageProps<DataProps, any, any, ArticleSD>> = function ({
  data: { node, translations },
  serverData,
}) {
  const { featuredArticle, relatedListings } = node.relationships;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const { t } = useTranslation();
  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Container fluid className="pds-bg-gray-100 pds-py-5 pds-text-center">
        <h1>{node.title}</h1>
        {node?.body?.processed && (
          <RichText className="pds-py-4 pds-text-center" body={node.body.processed} />
        )}
      </Container>
      {relatedListings?.length > 0 && (
        <AnalyticsPoint
          type="module"
          label="Article subcategories"
          typeLabel="article_categories"
          as={Container}
          role="region"
          aria-label={`Categories related to ${node.title}`}
          className="pds-px-0 pds-py-5"
          fluid
        >
          <Container className="text-sm-start pds-mb-3">
            <h2 className="pds-text-left pds-text-title-sm-legacy md:pds-text-center">
              {t('Related Categories')}
            </h2>
          </Container>
          <Container className="pds-flex pds-flex-wrap pds-gap-4 md:pds-flex-row md:pds-justify-center">
            {relatedListings.map((listing: any, i: number) => {
              return (
                <AnalyticsPoint
                  key={i}
                  type="component"
                  node={{ ...listing, type: 'article_category' }}
                  className="pds-w-fit"
                >
                  <CategoryTag
                    url={listing.path.alias}
                    name={listing.relationships?.field_tags[0]?.name}
                  />
                </AnalyticsPoint>
              );
            })}
          </Container>
        </AnalyticsPoint>
      )}
      <AnalyticsPoint
        type="module"
        label="Articles section"
        typeLabel="article_listing"
        as={Container}
        fluid
      >
        <ArticleListingBody
          data={serverData.articleData}
          serverData={serverData.articleData}
          error={serverData.error}
          contextualFilters={serverData.contextualFilters}
          isFetching={false}
          defaultLoadedState
        />
      </AnalyticsPoint>
    </Layout>
  );
};

export const getServerData = async (props: any) => {
  try {
    const articleData = await getArticleSearchData(props.pageContext, props.query);
    return {
      status: 200,
      headers: {
        'Cache-Control': 'public, max-age=3600, s-maxage=3600, stale-while-revalidate=3600',
      },
      props: {
        articleData: articleData.data,
        contextualFilters: articleData.contextualFilters,
        error: null,
      },
    };
  } catch (e) {
    return {
      status: 500,
      props: {
        error: (e as AxiosError).response,
        articleData: null,
      },
    };
  }
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeArticleListingPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      path {
        alias
      }
      body {
        processed
      }
      langcode
      ...Metatag
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
      relationships {
        featuredArticle: field_article {
          ...FeaturedArticleCard
        }
        species: field_species_term {
          drupal_internal__tid
        }
        tags: field_tags {
          drupal_internal__tid
        }
        relatedListings: field_related_listings {
          id
          title
          path {
            alias
          }
          langcode
          relationships {
            field_tags {
              name
            }
          }
        }
      }
    }
    translations: allNodeArticleListingPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ArticleListingTemplate;
