import { styled } from '@linaria/react';
import { useLocation } from '@reach/router';
import { AxiosError } from 'axios';
import { PageProps, graphql, navigate } from 'gatsby';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import Link from '../../components/Link';
import Seo from '../../components/Seo';
import RichText from '../../components/rich_text/RichText';
import SearchListing from '../../components/search_listing/SearchListing';
import StickyFacetContainer from '../../components/search_listing/StickyFacetContainer';
import { useSearchDrupal } from '../../hooks/useSearchDrupal';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';

interface IOffersListingPage extends NodeContent {
  field_cta: FieldCta[];
  relationships?: {
    brand?: TaxonomyTerm;
    category?: TaxonomyTerm[];
    species?: TaxonomyTerm;
  };
}

interface DataProps {
  node: IOffersListingPage;
  translations: Translations;
}

const CtaLinks = styled.div`
  display: flex;
  justify-content: center;

  a {
    text-decoration: none;
    border: 1px solid #000;
    border-radius: 999999px;
    padding: 10px 20px;
    color: #000;
    font-weight: 300;
  }
`;

const OffersListingTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
}) {
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const location = useLocation();

  const { t } = useTranslation();

  const { brand, category, species } = node.relationships;

  const contextualFilters: SearchParams = useMemo(() => {
    const restProperties: SearchParams = {};

    if (brand?.drupal_internal__tid) {
      restProperties.brand = brand.drupal_internal__tid;
    }

    if (category?.length) {
      restProperties.category = category.map(item => item.drupal_internal__tid).join('+');
    }

    if (species?.drupal_internal__tid) {
      restProperties.species = species.drupal_internal__tid;
    }

    return {
      api_slug: 'offers',
      langcode: node.langcode,
      ...restProperties,
    };
  }, [brand?.drupal_internal__tid, category, node.langcode, species?.drupal_internal__tid]);

  const params = useMemo(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(location.search)) || {};
    if (queryParams.page) queryParams.page = String(Number(queryParams.page) - 1);
    return { ...queryParams, ...contextualFilters };
  }, [location.search, contextualFilters]);

  const handleFacetChange: any = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { url } = event.currentTarget.dataset;
    if (url) {
      const facetUrlObject = new URL(url);
      const facetSearchParams = new URLSearchParams(facetUrlObject.search);
      Object.keys(contextualFilters).forEach(key => facetSearchParams.delete(key));
      if (facetSearchParams.toString() !== new URLSearchParams(location.search).toString()) {
        navigate(`?${facetSearchParams}`);
      }
    }
  };

  const { data, error, isFetching } = useSearchDrupal(params, node.langcode);

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Container fluid className="bg-gray-100 py-5 text-center">
        <h1>{node.title}</h1>
        {node.body?.processed && <RichText body={node.body.processed} />}
        <AnalyticsPoint type="module" label="Cta links" typeLabel="cta_section" as={CtaLinks}>
          {node.field_cta.map(cta => (
            <AnalyticsPoint
              type="component"
              node={{ ...cta, id: '', name: cta.title, type: 'cta_link' }}
            >
              <Link to={cta.url}>{cta.title}</Link>
            </AnalyticsPoint>
          ))}
        </AnalyticsPoint>
      </Container>
      <Container className="my-5">
        <SearchListing
          data={data}
          error={(error as AxiosError) || new Error(t('Sorry, there was an error'))}
          loaded={!isFetching}
        >
          <Row className="align-items-center py-3">
            <SearchListing.SearchHeader title={t('Products')} onFacetRemove={handleFacetChange} />
          </Row>
          <Row className="position-relative">
            <Col lg={3} className="py-3 d-none d-lg-block">
              <StickyFacetContainer>
                <SearchListing.SearchFacets onChange={handleFacetChange} />
              </StickyFacetContainer>
            </Col>
            <Col>
              <SearchListing.OfferSearchResults />
              <SearchListing.Pagination />
            </Col>
            <SearchListing.FacetModal onChange={handleFacetChange} />
          </Row>
        </SearchListing>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeOffersListingPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      field_cta {
        title
        url
      }
      path {
        alias
      }
      body {
        processed
      }
      langcode
      ...Metatag
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
      relationships {
        species: field_species_term {
          drupal_internal__tid
        }
        category: field_category {
          drupal_internal__tid
        }
        brand: field_brand {
          drupal_internal__tid
        }
      }
    }
    translations: allNodeOffersListingPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default OffersListingTemplate;
