import { styled } from '@linaria/react';
import React, { useContext, useMemo } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import StorageBrazeCard from 'src/cms-components/StorageBrazeCard';
import { FORMAT_BUCKETS } from 'src/hooks/useBraze';
import { formatSku } from '../../utils/helpers';
import ProductCard from '../product_card/ProductCard';
import ProductCardSkeleton from '../product_card/ProductCardSkeleton';
import NoResults from './NoResults';
import { SearchListingContext } from './SearchListingContext';

interface Props {
  serverData?: DrupalSearch;
  brazeCardSlots?: IStorageBrazeCard[];
}
const StyledCol = styled(Col)`
  @media (max-width: 350px) {
    width: 100%;
  }
`;
const ProductSearchResults: React.FC<Props> = ({ serverData, brazeCardSlots }) => {
  const context = useContext(SearchListingContext);

  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }
  const { data, loaded } = context || {};
  const search_results = serverData?.search_results || data?.search_results;
  const banners = brazeCardSlots?.filter(card => card.field_formats?.includes('banner'));
  const resultsWithBrazeCards = useMemo(() => {
    const newResults: any[] = search_results?.slice() || [];
    // Limit to 2 cards.
    const cards = brazeCardSlots
      ?.filter(card => !card.field_formats?.includes('banner'))
      .slice(0, 2);
    if (newResults?.length && cards?.length) {
      // Insert first card as 5th result.
      if (newResults?.length >= 5 && cards[0]) {
        newResults.splice(4, 0, cards[0]);
      }
      // Insert second card as 12th result.
      if (newResults?.length >= 11 && cards[1]) {
        newResults.push(cards[1]);
      }
    }
    return newResults;
  }, [brazeCardSlots, search_results]);
  const allBrazeCards = context.brazeCards;
  const brazeListingCards = allBrazeCards.filter(card =>
    FORMAT_BUCKETS.card.includes(card.extras.format),
  );

  if (!loaded) {
    return (
      <Row className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
        {[...Array(12)].map((_, i) => {
          return (
            <Col key={`loading-card-${i}`} xs={6} md={4} className="d-flex">
              <ProductCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }

  if (!resultsWithBrazeCards) {
    return <NoResults />;
  }

  const renderListingGrid = (
    <Row id="search-results" className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3">
      {Object.values(resultsWithBrazeCards).map(node => {
        const isBrazeCard = node.field_campaign_type?.length;
        if (isBrazeCard) {
          const brazeCardIndex = resultsWithBrazeCards
            .filter(result => result?.field_campaign_type)
            .findIndex(card => card.id === node.id);
          return (
            <StyledCol key={node.nid} xs={12} md={4} className="d-flex">
              <StorageBrazeCard
                defaultCard={{
                  ...node,
                  title: node.name,
                }}
                brazeCard={brazeListingCards[brazeCardIndex] ?? undefined}
              />
            </StyledCol>
          );
        }
        const image = node.product_image
          ? process.env.GATSBY_DRUPAL_ENDPOINT + node.product_image.substring(1)
          : '';

        const sku = node.upc ? formatSku(node.upc) : '';
        const isDirect = node?.field_alternate_url?.url?.length > 0;
        const url = isDirect ? node?.field_alternate_url?.url : node.url;
        const ctaText = isDirect ? node?.field_alternate_url?.text : null;

        return (
          <StyledCol key={node.nid} xs={6} md={4} className="d-flex">
            <ProductCard
              image={{
                images: {
                  fallback: {
                    src: image,
                  },
                },
                layout: 'constrained',
                width: 800,
                height: 800,
              }}
              type="products"
              brand={node.field_brand}
              link={url}
              title={node.title}
              sku={sku}
              bvID={node.bazaarvoice_id}
              isDirect={isDirect}
              ctaText={ctaText}
            />
          </StyledCol>
        );
      })}
    </Row>
  );

  if (banners?.length) {
    const bannersMapping = (banner: IStorageBrazeCard) => {
      return (
        <Row
          key={banner.drupal_internal__id}
          id="braze-card-banner"
          className="mb-5 gy-3 gy-md-4 gx-2 gx-md-3"
        >
          <StyledCol xs={12} className="d-flex">
            <StorageBrazeCard
              node={{
                ...banner,
                title: banner.name,
              }}
            />
          </StyledCol>
        </Row>
      );
    };
    const mappedBanners =
      banners.length > 2 ? banners.slice(2).map(bannersMapping) : banners.map(bannersMapping);

    if (mappedBanners?.length > 1) {
      mappedBanners.splice(1, 0, renderListingGrid);
    } else {
      mappedBanners.push(renderListingGrid);
    }

    return mappedBanners;
  }

  return renderListingGrid;
};

ProductSearchResults.displayName = 'SearchListing.ProductSearchResults';

export default ProductSearchResults;
