import Icon from '@mdi/react';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { SearchListingContext } from './SearchListingContext';
import { mdiAlertCircleOutline } from 'src/assets/icons/mdiIcons';

const NoResults: React.FC = function () {
  const context = useContext(SearchListingContext);
  const { t } = useTranslation();
  if (!context) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const { data } = context || {};
  // const { keywords } = data.params?.keywords?? {"keywords": "that term."};
  // const { type } = data.params?.index?? '';

  const similarWords = data?.did_you_mean
    ?.map((newTerm, index) => {
      if (!newTerm) return null;

      return (
        <Link key={index} to={`?keywords=${newTerm}`}>
          {newTerm}
          {index + 1 !== data?.did_you_mean?.length ? ', ' : ''}
        </Link>
      );
    })
    .filter(n => n);

  const getContextualBulletInfo = (index: string) => {
    switch (index) {
      case 'articles':
        return {
          clear: '/search/articles',
          bullets: [
            <li key={0}>
              {t('View all')}{' '}
              <Link to="/articles" title="articles">
                {t('articles')}
              </Link>
              .
            </li>,
          ],
        };
      case 'breeds':
        return {
          clear: '/search/breeds',
          bullets: [
            <li key={0}>
              {t('View all')} <Link to="/cats/cat-breeds">{t('Cat Breeds')}</Link>.
            </li>,
            <li key={1}>
              {t('View all')} <Link to="/dogs/dog-breeds">{t('Dog Breeds')}</Link>.
            </li>,
          ],
        };
      default:
        return {
          clear: '/search/products',
          bullets: [
            <li key={0}>
              {t('Try the')} <Link to="/pet-food-finder-direct">{t('Pet Food Finder')}</Link>.
            </li>,
          ],
        };
    }
  };

  const contextualBulletInfo = getContextualBulletInfo(data?.params?.api_slug ?? '');
  return (
    <Row className="gy-3 gy-md-4 gx-2 gx-md-3 mb-5 justify-content-center typography_body">
      <Col md={8} xl={6}>
        <div className="text-center mb-4">
          {/* This div content should be centered*/}
          <Icon path={mdiAlertCircleOutline} size={5} className="me-2 mb-4" />
          <h3 className="typography_h3">
            {t('Sorry, there are no results for')} {data?.params?.keywords ?? t('that term')}
          </h3>
        </div>
        <p className="mb-4">{t("Try adjusting your search to find what you're looking for.")}</p>
        <p>{t('You can')}:</p>
        <ul>
          {similarWords && similarWords?.length > 0 && (
            <li>
              {t('Try similar searches')}: {similarWords}
            </li>
          )}
          <li>{t('Use one term instead of multiple words')}.</li>
          <li>
            <a href={contextualBulletInfo.clear}>{t('Clear your search term')}</a>{' '}
            {t('to view all')} {data?.params?.api_slug ?? ''}.
          </li>
          <li>{t('Try a new search term')}.</li>
          {contextualBulletInfo && contextualBulletInfo.bullets.map(bullet => bullet)}
          <li>
            {t('Visit the')}{' '}
            <Link to="/" title="homepage">
              {t('homepage')}
            </Link>
            .
          </li>
        </ul>
      </Col>
    </Row>
  );
};

export default NoResults;
