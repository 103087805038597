import { SidebarMenu } from '@purinanbm/pds-ui';
import { ISidebarMenuOption } from '@purinanbm/pds-ui/dist/components/SidebarMenu/types';
import { useLocation } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { FC } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Layout from 'src/components/Layout';
import { PrivateRoute } from 'src/components/PrivateRoute/PrivateRoute';
import { useAuth } from 'src/hooks/useAuth';
import { LOYALTY_QUERIES, useLoyaltyQuery } from 'src/hooks/useLoyaltyService';
import {
  MetatagProps,
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';
import Seo from '../Seo';
import PurinaPerksFooter from './PurinaPerksFooter';

export const PURINA_PERKS_URL_BASE_PATH = '/purina-perks';

export const PURINA_PERKS_ROUTES = {
  Receipt: `${PURINA_PERKS_URL_BASE_PATH}/receipt`,
  ReceiptHistory: `${PURINA_PERKS_URL_BASE_PATH}/receipt-history`,
  ReceiptUpload: `${PURINA_PERKS_URL_BASE_PATH}/receipt-upload`,
  ReceiptUploadSuccess: `${PURINA_PERKS_URL_BASE_PATH}/receipt-upload/success`,
  Catalog: `${PURINA_PERKS_URL_BASE_PATH}/rewards-catalog`,
  RewardDetails: `${PURINA_PERKS_URL_BASE_PATH}/reward-details`,
  Checkout: `${PURINA_PERKS_URL_BASE_PATH}/checkout`,
  CheckoutSuccess: `${PURINA_PERKS_URL_BASE_PATH}/checkout/success`,
  Review: `${PURINA_PERKS_URL_BASE_PATH}/write-a-review`,
} as const;

const navOptions: ISidebarMenuOption[] = [
  {
    title: 'Receipt Upload',
    url: PURINA_PERKS_ROUTES.ReceiptUpload,
    attributes: {
      onClick: () => {
        sendIt({
          event: 'nav_click',
          eventParams: { nav_item: 'Receipt Upload', nav_type: 'account' },
        });
      },
    },
    subtitle: 'Upload receipts and submit to earn points.',
    submenu: [
      // @DEV - Commented out until the feature is ready or Feature Flag can wrap.
      // {
      //   title: 'Receipt Home',
      //   url: PURINA_PERKS_ROUTES.Receipt,
      //   attributes: {
      //     onClick: () => {
      //       sendIt({ event: 'nav_click', eventParams: { nav_item: 'Receipt Home', nav_type: 'account' } });
      //     },
      //   },
      //   subtitle: 'Upload receipts and submit to earn points.',
      // },
      {
        title: 'Upload a Receipt',
        url: PURINA_PERKS_ROUTES.ReceiptUpload,
        attributes: {
          onClick: () => {
            sendIt({
              event: 'nav_click',
              eventParams: { nav_item: 'Upload a Receipt', nav_type: 'account' },
            });
          },
        },
      },
      {
        title: 'Submission History',
        url: PURINA_PERKS_ROUTES.ReceiptHistory,
        attributes: {
          // @ts-ignore: Allow the GatsbyLink property to be passed to the SidebarMenu component
          onClick: () => {
            sendIt({
              event: 'nav_click',
              eventParams: { nav_item: 'Submission History', nav_type: 'account' },
            });
          },
        },
      },
    ],
  },

  // @DEV - Commented out until the feature is ready or Feature Flag can wrap.
  {
    title: 'Rewards Catalog',
    url: PURINA_PERKS_ROUTES.Catalog,
    subtitle: 'Browse the catalog to redeem points for tail-wagging rewards.',
    attributes: {
      onClick: () => {
        sendIt({
          event: 'nav_click',
          eventParams: { nav_item: 'Rewards Catalog', nav_type: 'account' },
        });
      },
    },
    submenu: [
      {
        title: 'Rewards Catalog',
        url: PURINA_PERKS_ROUTES.Catalog,
        attributes: {
          onClick: () => {
            sendIt({
              event: 'nav_click',
              eventParams: { nav_item: 'Rewards Catalog (sub)', nav_type: 'account' },
            });
          },
        },
      },
    ],
  },
  // {
  //   title: 'Write a Review',
  //   url: PURINA_PERKS_ROUTES.Review,
  //   subtitle: 'Information about Write a Review goes here',
  //   submenu: [
  //     {
  //       title: 'Reviews',
  //       url: PURINA_PERKS_ROUTES.Review,
  //       attributes: {
  //         onClick: () => {
  //           sendIt({ event: 'nav_click', eventParams: { nav_item: 'Write a Review', nav_type: 'account' } });
  //         },
  //       },
  //     },
  //   ],
  // },
];

interface PurinaPerksLayoutProps {
  readonly children: React.ReactNode;
  metaData?: Array<MetatagProps>;
  language: Languages;
  translations?: Translations;
  node: NodeContent;
}

const PurinaPerksLayout: FC<PurinaPerksLayoutProps> = ({
  children,
  metaData,
  language,
  translations,
  node,
}) => {
  const location = useLocation();
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const { user } = useAuth();
  const userAnsiraId = user?.ansiraUuid || '';
  const { data: customer, isLoading } = useLoyaltyQuery(
    LOYALTY_QUERIES.GET_CUSTOMERS_DETAIL,
    {
      customerId: userAnsiraId,
    },
    { enabled: Boolean(userAnsiraId) },
  );

  /**
   * TODO: update this condition when following pages are implemented:
   * Rewards listing page :check:
   * Rewards detail page
   * Redemption checkout
   * Something like:
   * [PURINA_PERKS_ROUTES.Catalog,PURINA_PERKS_ROUTES.RewardsDetailPage, PURINA_PERKS_ROUTES.RedemptionCheckout]
   *  .includes(location.pathname)
   */
  const isPageWithPurinaPerksFooter =
    location.pathname === PURINA_PERKS_ROUTES.Catalog ||
    location.pathname.includes(PURINA_PERKS_ROUTES.RewardDetails);

  return (
    <Layout
      language={language}
      metaData={metaData}
      translations={translations}
      node={node}
      excludeMain
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <div className="pds-flex pds-flex-1 pds-bg-surface-layer">
        <Container className="!pds-max-w-[1331px] pds-px-4 pds-py-5 md:!pds-w-4/5">
          {node.title && (
            <h1 className="pds-mb-[24px] pds-text-center pds-text-title-md pds-font-semibold md:pds-mb-[40px] md:pds-mt-3 md:pds-text-title-lg">
              {node.title}
            </h1>
          )}

          <Row
            style={{
              // @ts-ignore - --bs-gutter-y is a bootstrap variable
              '--bs-gutter-y': '1rem',
            }}
          >
            <Col xs={12} md={3}>
              <SidebarMenu
                points={{ amount: customer?.customer?.loyaltyPoints, isLoading }}
                title="Purina Perks"
                backButtonTitle="Back to Perks Menu"
                activeUrl={location.href}
                navigationOptions={navOptions}
                optionComponent={GatsbyLink}
                navigationMenuProps={{
                  'aria-label': 'Purina perks',
                }}
                optionProps={{
                  className: 'pds-border-none',
                }}
                optionPropsMap={{ url: 'to' }}
              />
            </Col>
            <Col xs={12} md={9}>
              <main role="main">
                <PrivateRoute>
                  {children}
                  {isPageWithPurinaPerksFooter && <PurinaPerksFooter />}
                </PrivateRoute>
              </main>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export default PurinaPerksLayout;
