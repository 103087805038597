import { PageProps, graphql } from 'gatsby';
import React from 'react';
import PurinaPerksLayout from 'src/components/purina_perks/PurinaPerksLayout';
import RewardCatalogGate from 'src/components/purina_perks/reward_catalog/RewardCatalogGate';
import RewardDetails from 'src/components/purina_perks/reward_details/RewardDetails';

interface DataProps {
  node: NodeContent;
  translations: Translations;
}

const RewardDetail: React.FC<PageProps<DataProps>> = props => {
  return (
    <PurinaPerksLayout
      node={props.data.node}
      language={props.data.node.langcode}
      metaData={props.data.node.metatag}
      translations={props.data.translations}
    >
      <div className="pds-rounded-[24px] pds-border pds-border-solid pds-border-surface-line pds-bg-surface pds-px-4.5 pds-pt-4.5 md-bt:pds-px-5 md-bt:pds-pt-5">
        <RewardCatalogGate>
          <RewardDetails id={Number(props.params.slug)} />
        </RewardCatalogGate>
      </div>
    </PurinaPerksLayout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeComplex(id: { eq: $id }, langcode: { eq: $language }) {
      id
      body {
        processed
      }
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      behaviors {
        page_layout {
          elements_to_hide {
            header
            footer
            chat
          }
        }
      }
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
    }
    translations: allNodeComplex(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default RewardDetail;
