import * as React from 'react';

interface ParagraphProps extends Paragraph {
  text: {
    value: string;
    format: TextFormat;
    processed: string;
  };
}

interface ParagraphTextProps {
  node: ParagraphProps;
}

const ParagraphText: React.FC<ParagraphTextProps> = function ({ node }) {
  const classes = node.behavior_settings?.decoupled_styles?.classes || [];
  const text =
    node.text?.format === 'plain_text'
      ? node.text?.value
      : node.text?.processed
      ? node.text.processed
      : '';
  return (
    <div className={classes && classes.join(' ')} dangerouslySetInnerHTML={{ __html: text }} />
  );
};

export default ParagraphText;
