import { objectKeysToSnakeCase } from 'gatsby-plugin-purina-analytics/common/functions';
import { IModule, usePageStore } from '../stores/page';

export interface ISendIt {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  eventCallback?: () => void;
  eventParams?: {
    [key: string]: string | undefined;
  };
}

interface IAHelperReturn {
  sendItLog: (v: IModule) => void;
  sendIt: (v: ISendIt) => void;
  generateModules: (v: NodeContent) => IModule[];
  getComponentInfo: (v: number, x: number, a?: Record<string, any>) => ISendIt | undefined;
}

export const useAnalyticsHelper = (): IAHelperReturn => {
  const dataLayerName = process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer';
  const pageContext = usePageStore(state => state.pageContext);

  /**
   * Demo log of component based context. This will eventually be attatched to certain GA sendIt events.
   **/
  const sendItLog = ({
    pageType,
    modulePosition,
    moduleId,
    moduleType,
    drupalId,
    components,
  }: IModule) => {
    console.groupCollapsed(`%c${modulePosition}: ${moduleType}`, 'color: #0099ff');
    console.log({
      pageType,
      modulePosition,
      moduleId,
      moduleType,
      drupalId,
    });
    console.log(document.querySelectorAll(`[data-id="${drupalId}"]`));
    console.group('%ccomponents', 'color: #0073ff');
    components?.forEach(({ componentID, drupalId: dId, componentType, componentPosition }) => {
      console.log({
        componentID,
        componentPosition,
        componentType,
        drupalId: dId,
      });
      console.log(document.querySelectorAll(`[data-id="${dId}"]`));
    });
    console.groupEnd();

    console.groupEnd();
  };

  /**
   * Lower cases values in an object
   **/
  const toLowerParams = (params?: { [key: string]: string | undefined }) => {
    if (!params) return {};

    const entries = Object.entries(params);
    const newObj: { [key: string]: string } = {};

    entries.forEach(entry => {
      if (entry[1] !== undefined) {
        newObj[entry[0]] = entry[1].toLowerCase();
      } else {
        newObj[entry[0]] = '';
      }
    });

    return newObj;
  };

  /**
   * Sends google analytics data via purData
   **/
  const sendIt = ({
    event,
    eventCategory,
    eventAction,
    eventLabel,
    eventParams,
    eventCallback,
  }: ISendIt) => {
    // Log for testing sendIt events as they come through
    if (process.env.GATSBY_GA_LOGGING === 'true') {
      console.groupCollapsed(`%cGA Event: ${eventCategory}`, 'color: #ffb300');
      console.log({
        dataLayerName,
        event: {
          event,
          eventCategory,
          eventAction,
          eventLabel,
          eventParams: objectKeysToSnakeCase(eventParams),
        },
      });
      console.groupEnd();
    }
    return window[dataLayerName].push(
      {
        event: event?.toLowerCase(),
        eventCategory: eventCategory?.toLowerCase(),
        eventAction: eventAction?.toLowerCase(),
        eventLabel: eventLabel?.toLowerCase(),
        eventCallback,
        event_params: objectKeysToSnakeCase(toLowerParams(eventParams)),
      },
      { event_params: undefined },
    );
  };

  /**
   * Used privatly to generate component level context for modules
   * A Module is a StorageEntity to the analytics team.
   * Component level context to the analytics team is nested components within a module.
   **/
  const generateComponents = (component: IStorage) => {
    const relationships = component?.relationships;
    const nested =
      relationships?.cards ||
      relationships?.icon_menu?.relationships?.field_menu_links ||
      relationships?.highlights ||
      relationships?.articles ||
      relationships?.products ||
      relationships?.reviews ||
      relationships?.field_ingredients ||
      relationships?.qa_sets ||
      [];

    // Not nested
    if (nested.length === 0) {
      const comp = {
        componentPosition: '1/1',
        componentID: component.name,
        componentType: component.type.replace('storage__', ''),
        drupalId: component.drupal_id,
      };

      return [comp];
    }

    return nested.map((nestedItem: any, i: number) => ({
      componentPosition: `${i + 1}/${nested.length}`,
      componentID: nestedItem.name,
      componentType:
        nestedItem?.type?.replace('storage__', '') ??
        nestedItem?.internal?.type?.replace('storage__', ''),
      drupalId: nestedItem.drupal_id,
    }));
  };

  /**
   * Generates Module Context for analytics.
   * A Module is a StorageEntity to the analytics team.
   * Component level context to the analytics team is nested components within a module.
   **/
  const generateModules = (node: NodeContent) => {
    const modules: IModule[] | undefined = node.relationships?.components?.map(item => {
      return {
        pageType: node.internal?.type?.replace('node__', ''),
        modulePosition: String(item.indexOnPage + 1),
        moduleId: item.name,
        moduleType: item.type.replace('storage__', ''),
        drupalId: item.drupal_id,
        components: generateComponents(item as IStorage),
      };
    });

    return modules || [];
  };

  /*
   * @componentIndex: number
   *   if we don't have nested components, 0 should be passed as a param
   */
  const getComponentInfo = (
    moduleIndex: number,
    componentIndex: number,
    additionalEventParams?: Record<string, any>,
  ): ISendIt | undefined => {
    const moduleInfo = pageContext.modules[moduleIndex];
    const componentInfo = moduleInfo?.components?.[componentIndex];
    if (!moduleInfo) return undefined;

    return {
      event: 'module_interaction',
      eventCategory: 'basic module interaction',
      eventAction: componentInfo?.componentType || moduleInfo?.moduleType,
      eventLabel: componentInfo?.componentID || moduleInfo?.moduleId,
      eventParams: {
        ...additionalEventParams,
        drupalId: moduleInfo?.drupalId,
        moduleId: moduleInfo?.moduleId,
        modulePosition: moduleInfo?.modulePosition,
        pageType: pageContext.pageType,
        componentId: componentInfo?.componentID || moduleInfo?.moduleId,
        componentPosition: componentInfo?.componentPosition || moduleInfo?.modulePosition,
        componentType: componentInfo?.componentType || moduleInfo?.moduleType,
      },
    };
  };

  return {
    getComponentInfo,
    sendItLog,
    sendIt,
    generateModules,
  };
};
