import * as Yup from 'yup';
import { TranslateFunctionType } from '../../../utils/TranslateFunctionType';

export const contactReasonAnswers = {
  QUESTION: 'Question',
  COMPLAINT: 'Complaint',
  COMMENT: 'General Comment',
} as const;

const MAX_FILE_SIZE = 8000000; // 8MB

export const textPattern = /^[a-zA-Z_-]+\s?([a-zA-Z_-]+\s?){0,3}$/;

const getExtension = (file: string) => {
  return file?.split('.').pop() ?? '';
};

type Keys = keyof typeof contactReasonAnswers;

type ContactReasonAnswersValues = (typeof contactReasonAnswers)[Keys];

export const moreSpecificReasonAnswers: Record<
  ContactReasonAnswersValues,
  Record<string, { label: string; value: string }>
> = {
  [contactReasonAnswers.QUESTION]: {
    PRODUCT_PACKAGING: { label: 'Product & Packaging', value: 'product' },
    WHERE_TO_BUY: { label: 'Where to Buy', value: 'wtb' },
    DONATIONS: { label: 'Donations', value: 'donation' },
    PROMOTIONS: { label: 'Promotions & Special Offers', value: 'promotions' },
    GENERAL_QUESTION: { label: 'General Questions', value: 'general_question' },
  },
  [contactReasonAnswers.COMPLAINT]: {
    PRODUCT_PACKAGING: { label: 'Product & Packaging', value: 'product' },
    PRODUCT_AVAILABILITY: { label: 'Product Availability', value: 'product_availability' },
    ECOMMERSE: {
      label: 'Ecommerce (Just Right Purina Store Only, Vet Direct, P4P)',
      value: 'ecommerce',
    },
    PROMOTIONS: { label: 'Promotions & Special Offers', value: 'promotions' },
    GENERAL_COMMENT: { label: 'General Comment', value: 'general_comment' },
  },
  [contactReasonAnswers.COMMENT]: {
    GENERAL_COMMENT: { label: 'General Comment', value: 'general_comment' },
  },
};

export const createContactUsSchema = (t: TranslateFunctionType) =>
  Yup.object({
    contactReason: Yup.string().required(t('Please fill out a Contact Reason.')),
    moreSpecificReason: Yup.string().required(t('Please fill out a Specific Reason.')),
    message: Yup.string()
      .max(5000, t('Max number of characters is 5000.'))
      .required(t('Please fill out a message.')),
    specificPurchaseReason: Yup.number()
      .when('contactReason', {
        is: contactReasonAnswers.COMPLAINT,
        then: Yup.number()
          .transform(value => value ?? null)
          .nullable()
          .when('moreSpecificReason', {
            is: moreSpecificReasonAnswers.Complaint.PRODUCT_PACKAGING.value, // product and packaging
            then: Yup.number()
              .transform(value => value ?? null)
              .required(t('Please fill out a Purchase Reason.'))
              .nullable(),
          }),
      })
      .nullable(),
    upcCode: Yup.string()
      .transform((o, c) => (o === '' ? null : c))
      .min(6, t('Minimum number is 6 characters.'))
      .max(10, t('Maximum number is 10 characters.'))
      .nullable()
      .matches(/^[0-9A-Z]{0,10}$/, t('Provide a valid UPC value.'))
      .when('specificPurchaseReason', {
        is: 1,
        then: Yup.string().nullable().required(t('Please fill out a UPC Code.')),
      }),
    manufactureCode: Yup.string()
      .transform((o, c) => (o === '' ? null : c))
      .min(8, t('Minimum number is 8 characters.'))
      .max(20, t('Maximum number is 20 characters.'))
      .nullable()
      .matches(/^[0-9A-Z]{0,20}$/, t('Provide a valid manufacturer code.'))
      .when('specificPurchaseReason', {
        is: 1,
        then: Yup.string().nullable().required(t('Please fill out a Manufacturer Code.')),
      }),
    file: Yup.mixed()
      .test({
        message: t('You have uploaded an invalid file format.'),
        test: (contactUsFile: File) => {
          if (!contactUsFile) return true;
          const isValid = ['png', 'jpg', 'gif', 'jpeg'].includes(getExtension(contactUsFile?.name));
          return isValid;
        },
      })
      .test(
        'single-extension',
        'File must have only a single extension. Avoid using "." multiple times in file name.',
        (contactUsFile: File) => {
          if (!contactUsFile) return true;
          const ext = contactUsFile?.name.split('.');
          const isValid =
            ext.length === 2 && ['png', 'jpg', 'gif', 'jpeg'].includes(ext[1].toLowerCase());
          return isValid;
        },
      )
      .test({
        message: t("File too big, can't exceed 8MB"),
        test: (contactUsFile: File) => {
          if (!contactUsFile) return true;
          const isValid = contactUsFile?.size < MAX_FILE_SIZE;
          return isValid;
        },
      })
      .nullable(),
    firstName: Yup.string()
      .matches(textPattern, t('First name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .when('contactReason', {
        is: contactReasonAnswers.COMPLAINT,
        then: Yup.string()
          .nullable()
          .when('moreSpecificReason', {
            is: moreSpecificReasonAnswers.Complaint.PRODUCT_PACKAGING.value, // product and packaging
            then: Yup.string().nullable().required(t('Please fill out a First Name.')),
            otherwise: Yup.string()
              .nullable()
              .when('moreSpecificReason', {
                is: moreSpecificReasonAnswers.Complaint.ECOMMERSE.value,
                then: Yup.string().nullable().required(t('Please fill out a First Name.')),
              }),
          }),
      }),

    lastName: Yup.string()
      .matches(textPattern, t('Last name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .when('contactReason', {
        is: contactReasonAnswers.COMPLAINT,
        then: Yup.string()
          .nullable()
          .when('moreSpecificReason', {
            is: moreSpecificReasonAnswers.Complaint.PRODUCT_PACKAGING.value, // product and packaging
            then: Yup.string().nullable().required(t('Please fill out a Last Name.')),
            otherwise: Yup.string()
              .nullable()
              .when('moreSpecificReason', {
                is: moreSpecificReasonAnswers.Complaint.ECOMMERSE.value,
                then: Yup.string().nullable().required(t('Please fill out a Last Name.')),
              }),
          }),
      }),
    city: Yup.string().when('contactReason', {
      is: contactReasonAnswers.COMPLAINT,
      then: Yup.string().when('moreSpecificReason', {
        is: moreSpecificReasonAnswers.Complaint.PRODUCT_PACKAGING.value, // product and packaging
        then: Yup.string().required(t('Please fill out a City.')),
        otherwise: Yup.string().when('moreSpecificReason', {
          is: moreSpecificReasonAnswers.Complaint.ECOMMERSE.value,
          then: Yup.string().required(t('Please fill out a City.')),
        }),
      }),
    }),
    state: Yup.string().when('contactReason', {
      is: contactReasonAnswers.COMPLAINT,
      then: Yup.string().when('moreSpecificReason', {
        is: moreSpecificReasonAnswers.Complaint.PRODUCT_PACKAGING.value, // product and packaging
        then: Yup.string().required(t('Please fill out a State.')),
        otherwise: Yup.string().when('moreSpecificReason', {
          is: moreSpecificReasonAnswers.Complaint.ECOMMERSE.value,
          then: Yup.string().required(t('Please fill out a State.')),
        }),
      }),
    }),
    zip: Yup.string()
      .min(5, t('Provide a 5 digit zip code.'))
      .max(5, t('Provide a 5 digit zip code.'))
      .transform((o, c) => (o === '' ? null : c))
      .nullable()
      .when('contactReason', {
        is: contactReasonAnswers.QUESTION,
        then: Yup.string()
          .nullable()
          .when('moreSpecificReason', {
            is: moreSpecificReasonAnswers.Question.WHERE_TO_BUY.value,
            then: Yup.string().nullable().required(t('Please fill out a Zip Code.')),
          }),
        otherwise: Yup.string()
          .nullable()
          .when('contactReason', {
            is: contactReasonAnswers.COMPLAINT,
            then: Yup.string()
              .nullable()
              .when('moreSpecificReason', {
                is: moreSpecificReasonAnswers.Complaint.PRODUCT_PACKAGING.value, // product and packaging
                then: Yup.string().nullable().required(t('Please fill out a Zip Code.')),
                otherwise: Yup.string()
                  .nullable()
                  .when('moreSpecificReason', {
                    is: moreSpecificReasonAnswers.Complaint.ECOMMERSE.value,
                    then: Yup.string().nullable().required(t('Please fill out a Zip Code.')),
                  }),
              }),
          }),
      }),
    // eslint-disable-next-line newline-per-chained-call
    email: Yup.string()
      .email(t('Provide a valid email address.'))
      .required(t('Please fill out an Email.')),
    phoneNumber: Yup.string().when('callFeedbackConsent', {
      is: 1,
      then: Yup.string().required(t('Please fill out a Phone Number.')),
    }),
    address1: Yup.string().when('contactReason', {
      is: contactReasonAnswers.COMPLAINT,
      then: Yup.string().when('moreSpecificReason', {
        is: moreSpecificReasonAnswers.Complaint.PRODUCT_PACKAGING.value, // product and packaging
        then: Yup.string().required(t('Please fill out an Address 1.')),
        otherwise: Yup.string().when('moreSpecificReason', {
          is: moreSpecificReasonAnswers.Complaint.ECOMMERSE.value,
          then: Yup.string().required(t('Please fill out an Address 1.')),
        }),
      }),
    }),
    address2: Yup.string(),
    ownedDogs: Yup.number()
      .nullable()
      .transform((value: unknown) => value || null),
    ownedCats: Yup.number()
      .nullable()
      .transform((value: unknown) => value || null),
    emailFeedbackConsent: Yup.number()
      .transform(value => value ?? null)
      .nullable()
      .required(t('Please fill out an Email Consent.')),
    callFeedbackConsent: Yup.number()
      .transform(value => value ?? null)
      .required(t('Please fill out a Call Consent.'))
      .nullable(),
    emailSubscribingConsent: Yup.boolean()
      .transform(value => value ?? null)
      .nullable(),
  }).required();

export type ContactUsInputsType = Yup.InferType<ReturnType<typeof createContactUsSchema>>;

const encodeBase64File = (file: string) => {
  let encoded = file.replace(/^data:(.*,)?/, '');
  if (encoded.length % 4 > 0) {
    encoded += '='.repeat(4 - ((encoded?.length || 0) % 4));
  }
  return encoded;
};

export const getBase64 = (file: File): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(encodeBase64File(reader.result?.toString() ?? '') || '');
    };
    reader.onerror = error => reject(error);
  });
};
