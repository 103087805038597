import { PageProps, graphql } from 'gatsby';
import * as React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import { getCmsComponent } from '../utils/cmsComponentHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface NodePageProps extends NodeContent {
  relationships?: {
    components: TCmsComponents[];
  };
}
interface DataProps {
  node: NodePageProps;
  translations: Translations;
}

const HomePageTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
}) {
  const components = node.relationships?.components;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {components &&
        components.map((component, index) => {
          return (
            <React.Fragment key={component.id}>
              {getCmsComponent({ node: component, index })}
            </React.Fragment>
          );
        })}
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!, $nid: Int!) {
    node: nodeHomePage(langcode: { eq: $language }) {
      internal {
        type
      }
      id
      path {
        alias
      }
      ...Metatag
      langcode
      title
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
      relationships {
        components: field_storage {
          type: __typename
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageProductCards
          ...StorageRichText
          ...StoragePageTitle
          ...StorageBanner
          ...StorageAnsiraProfileForm
          ...StorageIngredientsMap
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageReviews
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
      }
    }
    translations: allNodeHomePage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default HomePageTemplate;
