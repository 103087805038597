import { Col, Row } from '@purinanbm/pds-ui';
import { motion } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import pointsBadge from 'src/assets/icons/pointsBadge.svg';
import squareCheck from 'src/assets/icons/squareCheck.svg';
import sparklePointsBadge from 'src/assets/media/images/sparkle-points-icon.png';
import PurinaConfetti from 'src/assets/media/lottie/PurinaConfetti.json';
import { LottieContainer } from 'src/utils/LottieContainer';
import Link from '../Link';

interface OfferSuccessProps {
  addedNewPet?: boolean;
}
const OfferSuccess: FC<OfferSuccessProps> = ({ addedNewPet = false }) => {
  const [isReady, setIsReady] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => setIsReady(true), []);
  return (
    <section>
      <motion.div
        aria-live="polite"
        aria-atomic="true"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { delay: 4 } }}
        className="pds-relative pds-z-20 pds-flex pds-flex-col pds-gap-4 pds-self-center pds-bg-transparent"
      >
        <Row>
          <Col>
            <h2 className="pds-max-w-[730px] pds-rounded pds-bg-white pds-text-center pds-text-title-lg pds-font-normal">
              Success! Savings are on the way to your inbox. Don’t forget to grab them!
            </h2>
          </Col>
        </Row>
        <hr
          className="pds-m pds-mx-0 pds-my-3 pds-h-px pds-border-surface-line pds-opacity"
          aria-hidden="true"
        />
        <Row
          className={`pds-flex-row pds-justify-start pds-rounded-pill pds-bg-white ${
            addedNewPet ? 'pds-justify-center' : ''
          }`}
        >
          {!addedNewPet && (
            <Col className="pds-w-1/12 pds-self-start">
              <motion.img
                src={sparklePointsBadge}
                alt=""
                className="pds-h-[40px] pds-w-[40px] md:pds-h-[58px] md:pds-w-[58px]"
              />
            </Col>
          )}
          <Col className="pds-w-11/12 pds-flex-col pds-items-center pds-gap-4 pds-pl-4 pds-text-title-sm pds-font-normal md:pds-pl-0">
            {addedNewPet ? (
              <div className="pds-flex pds-w-[484px] pds-flex-row pds-items-center pds-justify-between pds-rounded pds-bg-surface-layer pds-p-4 pds-text-body-md">
                <img src={squareCheck} alt="" />
                Created a pet profile for the first time
                <div className="pds-rounded-pill pds-bg-surface pds-text-body-sm">
                  <img src={pointsBadge} alt="Perks points" /> + 400 pts
                </div>
              </div>
            ) : (
              <h3 className="pds-text-title-sm pds-font-normal">
                Earn rewards through the Purina Perks loyalty program
              </h3>
            )}
            {addedNewPet ? (
              <p className="pds-text-body-lg">
                Way to go! View your total loyalty points on the myPurina app.
              </p>
            ) : (
              <p className="pds-max-w-[625px] pds-text-body-md pds-font-normal">
                You and your pet will love watching the points pile up. Trade them in for all sorts
                of pet-perfect prizes!
              </p>
            )}
          </Col>
        </Row>
        <Row className="pds-justify-center pds-rounded-pill">
          <Link
            to="https://mypurina.onelink.me/obkf/offers"
            className="pds-flex pds-h-[54px] pds-w-[301px] pds-items-center pds-justify-center pds-rounded-pill pds-border pds-border-solid pds-border-neutral pds-bg-transparent pds-text-center pds-text-body-md pds-font-light pds-text-neutral pds-no-underline hover:pds-bg-surface-layer"
          >
            {t('Go to myPurina')}
          </Link>
        </Row>
      </motion.div>
      {isReady &&
        createPortal(
          <LottieContainer
            animationData={PurinaConfetti}
            className="pds-absolute pds-top-0 pds-min-w-[1600px]"
            options={{}}
          />,
          document.getElementById('offer-container') as Element,
        )}
    </section>
  );
};

export default OfferSuccess;
