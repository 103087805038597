import { pdsTv } from '@purinanbm/pds-ui';
import React, { useRef } from 'react';
import { Container } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import IconCard from '../components/icon_card/IconCard';
import RichText from '../components/rich_text/RichText';
import useIsOverflowX from '../hooks/useIsOverflowX';
import { getFocalPoint, getMedia } from '../utils/mediaHelpers';

type Props = {
  node: IStorageCategoryCards;
};

const styles = pdsTv({
  slots: {
    text: 'pds-flex pds-flex-col pds-items-center pds-justify-center',
    container: 'pds-py-[120px]',
    grid: 'pds-grid pds-gap-4 pds-overflow-x-auto pds-p-5 [&_.card]:pds-min-h-[280px]',
  },
  variants: {
    theme: {
      light: {
        text: 'pds-text-paper-text',
        container: 'pds-bg-white',
      },
      dark: {
        text: 'pds-text-white',
        container: 'pds-bg-[#1E1E1E]',
      },
      neutral: {
        text: 'pds-text-paper-text',
        container: 'pds-bg-surface-foreground',
      },
    },
    isOverflow: {
      true: {
        grid: 'pds-justify-start',
      },
      false: {
        grid: 'pds-justify-center',
      },
    },
  },
});

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const StorageCategoryCards: React.FC<Props> = ({ node: categoryCards }) => {
  const settings = Object.assign(defaultSettings, categoryCards?.behaviors || {});
  const containerRef = useRef(null);
  const rows = Number(categoryCards.behaviors?.entity_rows_columns?.rows);
  const columns = Number(categoryCards.behaviors?.entity_rows_columns?.columns);
  const { title } = categoryCards;
  const richText = categoryCards?.text?.processed;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const iconMenu = categoryCards.relationships.icon_menu;
  const items = iconMenu?.relationships.field_menu_links;

  const cmsTheme = settings?.entity_variant?.theme;
  const isOverflow = useIsOverflowX(containerRef);

  // If menu is draft, all items are draft, or menu items untranslated, don't render.
  if (!iconMenu || !items || !items.length) {
    console.error(
      `StorageCategoryCards: Missing menu or menu items for StorageCards component ${categoryCards.drupal_id}.`,
    );
    return null;
  }

  const numOfItems = items.length;
  const { container, grid, text } = styles({ theme: cmsTheme, isOverflow });
  return (
    <Container
      id={categoryCards.component_id}
      role="group"
      aria-labelledby={`${categoryCards.drupal_id}-title`}
      data-id={categoryCards.drupal_id}
      fluid
      className={container()}
    >
      <Container fluid className={text()}>
        {title && (
          <HeadingElement
            id={`${categoryCards.drupal_id}-title`}
            className={`pds-mb-4 ${headingStyle}`}
            aria-label={title}
          >
            {title}
          </HeadingElement>
        )}
        {richText && (
          <RichText
            cmsTheme={cmsTheme}
            className="category-cards__body pds-mb-5.5"
            body={richText}
          />
        )}
      </Container>
      <ul
        ref={containerRef}
        className={grid()}
        style={{
          gridTemplateRows: isOverflow ? '1fr' : `repeat(${rows}, 1fr)`,
          gridTemplateColumns: isOverflow
            ? `repeat(${numOfItems}, 260px)`
            : `repeat(${columns}, 260px)`,
        }}
      >
        {items
          .filter(item => item.link)
          .map(item => {
            const fieldMedia = item.relationships.field_media;
            const isIcon =
              'svg' in fieldMedia
                ? fieldMedia?.relationships?.svg?.filemime.search(/svg/i) > -1
                : fieldMedia?.relationships?.field_media_image?.filemime.search(/png/i) > -1;

            return (
              <li key={item.drupal_id} className="pds-list-none">
                <IconCard
                  drupalId={item.drupal_id}
                  cmsTheme={cmsTheme}
                  image={getMedia({
                    media: fieldMedia,
                    objectFit: isIcon ? 'contain' : 'cover',
                    alt: '',
                    objectPosition: isIcon
                      ? '50% 50%'
                      : getFocalPoint(item.relationships.field_media),
                  })}
                  to={item.link.url}
                  title={item.link.title}
                  {...item.link.options?.attributes}
                />
              </li>
            );
          })}
      </ul>
    </Container>
  );
};

export default StorageCategoryCards;
