import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import * as React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import IngredientModal from '../ingredients/IngredientModal';

const StyledImageWrapper = styled.div`
  display: inline-flex;
  padding: 25px;
  border-radius: 50%;
  background: #ffffff;
  margin-bottom: 1rem;
`;

interface IngredientProps {
  ingredients: TaxonomyTermIngredient[];
  title?: string;
  bgColor?: 'white' | 'light';
  maxHeight?: string;
}

const ProductIngredients: React.FC<IngredientProps> = function ({
  ingredients,
  title,
  bgColor = 'light',
  maxHeight,
}) {
  const [showAll, setShowAll] = React.useState(false);
  const [modalId, setModalId] = React.useState('');
  const ref = React.useRef<React.ElementRef<'div'>>(null);

  React.useEffect(() => {
    if (showAll) {
      ref.current?.focus();
    }
  }, [showAll]);

  const { t } = useTranslation();

  const handleCloseModal = () => {
    setModalId('');
  };

  const handleKeyUp = (event: React.KeyboardEvent, id: string) => {
    const keyValue = event.key;
    if (keyValue === 'Enter' || keyValue === ' ') {
      event.preventDefault();
      setModalId(id);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const keyValue = event.key;
    if (keyValue === 'Enter' || keyValue === ' ') {
      event.preventDefault();
    }
  };

  const handleShow = () => {
    setShowAll(!showAll);
  };

  return (
    <AnalyticsPoint type="module" typeLabel="ingredients" label="ingredients module">
      <Container className={cx(bgColor ? `bg-${bgColor}` : 'bg-light', 'p-md-5 p-3 rounded-2')}>
        {title && (
          <Row className="text-center">
            <h2 className="h4">{title}</h2>
          </Row>
        )}
        <Row className="overflow-auto py-2 gy-4 my-3" style={{ maxHeight: maxHeight || '400px' }}>
          {ingredients.map((ingredient, index) => {
            const hide = index > 5 && !showAll ? 'd-none' : 'd-block';
            const image = ingredient.relationships?.field_image?.gatsbyImage;
            if (image) {
              return (
                <React.Fragment key={ingredient.id}>
                  <AnalyticsPoint
                    type="component"
                    action="ingredient modal"
                    category="ingredients"
                    eventLabel={`ingredient - ${ingredient.name}`}
                    label={`ingredient - ${ingredient.name}`}
                    node={{ ...ingredient, type: 'ingredient_item' }}
                    as={Col}
                    key={ingredient.id}
                    xs={6}
                    sm={4}
                    lg={3}
                    xl={2}
                    className={`${hide} text-center`}
                  >
                    <StyledImageWrapper
                      role="button"
                      onClick={() => setModalId(ingredient.id)}
                      onKeyUp={event => handleKeyUp(event, ingredient.id)}
                      onKeyDown={handleKeyDown}
                      tabIndex={0}
                      ref={index === 6 ? ref : undefined}
                      className="js-track"
                    >
                      <GatsbyImage
                        alt={ingredient.name}
                        image={image}
                        style={{ height: '100px', width: '100px' }}
                      />
                    </StyledImageWrapper>
                    <div>{ingredient.name}</div>
                  </AnalyticsPoint>
                  <IngredientModal
                    ingredient={ingredient}
                    show={modalId === ingredient.id}
                    handleClose={handleCloseModal}
                  />
                </React.Fragment>
              );
            }
            return null;
          })}
        </Row>
        <div className="text-center mt-5">
          <Button
            onClick={handleShow}
            variant={`${bgColor === 'white' ? 'primary' : 'outline-primary'}`}
            className="rounded-pill px-5 js-track"
          >
            {showAll ? t('Show less ingredients') : t('View all ingredients')}
          </Button>
        </div>
      </Container>
    </AnalyticsPoint>
  );
};

export default ProductIngredients;
