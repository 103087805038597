export function getFocusableChildren(element: HTMLElement | null | undefined): HTMLElement[] {
  if (!element) return [];

  const FOCUSABLE_ELEMENT_SELECTOR =
    'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])';

  const isVisible = (el: HTMLElement) => {
    const { width, height } = el.getBoundingClientRect();
    return width > 0 && height > 0;
  };

  const focusableElements = Array.from(
    element.querySelectorAll<HTMLElement>(FOCUSABLE_ELEMENT_SELECTOR),
  ).filter(isVisible);

  return focusableElements;
}
