import { SSOUtilityModal } from '@purinanbm/pup-script/dist/react/SSOUtilityModal';
import React from 'react';
import { SSO_UTILITY_MODAL_CONFIG } from '../common/constants';

export const Gate: React.FC = () => {
  return (
    <SSOUtilityModal
      open
      canContinueAsGuest={false}
      handleClose={() => console.log('modal close')}
      config={SSO_UTILITY_MODAL_CONFIG}
    />
  );
};
