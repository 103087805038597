/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BodyUploadMediaSstServicesV1MediaUserIdPost,
  BreedResponse,
  CreateUpdateUserSubscriptionResponse,
  DeleteUserRequestBody,
  DisableUserRequestBody,
  EmailPayload,
  GetUserSubscriptionResponse,
  HTTPValidationError,
  MediaResponseListMediaItem,
  MediaResponseMediaObject,
  Pet,
  PetOwnershipResponse,
  PetResponse,
  PetTypeID,
  PetTypeResponse,
  PutMediaRequest,
  SetTempPasswordPayload,
  UpdatePasswordPayload,
  User,
  UserAvailableSubscriptionResponse,
  UserExistsResponse,
  UserResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SstServices<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Used for getting all media for a given user.
   *
   * @tags Media
   * @name GetAllMediaSstServicesV1MediaUserIdGet
   * @summary Get All Media
   * @request GET:/sst-services/v1/media/{user_id}
   * @secure
   */
  getAllMediaSstServicesV1MediaUserIdGet = (args: { userId: string; params?: RequestParams }) =>
    this.request<MediaResponseListMediaItem, HTTPValidationError>({
      path: `/sst-services/v1/media/${args.userId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Used for uploading media for a given user. The request should be a multipart/form-data with the following parts: - `file`: The file you're uploading - `data`: A JSON string containing the data needed to be able to retrieve this media. An example is below. Example data object: ```json { "fileType": "Pet", "mediaType": "Images", "petId": "123456789", "isPrimary": true }
   *
   * @tags Media
   * @name UploadMediaSstServicesV1MediaUserIdPost
   * @summary Upload Media
   * @request POST:/sst-services/v1/media/{user_id}
   * @secure
   */
  uploadMediaSstServicesV1MediaUserIdPost = (args: {
    userId: string;
    data: BodyUploadMediaSstServicesV1MediaUserIdPost;
    params?: RequestParams;
  }) =>
    this.request<MediaResponseMediaObject, HTTPValidationError>({
      path: `/sst-services/v1/media/${args.userId}`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.FormData,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Used to update a media item as the primary media. Typically used for displaying the specified media for a pet or profile.
   *
   * @tags Media
   * @name UpdateIsprimaryFlagSstServicesV1MediaUserIdMediaIdPut
   * @summary Update Isprimary Flag
   * @request PUT:/sst-services/v1/media/{user_id}/{media_id}
   * @secure
   */
  updateIsprimaryFlagSstServicesV1MediaUserIdMediaIdPut = (args: {
    mediaId: string;
    userId: string;
    data: PutMediaRequest;
    params?: RequestParams;
  }) =>
    this.request<MediaResponseListMediaItem, HTTPValidationError>({
      path: `/sst-services/v1/media/${args.userId}/${args.mediaId}`,
      method: 'PUT',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Deletes a specific piece of media for a user. This is a hard delete and is not able to be reversed.
   *
   * @tags Media
   * @name DeleteMediaSstServicesV1MediaUserIdMediaIdDelete
   * @summary Delete Media
   * @request DELETE:/sst-services/v1/media/{user_id}/{media_id}
   * @secure
   */
  deleteMediaSstServicesV1MediaUserIdMediaIdDelete = (args: {
    userId: string;
    mediaId: string;
    params?: RequestParams;
  }) =>
    this.request<void, HTTPValidationError>({
      path: `/sst-services/v1/media/${args.userId}/${args.mediaId}`,
      method: 'DELETE',
      secure: true,
      ...args.params,
    });
  /**
   * @description Get list of breeds.
   *
   * @tags Pets
   * @name GetBreedsSstServicesV1PetsBreedsGet
   * @summary Get Breeds
   * @request GET:/sst-services/v1/pets/breeds
   * @secure
   */
  getBreedsSstServicesV1PetsBreedsGet = (args: { params?: RequestParams }) =>
    this.request<BreedResponse, any>({
      path: `/sst-services/v1/pets/breeds`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get single breed information.
   *
   * @tags Pets
   * @name GetBreedSstServicesV1PetsBreedsBreedIdGet
   * @summary Get Breed
   * @request GET:/sst-services/v1/pets/breeds/{breed_id}
   * @secure
   */
  getBreedSstServicesV1PetsBreedsBreedIdGet = (args: { breedId: string; params?: RequestParams }) =>
    this.request<BreedResponse, HTTPValidationError>({
      path: `/sst-services/v1/pets/breeds/${args.breedId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get all pet ownership plans.
   *
   * @tags Pets
   * @name GetPetownershipplansSstServicesV1PetsPetownershipplansGet
   * @summary Get Petownershipplans
   * @request GET:/sst-services/v1/pets/petownershipplans
   * @secure
   */
  getPetownershipplansSstServicesV1PetsPetownershipplansGet = (args: { params?: RequestParams }) =>
    this.request<PetOwnershipResponse, any>({
      path: `/sst-services/v1/pets/petownershipplans`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get all Pet Type information.
   *
   * @tags Pets
   * @name GetPetTypesSstServicesV1PetsPettypesGet
   * @summary Get Pet Types
   * @request GET:/sst-services/v1/pets/pettypes
   * @secure
   */
  getPetTypesSstServicesV1PetsPettypesGet = (args: { params?: RequestParams }) =>
    this.request<PetTypeResponse, any>({
      path: `/sst-services/v1/pets/pettypes`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get Pet Type from single Pet Type ID.
   *
   * @tags Pets
   * @name GetPetTypeSstServicesV1PetsPettypesPetTypeIdGet
   * @summary Get Pet Type
   * @request GET:/sst-services/v1/pets/pettypes/{pet_type_id}
   * @secure
   */
  getPetTypeSstServicesV1PetsPettypesPetTypeIdGet = (args: {
    petTypeId: PetTypeID;
    params?: RequestParams;
  }) =>
    this.request<PetTypeResponse, HTTPValidationError>({
      path: `/sst-services/v1/pets/pettypes/${args.petTypeId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get all pets for a user.
   *
   * @tags Pets
   * @name GetUserAllPetsSstServicesV1PetsUsersUserIdPetsGet
   * @summary Get User All Pets
   * @request GET:/sst-services/v1/pets/users/{user_id}/pets
   * @secure
   */
  getUserAllPetsSstServicesV1PetsUsersUserIdPetsGet = (args: {
    userId: string;
    params?: RequestParams;
  }) =>
    this.request<PetResponse, HTTPValidationError>({
      path: `/sst-services/v1/pets/users/${args.userId}/pets`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Creates User Pet Profile.
   *
   * @tags Pets
   * @name PostUserPetsSstServicesV1PetsUsersUserIdPetsPost
   * @summary Post User Pets
   * @request POST:/sst-services/v1/pets/users/{user_id}/pets
   * @secure
   */
  postUserPetsSstServicesV1PetsUsersUserIdPetsPost = (args: {
    userId: string;
    data: Pet;
    params?: RequestParams;
  }) =>
    this.request<PetResponse, HTTPValidationError>({
      path: `/sst-services/v1/pets/users/${args.userId}/pets`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get information for a given user's pet.
   *
   * @tags Pets
   * @name GetUserPetSstServicesV1PetsUsersUserIdPetsPetIdGet
   * @summary Get User Pet
   * @request GET:/sst-services/v1/pets/users/{user_id}/pets/{pet_id}
   * @secure
   */
  getUserPetSstServicesV1PetsUsersUserIdPetsPetIdGet = (args: {
    userId: string;
    petId: string;
    params?: RequestParams;
  }) =>
    this.request<PetResponse, HTTPValidationError>({
      path: `/sst-services/v1/pets/users/${args.userId}/pets/${args.petId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Update user's Pet.
   *
   * @tags Pets
   * @name UpdateUserPetSstServicesV1PetsUsersUserIdPetsPetIdPut
   * @summary Update User Pet
   * @request PUT:/sst-services/v1/pets/users/{user_id}/pets/{pet_id}
   * @secure
   */
  updateUserPetSstServicesV1PetsUsersUserIdPetsPetIdPut = (args: {
    userId: string;
    petId: string;
    data: Pet;
    params?: RequestParams;
  }) =>
    this.request<PetResponse, HTTPValidationError>({
      path: `/sst-services/v1/pets/users/${args.userId}/pets/${args.petId}`,
      method: 'PUT',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Delete user's pet.
   *
   * @tags Pets
   * @name DeleteUserPetSstServicesV1PetsUsersUserIdPetsPetIdDelete
   * @summary Delete User Pet
   * @request DELETE:/sst-services/v1/pets/users/{user_id}/pets/{pet_id}
   * @secure
   */
  deleteUserPetSstServicesV1PetsUsersUserIdPetsPetIdDelete = (args: {
    userId: string;
    petId: string;
    params?: RequestParams;
  }) =>
    this.request<PetResponse, HTTPValidationError>({
      path: `/sst-services/v1/pets/users/${args.userId}/pets/${args.petId}`,
      method: 'DELETE',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get a user's current subscriptions
   *
   * @tags Subscriptions
   * @name GetUserSubscriptionsSstServicesV1SubscriptionsUserIdGet
   * @summary Get User Subscriptions
   * @request GET:/sst-services/v1/subscriptions/{user_id}
   * @secure
   */
  getUserSubscriptionsSstServicesV1SubscriptionsUserIdGet = (args: {
    userId: string;
    params?: RequestParams;
  }) =>
    this.request<GetUserSubscriptionResponse, HTTPValidationError>({
      path: `/sst-services/v1/subscriptions/${args.userId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get all subscriptions available to a user not in their current subscriptions
   *
   * @tags Subscriptions
   * @name ListAvailableSubscriptionsSstServicesV1SubscriptionsUserIdAvailableSubscriptionsGet
   * @summary List Available Subscriptions
   * @request GET:/sst-services/v1/subscriptions/{user_id}/available_subscriptions
   * @secure
   */
  listAvailableSubscriptionsSstServicesV1SubscriptionsUserIdAvailableSubscriptionsGet = (args: {
    userId: string;
    params?: RequestParams;
  }) =>
    this.request<UserAvailableSubscriptionResponse, HTTPValidationError>({
      path: `/sst-services/v1/subscriptions/${args.userId}/available_subscriptions`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Creates Subscription for a user.
   *
   * @tags Subscriptions
   * @name CreateUserPostEndpointSstServicesV1SubscriptionsUserIdSubscriptionsPost
   * @summary Create User Post Endpoint
   * @request POST:/sst-services/v1/subscriptions/{user_id}/subscriptions
   * @secure
   */
  createUserPostEndpointSstServicesV1SubscriptionsUserIdSubscriptionsPost = (args: {
    userId: string;
    data: any[];
    params?: RequestParams;
  }) =>
    this.request<CreateUpdateUserSubscriptionResponse, HTTPValidationError>({
      path: `/sst-services/v1/subscriptions/${args.userId}/subscriptions`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get User information based on user's email address.
   *
   * @tags Users
   * @name GetUserByEmailSstServicesV1UsersGet
   * @summary Get User By Email
   * @request GET:/sst-services/v1/users/
   * @secure
   */
  getUserByEmailSstServicesV1UsersGet = (args: {
    query: {
      /**
       * User Email
       * The user email address
       * @format email
       */
      user_email: string;
    };
    params?: RequestParams;
  }) =>
    this.request<UserResponse, HTTPValidationError>({
      path: `/sst-services/v1/users/`,
      method: 'GET',
      query: args.query,
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Get User information based on user's Ansira ID.
   *
   * @tags Users
   * @name GetUserByUserIdSstServicesV1UsersUserIdGet
   * @summary Get User By User Id
   * @request GET:/sst-services/v1/users/{user_id}
   * @secure
   */
  getUserByUserIdSstServicesV1UsersUserIdGet = (args: { userId: string; params?: RequestParams }) =>
    this.request<UserResponse, HTTPValidationError>({
      path: `/sst-services/v1/users/${args.userId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Update user
   *
   * @tags Users
   * @name UpdateUserSstServicesV1UsersUserIdPut
   * @summary Update User
   * @request PUT:/sst-services/v1/users/{user_id}
   * @secure
   */
  updateUserSstServicesV1UsersUserIdPut = (args: {
    userId: string;
    data: User;
    params?: RequestParams;
  }) =>
    this.request<UserResponse, HTTPValidationError>({
      path: `/sst-services/v1/users/${args.userId}`,
      method: 'PUT',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Updates user's password.
   *
   * @tags Users
   * @name UpdateUserPasswordSstServicesV1UsersUserIdPasswordPost
   * @summary Update User Password
   * @request POST:/sst-services/v1/users/{user_id}/password
   * @secure
   */
  updateUserPasswordSstServicesV1UsersUserIdPasswordPost = (args: {
    userId: string;
    data: UpdatePasswordPayload;
    params?: RequestParams;
  }) =>
    this.request<void, HTTPValidationError>({
      path: `/sst-services/v1/users/${args.userId}/password`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      ...args.params,
    });
  /**
   * @description Checks if user's email is in the cognito user pools
   *
   * @tags Users
   * @name PostCheckUserByEmailSstServicesV1UsersCheckUserEmailPost
   * @summary Post Check User By Email
   * @request POST:/sst-services/v1/users/check-user-email
   * @secure
   */
  postCheckUserByEmailSstServicesV1UsersCheckUserEmailPost = (args: {
    data: EmailPayload;
    params?: RequestParams;
  }) =>
    this.request<UserExistsResponse, HTTPValidationError>({
      path: `/sst-services/v1/users/check-user-email`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Disable a user in the Cognito User Pool
   *
   * @tags Users
   * @name DisableUserSstServicesV1UsersDisablePost
   * @summary Disable User
   * @request POST:/sst-services/v1/users/disable
   * @secure
   */
  disableUserSstServicesV1UsersDisablePost = (args: {
    data: DisableUserRequestBody;
    params?: RequestParams;
  }) =>
    this.request<void, HTTPValidationError>({
      path: `/sst-services/v1/users/disable`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      ...args.params,
    });
  /**
   * @description Delete a user that has been disabled in the Cognito User Pool
   *
   * @tags Users
   * @name DeleteUserSstServicesV1UsersDeletePost
   * @summary Delete User
   * @request POST:/sst-services/v1/users/delete
   * @secure
   */
  deleteUserSstServicesV1UsersDeletePost = (args: {
    data: DeleteUserRequestBody;
    params?: RequestParams;
  }) =>
    this.request<void, HTTPValidationError>({
      path: `/sst-services/v1/users/delete`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      ...args.params,
    });
  /**
   * @description Allow OCA or other admins to set a temporary password for a user
   *
   * @tags Users
   * @name SetTempPasswordSstServicesV1UsersSetTempPasswordPost
   * @summary Set Temp Password
   * @request POST:/sst-services/v1/users/set-temp-password
   * @secure
   */
  setTempPasswordSstServicesV1UsersSetTempPasswordPost = (args: {
    data: SetTempPasswordPayload;
    params?: RequestParams;
  }) =>
    this.request<void, HTTPValidationError>({
      path: `/sst-services/v1/users/set-temp-password`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      ...args.params,
    });
}
