import { pdsTv } from '@purinanbm/pds-ui';
import { GatsbyImageProps } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { CMS_THEME } from '../../common/enums';
import Link, { LinkProps } from '../Link';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';

type Props = {
  drupalId?: string;
  cmsTheme?: CMS_THEME;
  image?: React.ReactElement<GatsbyImageProps> | null;
  link: string;
  title: string;
};

const LinkedCard = (props: LinkProps) => <Card as={Link} {...props} />;

const styles = pdsTv({
  slots: {
    iconCard:
      '!pds-h-full !pds-border-none pds-pb-4 pds-pt-5 pds-no-underline pds-transition-shadow pds-duration-300 hover:pds-cursor-pointer hover:pds-underline focus:pds-cursor-pointer focus:pds-underline active:pds-cursor-pointer active:pds-underline',
    imageContainer:
      'pds-relative pds-mx-auto pds-flex pds-w-full pds-overflow-hidden pds-rounded-circle pds-pt-[100%]',
  },
  variants: {
    theme: {
      light: {
        iconCard:
          '!pds-bg-paper-500 !pds-text-paper-text hover:!pds-text-paper-text  focus:!pds-text-paper-text active:!pds-text-paper-text',
        imageContainer: 'pds-bg-[#EEF1F8]',
      },
      dark: {
        iconCard:
          '!pds-bg-paper-700 !pds-text-white hover:!pds-text-white  focus:!pds-text-white active:!pds-text-white',
        imageContainer: 'pds-bg-white',
      },
      neutral: {
        iconCard:
          '!pds-bg-white !pds-text-paper-text hover:!pds-text-paper-text  focus:!pds-text-paper-text active:!pds-text-paper-text',
        imageContainer: 'pds-bg-surface-foreground',
      },
    },
  },
});

const IconCard = function ({
  image,
  link,
  title,
  cmsTheme = CMS_THEME.LIGHT,
  drupalId,
  ...props
}: Props) {
  const { iconCard, imageContainer } = styles({ theme: cmsTheme });
  return (
    <LinkedCard
      onClick={() => {
        sendIt({
          event: 'nav_click',
          eventParams: {
            nav_item: title,
            nav_type: 'category_icons',
          },
        });
      }}
      to={link}
      className={iconCard()}
      {...props}
    >
      <div className="pds-mx-auto pds-flex pds-h-full pds-w-[65%] pds-flex-col [&_*_+_*]:pds-mt-4.5">
        {image && (
          <div className={imageContainer()}>
            {React.cloneElement(image, {
              imgStyle: {
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
              },
              style: {
                height:
                  image?.props?.objectFit === 'contain' ? 'calc(50% * 1.4142 - 0.625rem)' : '100%', // Circle inscribed with a square with a 10px margin.
                left: '50%',
                position: 'absolute',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                width:
                  image?.props?.objectFit === 'contain' ? 'calc(50% * 1.4142 - 0.625rem)' : '100%',
              },
            })}
          </div>
        )}
        <span className="typography_body pds-flex pds-grow pds-flex-col pds-content-center pds-text-center pds-font-light">
          {title}
        </span>
      </div>
    </LinkedCard>
  );
};

export default IconCard;
