import Icon from '@mdi/react';
import { Paper } from '@purinanbm/pds-ui';
import { AnimatePresence, motion } from 'framer-motion';

import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useEffect, useRef, useState } from 'react';
import {
  Controller,
  ControllerRenderProps,
  FieldErrors,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { mdiAlertCircleOutline } from 'src/assets/icons/mdiIcons';
import { MimeType, isMimeType } from 'src/common/enums';
import Alert from 'src/components/alert/Alert';
import { useAuth } from 'src/hooks/useAuth';
import { LOYALTY_MUTATIONS, useLoyaltyMutation } from 'src/hooks/useLoyaltyService';
import { PostReceiptImageRequestBody } from 'src/services/loyalty/data-contracts';
import { PURINA_PERKS_ROUTES } from '../PurinaPerksLayout';
import { UploadReceiptInput } from './UploadReceiptInput';
import { compressImage, hashImage } from './receipt-processing';

type AlertData = {
  key: string;
  type: 'error' | 'success' | 'info' | 'warning';
  message: string;
};

type FormValues = {
  files: File[];
};

const ONE_MEGABYTE = 1024 * 1024;
const MAX_IMAGE_SUBMISSION_SIZE = 1.8 * ONE_MEGABYTE;
const MAX_IMAGE_INPUT_SIZE = 6 * ONE_MEGABYTE;
const ALLOWED_MIME_TYPES = [MimeType.IMAGE_JPEG, MimeType.IMAGE_JPG, MimeType.IMAGE_PNG];

const isAllowedMimeType = (s: string): boolean => {
  return isMimeType(s) ? ALLOWED_MIME_TYPES.includes(s) : false;
};

const createInfoAlert = (message: string): AlertData => {
  return {
    key: crypto.randomUUID(),
    type: 'info',
    message,
  };
};
const createErrorAlert = (message: string): AlertData => {
  return {
    key: crypto.randomUUID(),
    type: 'error',
    message,
  };
};

const createErrorAlerts = (errors: FieldErrors<FormValues>): AlertData[] => {
  return errors.files ? [createErrorAlert(errors.files.message ?? '')] : [];
};
interface IError {
  error: {
    title: string;
    status: string;
    details: string;
  };
}

// needed to safely extract the error object type from the server response
function isErrorFromServer(obj: unknown): obj is IError {
  return (
    (obj as IError)?.error !== undefined &&
    typeof (obj as IError).error.status === 'string' &&
    typeof (obj as IError).error.title === 'string'
  );
}

const extractDuplicateFileNames = async (files: File[]): Promise<string[]> => {
  const elementMap = new Map<string, { file: File; count: number }>();

  const filesWithHash = await Promise.all(
    files.map(async f => ({ file: f, hash: await hashImage(f) })),
  );

  filesWithHash.forEach(({ file, hash }) => {
    const count = elementMap.get(hash)?.count ?? 0;
    elementMap.set(hash, { file, count: count + 1 });
  });

  return Array.from(elementMap)
    .filter(([_, { count }]) => count > 1)
    .map(([_, { file }]) => file.name);
};

export const UploadReceiptForm = () => {
  const alertsRef = useRef<HTMLDivElement | null>(null);

  const [isCompressing, setIsCompressing] = useState(false);
  const [alerts, setAlerts] = useState<AlertData[]>([]);
  const [infoMessage, setInfoMessage] = useState<string>('');

  const { user } = useAuth();

  const { control, handleSubmit, getValues, formState, setError, clearErrors, reset, setValue } =
    useForm<FormValues>({
      defaultValues: {
        files: [],
      },
    });

  const mutation = useLoyaltyMutation(LOYALTY_MUTATIONS.POST_RECEIPTS_CREATE, {
    customerId: user?.ansiraUuid || '',
    data: {},
  });

  const isSubmitting = isCompressing || mutation.isLoading;

  useEffect(() => {
    if (!infoMessage) return;
    const newAlerts = [createInfoAlert(infoMessage)];
    setAlerts(newAlerts);

    if (newAlerts.length > 0) {
      alertsRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
      alertsRef.current?.focus();
    }
  }, [infoMessage]);

  useEffect(() => {
    const newAlerts = createErrorAlerts(formState.errors);
    setAlerts(newAlerts);

    if (newAlerts.length > 0) {
      alertsRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  }, [formState.errors, formState.errors.files]);

  const onSubmit: SubmitHandler<FormValues> = async () => {
    if (isSubmitting) return;
    if (formState.errors.files) return;

    let base64Images: string[];

    try {
      setIsCompressing(true);

      base64Images = await Promise.all(
        getValues('files').map(async file => {
          return compressImage(file, MAX_IMAGE_SUBMISSION_SIZE);
        }),
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      setError('files', {
        type: 'compression',
        message:
          'We’re having a hard time compressing the images. Please re-upload. If it happens again, try uploading new images.',
      });

      return;
    } finally {
      setIsCompressing(false);
    }

    const requestBody: PostReceiptImageRequestBody = { images: base64Images };
    // submit to analytics
    const totalCount = base64Images.length;
    sendIt({
      event: 'scan_receipt_submit',
      eventParams: {
        photo_count: totalCount.toString(),
        method: '(not set)',
      },
    });

    mutation.mutate(requestBody, {
      onSuccess: () => {
        reset();
        sendIt({
          event: 'scan_receipt_success',
          eventParams: {
            photo_count: totalCount.toString(),
            method: '(not set)',
          },
        });
        navigate(PURINA_PERKS_ROUTES.ReceiptUploadSuccess, {
          state: { showReceiptUploadSuccess: true },
        });
      },
      onError: error => {
        if (isErrorFromServer(error)) {
          const { details = '', title = '', status = '' } = error.error ?? {};
          sendIt({
            event: 'error_occurred',
            eventParams: {
              error_field: 'receipt_form',
              error_code: status,
              error_name: details ?? title,
              error_feature: 'receipt_upload_submission',
            },
          });
        }
        setError('files', {
          type: 'submit',
          message:
            'Connection error. The wires most likely got crossed on our end, so please try again.',
        });
        sendIt({ event: 'receipt_rejected' });
      },
    });
  };

  const validateFiles = async (files: File[]): Promise<string | boolean> => {
    if (!files || files.length === 0) {
      return 'Please upload at least one image to submit your receipt.';
    }

    if (files.length > 3) {
      return 'Too many images. Please only upload up to 3 images per receipt submission.';
    }

    const oversizedFiles = files.filter(f => f.size > MAX_IMAGE_INPUT_SIZE);

    if (oversizedFiles.length > 0) {
      return 'The image is too large. Uploaded images must be 6 MB or less. Please try again with a smaller image.';
    }

    const invalidMimeTypeFiles = files.filter(f => !isAllowedMimeType(f.type));

    if (invalidMimeTypeFiles.length > 0) {
      return 'Please check the file type. All images must be in jpg, jpeg or png format.';
    }

    const duplicateFiles = await extractDuplicateFileNames(files);

    if (duplicateFiles.length > 0) {
      return `This looks familiar. ${duplicateFiles.join(', ')} has already been uploaded.`;
    }

    return true;
  };

  const handleFileUpdate = async (
    fileList: FileList | null,
    field: ControllerRenderProps<FormValues, 'files'>,
  ) => {
    if (!fileList || fileList.length === 0) return;

    clearErrors('files');

    const prevFiles = field.value;
    const newFiles = Array.from(fileList);
    const files = [...prevFiles, ...newFiles];

    const validation = await validateFiles(files);

    if (typeof validation === 'string') {
      setError('files', { type: 'validate', message: validation });
      sendIt({ event: 'receipt_rejected' });
      sendIt({
        event: 'error_occurred',
        eventParams: {
          error_field: 'receipt_form',
          error_code: 'ValidationFail',
          error_name: validation,
          error_feature: 'receipt_upload_file_field',
        },
      });
    } else {
      setInfoMessage(`Uploaded files: ${files.map(file => file.name).join(', ')}`);
      field.onChange(files);
      sendIt({
        event: 'scan_receipt_upload',
        eventParams: {
          method: '(not set)',
        },
      });
    }
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    field: ControllerRenderProps<FormValues, 'files'>,
  ) => {
    const { files } = event.dataTransfer;
    handleFileUpdate(files, field);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<FormValues, 'files'>,
  ) => {
    const { files } = event.currentTarget;
    handleFileUpdate(files, field);
  };

  const handleRemove = async (index: number) => {
    if (isSubmitting) return;

    const prevFiles = getValues('files');
    const files = [...prevFiles.slice(0, index), ...prevFiles.slice(index + 1)];

    if (files.length > 0) {
      setInfoMessage(`Uploaded files: ${files.map(file => file.name).join(', ')}`);
    } else {
      setInfoMessage('');
      setAlerts([]);
    }

    clearErrors('files');
    setValue('files', files);
  };

  return (
    <Paper role="group" aria-label="Upload Receipt" className="pds-px-4.5 pds-py-5">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={alerts.length > 0 ? 'pds-mb-4 md:pds-mb-5' : ''}
          ref={alertsRef}
          tabIndex={-1}
        >
          <AnimatePresence mode="wait">
            {alerts.map(alert => (
              <motion.div
                key={alert.key}
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 100 }}
              >
                <Alert variant={alert.type}>
                  <div className="flex-row pds-flex pds-gap-3">
                    <Icon path={mdiAlertCircleOutline} size={1} className="pds-shrink-0" />
                    <div>{alert.message}</div>
                  </div>
                </Alert>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        {/* Use flex-row-reverse and flex-wrap-reverse to adjust the visual ordering to allow the description to be first in the DOM order */}
        <div className="pds-flex pds-flex-row-reverse pds-flex-wrap-reverse pds-gap-x-5 pds-gap-y-4">
          <Controller
            name="files"
            control={control}
            rules={{
              validate: validateFiles,
            }}
            render={({ field }) => (
              <UploadReceiptInput
                {...field}
                isSubmitting={isSubmitting}
                allowedMimeTypes={ALLOWED_MIME_TYPES}
                onDrop={e => handleDrop(e, field)}
                onChange={e => handleChange(e, field)}
                value={getValues('files')}
                onRemoveClick={(e, index) => handleRemove(index)}
              />
            )}
          />
        </div>
      </form>
    </Paper>
  );
};
