import Loadable from '@loadable/component';
import Icon from '@mdi/react';
import { pdsTv } from '@purinanbm/pds-ui';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useEffect, useRef } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { mdiArrowLeft, mdiMapMarker } from 'src/assets/icons/mdiIcons';
import { CMS_THEME } from '../common/enums';
import { ButtonLink as H20Button } from '../components/button/Button';
import { useMap } from '../components/ingredients/helpers/useMap';

const IngredientMap = Loadable(() => import('../components/ingredients/IngredientMap'));

interface Props {
  node: IStorageIngredientsMap;
}
interface IngredientProps {
  paragraph: ParagraphIngredientsData;
  locations: TaxonomyTermLocation[];
  handleClose: () => void;
}

interface IngredientShopButtonProps {
  paragraph: ParagraphIngredientsData;
}

const DEFAULT_LOCATIONS: TaxonomyTermLocation[] = [];

const rowContainer = pdsTv({
  base: [
    'pds-border pds-border-solid pds-border-surface-line',
    'pds-grid pds-grid-cols-[60px_1fr] pds-grid-rows-[93px]',
    'pds-content-center pds-items-center pds-gap-[10px] pds-pl-4',
    'pds-rounded pds-bg-surface pds-no-underline',
    'hover:pds-cursor-pointer hover:pds-bg-surface-layer',
  ],
});

const styles = pdsTv({
  slots: {
    titleDescriptionText: 'pds-mt-3 pds-text-title-md-legacy pds-capitalize !pds-leading-[-0.5px]',
    locationBtn:
      'pds-text-focus !pds-px-2 !pds-py-2 !pds-text-body-md !pds-underline hover:pds-cursor-pointer hover:!pds-no-underline',
  },
});

const viewButton = pdsTv({
  base: 'pds-mb-4 !pds-w-full pds-no-underline',
});

const IngredientShopButtons = ({ paragraph }: IngredientShopButtonProps) => {
  const { t } = useTranslation();
  const ingredient = paragraph.relationships.field_ingredient[0];
  const { links } = paragraph;
  if (links.length > 0) {
    return (
      <Row className="pds-my-3">
        {links?.map(link => {
          // 118
          return (
            <H20Button
              key={`${link.url}_ingredient-shop-btn`}
              to={link.url}
              analytics={false}
              variant="btn-outline"
              cmsTheme={CMS_THEME.LIGHT}
              className={viewButton()}
              onClick={() => {
                sendIt({
                  event: 'module_interaction',
                  eventParams: {
                    component_id: link.title,
                    component_type: 'ingredient_detail',
                    content_name: 'shop',
                  },
                });
              }}
            >
              {link.title}
            </H20Button>
          );
        })}
      </Row>
    );
  }
  return (
    <Row className="pds-my-3">
      <H20Button
        to={`/search/products?f[0]=ingredients:${ingredient.drupal_internal__tid}`}
        analytics={false}
        variant="btn-outline"
        cmsTheme={CMS_THEME.LIGHT}
        className={viewButton()}
        onClick={() => {
          sendIt({
            event: 'module_interaction',
            eventParams: {
              component_id: t('Shop by {{ingredient.name}}', { ingredient }),
              component_type: 'ingredient_detail',
              content_name: 'shop',
            },
          });
        }}
      >
        {t('Shop by {{ingredient.name}}', { ingredient })}
      </H20Button>
    </Row>
  );
};

const IngredientDescription = ({ paragraph, locations, handleClose }: IngredientProps) => {
  const ingredient = paragraph.relationships.field_ingredient[0];
  const { focusMapLocation } = useMap();
  const { t } = useTranslation();
  const image = ingredient.relationships?.field_image?.gatsbyImage;
  const benefits = ingredient.relationships?.field_benefits;
  const scrollToTopRef = useRef<HTMLButtonElement>(null);
  const { titleDescriptionText, locationBtn } = styles();
  useEffect(() => {
    if (scrollToTopRef.current) {
      scrollToTopRef.current.focus();
      scrollToTopRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }
  }, []);

  return (
    <div className="pds-flex pds-flex-col pds-gap-4 pds-self-stretch">
      <Button
        ref={scrollToTopRef}
        variant="link"
        aria-label="Back to Ingredients"
        id="backButton"
        onClick={handleClose}
        className="pds-align-start pds-h2 pds-text-neutral pds-flex pds-gap-4  pds-p-0 pds-uppercase pds-tracking-[.1445em]"
        style={{ padding: '0px', marginLeft: '-10px', fontSize: '16px', textDecoration: 'none' }}
      >
        <span
          className="pds-ms-3 pds-content-center pds-rounded-pill pds-p-3 pds-shadow-strong"
          style={{ height: '44px', width: '44px' }}
        >
          <Icon
            path={mdiArrowLeft}
            style={{ width: '100%', height: '100%' }}
            color="black"
            aria-hidden
          />
        </span>
        <p className="pds-text-neutral pds-m-0 pds-font-normal">{t('back to ingredients')}</p>
      </Button>

      <h2 className={titleDescriptionText()}>{ingredient.name}</h2>
      {ingredient.description && (
        <Row>
          <Col
            className="pds-font-light"
            dangerouslySetInnerHTML={{ __html: ingredient.description?.processed }}
          />
        </Row>
      )}
      {image && (
        <>
          <Row>
            <Col xs={6}>
              <GatsbyImage alt={ingredient.name} image={image} objectFit="cover" />
            </Col>
          </Row>
          {benefits && benefits.length >= 1 && (
            <Row>
              <h3 className="pds-mb-4 pds-text-body-md pds-font-normal">{t('Benefits')}</h3>
              {benefits.map(benefit => {
                const benefitIcon = benefit.relationships?.icon?.relationships?.svg?.publicUrl;
                return (
                  <React.Fragment key={benefit.id}>
                    <Row className="pds-items-center pds-py-2">
                      {benefitIcon && (
                        <Col xs="auto" className="pe-0">
                          <img
                            src={benefitIcon}
                            alt={ingredient.name}
                            style={{ height: '24px', width: '24px' }}
                          />
                        </Col>
                      )}
                      <Col>{benefit.name}</Col>
                    </Row>
                  </React.Fragment>
                );
              })}
            </Row>
          )}
          <Row>
            <h3 className="pds-text-body-md pds-font-normal">{t('Sourced from:')}</h3>
            <Col className="pds-flex pds-flex-row pds-flex-wrap">
              <Icon path={mdiMapMarker} size={2} color="black" />
              <Col className="pds-flex pds-flex-row pds-flex-wrap">
                {locations.map((location, i) => {
                  return (
                    <div key={location.id}>
                      <Button
                        size="sm"
                        variant="link"
                        onClick={() => {
                          focusMapLocation(i);
                        }}
                        className={locationBtn()}
                      >
                        {location.name}
                      </Button>
                      <span>{i !== locations.length - 1 ? ' |' : ''}</span>
                    </div>
                  );
                })}
              </Col>
            </Col>
          </Row>
          <IngredientShopButtons paragraph={paragraph} />
        </>
      )}
    </div>
  );
};

interface IngredientItemProps {
  paragraph: ParagraphIngredientsData;
  handleSelectNewIngredient: (id: string, locations: TaxonomyTermLocation[]) => void;
}

const IngredientItem = ({ paragraph, handleSelectNewIngredient }: IngredientItemProps) => {
  const ingredient = paragraph.relationships.field_ingredient[0];
  const image = ingredient.relationships?.field_image?.gatsbyImage;
  const newLocations = ingredient.relationships?.field_locations || [];
  // row 117
  const handleSelect = () => {
    sendIt({
      event: 'module_interaction',
      eventParams: {
        component_id: ingredient.name,
        component_type: 'ingredient_item',
        content_name: 'ingredient',
      },
    });
    handleSelectNewIngredient(paragraph.id, newLocations);
  };

  if (!image) {
    return null;
  }

  return (
    <React.Fragment key={paragraph.id}>
      <button
        className={rowContainer({ class: 'pds-text-start' })}
        type="button"
        data-id={ingredient.drupal_id}
        onClick={handleSelect}
        key={paragraph.id}
      >
        <GatsbyImage
          alt={ingredient.name}
          image={image}
          style={{ height: '55px', width: '55px' }}
        />
        <p className="pds-m-0 pds-font-light pds-text-secondary">{ingredient.name}</p>
      </button>
    </React.Fragment>
  );
};

const StorageIngredientsMap = function ({ node: ingredientMap }: Props) {
  const [selectedIngredientId, setSelectedIngredientId] = React.useState('');
  const breakpoint = useBreakpoint();
  const { updateMapMarkers, removeMarkers } = useMap();
  const [selectedLocations, setSelectedLocations] =
    React.useState<TaxonomyTermLocation[]>(DEFAULT_LOCATIONS);
  const ingredients = ingredientMap.relationships.field_ingredients_data;
  const findSelectedIngredient = (id: string) =>
    ingredients?.find(
      (paragraph: ParagraphIngredientsData) => paragraph.id === id,
    ) as ParagraphIngredientsData;
  const selectedIngredient = findSelectedIngredient(selectedIngredientId || '0');

  const isMobileBreakpoint = breakpoint.xs || (breakpoint.sm && !breakpoint.md);

  const handleSelectNewIngredient = (ingredientId: string, locations: TaxonomyTermLocation[]) => {
    setSelectedIngredientId(ingredientId);
    setSelectedLocations(locations);

    updateMapMarkers(locations);
  };

  const handleClose = () => {
    setSelectedIngredientId('');
    setSelectedLocations(DEFAULT_LOCATIONS);
    removeMarkers();
  };

  return (
    <div
      id={ingredientMap.component_id}
      className="pds-relative pds-flex pds-justify-center pds-rounded pds-bg-white"
      style={{ height: isMobileBreakpoint ? '640px' : '1130px' }}
    >
      <div
        className="pds-rounded-xl pds-absolute pds-w-auto pds-overflow-auto pds-p-4.5"
        id="map-menu"
        style={{
          maxWidth: isMobileBreakpoint ? '360px' : '460px',
          top: isMobileBreakpoint ? '' : '28px',
          bottom: isMobileBreakpoint ? '28px' : '',
          left: isMobileBreakpoint ? '1rem' : '24px',
          right: '24px',
          height: isMobileBreakpoint ? '380px' : '1068px',
          backgroundColor: 'white',
          zIndex: 699,
        }}
      >
        <Col className="pds-grid pds-gap-4">
          {selectedIngredientId.length === 0 &&
            ingredients?.map((paragraph: ParagraphIngredientsData) => (
              <IngredientItem
                key={paragraph.id}
                paragraph={paragraph}
                handleSelectNewIngredient={handleSelectNewIngredient}
              />
            ))}
          {selectedIngredientId.length > 0 && (
            <IngredientDescription
              handleClose={handleClose}
              locations={selectedLocations as TaxonomyTermLocation[]}
              paragraph={selectedIngredient}
            />
          )}
        </Col>
      </div>
      <IngredientMap
        style={{ width: '100%', height: '100%', zIndex: 200 }}
        locations={selectedLocations as TaxonomyTermLocation[]}
      />
    </div>
  );
};

export default StorageIngredientsMap;
