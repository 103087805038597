import { ITheme } from './types';

export const common: Omit<ITheme, 'mode' | 'primary' | 'text' | 'background' | 'action'> = {
  common: {
    black: '#000',
    white: '#FFF',
  },
  error: {
    light: '#FDE8E8',
    dark: '#D21111',
  },
  warning: {
    light: '#F5E5AA',
    dark: '#79620E',
  },
  info: {
    light: '#BED0F8',
    dark: '#1D3873',
  },
  success: {
    light: '#C8DDC8',
    dark: '#0B580B',
  },
  shape: {
    rounded1: '8px',
    rounded2: '16px',
    rounded3: '24px',
    roundedPill: '9999999px',
    roundedCircle: '50%',
  },
  spacing: {
    1: '1rem',
    2: '1.5rem',
    3: '2rem',
    4: '2.5rem',
    5: '3rem',
    6: '3.75rem',
    7: '4.5rem',
    8: '5rem',
    9: '7.5rem',
  },
  gray: {
    100: '#F8F9FC',
    200: '#EDF0F8',
    300: '#E3E7F2',
    400: '#CED4E6',
    500: '#767676',
    600: '#2B2B2B',
  },
  overlay: {
    dark: {
      background: '#2B2B2B',
      backgroundRgba: 'rgba(43, 43, 43, 0.2)',
      text: '#FFF',
      shadow: '0 0 3px rgba(43, 43, 43, 0.8)',
    },
    light: {
      background: '#FFF',
      backgroundRgba: 'rgba(255, 255, 255, 0.2)',
      text: '#FFF',
      shadow: '0 0 3px rgba(255, 255, 255, 0.8)',
    },
    bgHeavy: '65%',
    bgSoft: '20%',
  },
  shadow: {
    shadow: '1px 1px 5px rgba(0,0,0, 0.6)',
    textShadow: '1px 1px 5px rgba(0, 0, 0, 0.6)',
  },
  gradient: {
    gradientLight: 'linear-gradient(90deg), #2b2b2b 100%, rgba(0, 0, 0, 0) 0%',
    gradientDark: 'linear-gradient(90deg), #FFF 100%, rgba(255, 255, 255, 0) 0%',
  },
};
