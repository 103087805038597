export default [
  {
    name: 'North America',
    list: [
      { countryName: 'United States', url: 'https://www.purina.com' },
      {
        countryName: 'Canada',
        url: 'https://www.purina.ca/',
      },
    ],
  },
  {
    name: 'Asia/Oceania/Africa',
    list: [
      {
        countryName: 'Australia',
        url: 'https://www.purina.com.au',
      },
      {
        countryName: 'Hong Kong SAR, Greater China',
        url: 'https://www.nestle.com.hk/zh/brands/pet',
      },
      {
        countryName: 'Israel',
        url: 'https://www.purina.co.il/',
      },
      {
        countryName: 'Japan',
        url: 'https://nestle.jp/brand/purina/',
      },
      {
        countryName: 'Korea',
        url: 'https://www.purinapetcare.co.kr/',
      },
      {
        countryName: 'Mainland China, Greater China',
        url: 'https://www.nestle.com.cn/brands/nestle-petcare',
      },
      {
        countryName: 'Malaysia',
        url: 'https://www.purina.com.my',
      },
      {
        countryName: 'Middle East & North Africa',
        url: 'https://www.purina-arabia.com',
      },
      {
        countryName: 'New Zealand',
        url: 'https://www.purina.co.nz',
      },
      {
        countryName: 'Philippines',
        url: 'https://www.nestle.com.ph/brands/allbrands/purina',
      },
      {
        countryName: 'Singapore',
        url: 'https://www.purina.com.sg',
      },
      {
        countryName: 'South Africa',
        url: 'https://www.purina.co.za',
      },
      {
        countryName: 'Taiwan, Greater China',
        url: 'https://purina.nestle.com.tw',
      },
      {
        countryName: 'Thailand',
        url: 'https://www.purina.co.th',
      },
      {
        countryName: 'Turkey',
        url: 'https://www.purina.com.tr/',
      },
    ],
  },
  {
    name: 'europe',
    list: [
      {
        countryName: 'Austria',
        url: 'https://www.purina.at',
      },
      {
        countryName: 'Bulgaria',
        url: 'https://www.purina.bg/',
      },
      {
        countryName: 'Croatia',
        url: 'https://www.purina.hr/',
      },
      {
        countryName: 'Czech Republic',
        url: 'https://www.purina.cz/',
      },
      {
        countryName: 'Denmark',
        url: 'https://www.purina.dk/proplan',
      },
      {
        countryName: 'Estonia',
        url: 'https://www.purina.eu/',
      },
      {
        countryName: 'Finland',
        url: 'https://www.purina.fi/',
      },
      {
        countryName: 'France',
        url: 'https://www.purina.fr',
      },
      {
        countryName: 'Germany',
        url: 'https://www.purina.de',
      },
      {
        countryName: 'Greece',
        url: 'https://www.purina.gr',
      },
      {
        countryName: 'Holland',
        url: 'https://www.purina.nl/',
      },
      {
        countryName: 'Hungary',
        url: 'https://www.purina.hu/',
      },
      {
        countryName: 'Italy',
        url: 'https://www.purina.it',
      },
      {
        countryName: 'Latvia',
        url: 'https://www.purina.lv/',
      },
      {
        countryName: 'Lithuania',
        url: 'https://www.purina.lt',
      },
      {
        countryName: 'Norway',
        url: 'https://www.purina.no',
      },
      {
        countryName: 'Portugal',
        url: 'https://www.purina.pt',
      },
      {
        countryName: 'Russia',
        url: 'https://www.purina.ru',
      },
      {
        countryName: 'Serbia',
        url: 'https://www.purina.lv',
      },
      {
        countryName: 'Slovakia',
        url: 'https://www.purina.eu',
      },
      {
        countryName: 'Spain',
        url: 'https://www.purina.es',
      },
      {
        countryName: 'Sweden',
        url: 'https://www.purina.se/',
      },
      {
        countryName: 'Switzerland',
        url: 'https://www.purina.ch/fr',
      },
      {
        countryName: 'United Kingdom',
        url: 'https://www.purina.co.uk',
      },
    ],
  },
  {
    name: 'south america',
    list: [
      {
        countryName: 'Argentina',
        url: 'https://www.purina.com.ar/home',
      },
      {
        countryName: 'Bolivia',
        url: 'https://www.purina.com.bo/home',
      },
      {
        countryName: 'Brazil',
        url: 'https://purina.com.br/home',
      },
      {
        countryName: 'Caribe',
        url: 'https://www.purina.com.ar/home',
      },
      {
        countryName: 'Chile',
        url: 'https://www.purina.com.ar/home',
      },
      {
        countryName: 'Colombia',
        url: 'https://purina.com.co/home',
      },
      {
        countryName: 'Costa Rica',
        url: 'https://purina.co.cr/home',
      },
      {
        countryName: 'Ecuador',
        url: 'https://purina.com.ec/home',
      },
      {
        countryName: 'El Salvador',
        url: 'https://purina.com.sv/home',
      },
      {
        countryName: 'Guatemala',
        url: 'https://purina.com.gt/home',
      },
      {
        countryName: 'Honduras',
        url: 'https://purina.com.hn/home',
      },
      {
        countryName: 'Jamaica',
        url: 'https://www.purina.com.jm/home',
      },
      {
        countryName: 'Mexico',
        url: 'https://purina.com.mx/home',
      },
      {
        countryName: 'Nicaragua',
        url: 'https://purina.com.ni/home',
      },
      {
        countryName: 'Panama',
        url: 'https://purina.com.pa/home',
      },
      {
        countryName: 'Paraguay',
        url: 'https://purina.com.py/home',
      },
      {
        countryName: 'Peru',
        url: 'https://purina.com.pe/home',
      },
      {
        countryName: 'Rep. Dominicana',
        url: 'https://purina.com.do/home',
      },
      {
        countryName: 'Trinidad and Tobago',
        url: 'https://purina.com.tt/home',
      },
      {
        countryName: 'Uruguay',
        url: 'https://purina.com.uy/home',
      },
      {
        countryName: 'Venezuela',
        url: 'https://purina.com.ve/home',
      },
    ],
  },
];
