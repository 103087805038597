import { styled } from '@linaria/react';
import { PageProps, graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Link from 'src/components/Link';
import Typography from 'src/components/typography/Typography';
import internationalSitesMock from 'src/utils/internationalSitesMock';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';

import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';

interface DataProps {
  node: NodeContent;
  translations: Translations;
}

const StyledContainer = styled.div`
  padding: 1rem;
`;

const HeroSection = styled.section`
  overflow: hidden;
  border-radius: 1rem;
  position: relative;
  min-height: 50vh;
  display: flex;
  align-items: center;
  @media (max-width: 560px) {
    align-items: flex-end;
  }
  .hero-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    filter: brightness(50%);
    @media (max-width: 991px) {
      padding-top: 50%; /* 2:1 aspect ratio for tablet */
    }

    @media (max-width: 767px) {
      padding-top: 100%; /* 1:1 aspect ratio for mobile */
    }
  }
`;
const HeroContent = styled(Container)`
  position: relative;
  z-index: 1;
  text-align: center;
  color: #ffffff;
  @media (max-width: 575px) {
    margin-bottom: 1rem;
  }
`;

const InternationalSitesTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
}) {
  const { t } = useTranslation();
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  // use i18next context with explicit country list to allow for key extraction
  const translatedCountry = (countryName: string): string => {
    // i18next-extract-mark-context-next-line ["United States", "Canada", "Australia", "Hong Kong SAR, Greater China", "Israel", "Japan", "Korea", "Mainland China, Greater China", "Malaysia", "Middle East & North Africa", "New Zealand", "Philippines", "Singapore", "South Africa", "Taiwan, Greater China", "Thailand", "Turkey", "Austria", "Bulgaria", "Croatia", "Czech Republic", "Denmark", "Estonia", "Finland", "France", "Germany", "Greece", "Holland", "Hungary", "Italy", "Latvia", "Lithuania", "Norway", "Portugal", "Russia", "Serbia", "Slovakia", "Spain", "Sweden", "Switzerland", "United Kingdom", "Argentina", "Bolivia", "Brazil", "Caribe", "Chile", "Colombia", "Costa Rica", "Ecuador", "El Salvador", "Guatemala", "Honduras", "Jamaica", "Mexico", "Nicaragua", "Panama", "Paraguay", "Peru", "Rep. Dominicana", "Trinidad and Tobago", "Uruguay", "Venezuela"]
    const country = t('country', { context: countryName });
    return country;
  };
  const translatedSite = (siteName: string): string => {
    // i18next-extract-mark-context-next-line ["North America", "Asia/Oceania/Africa", "europe", "south america"]
    const site = t('site', { context: siteName });
    return site;
  };

  return (
    <Layout node={node} language={node.langcode} translations={translations}>
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <StyledContainer>
        <HeroSection>
          <StaticImage
            src="../../assets/media/images/purina-hero-intl-3200x800.jpg"
            // we are going to let blank alt text go
            alt=""
            aspectRatio={3}
            layout="constrained"
            className="hero-img"
            loading="eager"
          />
          <HeroContent>
            <Row className="text-start align-items-center">
              <Col xs={12} md={8} lg={4}>
                <Typography variant="h4">{t('Purina Worldwide')}</Typography>
                <p>
                  {t(
                    "Purina is committed to helping make pets' lives better worldwide. To view Purina sites in other markets, click below.",
                  )}
                </p>
              </Col>
            </Row>
          </HeroContent>
        </HeroSection>
      </StyledContainer>
      <Container className="py-5 d-grid gap-4">
        <Typography className="text-center fw-bold" variant="h3">
          {t('Purina International Sites')}
        </Typography>
        <Row className="justify-content-center">
          {internationalSitesMock.map(site => (
            <Col xs={12} sm={6} md={3} className="justify-center" key={site.name}>
              <p className="text-capitalize fw-semibold">{translatedSite(site.name)}</p>
              <p>
                {site.list.map(({ countryName, url }) => (
                  <>
                    <Link key={countryName} className="text-capitalize" to={url}>
                      {translatedCountry(countryName)}
                    </Link>
                    <br />
                  </>
                ))}
              </p>
            </Col>
          ))}
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeComplex(id: { eq: $id }, langcode: { eq: $language }) {
      id
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      behaviors {
        page_layout {
          elements_to_hide {
            header
            footer
            chat
          }
        }
      }
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
    }
    translations: allNodeComplex(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default InternationalSitesTemplate;
