import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import Filmstrip from '../components/filmstrip/Filmstrip';
import ProductCard from '../components/product_card/ProductCard';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';
import { formatBazaarVoiceId, formatSku } from '../utils/helpers';

interface StorageProductCardsProps {
  node: IStorageProductCards;
}

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled(Container) <{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const getProductCards = (products: ProductCard[], cmsTheme: CMS_THEME) => {
  return products.map((product, i) => {
    const image = product.relationships.image.gatsbyImage;
    const { title } = product;
    const link = product.field_alternate_url?.uri
      ? product.field_alternate_url.uri
      : product.path.alias;
    const bvID = formatBazaarVoiceId(product?.field_bazaarvoice_id);
    const sku: string =
      product.relationships.skus?.length && product.relationships.skus[0]?.upc
        ? formatSku(product.relationships.skus[0]?.upc)
        : '';

    return (
      <ProductCard
        drupalId={product.drupal_id}
        cmsTheme={cmsTheme}
        key={i}
        image={image}
        title={title}
        link={link}
        bvID={bvID}
        sku={sku}
      />
    );
  });
};

const StorageProductCards: React.FC<StorageProductCardsProps> = function ({ node: productCards }) {
  const settings = Object.assign(DefaultSettings, productCards?.behaviors || {});
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const cmsTheme = settings.entity_variant.theme;
  const count = productCards.relationships.products.length;
  const products = getProductCards(productCards.relationships.products, cmsTheme);
  const filmstripBreakpointSettings = {
    1024: {
      slidesPerView: 4,
      spaceBetween: 20,
    },
  };
  return (
    <Wrapper
      id={productCards.component_id}
      data-id={productCards.drupal_id}
      className="py-3 py-lg-5 px-0"
      fluid
      cmsTheme={cmsTheme}
    >
      <Container
        className={cx(productCards?.title || productCards?.body?.processed ? 'pb-3 pb-lg-5' : '')}
      >
        {productCards?.title && (
          <HeadingElement className={cx(headingStyle)}>{productCards.title}</HeadingElement>
        )}
        {productCards?.body?.processed && (
          <RichText cmsTheme={cmsTheme} className="mt-3" body={productCards.body.processed} />
        )}
      </Container>
      {count > 4 && (
        <Filmstrip className="fs-offset" breakpointSettings={filmstripBreakpointSettings}>
          {products}
        </Filmstrip>
      )}
      {count <= 4 && (
        <Container>
          <Row>
            {products.map((product, i) => {
              return (
                <Col xs={12} sm={12} md={12} lg={4} key={i} className="mb-3">
                  {product}
                </Col>
              );
            })}
          </Row>
        </Container>
      )}
    </Wrapper>
  );
};

export default StorageProductCards;
