import { AxiosError } from 'axios';
import { PageProps, graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import SearchAdditionalBar from '../components/search_listing/SearchAdditionalBar';
import SearchListing from '../components/search_listing/SearchListing';
import SearchTabs from '../components/search_listing/SearchTabs';
import StickyFacetContainer from '../components/search_listing/StickyFacetContainer';
import { useSearchDrupal } from '../hooks/useSearchDrupal';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface SearchNodeProps extends NodeContent {
  api_slug: string;
}

interface DataProps {
  node: SearchNodeProps;
  translations: Translations;
}

const SearchTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
  location,
}) {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  const getComponentSearchResult = React.useCallback(() => {
    let componentToRender = null;
    switch (node.api_slug) {
      case 'breeds':
        componentToRender = <SearchListing.BreedSearchResults />;
        break;
      case 'articles':
        componentToRender = <SearchListing.ArticleSearchResults columns={3} />;
        break;
      case 'pages':
        componentToRender = <SearchListing.PageSearchResults />;
        break;
      case 'products':
        componentToRender = <SearchListing.ProductSearchResults />;
        break;
      default:
        break;
    }
    return componentToRender;
  }, [node.api_slug]);

  function onChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const { url } = event.currentTarget.dataset;
    if (url) {
      const facetUrlObject = new URL(url);
      const facetSearchParams = new URLSearchParams(facetUrlObject.search);
      // delete langcode from facet search params
      if (facetSearchParams.has('langcode')) facetSearchParams.delete('langcode');
      if (facetSearchParams.toString() !== new URLSearchParams(location.search).toString()) {
        navigate(`?${facetSearchParams}`);
      }
    }
  }

  const sp = React.useMemo(() => {
    const queryParams = Object.fromEntries(new URLSearchParams(location.search)) || {};
    if (queryParams.page) queryParams.page = String(Number(queryParams.page) - 1);
    return { ...queryParams, api_slug: node.api_slug };
  }, [location.search, node.api_slug]);

  const { data: searchData, error, isFetching } = useSearchDrupal(sp, activeLanguage);

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <SearchAdditionalBar />
      <SearchTabs language={node.langcode}>
        <Container>
          <SearchListing
            data={searchData}
            error={(error as AxiosError) || t('Sorry, there was an error')}
            loaded={!isFetching}
          >
            <Row className="align-items-center py-3">
              <SearchListing.SearchHeader
                title={`${t('Search Results')}`}
                onFacetRemove={onChange}
              />
            </Row>
            <Row className="position-relative">
              <Col lg={3} className="py-3 d-none d-lg-block">
                <StickyFacetContainer>
                  <SearchListing.SearchFacets onChange={onChange} />
                </StickyFacetContainer>
              </Col>
              <Col>
                {getComponentSearchResult()}
                <SearchListing.Pagination />
              </Col>
              <SearchListing.FacetModal onChange={onChange} />
            </Row>
          </SearchListing>
        </Container>
      </SearchTabs>
    </Layout>
  );
};

export default SearchTemplate;

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeSearchPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      title
      path {
        alias
      }
      default_langcode
      langcode
      ...Metatag
      api_slug: field_api_slug
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
    }
    translations: allNodeSearchPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
