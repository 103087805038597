import { Button } from '@purinanbm/pds-ui';
import React from 'react';
import pointsBadgeSvg from 'src/assets/icons/pointsBadge.svg';
import shippingSvg from 'src/assets/icons/shipping.svg';
import RichText from 'src/components/rich_text/RichText';
import { GetRewardsResponse } from 'src/services/loyalty/data-contracts';
import { onRedeemClick } from '../helpers';
import RewardError from '../reward_catalog/RewardError';
import { useRewardsCatalog } from '../reward_catalog/useRewardsCatalog';
import RewardDetailsSkeleton from './RewardDetailsListSkeleton';

/**
 * Converts bullets into actual ul/li elements.
 * Example &bull; to `<li>`
 */
const sanitizeRewardDescription = (description: string) => {
  let listItemCount = 0;
  const sanitizedRewardDescription = description.split('<br />').reduce((prev, current) => {
    if (current.startsWith('&bull;')) {
      const listItem = `<li>${current.replace('&bull;', '').replace('<br />', '')}</li>`;

      if (current.startsWith('&bull;') && listItemCount === 0) {
        listItemCount += 1;
        return `${prev}<ul>${listItem}`;
      }

      return `${prev}${listItem}`;
    }

    if (listItemCount > 0) {
      listItemCount = 0;
      return `${prev}</ul>${current}`;
    }

    return `${prev}${current}`;
  }, '');

  return sanitizedRewardDescription;
};

const RewardDetails: React.FC<{ id: number }> = ({ id }) => {
  const filterById = React.useCallback(
    (data: GetRewardsResponse) => {
      const currentReward = data.rewards?.filter(r => r.id === id);
      return { rewards: currentReward || [] };
    },
    [id],
  );

  const { hasErrored, isLoading, rewardItems, userPoints } = useRewardsCatalog(filterById);
  if (isLoading) {
    return <RewardDetailsSkeleton />;
  }

  if (hasErrored) {
    return <RewardError />;
  }

  const reward = rewardItems[0];

  if (!isLoading && !reward) {
    return (
      <>
        <h2 className="pds-m-0 pds-pb-5 pds-text-center pds-text-title-md pds-font-semibold">
          Redeem Rewards
        </h2>
        <span className="pds-mb-4 pds-block pds-bg-surface-layer pds-p-4 pds-text-center">
          Reward could not be found.
        </span>
      </>
    );
  }

  const hasEnoughPointsToRedeem = userPoints >= reward!.points;

  return (
    <>
      <div className="pds-grid pds-gap-4 md:pds-grid-cols-2 md:pds-gap-5">
        <div className="pds-rounded-xl pds-bg-[#F8F9F9] pds-p-4 md:pds-max-h-[332px] md:pds-max-w-[378px]">
          <img
            src={reward!.imageUrl}
            alt=""
            className="pds-h-full pds-w-full pds-bg-[#EDF0F8] pds-object-contain md:pds-max-h-[300px] md:pds-max-w-[343px]"
          />
        </div>
        <div className="pds-grid pds-gap-5 md:pds-max-w-[349px]">
          <div className="pds-grid pds-content-baseline pds-gap-4 pds-pt-5">
            <h1 className="pds-m-0 pds-text-title-sm pds-font-semibold pds-leading-[30px]">
              {reward!.name}
            </h1>
            <div className="pds-relative pds-flex pds-w-fit pds-items-center pds-gap-2 pds-self-baseline pds-rounded-pill pds-border pds-bg-surface-heavy pds-px-[0.75rem] pds-text-body-sm pds-font-semibold">
              <img src={pointsBadgeSvg} className="pds-scale-125" alt="Product Perks points" />
              {reward!.points} pts
            </div>
            <div className="pds-text-body-md pds-font-semibold">
              <img
                src={shippingSvg}
                className="pds-mr-3 pds-inline-block pds-pl-3 pds-align-middle"
                alt=""
              />
              Free Shipping
            </div>
          </div>
          <Button
            buttonColor="neutral"
            size="medium"
            buttonStyle="solid"
            disabled={!hasEnoughPointsToRedeem}
            aria-label={
              hasEnoughPointsToRedeem
                ? `Redeem ${reward.name}`
                : `Low Balance to Redeem ${reward.name}`
            }
            // TODO: Implement onclick to redeem
            onClick={() => onRedeemClick(reward, 'reward detail')}
            className="pds-self-baseline pds-font-semibold"
          >
            {hasEnoughPointsToRedeem ? 'Redeem' : 'Low Balance'}
          </Button>
        </div>
      </div>
      <div className="pds-grid pds-p-4.5">
        <h2 className="pds-text-body-lg pds-font-semibold">Product Description:</h2>
        <RichText
          body={sanitizeRewardDescription(reward.description)}
          className="pds-text-body-md prose-ul:pds-m-0 prose-ul:pds-pl-4.5 prose-li:pds-m-0 prose-li:pds-pl-1 prose-li:pds-leading-6"
        />
      </div>
    </>
  );
};

export default RewardDetails;
