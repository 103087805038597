import React, { PropsWithChildren } from 'react';
import { useAuth } from 'src/hooks/useAuth';
import { Gate } from '../../templates/Gate';

interface PrivateRouteProps {
  component?: React.ComponentType<any>;
  path?: string;
}

export const PrivateRoute: React.FC<PropsWithChildren<PrivateRouteProps>> = ({
  component: Component,
  children,
  ...props
}) => {
  const { user, isLoading } = useAuth();

  if (isLoading) {
    return null;
  }

  if (!user) {
    return <Gate />;
  }

  if (!Component) {
    return children;
  }

  return <Component {...props} />;
};
