/**
 * Removes a specific key even if nested in an object and converts it to a string
 *
 * @remarks This was initially created to remove the 'ref' property from a FieldError object
 */
export function removeKeyAndStringify(object: {}, keyName: string): string {
  try {
    return JSON.stringify(object, (key, value) => (key === keyName ? undefined : value));
  } catch (e) {
    console.error(e);
    return "Error occurred while trying to remove 'ref' property from object";
  }
}
