import { useLocation } from '@reach/router';
import { AxiosError } from 'axios';
import { navigate } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SearchListing from '../../components/search_listing/SearchListing';
import { handleFacetChange } from '../../utils/helpers';

interface Base {
  error: unknown;
  isFetching: boolean;
  contextualFilters?: SearchParams;
  defaultLoadedState?: boolean;
}

interface Api extends Base {
  data: DrupalSearch | undefined;
  serverData?: DrupalSearch;
}

interface Server extends Base {
  data: DrupalSearch | undefined;
  serverData: DrupalSearch;
}

const ArticleAuthorListingBody: React.FC<(Server | Api) & { title?: string }> = ({
  data,
  serverData,
  error,
  isFetching,
  contextualFilters,
  defaultLoadedState,
  title,
}) => {
  const location = useLocation();
  return (
    <Container className="my-5">
      <SearchListing
        data={data}
        error={(error as AxiosError) || new Error('Sorry, there was an error')}
        loaded={!isFetching}
        defaultLoadedState={defaultLoadedState}
      >
        <Row className="pds-justify-center pds-py-4 sm:pds-justify-normal">
          {/* eslint-disable-next-line */}
          <h2 className="!pds-max-w-[332px] pds-text-title-sm sm:!pds-max-w-full md:pds-text-title-md-legacy">
            {title}
          </h2>
        </Row>
        <SearchListing.ArticleAuthorSearchResults serverData={serverData} />
        <SearchListing.Pagination serverData={serverData} />
      </SearchListing>
    </Container>
  );
};
export default ArticleAuthorListingBody;
