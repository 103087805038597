import { Button } from '@purinanbm/pds-ui';
import { WindowLocation, useLocation } from '@reach/router';
import { Link as GatsbyLink, graphql, navigate } from 'gatsby';
import React from 'react';

import { PURINA_PERKS_ROUTES } from 'src/components/purina_perks/PurinaPerksLayout';
import Success from 'src/components/success/Success';

const SuccessPage: React.FC = () => {
  const location = useLocation() as WindowLocation<
    { showReceiptUploadSuccess?: boolean } | undefined
  >;

  // Prevent coming directly to this route in a new session
  if (typeof window !== 'undefined' && !location.state?.showReceiptUploadSuccess) {
    navigate(PURINA_PERKS_ROUTES.ReceiptUpload, { replace: true });
    return null;
  }

  return (
    <Success>
      <>
        <h1 className="pds-mb-4 pds-text-center pds-text-title-md pds-font-semibold md:pds-mb-5 md:pds-text-title-lg">
          Receipt Submitted!
        </h1>

        <p className="pds-mb-4 pds-max-w-[47rem] pds-text-center pds-text-body-md md:pds-mb-5 md:pds-text-title-sm">
          Your points will be awarded after your receipt has been approved, which may take up to 3
          days.
        </p>

        <Button
          className="pds-w-full sm:pds-w-auto"
          buttonColor="neutral"
          // TS error is because "GatsbyLink" is not a defined option for the "as" property
          as={GatsbyLink}
          to={PURINA_PERKS_ROUTES.ReceiptUpload}
        >
          Back to Your Receipts
        </Button>
      </>
    </Success>
  );
};

export default SuccessPage;

// "en" is hardcoded since this page is not currently localized
export const query = graphql`
  query {
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
