import { pdsTv } from '@purinanbm/pds-ui';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CMS_OVERLAY, CMS_THEME } from '../../common/enums';
import Link from '../Link';

interface Props {
  imageComponent: JSX.Element;
  condensed: boolean;
  drupal_id: string;
  title: string;
  url: string;
  summary?: string;
  promoted: boolean;
  cmsOverlay?: CMS_OVERLAY;
  cmsTheme?: CMS_THEME;
  type: string;
  name: string;
  onClick?: () => void; // TODO: implement onClick for article pages & article cards
}

const styles = pdsTv({
  slots: {
    articleImage: 'pds-h-full pds-overflow-hidden pds-rounded-t-[10px] *:pds-h-[inherit]',
    link: 'pds-truncate-text pds-no-underline after:pds-content-empty md:hover:pds-underline',
    card: 'pds-relative pds-flex pds-w-full pds-flex-col pds-overflow-hidden pds-rounded-xl pds-border pds-border-solid pds-transition-shadow pds-duration-300 focus-within:pds-shadow-weaker hover:pds-shadow-weaker md:pds-border-[0]',
    box: 'pds-flex pds-h-auto pds-w-full pds-flex-col pds-justify-end pds-p-4.5 md:pds-absolute md:pds-h-full',
  },
  variants: {
    theme: {
      light: {
        link: 'pds-text-paper-text',
        card: 'pds-border-[#F8FAFF]',
        box: 'pds-bg-[#F8FAFF] pds-text-paper-text',
      },
      dark: {
        link: 'pds-text-white',
        card: 'pds-border-[#464646]',
        box: 'pds-bg-[#464646]',
      },
      neutral: {
        link: 'pds-text-paper-text hover:pds-text-paper-text',
        card: 'pds-border-[#F8FAFF]',
        box: 'pds-bg-white pds-text-paper-text',
      },
    },
    overlay: {
      light: {
        link: 'md:pds-text-white md:hover:pds-text-white',
        box: 'md:pds-bg-white/subtle md:pds-text-white md:pds-txt-shadow-light',
      },
      dark: {
        link: 'md:pds-text-white md:hover:pds-text-white',
        box: 'md:pds-bg-paper-text/subtle md:pds-text-white md:pds-txt-shadow-dark',
      },
    },
    condensed: {
      true: {
        box: [
          'md:pds-p-4',
          // BOOTSTRAP
          'condensed',
        ],
      },
      false: {
        box: 'md:pds-p-5',
      },
    },
  },
});

const ArticleOverlayCard: React.FC<Props> = function ({
  title,
  url,
  summary,
  imageComponent,
  condensed,
  promoted,
  cmsOverlay = CMS_OVERLAY.DARK,
  cmsTheme = CMS_THEME.LIGHT,
  onClick = () => {},
  ...props
}: Props) {
  const { t } = useTranslation();
  const { link, card, box } = styles({ theme: cmsTheme, overlay: cmsOverlay, condensed });
  return (
    <article className={card()} {...props}>
      <div className="pds-h-full pds-overflow-hidden pds-rounded-t-[10px] *:pds-h-[inherit]">
        {imageComponent}
      </div>
      <div className={box()}>
        {promoted && (
          <div
            className="typography_overline text-uppercase mb-2"
            style={{ letterSpacing: '0.2em' }}
          >
            {t('Promoted')}
          </div>
        )}
        <div className={condensed ? 'typography_h4' : 'typography_h3'}>
          <Link onClick={onClick} analytics={false} className={link()} to={url}>
            {title}
          </Link>
        </div>
        {!condensed && summary && (
          <div
            style={{ zIndex: 1, position: 'relative' }}
            className="pds-truncate-text typography_body mb-0"
          >
            {summary}
          </div>
        )}
      </div>
    </article>
  );
};

export default ArticleOverlayCard;
