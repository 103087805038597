import Icon from '@mdi/react';
import React from 'react';

export const RewardDivider: React.FC<{ title: string; icon: string }> = ({ title, icon }) => {
  return (
    <>
      <div className="pds-col-span-12 pds-my-4 pds-flex pds-items-center">
        <Icon
          aria-hidden="true"
          className="pds-mr-3 pds-h-4.5 pds-w-4.5 pds-text-primary"
          path={icon}
          // @ts-ignore - A11y Requirement
          role={undefined}
        />
        <h2 className="pds-mb-0 pds-mt-0 pds-text-body-md pds-font-bold">{title}</h2>
      </div>
      <div
        aria-hidden
        className="pds-mb-4 pds-ml-[-5rem] pds-mt-0 pds-h-[1px] pds-w-[100000000000px] pds-bg-surface-line"
      />
    </>
  );
};
