import { Script } from 'gatsby';
/* eslint-disable react/no-danger */
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import { formatGroupLinks } from 'src/utils/helpers';
import { globals } from '../theme/globals';
import { MetatagProps } from '../utils/metatagHelpers';
import '../theme/layout.css';
import Footer from './Footer';
import Seo from './Seo';
import BasicBlock from './blocks/BasicBlock';
import { NotificationBlockData } from './blocks/NotificationBlockData';
import { PostContentBlockData } from './blocks/PostContentBlockData';
import { PreContentBlockData } from './blocks/PreContentBlockData';
import Breadcrumbs from './breadcrumbs/Breadcrumbs';
import Header from './header';

interface LayoutProps {
  readonly children: React.ReactNode;
  theme?: string;
  metaData?: Array<MetatagProps>;
  language: Languages;
  translations?: Translations;
  node?: NodeContent;
  excludeMain?: boolean;
}

const Layout: React.FC<LayoutProps> = function ({
  children,
  theme = 'default',
  language,
  translations,
  node,
  metaData,
  excludeMain = false,
}) {
  const pagePath = node?.path?.alias;

  // Uncomment to see page component tree that is used for analytic context.
  // const pageContext = usePageStore(state => state.pageContext);
  const {
    header: hideHeader = false,
    footer: hideFooter = false,
    chat: hideChat = false,
  } = node?.behaviors?.page_layout?.elements_to_hide || {};
  // console.log({ pageContext });

  const showChat = !hideChat && process.env.GATSBY_EMPLIFI_CHANNEL_ID;
  const linkgroup = formatGroupLinks(node?.country_links);

  return (
    <div className={`${globals} pds-flex pds-min-h-screen pds-flex-col`}>
      <Seo htmlAttributes={{ lang: language }} />
      {showChat && (
        <>
          <Script type="text/javascript" id="emplifi-settings">
            {`window.astuteBotMessengerSettings = { channelId: "${process.env.GATSBY_EMPLIFI_CHANNEL_ID}" }; `}
          </Script>
          <Script type="text/javascript" id="emplifi-sdk">
            {
              'function includeScript(e){var t=document.createElement("script"),n=document.getElementsByTagName("script")[0];t.type="text/javascript",t.src=e,t.async=!0,n.parentNode.insertBefore(t,n)}includeScript("https://bot.emplifi.io/components/Launchers/Messenger/LauncherSelector.js");'
            }
          </Script>
        </>
      )}
      {pagePath && <BasicBlock location={pagePath} data={NotificationBlockData()} />}
      {!hideHeader && (
        <Header language={language} translations={translations} linkgroup={linkgroup} />
      )}
      <AnalyticsPoint
        as="div"
        type="page"
        pageType={node?.internal?.type || ''}
        label={node?.title || ''}
        className="pds-flex pds-flex-1 pds-flex-col"
      >
        <Breadcrumbs metaData={metaData} />
        {!excludeMain ? (
          <main id="mainContent" role="main" className="pds-flex pds-flex-1 pds-flex-col">
            {pagePath && <BasicBlock location={pagePath} data={PreContentBlockData()} />}
            {children}
            {pagePath && <BasicBlock location={pagePath} data={PostContentBlockData()} />}
          </main>
        ) : (
          <div className="pds-flex pds-flex-1 pds-flex-col">
            {pagePath && <BasicBlock location={pagePath} data={PreContentBlockData()} />}
            {children}
            {pagePath && <BasicBlock location={pagePath} data={PostContentBlockData()} />}
          </div>
        )}
      </AnalyticsPoint>
      {!hideFooter && <Footer language={language} theme={theme} />}
    </div>
  );
};

export default Layout;
