import Icon from '@mdi/react';
import { navigate } from 'gatsby';
import React from 'react';
import { mdiShoppingCartOutline, mdiTrashCanOutline } from 'src/assets/icons/mdiIcons';
import { RewardCard } from 'src/components/card/RewardCard';
import { Reward } from '../../../services/loyalty/types';
import { PURINA_PERKS_ROUTES } from '../PurinaPerksLayout';

interface IRewardCatalogItemSummary {
  item: Reward;
}

interface IRewardItemWrapper extends Partial<Omit<HTMLDivElement, 'title' | 'children'>> {
  title?: string;
  children: React.ReactNode;
}

/**
 * Reward Item Summary Wrapper
 * This is an optional wrapper for Reward Item Summary components.
 *
 * All Reward Item Summary Components should be able to be used seperatly
 **/
export const RewardItemSummaryWrapper: React.FC<IRewardItemWrapper> = (
  { title, children },
  ...props
) => {
  return (
    <RewardCard {...props} iconPath={mdiShoppingCartOutline} title={title}>
      {children}
    </RewardCard>
  );
};

/**
 * Reward Item Summary Product
 * This is the product display for an item in the summary.
 *
 * All Reward Item Summary Components should be able to be used seperatly
 **/
export const RewardItemSummaryProduct: React.FC<
  IRewardCatalogItemSummary & { withDelete?: boolean; onTrashRoute?: string }
> = ({
  item: { name, points, imageUrl },
  onTrashRoute = PURINA_PERKS_ROUTES.Catalog,
  withDelete = true,
}) => {
  return (
    <div className="pds-flex pds-max-w-[328px] pds-justify-between">
      <div className="pds-mr-4 pds-max-w-[72px]">
        <img src={imageUrl} alt="" />
      </div>
      <div className="pds-flex pds-flex-col pds-text-body-md pds-font-semibold">
        <div>{name}</div>
        <div className="pds-mt-4 pds-flex pds-justify-between">
          <span>{points === 0 ? 'FREE' : `${points} pts`}</span>
          {withDelete && (
            <button type="button" onClick={() => navigate(onTrashRoute)}>
              <span className="pds-sr-only">{`Remove ${name}`}</span>
              <Icon
                aria-hidden="true"
                className="pds-h-4.5 pds-w-4.5 pds-text-surface-text"
                path={mdiTrashCanOutline}
                // @ts-ignore - A11y Requirement
                role={undefined}
              />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

/**
 * Reward Item Order Summary
 * This is the order summary portion that displays the total cost and break down of a reward.
 *
 * All Reward Item Summary Components should be able to be used seperatly
 **/
export const RewardItemOrderSummary: React.FC<IRewardCatalogItemSummary> = ({
  item: { points },
}) => {
  return (
    <div className="pds-mt-4 pds-bg-surface-layer pds-p-4">
      <h2 className="pds-mb-4 pds-text-body-md pds-font-semibold">Order Summary</h2>
      <div className="pds-mb-3 pds-mt-4 pds-flex pds-justify-between pds-font-bold">
        <span>Points Total</span>
        <span>{points === 0 ? 'FREE' : `${points} Points`}</span>
      </div>
      <div className="pds-mb-3 pds-flex pds-justify-between">
        <span>Shipping</span>
        <span>FREE</span>
      </div>
      <hr aria-hidden="true" className="pds-bg-surface-line" />
      <div className="pds-mt-4 pds-flex pds-justify-between pds-text-body-lg pds-font-semibold">
        <span>Order Total:</span>
        <span>{points === 0 ? 'FREE' : `${points} pts`}</span>
      </div>
    </div>
  );
};
