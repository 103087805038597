import { InferType, object, string } from 'yup';

const poBox =
  // eslint-disable-next-line
  /^ *((#\d+)|((box|bin)[-. \/\\]?\d+)|(.*p[ \.]? ?(o|0)[-. \/\\]? *-?((box|bin)|b|(#|n|num|number)?\d+))|(p(ost|ostal)? *(o(ff(ice)?)?)? *((box|bin)|b)? *(#|n|num|number)*\d+)|(p *-?\/?(o)? *-?box)|post office box|((box|bin)|b) *(#|n|num|number)? *\d+|(#|n|num|number) *\d+)/i;
export const rewardPhysicalCheckoutSchema = object().shape({
  email: string().email().required().label('Email'),
  first: string().required().label('First Name'),
  last: string().required().label('Last Name'),
  street1: string()
    .test({
      name: 'hasPoBox',
      message: 'Street Address 1 contains a PO Box please add that to Street Address 2.',
      test: v => {
        if (!v) return true;
        const hasPoBox = poBox.test(v);
        if (hasPoBox) {
          return false;
        }
        return true;
      },
    })
    // .matches(poBox, 'Street Address contains a PO Box please add that to Street Address 2.')
    .required()
    .label('Street Address 1'),
  street2: string().label('Street Address 2'),
  city: string().required().label('City'),
  state: string()
    .required('Please fill out a State.')
    .notOneOf(['default'], 'Please select a State.'),
  zip: string()
    .required()
    .matches(/^\d{5}$/, 'Must be 5 digits')
    .label('ZIP Code'),
});

export type RewardPhysicalFormType = InferType<typeof rewardPhysicalCheckoutSchema>;

export const rewardDigitalCheckoutSchema = object().shape({
  email: string().required().label('Email'),
});

export type RewardDigitalFormType = InferType<typeof rewardDigitalCheckoutSchema>;
