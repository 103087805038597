import Icon from '@mdi/react';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  pdsTv,
} from '@purinanbm/pds-ui';
import { graphql, useStaticQuery } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { mdiEarth } from 'src/assets/icons/mdiIcons';
import { IGeoMenu } from 'src/utils/helpers';
import Link, { LinkProps as GatsbyLinkProps } from '../Link';
import MediaImage from '../media/MediaImage';

interface ParagraphProps extends Paragraph {
  link: LinkProps;
  menu: [LinkProps];
  relationships: { menus?: IStorageSubMenu[] };
}
interface GeoMenuProps {
  linkgroup: IGeoMenu;
}
interface ParagraphMenuProps {
  node: ParagraphProps;
  props?: {
    location?: string;
    translations?: Translations;
    linkgroup?: IGeoMenu;
  };
}

const Separator = () => {
  return (
    <div className="pds-not-sr-only pds-h-[0.875rem] pds-w-[1px] pds-bg-surface/medium" />
  );
};

const PurinaListItem = React.forwardRef<React.ElementRef<'a'>, GatsbyLinkProps>(
  ({ className, children, ...props }, ref) => {
    const styles = pdsTv({
      base: 'pds-block pds-select-none pds-list-none pds-space-y-1 pds-bg-[#F8FAFF] pds-p-3 pds-px-4.5 pds-py-4 pds-text-paper-text pds-no-underline pds-outline-none pds-transition-colors hover:pds-bg-gray-400 hover:pds-text-surface-text focus:pds-bg-gray-400 focus:pds-text-surface-text',
    });

    const handleAnalytics = () => {
      sendIt({
        event: 'nav_click',
        eventParams: {
          nav_item: props.title,
          nav_type: 'header',
        },
      });
    };
    return (
      <li className="pds-list-none">
        <NavigationMenuLink ref={ref} asChild>
          <Link onClick={handleAnalytics} className={styles({ className })} {...props}>
            <span className="pds-text-body-md pds-font-normal pds-leading-none">{children}</span>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  },
);

type MenuProps = {
  className: string;
  menus: IStorageSubMenu[];
  linkgroup: IGeoMenu;
  translations:
    | {
        path: {
          alias: string;
        };
        langcode: Languages;
      }
    | undefined;
};

const GeoMenu = (props: GeoMenuProps) => {
  const { linkgroup } = props;

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const currentCountry = new Intl.DisplayNames(currentLanguage, { type: 'region' }).of('US');
  return (
    <React.Fragment key="countries">
      <Separator />
      <NavigationMenuItem>
        <NavigationMenuTrigger className="pds-items-center pds-border-none pds-bg-opacity-none !pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-text-surface hover:pds-bg-transparent hover:pds-text-surface focus:pds-bg-transparent focus:pds-text-surface focus:pds-outline-none disabled:pds-pointer-events-none disabled:pds-opacity-medium data-[active]:pds-bg-transparent data-[state=open]:pds-bg-transparent">
          {currentCountry}
        </NavigationMenuTrigger>
        <NavigationMenuContent className="pds-p-4.5 pds-shadow-stronger">
          <ul className="pds-m-0 pds-overflow-clip pds-rounded pds-p-0 md:pds-w-[270px]">
            {linkgroup.map(item => (
              <PurinaListItem key={item.id} title={item.title} to={item.url}>
                {item.title}
              </PurinaListItem>
            ))}
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </React.Fragment>
  );
};

const Menu = (props: MenuProps) => {
  const { menus, translations, className } = props;
  const renderMenu = (menu: IStorageSubMenu, index: number) => {
    const hasSubMenu = menu.links.length > 0;
    const isLast = index === menus.length - 1;

    const handleAnalytics = () => {
      sendIt({
        event: 'nav_click',
        eventParams: {
          nav_item: menu.link.title,
          nav_type: 'header',
        },
      });
    };
    if (hasSubMenu) {
      const hasLink = menu.link.uri !== 'route:<nolink>';
      return (
        <React.Fragment key={menu.id}>
          <NavigationMenuItem>
            <NavigationMenuTrigger className="pds-items-center pds-border-none pds-bg-opacity-none !pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-text-surface hover:pds-bg-transparent hover:pds-text-surface focus:pds-bg-transparent focus:pds-text-surface focus:pds-outline-none disabled:pds-pointer-events-none disabled:pds-opacity-medium data-[active]:pds-bg-transparent data-[state=open]:pds-bg-transparent">
              {menu.link.title}
            </NavigationMenuTrigger>
            <NavigationMenuContent className="pds-space-y-4 pds-p-4.5 pds-shadow-stronger">
              {hasLink ? (
                <NavigationMenuLink asChild>
                  <Link
                    to={menu.link.url}
                    className="pds-no-underline"
                    onClick={handleAnalytics}
                    {...menu.link.options?.attributes}
                  >
                    <span className="pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-text-primary">
                      {menu.link.title}
                    </span>
                  </Link>
                </NavigationMenuLink>
              ) : (
                <span className="pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-text-primary">
                  {menu.link.title}
                </span>
              )}
              <ul className="pds-overflow-clip pds-rounded pds-p-0 md:pds-w-[270px]">
                {menu.links.map(item => (
                  <PurinaListItem
                    key={item.title}
                    title={item.title}
                    to={item.url}
                    {...item.options?.attributes}
                  >
                    {item.title}
                  </PurinaListItem>
                ))}
              </ul>
            </NavigationMenuContent>
          </NavigationMenuItem>
          {!isLast && <Separator />}
        </React.Fragment>
      );
    }
    if (!menu.link) {
      return null;
    }
    return (
      <React.Fragment key={menu.id}>
        <NavigationMenuItem>
          <NavigationMenuLink
            asChild
            className="pds-group/item pds-flex pds-bg-opacity-none pds-px-4 pds-py-3 pds-text-body-md pds-font-medium pds-tracking-[2.5px] pds-text-surface pds-no-underline pds-transition-colors"
          >
            <Link to={menu.link?.url} onClick={handleAnalytics} {...menu.link.options?.attributes}>
              <span className="pds-relative pds-text-center pds-text-body-md pds-font-medium pds-uppercase [--tw-content:''] after:pds-animated-underline group-hover/item:after:pds-w-full group-hover/item:after:pds-bg-[currentColor]">
                {menu.link?.title}
              </span>
            </Link>
          </NavigationMenuLink>
        </NavigationMenuItem>
        {!isLast && <Separator />}
      </React.Fragment>
    );
  };
  const hasGeoTargeting = props?.linkgroup.length > 0;
  return (
    <NavigationMenu className={`pds-z-[999] [&_>_div]:!pds-h-[40px] ${className}`}>
      <NavigationMenuList className="pds-h-[40px]">
        {menus.map(renderMenu)}
        {hasGeoTargeting && <GeoMenu linkgroup={props.linkgroup} />}
        {translations && (
          <>
            <Separator />
            <NavigationMenuItem>
              <NavigationMenuLink
                asChild
                className="pds-group/item pds-flex pds-bg-opacity-none pds-px-4 pds-py-3 pds-font-medium pds-tracking-[2.5px] pds-text-surface pds-no-underline pds-transition-colors"
              >
                <Link
                  to={translations.path.alias}
                  lang={translations.langcode}
                  onClick={() => {
                    sendIt({
                      event: 'nav_click',
                      eventParams: {
                        nav_item: translations.langcode,
                        nav_type: 'header',
                      },
                    });
                  }}
                >
                  <span className="pds-relative pds-text-center pds-text-body-md pds-font-medium pds-uppercase [--tw-content:''] after:pds-animated-underline group-hover/item:after:pds-w-full group-hover/item:after:pds-bg-[currentColor]">
                    {translations.langcode} <Icon path={mdiEarth} size={0.7} />
                  </span>
                </Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
          </>
        )}
        <NavigationMenuIndicator className=" [&_>_div]:pds-border-gray-200" />
      </NavigationMenuList>
    </NavigationMenu>
  );
};

const ParagraphMenu: React.FC<ParagraphMenuProps> = function ({ node, props }) {
  const classes = node.behavior_settings?.decoupled_styles?.classes || [];
  const allClasses = [...classes, 'menu'];
  const translations = props?.translations?.edges[0]?.node;
  const globalData = useStaticQuery<IStorageGlobalContent>(graphql`
    {
      allStorageGlobalContent(filter: { field_type: { eq: "privacy_link_modal_content" } }) {
        edges {
          node {
            field_type
            field_unfiltered_html {
              value
            }
            relationships {
              image: field_media {
                ...MediaImage
                relationships {
                  field_media_image {
                    gatsbyImage(layout: CONSTRAINED, aspectRatio: 2, width: 76, placeholder: NONE)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const embedScript =
    globalData.allStorageGlobalContent.edges[0]?.node?.field_unfiltered_html?.value;
  const hasImage = !isEmpty(globalData.allStorageGlobalContent.edges[0]?.node?.relationships.image);
  const imageIcon = globalData.allStorageGlobalContent.edges[0]?.node?.relationships
    .image as MediaImage;
  if (node.relationships.menus?.length && node?.relationships?.menus?.length > 0) {
    return (
      <Menu
        className={classes.join(' ')}
        menus={node.relationships.menus}
        linkgroup={props?.linkgroup ?? []}
        translations={translations}
      />
    );
  }

  if (node?.menu.length) {
    return (
      <ul className={`pds-list-none ${allClasses.join(' ')}`}>
        {node.menu.map((link: LinkProps, index) => {
          const attributes = link?.options?.attributes || {};
          const { is_privacy_link, ...newAttributes } = attributes;
          const handleAnalytics = () => {
            sendIt({
              event: 'nav_click',
              eventParams: {
                nav_item: link.title,
                nav_type: 'header',
              },
            });
          };
          if (is_privacy_link) {
            return (
              <li key={index} className="d-flex pds-items-center">
                <Link
                  to="#toggleOneTrust"
                  onClick={() => {
                    handleAnalytics();
                    window.OneTrust.ToggleInfoDisplay();
                  }}
                  className="pds-group pds-flex pds-py-2 pds-pl-[0.75rem] pds-leading-4 pds-tracking-[2.5px] pds-no-underline"
                  {...newAttributes}
                >
                  <span className="pds-relative pds-text-center pds-uppercase [--tw-content:''] after:pds-animated-underline group-hover:after:pds-w-full group-hover:after:pds-bg-[currentColor]">
                    {link.title}
                  </span>
                </Link>
                {hasImage && (
                  <MediaImage
                    {...(imageIcon as MediaImage)}
                    className="pds-rounded-none pds-mx-2 pds-mb-2 pds-w-[38px]"
                  />
                )}
              </li>
            );
          }
          return (
            <li key={index}>
              <Link
                to={link.url}
                onClick={handleAnalytics}
                className="pds-group pds-flex pds-px-[0.75rem] pds-py-2 pds-leading-4 pds-tracking-[2.5px] pds-no-underline"
                {...newAttributes}
              >
                <span className="pds-relative pds-text-center pds-uppercase [--tw-content:''] after:pds-animated-underline group-hover:after:pds-w-full group-hover:after:pds-bg-[currentColor]">
                  {link.title}
                </span>
              </Link>
            </li>
          );
        })}
        {translations && (
          <li key="translation-link">
            <Link
              to={translations.path.alias}
              onClick={() => {
                sendIt({
                  event: 'nav_click',
                  eventParams: {
                    nav_item: translations.langcode,
                    nav_type: 'header',
                  },
                });
              }}
              className="pds-group pds-flex pds-px-[0.75rem] pds-py-2 pds-leading-4 pds-tracking-[2.5px] pds-no-underline"
              lang={translations.langcode}
            >
              <span className="pds-relative pds-text-center pds-uppercase [--tw-content:''] after:pds-animated-underline group-hover:after:pds-w-full group-hover:after:pds-bg-[currentColor]">
                {translations.langcode} <Icon path={mdiEarth} size={0.7} />
              </span>
            </Link>
          </li>
        )}
      </ul>
    );
  }
  return null;
};

export default ParagraphMenu;
