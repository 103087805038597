import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { AuthSstService } from 'src/services/services';
import { SstServices } from 'src/services/sst/SstServices';

// @DEV - Not all queries and mutations are implemented here. Add as needed.
export enum SST_QUERIES {
  GET_PET_OWNER_PLANS = 'getPetownershipplansSstServicesV1PetsPetownershipplansGet',
  GET_PET_TYPE_BY_ID = 'getPetTypeSstServicesV1PetsPettypesPetTypeIdGet',
  GET_PET_TYPES = 'getPetTypesSstServicesV1PetsPettypesGet',
  GET_BREED_BY_ID = 'getBreedSstServicesV1PetsBreedsBreedIdGet',
  GET_ALL_BREEDS = 'getBreedsSstServicesV1PetsBreedsGet',
  GET_USER_BY_ID = 'getUserByUserIdSstServicesV1UsersUserIdGet',
  GET_USER_BY_EMAIL = 'getUserByEmailSstServicesV1UsersGet',
  GET_ALL_MEDIA = 'getAllMediaSstServicesV1MediaUserIdGet',
  GET_USER_PETS = 'getUserAllPetsSstServicesV1PetsUsersUserIdPetsGet',
}

export enum SST_MUTATIONS {
  UPDATE_USER_BY_ID = 'updateUserSstServicesV1UsersUserIdPut',
  UPDATE_PASSWORD_BY_ID = 'updateUserPasswordSstServicesV1UsersUserIdPasswordPost',
  DELETE_USER = 'deleteUserSstServicesV1UsersDeletePost',
  DISABLE_USER = 'disableUserSstServicesV1UsersDisablePost',
  POST_CHECK_USER_BY_EMAIL = 'postCheckUserByEmailSstServicesV1UsersCheckUserEmailPost',
  POST_MEDIA = 'uploadMediaSstServicesV1MediaUserIdPost',
  UPDATE_MEDIA_IS_PRIMARY = 'updateIsprimaryFlagSstServicesV1MediaUserIdMediaIdPut',
}

export const useSstQuery = <T extends SST_QUERIES>(
  endpoint: T,
  params: Parameters<SstServices[T]>[0],
  options: UseQueryOptions<Awaited<ReturnType<SstServices[T]>>['data']> = {},
) => {
  type ResponseType = Awaited<ReturnType<SstServices[T]>>['data'];
  const paramQueryKeys = Object.entries(params).map(([key, value]) => `${key}:${value}`);
  return useQuery<ResponseType>(
    [endpoint, ...paramQueryKeys],
    async () => {
      await AuthSstService.setToken();
      const response = await (AuthSstService as any)[endpoint](params);
      return response.data;
    },
    options,
  );
};

export const useSstMutation = <T extends SST_MUTATIONS>(
  endpoint: T,
  params: Parameters<SstServices[T]>[0],
  options: UseMutationOptions<
    Awaited<ReturnType<SstServices[T]>>['data'],
    unknown,
    Parameters<SstServices[T]>[0]['data']
  > = {},
) => {
  type ResponseType = Awaited<ReturnType<SstServices[T]>>['data'];
  type RequestBody = Parameters<SstServices[T]>[0]['data'];
  const paramQueryKeys = Object.entries(params).map(([key, value]) => `${key}:${value}`);
  return useMutation<ResponseType, unknown, RequestBody>(
    [endpoint, ...paramQueryKeys],
    async data => {
      const response = await (AuthSstService as any)[endpoint]({ ...params, data });
      return response.data;
    },
    options,
  );
};
