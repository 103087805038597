import { styled } from '@linaria/react';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

type Props = {
  node: IStorageRichText;
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const DefaultSettings = {
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const StorageRichText = function ({ node: richText }: Props) {
  const settings = Object.assign(DefaultSettings, richText?.behaviors || {});
  const body = richText.body.processed || '';
  const cmsTheme = settings.entity_variant.theme;

  return (
    <Wrapper id={richText.component_id} data-id={richText.drupal_id} className="py-3 py-lg-5 px-0" fluid cmsTheme={cmsTheme}>
      <Container>
        <Row className="justify-content-md-center">
          <Col lg="8">
            <RichText body={body} cmsTheme={cmsTheme} />
          </Col>
        </Row>
      </Container>
    </Wrapper>
  );
};

export default StorageRichText;
