import { pdsTv } from '@purinanbm/pds-ui';
import React from 'react';
import { Accordion } from 'react-bootstrap';
import { themeStyles } from 'src/theme/basic-styles';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import RichText from '../rich_text/RichText';
import Typography from '../typography/Typography';

type Props = {
  node: IStorageFaq;
  cmsTheme: CMS_THEME;
  headingStyle?: 'h3' | 'h4';
};

const styles = pdsTv({
  base: '[--bs-accordion-border-color:#CED4E6] [--bs-accordion-btn-padding-x:2rem] [--bs-accordion-btn-padding-y:2.5rem]',
  variants: {
    theme: {
      dark: '[--bs-accordion-btn-bg:#1E1E1E] [--bs-accordion-btn-color:#fff]',
      light: '[--bs-accordion-btn-bg:#fff] [--bs-accordion-btn-color:#2b2b2b]',
      neutral: '[--bs-accordion-btn-color:#2b2b2b] [--bs-accordion-btn-bg:#EDF0F8]',
    },
  },
  defaultVariants: {
    theme: 'light',
  },
});

const FaqItem = ({ node, headingStyle = 'h4', cmsTheme }: Props) => {
  const iconStyles = {
    '--bs-accordion-btn-icon': `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='${theme[
      cmsTheme
    ].text.default.replace(
      '#',
      '%23',
    )}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e")`,
    '--bs-accordion-btn-active-icon':
      "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%230c63e4' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e\")",
  };
  return (
    <Accordion.Item className={themeStyles({ theme: cmsTheme })} eventKey={node.id}>
      <div id={node.component_id} className={styles({ theme: cmsTheme })}>
        <Accordion.Header style={iconStyles}>
          <Typography variant={headingStyle}>{node.question}</Typography>
        </Accordion.Header>
      </div>
      <Accordion.Body>
        <RichText body={node.answer.processed} />
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default FaqItem;
