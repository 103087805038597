import React, { useEffect } from 'react';
import { useLDClient } from 'gatsby-plugin-launchdarkly';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';

type Props = {};

const LaunchDarklyFlags = (props: Props) => {
  const ldClient = useLDClient();

  useEffect(() => {
    if (!ldClient) return;
    const activeFlags = ldClient?.allFlags();

    Object.keys(activeFlags).forEach(flag => {
      // #88: Launch Darkly Tag
      sendIt({
        event: 'launch_darkly',
        eventCategory: 'launch_darkly',
        eventAction: 'launch_darkly',
        eventLabel: flag,
        eventParams: {
          feature_name: flag,
          index: activeFlags[flag] ? 'flag 1' : 'flag 0',
        },
      });
    });
  }, [ldClient]);
  return null;
};

export default LaunchDarklyFlags;
