import React from 'react';
import ReactDOMServer from 'react-dom/server';

export const getListStyleIcon = (
  icon: React.ReactElement<any, string | React.JSXElementConstructor<any>>,
): string => {
  const iconString = ReactDOMServer.renderToString(icon);
  const btoaString = btoa(iconString);
  return `data:image/svg+xml;base64,${btoaString}`;
};
