import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { FieldError, FieldErrors } from 'react-hook-form';
import { Reward } from '../../services/loyalty/types';
import { PURINA_PERKS_ROUTES } from './PurinaPerksLayout';
import { RewardDigitalFormType, RewardPhysicalFormType } from './reward_checkout/types';

export const onRedeemClick = (reward: Reward, location: 'catalog' | 'reward detail') => {
  sendIt({
    event: 'redeem_reward_initiate',
    eventParams: {
      cta_location: location,
      item_id: reward.name,
      item_code: reward.pid,
      item_category: reward.type,
    },
  });
  navigate(PURINA_PERKS_ROUTES.Checkout, { state: { reward } });
};

export const onRewardClick = (e: React.MouseEvent, reward: Reward) => {
  const target = e.target as HTMLElement;
  const isImage = target.tagName === 'IMG';
  const isText = target.innerText === reward.name;
  // eslint-disable-next-line no-nested-ternary
  const method = isImage ? 'image' : isText ? 'text' : 'unknown';
  const submitAnalyticsRewardClick = {
    event: 'view_reward',
    eventParams: {
      item_id: reward.name,
      item_code: reward.pid,
      item_category: `${reward.type}${reward.points === 0 ? ', free sample' : ''}`,
      method,
    },
  };
  sendIt(submitAnalyticsRewardClick);
};

export const onCheckoutError = (
  validationErrors: FieldErrors<RewardDigitalFormType | RewardPhysicalFormType>,
) => {
  const errorsArray = Object.entries(validationErrors);
  (errorsArray as [string, FieldError][]).forEach(([key, err]) => {
    const sendErrorToGA = ([errorKey, fieldError]: [string, FieldError]) => {
      const { message, type } = fieldError;
      const submitAnalyticsErrorForm = {
        event: 'error_occured',
        eventParams: {
          error_field: errorKey,
          error_code: type?.toString() ?? 'reward-checkout-error',
          error_name: message as string,
          error_feature: 'reward-checkout',
        },
      };
      sendIt(submitAnalyticsErrorForm);
    };
    sendErrorToGA([key, err]);
  });
};
