import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import StorageAnsiraProfileForm from '../cms-components/StorageAnsiraProfileForm';
import StorageArticleCards from '../cms-components/StorageArticleCards';
import StorageBanner from '../cms-components/StorageBanner';
import StorageBrandGrid from '../cms-components/StorageBrandGrid';
import StorageCardGrid from '../cms-components/StorageCardGrid';
import StorageCategoryCards from '../cms-components/StorageCategoryCards';
import StorageContactCards from '../cms-components/StorageContactCards';
import StorageFaqSection from '../cms-components/StorageFaqSection';
import StorageHeroCard from '../cms-components/StorageHeroCard/HeroCardHelper';
import StorageHighlights from '../cms-components/StorageHighlights';
import StorageImageContent from '../cms-components/StorageImageContent';
import StorageIngredientsMap from '../cms-components/StorageIngredientsMap';
import StorageManualCards from '../cms-components/StorageManualCards';
import StoragePageTitle from '../cms-components/StoragePageTitle';
import StorageProductCards from '../cms-components/StorageProductCards';
import StorageReviews from '../cms-components/StorageReviews';
import StorageRichText from '../cms-components/StorageRichText';
import StorageSubnav from '../cms-components/StorageSubnav';
import StorageUnfilteredHtml from '../cms-components/StorageUnfilteredHtml';
import StorageVideo from '../cms-components/StorageVideo';

interface ComponentsProps {
  [index: string]: React.FC<any>;
}

const components: ComponentsProps = {
  storage__article_cards: StorageArticleCards,
  storage__image_content: StorageImageContent,
  storage__hero_card: StorageHeroCard,
  storage__manual_cards: StorageManualCards,
  storage__product_cards: StorageProductCards,
  storage__highlights: StorageHighlights,
  storage__rich_text: StorageRichText,
  storage__ansira_profile_form: StorageAnsiraProfileForm,
  storage__ingredients_map: StorageIngredientsMap,
  storage__page_title: StoragePageTitle,
  storage__banner: StorageBanner,
  storage__brand_grid: StorageBrandGrid,
  storage__faq_section: StorageFaqSection,
  storage__html: StorageUnfilteredHtml,
  storage__subnav: StorageSubnav,
  storage__card_grid: StorageCardGrid,
  storage__reviews: StorageReviews,
  storage__category_cards: StorageCategoryCards,
  storage__contact_cards: StorageContactCards,
  storage__video: StorageVideo
};

interface Props {
  [key: string]: any;
}
interface ICmsComponentProps {
  node: IStorage | TCmsComponents;
  index: number;
  props?: Props;
}

export const getCmsComponent = ({
  node,
  index,
  props,
}: ICmsComponentProps): React.ReactElement | null => {
  if (components.hasOwnProperty(node.type)) {
    const CmsComponent = components[node.type];
    // eslint-disable-next-line
    node.indexOnPage = index;
    return (
      <AnalyticsPoint type="module" node={node}>
        <CmsComponent node={node} props={props} />
      </AnalyticsPoint>
    );
  }
  return null;
};
