import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '@purinanbm/pds-ui';
import { useLocation } from '@reach/router';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

import errorSvg from 'src/assets/icons/error.svg';

import { PURINA_PERKS_ROUTES } from 'src/components/purina_perks/PurinaPerksLayout';
import ReceiptList from 'src/components/purina_perks/receipt_history/ReceiptList';
import ReceiptListSkeleton from 'src/components/purina_perks/receipt_history/ReceiptListSkeleton';
import { useAuth } from 'src/hooks/useAuth';
import { LOYALTY_QUERIES, useLoyaltyQuery } from 'src/hooks/useLoyaltyService';

const TAB_FRAGMENT_MAP = {
  '#approved': 'approved',
  '#rejected': 'rejected',
} as const;

const ReceiptHistoryLoader: React.FC = () => {
  return (
    <Row className="justify-content-md-center">
      <Col xs={12}>
        <ReceiptListSkeleton />
      </Col>
    </Row>
  );
};

const ReceiptHistoryError: React.FC = () => {
  return (
    <div className="pds-mt-5 pds-flex pds-flex-col pds-items-center md:pds-my-6">
      <div className="pds-mb-5">
        <img src={errorSvg} alt="" />
      </div>

      <div className="text-center pds-mb-2 pds-flex pds-flex-col pds-flex-wrap pds-justify-center pds-gap-x-2 pds-text-title-sm pds-font-extrabold md:pds-flex-row">
        <div>Oops!</div>
        <div>Something went wrong.</div>
      </div>

      <div className="text-center pds-mb-5">Check your connection and try again.</div>

      <Button
        buttonColor="neutral"
        // TS error is because "GatsbyLink" is not a defined option for the the "as" property
        as={GatsbyLink}
        to={PURINA_PERKS_ROUTES.ReceiptUpload}
        className="pds-no-underline"
      >
        Go to Your Receipts
      </Button>
    </div>
  );
};

const ReceiptHistoryContent: React.FC<{
  error: any;
  isLoading: boolean;
  children: React.ReactNode;
}> = ({ error, isLoading, children }) => {
  if (isLoading) {
    return <ReceiptHistoryLoader />;
  }

  if (error) {
    return <ReceiptHistoryError />;
  }

  return children;
};

const ReceiptHistory: React.FC = () => {
  const location = useLocation();
  const { user, error: errorUser, isLoading: isLoadingUser } = useAuth();
  const userAnsiraId = user?.ansiraUuid || '';

  const {
    data: receiptsResponse,
    error: errorReceipts,
    isLoading: isLoadingReceipts,
  } = useLoyaltyQuery(
    LOYALTY_QUERIES.GET_RECEIPTS,
    {
      customerId: userAnsiraId,
    },
    {
      enabled: Boolean(userAnsiraId),
    },
  );

  const receipts = receiptsResponse?.receipts || [];

  const [activeTab, setActiveTab] = React.useState(
    () => location?.hash ?? TAB_FRAGMENT_MAP['#approved'],
  );

  React.useEffect(() => {
    // Load "approved" as the default
    if (!location.hash) {
      setActiveTab(TAB_FRAGMENT_MAP['#approved']);
      return;
    }

    const regex = new RegExp(`^(${Object.keys(TAB_FRAGMENT_MAP).join('|')})$`);

    if (location.hash && regex.test(location.hash)) {
      setActiveTab(TAB_FRAGMENT_MAP[location.hash as keyof typeof TAB_FRAGMENT_MAP]);
    }
  }, [location.hash]);

  const onTabSelect = (key: string | null) => {
    window.location.href = key ? `#${key}` : '#approved';
    setActiveTab(key || TAB_FRAGMENT_MAP['#approved']);
  };

  return (
    <div className="pds-rounded-[24px] pds-border pds-border-solid pds-border-gray-300 pds-bg-surface pds-p-4.5 md-bt:pds-p-5">
      <div className="pds-flex pds-w-full pds-flex-col pds-gap-4.5">
        <h2 className="pds-text-center pds-text-title-sm pds-font-semibold">
          Receipt Submission Activity
        </h2>
        <p className="pds-mx-auto pds-mb-0 pds-max-w-xl pds-text-center pds-text-body-md">
          We know waiting can be ruff, but it can take up to 3 business days for recently uploaded
          receipts to appear.
        </p>
        <Tabs
          activationMode="manual"
          value={activeTab}
          onValueChange={onTabSelect}
          className="pds-gap-4.5"
        >
          <TabsList className="pds-overflow-x-scroll pds-p-2 pds-pb-0 sm:pds-justify-center">
            <TabsTrigger
              value={TAB_FRAGMENT_MAP['#approved']}
              className="pds-max-w-[220px]"
              classNames={{ cursor: 'group-aria-[selected=true]:pds-bg-[#009A4D]' }}
              isActive={activeTab === TAB_FRAGMENT_MAP['#approved']}
            >
              Approved
            </TabsTrigger>
            <TabsTrigger
              isActive={activeTab === TAB_FRAGMENT_MAP['#rejected']}
              value={TAB_FRAGMENT_MAP['#rejected']}
              className="pds-max-w-[220px]"
            >
              Rejected
            </TabsTrigger>
          </TabsList>
          <TabsContent value={TAB_FRAGMENT_MAP['#approved']}>
            <ReceiptHistoryContent
              error={errorUser || errorReceipts}
              isLoading={isLoadingUser || isLoadingReceipts}
            >
              <ReceiptList receipts={receipts} type="approved" />
            </ReceiptHistoryContent>
          </TabsContent>
          <TabsContent value={TAB_FRAGMENT_MAP['#rejected']}>
            <ReceiptHistoryContent
              error={errorUser || errorReceipts}
              isLoading={isLoadingUser || isLoadingReceipts}
            >
              <ReceiptList receipts={receipts} type="rejected" />
            </ReceiptHistoryContent>
          </TabsContent>
        </Tabs>
      </div>
    </div>
  );
};

export default ReceiptHistory;
