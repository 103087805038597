import { pdsTv } from '@purinanbm/pds-ui';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { CSSProperties } from 'react';
import { Card, Stack } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import GatsbyMediaImage from '../components/media/MediaImage';
import Typography from '../components/typography/Typography';

const styles = pdsTv({
  slots: {
    gridContainer: 'pds-flex pds-flex-col pds-gap-5.5',
    grid: 'pds-grid pds-place-items-center pds-gap-y-[70px]',
    stack: '',
    card: 'pds-flex pds-flex-col pds-border-none pds-text-left',
    cardMedia: '!pds-flex !pds-rounded-[1rem] !pds-border-none',
    gridClass: [
      'pds-py-5.5',
      'pds-gap-5.5',
      'pds-flex',
      'pds-flex-col',
      'pds-w-full',
      'pds-text-center',
      'pds-pl-4',
      'pds-pr-3.5',
      'pds-mx-auto',
      'sm:pds-pr-0',
      '3xl:pds-max-w-[1320px]',
      '4xl:pds-max-w-[1600px]',
    ],
    buttonContainer: 'pds-mt-auto',
    title: '!pds-font-light',
  },
  variants: {
    theme: {
      light: {
        stack: 'pds-text-default pds-bg-white',
        card: 'pds-bg-default pds-text-default',
      },
      dark: {
        stack: 'pds-text-neutral-text pds-bg-[#1E1E1E]',
        card: 'pds-text-neutral-text pds-bg-[#1E1E1E]',
      },
      neutral: {
        stack: 'pds-text-default pds-bg-surface-foreground',
        card: 'pds-bg-default pds-text-default',
      },
    },
  },
});

const defaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_subheading: {
    element: 'h3',
    style: 'typography_h3',
  },
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_variant: {
    text_align: 'left',
    theme: CMS_THEME.LIGHT,
  },
  entity_rows_columns: {
    columns: '2',
  },
};

const gridStyles = (columns: string) => ({
  '--tw-grid-layout-gap': '1rem',
  '--tw-grid-column-count': `${columns}`,
  '--tw-grid-item--min-width': '250px',
  '--tw-gap-count': 'calc(var(--tw-grid-column-count) - 1)',
  '--tw-total-gap-width': 'calc(var(--tw-gap-count) * var(--tw-grid-layout-gap))',
  '--tw-grid-item--max-width':
    'calc((100% - var(--tw-total-gap-width)) / var(--tw-grid-column-count))',
  'grid-template-columns': `repeat(
    auto-fill,
    minmax(
      max(
        var(--tw-grid-item--min-width), 
        var(--tw-grid-item--max-width)
      ),
      1fr
    )
  )`,
  'column-gap': 'var(--tw-grid-layout-gap)',
});

type ContentCardItemProps = IStorageContentCard & {
  mode?: string;
  buttonVariant: 'btn' | 'btn-outline' | 'btn-link';
  columns: number;
  cmsTheme: CMS_THEME;
  indexOfComponent: number;
  indexOfModule: number;
};

const ContentCardItem = (el: ContentCardItemProps): JSX.Element => {
  const headingStyle = el.columns < 4 ? 'h3' : 'h4';
  const { card, cardMedia, buttonContainer, title } = styles({
    theme: el.cmsTheme,
  });

  const handleGA = () => {
    sendIt({
      event: 'module_interaction',
      eventParams: {
        component_id: `primary_cta: ${el?.link?.title ?? '[not set]'}`,
        component_type: 'content_card',
        content_name: el.title,
      },
    });
  };
  return (
    <Stack id={el.component_id} data-id={el.drupal_id} className={card()} gap={3}>
      <Card className={cardMedia()}>
        {el.relationships?.field_media?.relationships &&
          (el.relationships.field_media as MediaImage).relationships.field_media_image && (
            <GatsbyMediaImage
              {...(el.relationships.field_media as MediaImage)}
              variant={el.imgSize}
            />
          )}
      </Card>
      <Typography variant={headingStyle} className={title()}>
        {el.title}
      </Typography>
      {/* eslint-disable-next-line react/no-danger */}
      {el.copy && <div dangerouslySetInnerHTML={{ __html: el.copy?.processed }} />}
      {el?.link?.title && (
        <div className={buttonContainer()}>
          <ButtonLink
            variant={el.buttonVariant}
            to={el.link.url}
            cmsTheme={el.cmsTheme}
            onClick={handleGA}
            analytics={false}
            {...el.link.options?.attributes}
          >
            {el.link.title}
          </ButtonLink>
        </div>
      )}
    </Stack>
  );
};

type StorageCardGridProps = {
  node: IStorageCardGrid;
};

const StorageCardGrid = ({ node: cardGrid }: StorageCardGridProps) => {
  const settings = Object.assign(defaultSettings, cardGrid?.behaviors || {});
  const themeType = cardGrid.relationships.theme?.field_color_type ?? 'light';
  const cmsTheme = settings.entity_variant.theme;
  const { primary_button: buttonStyle } = settings.entity_buttons;
  const { columns } = settings.entity_rows_columns;
  const { element: headingElement, style: headingStyle } = settings.entity_heading;
  const { element: subheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const {
    relationships: { cards },
  } = cardGrid;
  const { stack, grid, gridClass, gridContainer } = styles({
    theme: cmsTheme,
  }) as any;

  const renderCardItem = (element: IStorageContentCard, i: number) => (
    <ContentCardItem
      indexOfModule={cardGrid.indexOnPage}
      indexOfComponent={i}
      {...element}
      cmsTheme={cmsTheme}
      columns={Number(columns)}
      buttonVariant={buttonStyle}
      key={element.id}
      mode={themeType}
    />
  );

  // If cards are all drafts, render nothing.
  if (!cards || cards.length === 0) {
    return null;
  }

  return (
    <Stack data-id={cardGrid.drupal_id} className={stack()}>
      <Stack className={gridClass()}>
        <div>
          <Typography key="card-title" variant={headingElement} className={headingStyle}>
            {cardGrid?.title ?? ''}
          </Typography>
          <Typography key="card-subtitle" variant={subheadingElement} className={subheadingStyle}>
            {cardGrid?.subtitle ?? ''}
          </Typography>
          {cardGrid.body?.processed && (
            <div
              key="card-content"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: cardGrid.body?.processed }}
            />
          )}
        </div>
        <div className={gridContainer()}>
          <div
            key="card-grid-desktop"
            className={grid()}
            style={gridStyles(columns) as CSSProperties}
          >
            {cards.map(renderCardItem)}
          </div>
        </div>
      </Stack>
    </Stack>
  );
};

export default StorageCardGrid;
