import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@linaria/react';
import Icon from '@mdi/react';
import { useMutation } from '@tanstack/react-query';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, { useEffect, useMemo, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { FormProvider, SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mdiAlertCircleOutline } from 'src/assets/icons/mdiIcons';
import { useAuth } from 'src/hooks/useAuth';
import { SST_QUERIES, useSstQuery } from 'src/hooks/useSstService';
import { Pet } from 'src/services/sst/data-contracts';
import { isValidPetProfile } from 'src/utils/helpers';
import { ansiraOfferPost } from '../../utils/clientRequests';
import { getRecaptchaToken } from '../../utils/formHelpers';
import MyPetView from '../my_pet_view/MyPetView';
import OfferErrorServer from '../offer/OfferErrorServer';
import OfferSuccess from '../offer/OfferSuccess';
import RichText from '../rich_text/RichText';
import AddressSection from './components/AddressSection';
import OptIn from './components/NewOfferForm/OptIn';
import PetCountSection from './components/NewOfferForm/PetCountSection';
import UserInfo from './components/NewOfferForm/UserInfo';
import RecaptchaDisclaimer from './components/RecaptchaDisclaimer';
import { NewOfferFormProps } from './newOfferFormTypes';
import {
  OfferFormTypes,
  createOfferSchema,
  getMessage,
  onOfferError,
  postOfferForm,
  submitAnalyticsForIncompleteResponse,
} from './utils/offer';

const SubmittedText = styled.p`
  font-weight: 300;
`;

// eslint-disable-next-line complexity
const NewOfferForm: React.FC<NewOfferFormProps> = function ({
  referrer,
  offerId,
  formType = 'short',
  legalText,
  optinLabel,
  optinError,
  errorTitle,
  errorDescription,
  ctaLabel,
  brand,
  title,
}: NewOfferFormProps) {
  const { user } = useAuth();
  const { enableOfferPetView = undefined } = useFlags();
  const {
    data: profile,
    error,
    isLoading: isLoadingSst,
  } = useSstQuery(
    SST_QUERIES.GET_USER_BY_ID,
    {
      userId: user?.ansiraId ?? '',
    },
    { enabled: Boolean(user?.ansiraId) },
  );
  const { t } = useTranslation();
  const [addedNewPet, setAddedNewPet] = useState<boolean>();
  const { activePets, hasValidPetsProfile } = useMemo(() => {
    const availablePets =
      (profile?.data?.pets && profile?.data?.pets.filter((pet: Pet) => !pet.deletedAt)) ??
      undefined;
    const hasValidPets =
      !availablePets?.length || availablePets?.some((pet: Pet) => isValidPetProfile(pet));
    return { activePets: availablePets, hasValidPetsProfile: hasValidPets };
  }, [profile?.data?.pets]);

  const schema = createOfferSchema(t, formType === 'short' ? 'long' : formType);
  const methods = useForm<OfferFormTypes>({
    defaultValues: {
      optIn: false,
      catCount: '0',
      dogCount: '0',
      email: user?.email ?? '',
      firstName: user?.name ?? '',
      lastName: user?.lastName ?? '',
      dogPets: [],
      catPets: [],
    },
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setValue,
    watch,
  } = methods;

  useEffect(() => {
    if (profile?.data?.address?.postalCode && !isLoadingSst) {
      setValue('postalCode', profile?.data?.address?.postalCode);
    }
  }, [profile?.data?.address?.postalCode, isLoadingSst, setValue]);

  useEffect(() => {
    if (activePets?.length && enableOfferPetView) {
      // Allows the validation to perform different actions based on user's current pets
      setValue('hasActivePets', Boolean(activePets?.length));
      const petMapper = (pet: Pet) => ({
        name: pet.name,
        gender: pet.gender ?? '',
        birthDate: new Date(pet.birthDate ?? 0),
        size: pet.size ?? '',
        primaryBreed: pet?.primaryBreed ?? undefined,
      });
      const dogs = activePets.filter((pet: Pet) => pet.petType?.name === 'Dog').map(petMapper);
      const cats = activePets.filter((pet: Pet) => pet.petType?.name === 'Cat').map(petMapper);
      if (dogs && dogs.length > 0) {
        setValue('dogCount', `${dogs.length}`);
        setValue('dogPets', dogs);
      }
      if (cats && cats.length > 0) {
        setValue('catCount', `${cats.length}`);
        setValue('catPets', cats);
      }
    }
    // isSubmitting is added here to allow the form not to reset petCount when it receives
    // some sort of server error on request.
  }, [activePets, isLoadingSst, setValue, isSubmitting, enableOfferPetView]);

  const { mutate, data, isLoading } = useMutation({
    mutationKey: ['ansiraForm'],
    mutationFn: ansiraOfferPost,
    onSuccess: (response, formData) => {
      if (!response.response.success) {
        // eslint-disable-next-line no-console
        console.error('Error:', response);
        return;
      }

      // The API can return a 200 without actually completing the request for the coupon
      if (!response.response.coupon_request) {
        submitAnalyticsForIncompleteResponse({ response });

        return;
      }

      postOfferForm(response, { formData, activePets, referrer, title, brand });
    },
  });
  const onSubmit: SubmitHandler<OfferFormTypes> = async formData => {
    setAddedNewPet(!activePets?.length);
    const RecaptchaToken = await getRecaptchaToken();
    const newData = { ...formData, RecaptchaToken };
    mutate({ data: newData, offerId });
  };

  const onError: SubmitErrorHandler<OfferFormTypes> = validationErrors =>
    onOfferError(validationErrors);

  if (error) {
    return (
      <OfferErrorServer errorTitle={errorTitle ?? ''} errorDescription={errorDescription ?? ''} />
    );
  }

  if (isLoading) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Submitting')}...</span>
          </Spinner>
          <span className="ms-3">{t('Please Wait')}...</span>
        </Col>
      </Row>
    );
  }

  if (data?.response?.success && !data.response.coupon_request) {
    return (
      <Row>
        <Col className="text-center ml-4">
          <Icon path={mdiAlertCircleOutline} size={5} color="#2553F8" aria-hidden />
          <h2 className="typography-h2 mt-5 mb-5">{t('Oops!... Something went wrong.')}</h2>

          <SubmittedText>
            {t("Looks like our wires got crossed. Please check your pets' profiles and try again.")}
          </SubmittedText>
        </Col>
      </Row>
    );
  }

  if (data?.response?.success) {
    return <OfferSuccess addedNewPet={addedNewPet} />;
  }

  if (data && !data?.response.success) {
    return (
      <Row>
        <Col className="text-center ml-4">
          <Icon path={mdiAlertCircleOutline} size={5} color="#2553F8" aria-hidden />
          <h2 className="typography-h2 mt-5 mb-5">{getMessage(data, t).heading}</h2>
          <SubmittedText>{getMessage(data, t).text}</SubmittedText>
        </Col>
      </Row>
    );
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off" noValidate>
        <Row className="gx-3 gy-3">
          <input type="hidden" aria-hidden value={referrer} {...register('referrer')} />
          <UserInfo profile={profile} isLoadingSst={isLoadingSst} user={user} />
          <span className="text-danger pds-font-weight-light !pds-mt-6">
            *{t('Required Field')}
          </span>
          {!isLoadingSst && (
            <>
              <fieldset className="!pds-mt-4 pds-flex pds-flex-wrap pds-gap-y-4.5">
                <legend className="pds-mb-0 pds-text-title-md">{t('Shipping Address')}</legend>
                <AddressSection
                  isLongAddress={formType === 'long_address'}
                  register={register}
                  errors={errors}
                />
              </fieldset>
              {activePets?.length && enableOfferPetView ? (
                <MyPetView
                  pets={activePets as Partial<Pet>[]}
                  isLoading={isLoading}
                  error={error}
                  ansiraId={user?.ansiraId ?? ''}
                  hasInvalidPetsProfile={!hasValidPetsProfile}
                />
              ) : (
                <PetCountSection
                  errors={errors}
                  register={register}
                  watch={watch}
                  formType={formType}
                />
              )}
            </>
          )}
          {optinLabel ? (
            <OptIn
              optinLabel={optinLabel}
              optinError={optinError}
              errors={errors}
              register={register}
            />
          ) : null}
          <Col xs={12}>
            {legalText && <RichText body={legalText} />}
            <RecaptchaDisclaimer />
          </Col>
          <Col xs={12} lg={4}>
            <Button
              className="rounded-pill justify-content-center mt-3 w-100 disabled:!pds-border-disabled disabled:!pds-bg-disabled-text disabled:!pds-shadow-transparent"
              type="submit"
              size="sm"
              style={{ padding: '.5rem 3rem' }}
              disabled={isLoading || !hasValidPetsProfile}
            >
              {isLoading ? `${t('Please wait')}...` : ctaLabel ?? t('Get Rebate')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default NewOfferForm;
