import Widget from 'gatsby-plugin-pricespider/Widget';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { getPageTypeFromDom } from 'src/common/functions';
import { CMS_THEME } from '../../common/enums';
import { ButtonDiv } from '../button/Button';

interface PricespiderButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  cmsTheme?: CMS_THEME;
  outlined?: boolean;
  widgetID?: string | null;
  sku?: string;
  skipAnalytics?: boolean;
}

const PricespiderWidget = function ({
  cmsTheme = CMS_THEME.LIGHT,
  outlined = false,
  widgetID = null,
  skipAnalytics,
  sku = '',
  title,
  ...rest
}: PricespiderButtonProps) {
  const { className } = rest;

  if (skipAnalytics) {
    return (
      <ButtonDiv
        as={Widget}
        variant={outlined ? 'btn-outline' : 'btn'}
        cmsTheme={cmsTheme}
        className={`${className} js-track`}
        sku={sku}
        widgetID={widgetID}
      />
    );
  }

  return (
    <AnalyticsPoint
      title={title}
      type="component"
      typeLabel="ps_widget"
      label="buy_now_button"
      eventOverride="module_interaction"
      additionalParams={{
        component_type: 'product card',
        component_name: 'product_card',
        page_type: getPageTypeFromDom() || '[not set]',
      }}
    >
      <ButtonDiv
        as={Widget}
        variant={outlined ? 'btn-outline' : 'btn'}
        cmsTheme={cmsTheme}
        className={`${className} js-track`}
        sku={sku}
        widgetID={widgetID}
        aria-describedby={sku}
      />
    </AnalyticsPoint>
  );
};

export default PricespiderWidget;
