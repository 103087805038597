import React from 'react';
import errorSvg from 'src/assets/icons/error.svg';

const RewardError: React.FC = () => {
  return (
    <>
      <h2 className="pds-mb-4 pds-pl-4 pds-pt-4 pds-text-left pds-text-title-md pds-font-semibold lg:pds-pt-4 lg:pds-text-center">
        Redeem Rewards
      </h2>
      <div className="pds-mt-5 pds-flex pds-flex-col pds-items-center md:pds-my-6">
        <div className="pds-mb-5">
          <img src={errorSvg} alt="" />
        </div>
        <div className="pds-mb-2 pds-flex pds-flex-col pds-flex-wrap pds-justify-center pds-gap-x-2 pds-text-center pds-text-title-sm pds-font-extrabold md:pds-flex-row">
          <div>Oops!... Something went wrong.</div>
        </div>
        <div className="pds-mb-5 pds-max-w-[400px] pds-text-center">
          Looks like our wires got crossed. Check your connection and try again.
        </div>
      </div>
    </>
  );
};

export default RewardError;
