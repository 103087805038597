import lottie, { AnimationConfig, AnimationItem } from 'lottie-web';
import React, { useEffect, useRef } from 'react';

interface AppLoaderProps {
  animationData: unknown;
  options?: Partial<AnimationConfig>;
  className?: string;
  persistAnimationOnComplete?: boolean;
}

export const LottieContainer: React.FC<AppLoaderProps> = ({
  animationData,
  options,
  className,
  persistAnimationOnComplete,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const animationRef = useRef<AnimationItem | null>(null);

  useEffect(() => {
    if (containerRef.current) {
      try {
        animationRef.current = lottie.loadAnimation({
          container: containerRef.current,
          renderer: 'svg',
          loop: false,
          autoplay: true,
          animationData,
          ...options,
        });

        animationRef.current.addEventListener('complete', () => {
          if (persistAnimationOnComplete) return;

          animationRef.current?.destroy();
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Failed to load animation', error);
      }
    }

    return () => animationRef.current?.destroy();
  }, [animationData, options, persistAnimationOnComplete]);

  return <div ref={containerRef} className={className} />;
};
