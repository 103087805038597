import { styled } from '@linaria/react';

interface Props {
  textColor?: string;
}

export const TextButton = styled.button<Props>`
  all: unset;
  cursor: pointer;
  color: ${({ textColor }) => textColor || '#000'};
`;
