import { Button } from '@purinanbm/pds-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React from 'react';
import Link from 'src/components/Link';

const RewardCatalogGate = ({ children }: { children: React.ReactNode }) => {
  const { enableCatalog = undefined } = useFlags();
  const isLoaded = enableCatalog !== undefined;
  if (!isLoaded) {
    return null;
  }

  if (isLoaded && enableCatalog) {
    return children;
  }

  // coming soon message
  return (
    <div className="pds-flex pds-flex-col pds-items-center pds-gap-4 pds-overflow-clip md-bt:pds-flex-row">
      <div className="pds-max-w-lg pds-space-y-4.5 pds-pb-4.5 md-bt:pds-pb-5 md-bt:pds-text-center">
        <div className="pds-space-y-4">
          <h2 className="pds-text-title-sm pds-font-medium md-bt:pds-text-title-md">
            Rewards Coming Soon
          </h2>
          <p>
            You will soon be able to view and redeem rewards on Purina.com, but you can do both
            right now on the myPurina app. Start exploring today.
          </p>
        </div>
        <div>
          <Button
            buttonColor="neutral"
            width="full"
            as={Link}
            className="pds-text-center pds-no-underline sm:pds-w-auto"
            target="_blank"
            to="https://mypurina.onelink.me/obkf/07zx4bvv"
          >
            Download the App
          </Button>
        </div>
      </div>
      <StaticImage
        className="pds-top-6 pds-max-w-[294px] md-bt:pds-top-0 md-bt:pds-max-w-max lg:pds-left-4.5"
        src="../../../assets/media/images/catalog-bg.png"
        alt=""
      />
    </div>
  );
};

export default RewardCatalogGate;
