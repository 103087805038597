import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { styled } from '@linaria/react';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Link from '../Link';
import RichText from '../rich_text/RichText';

interface Props {
  link: string;
  title: string;
  summary?: string;
}

const TitleLink = styled(Link)`
  color: black;

  text-decoration: underline;
  text-underline-offset: 0.5rem;

  s &:hover {
    cursor: pointer;
  }
`;

const PageCard: React.FC<Props> = function ({ title, link, summary }: Props) {
  return (
    <Container>
      <Col className="d-grid gap-3">
        <TitleLink to={link} className="fs-2">
          {title}
        </TitleLink>
        {summary && <RichText body={summary} />}
      </Col>
    </Container>
  );
};

export default PageCard;
