import * as React from 'react';
import { SVGProps } from 'react';

const AdornmentDogIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    style={{ '--fill-color': 'currentColor' } as React.CSSProperties}
    {...props}
  >
    <path
      fill="var(--fill-color, #000)"
      d="M22.6025 10.4232L20.0614 5.15312C19.9796 4.98249 19.8564 4.88404 19.7271 4.88404H15.5219C13.4919 3.70532 11.0069 3.70532 8.97565 4.88404H4.77048C4.64116 4.88404 4.51916 4.98249 4.43621 5.15312L1.89505 10.4232C1.68644 10.8577 1.70474 11.4904 1.93775 11.8868L4.546 16.3208C4.86197 16.8576 5.39387 16.7185 5.60004 16.0438L5.99165 14.7614L7.46657 9.04106C7.50927 8.87305 7.67152 8.7746 7.82767 8.82054C7.98383 8.8678 8.07532 9.04106 8.03263 9.20907L6.45279 15.3389C6.38081 15.6947 6.61504 16.0123 7.39215 16.599L12.3281 20L17.1078 16.599C18.046 15.881 18.1472 15.65 18.0667 15.3035L16.5015 9.23007C16.4588 9.06337 16.5357 8.87567 16.6894 8.82448C16.8504 8.77066 17.0176 8.86911 17.0627 9.04106L18.6938 15.3678L18.9 16.0438C19.1061 16.7185 19.638 16.8576 19.954 16.3208L22.5623 11.8868C22.7953 11.4904 22.8136 10.8577 22.6049 10.4232H22.6025ZM12.3281 14.7378L10.1346 12.8542H14.5228L12.3293 14.7378H12.3281Z"
    />
  </svg>
);
export default AdornmentDogIcon;
