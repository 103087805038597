import { pdsTv } from '@purinanbm/pds-ui/dist/common/pdsTv';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';
import Container from 'react-bootstrap/Container';
import { getFocalPoint } from 'src/utils/mediaHelpers';
import { CMS_OVERLAY, CMS_THEME } from '../common/enums';
import ArticleOverlayCard from '../components/article_card/ArticleOverlayCard';
import RichText from '../components/rich_text/RichText';

const styles = pdsTv({
  slots: {
    cardsContainer: 'pds-py-4 lg:pds-py-5 [&_img]:pds-max-w-full',
    gridContainer: '',
    title: '',
    articleGrid: '',
  },
  variants: {
    theme: {
      dark: { cardsContainer: 'pds-bg-[#1E1E1E] pds-text-white' },
      light: { cardsContainer: 'pds-bg-white pds-text-paper-text' },
      neutral: { cardsContainer: 'pds-bg-surface-foreground pds-text-paper-text' },
    },
    needsPadding: {
      true: { title: 'pds-pb-4 lg:pds-pb-[48px]' },
    },
    grid: {
      true: {
        articleGrid: [
          'pds-grid pds-auto-rows-auto pds-grid-cols-1 pds-gap-4 md:pds-grid-cols-2 md:&first:pds-col-[1/3] md:&first:pds-row-[1] lg:pds-grid-flow-col lg:pds-grid-cols-[2fr,1fr] lg:pds-grid-rows-[auto] lg:&first:pds-col-[1] lg:&first:pds-row-[1/3] lg:&first:*:pds-h-full',
        ],
      },
      false: { articleGrid: 'row' },
    },
  },
});

interface StorageArticleCardsProps {
  node: IStorageArticleCards;
}

const getArticleCardProps = (node: IArticleContent) => {
  const { title, type, name, drupal_id } = node;
  const summary = node.body.summary ?? undefined;
  const url = node.path?.alias;
  // eslint-disable-next-line no-underscore-dangle
  const image = node.relationships.field_image?.relationships.field_media_image?.gatsbyImage;
  const imageAlt = node.relationships.field_image?.field_media_image.alt ?? '';
  const promoted = node.sticky;

  return {
    title,
    type,
    name,
    drupal_id,
    url,
    summary,
    imageComponent: image ? (
      <GatsbyImage
        alt={imageAlt}
        image={image}
        imgStyle={{
          objectFit: 'cover',
        }}
        objectPosition={getFocalPoint(node.relationships.field_image)}
      />
    ) : (
      <StaticImage
        aspectRatio={1.5}
        src="../assets/media/images/cat-dog-article-placeholder-2.jpg"
        alt="A cute dog & a cute cat."
      />
    ),
    promoted,
  };
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
    overlay: CMS_OVERLAY.DARK,
  },
};
const StorageArticleCards: React.FC<StorageArticleCardsProps> = function ({
  node: cmsArticleCards,
}) {
  const settings = Object.assign(DefaultSettings, cmsArticleCards?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const cmsOverlay = settings.entity_variant.overlay || CMS_OVERLAY.DARK;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;

  const { cardsContainer, title, articleGrid } = styles({
    theme: cmsTheme,
    needsPadding: Boolean(cmsArticleCards?.title || cmsArticleCards?.body?.processed),
    grid: cmsArticleCards.relationships.articles.length === 3,
  });
  return (
    <Container
      id={cmsArticleCards.component_id}
      data-id={cmsArticleCards.drupal_id}
      className={cardsContainer()}
      fluid
    >
      <Container className={title()}>
        {cmsArticleCards?.title && (
          <HeadingElement className={headingStyle}>{cmsArticleCards.title}</HeadingElement>
        )}
        {cmsArticleCards?.body?.processed && (
          <RichText
            cmsTheme={cmsTheme}
            className="pds-mt-4"
            body={cmsArticleCards.body.processed}
          />
        )}
      </Container>
      <Container>
        <div className={articleGrid()}>
          {cmsArticleCards.relationships.articles.map((article, index) => {
            return (
              <div
                data-id={article.drupal_id}
                key={`${index}-${article.drupal_internal__nid}`}
                className={
                  cmsArticleCards.relationships.articles.length === 2
                    ? 'col-12 col-lg-6 pds-mb-4.5'
                    : ''
                }
              >
                <ArticleOverlayCard
                  cmsTheme={cmsTheme}
                  cmsOverlay={cmsOverlay}
                  {...getArticleCardProps(article)}
                  condensed={index !== 0 && cmsArticleCards.relationships.articles.length > 2}
                />
              </div>
            );
          })}
        </div>
      </Container>
    </Container>
  );
};

export default StorageArticleCards;
