import { pdsTv } from '@purinanbm/pds-ui';
import React from 'react';

import PurinaConfetti from 'src/assets/media/lottie/PurinaConfetti.json';
import Layout from 'src/components/Layout';
import { LottieContainer } from 'src/utils/LottieContainer';

type Props = {
  className?: string;
  children: React.ReactNode;
};

const success = pdsTv({
  base: 'pds-z-10 pds-flex pds-flex-col pds-items-center pds-justify-center pds-rounded-xl pds-bg-surface pds-p-5 pds-shadow-strong',
});

const Success: React.FC<Props> = props => {
  const styles = success({ className: props?.className });
  return (
    <Layout language="en">
      <div className="pds-relative pds-flex pds-flex-1 pds-items-center pds-justify-center pds-overflow-hidden pds-bg-surface-layer pds-px-4 pds-pb-7 pds-pt-6 md:pds-py-7">
        <LottieContainer
          className="pds-absolute pds-bottom-0 pds-min-w-[1600px] xl:pds-w-full"
          animationData={PurinaConfetti}
          persistAnimationOnComplete
        />
        <div className={styles}>{props.children}</div>
      </div>
    </Layout>
  );
};

export default Success;
