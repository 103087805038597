import { PurinaProfile, sendLoginAnalytics } from '@purinanbm/pup-script/dist/react/PurinaProfile';
import { navigate } from '@reach/router';
import React from 'react';
import { PROFILE_CONFIG } from 'src/common/constants';
import { useAuth } from 'src/hooks/useAuth';
import { LOYALTY_QUERIES, useLoyaltyQuery } from 'src/hooks/useLoyaltyService';

const PupProfile: React.FC = () => {
  const { user } = useAuth();
  const userAnsiraId = user?.ansiraUuid || '';
  const { data: customer } = useLoyaltyQuery(
    LOYALTY_QUERIES.GET_CUSTOMERS_DETAIL,
    {
      customerId: userAnsiraId,
    },
    { enabled: Boolean(userAnsiraId) },
  );

  return (
    <PurinaProfile
      loyaltyPoints={customer?.customer?.loyaltyPoints}
      config={PROFILE_CONFIG}
      shouldSendLoginAnalytics={false}
      shouldRedirectOnLogin={false}
      handleLoginRedirect={(ansiraUuid, oAuthState) => {
        if (oAuthState?.prevHref) {
          navigate(oAuthState?.prevHref);
        }
        sendLoginAnalytics(PROFILE_CONFIG, ansiraUuid);
      }}
    />
  );
};

export default PupProfile;
