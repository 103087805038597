import { useLocation } from '@reach/router';
import { AxiosError } from 'axios';
import { navigate } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SearchListing from '../../components/search_listing/SearchListing';
import { handleFacetChange } from '../../utils/helpers';

interface Base {
  error: unknown;
  isFetching: boolean;
  contextualFilters?: SearchParams;
  defaultLoadedState?: boolean;
}

interface Api extends Base {
  data: DrupalSearch | undefined;
  serverData?: DrupalSearch;
}

interface Server extends Base {
  data: DrupalSearch | undefined;
  serverData: DrupalSearch;
}

const ArticleListingBody: React.FC<(Server | Api) & { title?: string }> = ({
  data,
  serverData,
  error,
  isFetching,
  contextualFilters,
  defaultLoadedState,
  title,
}) => {
  const location = useLocation();
  const { t } = useTranslation();
  return (
    <Container className="my-5">
      <SearchListing
        data={data}
        error={(error as AxiosError) || new Error('Sorry, there was an error')}
        loaded={!isFetching}
        defaultLoadedState={defaultLoadedState}
      >
        <Row className="align-items-center py-3">
          <SearchListing.SearchHeader
            title={''}
            hideFilters
            onFacetRemove={e => handleFacetChange(e, location, navigate, contextualFilters)}
          />
        </Row>
        <SearchListing.ArticleSearchResults serverData={serverData} />
        <SearchListing.Pagination serverData={serverData} />
        <SearchListing.FacetModal
          onChange={e => handleFacetChange(e, location, navigate, contextualFilters)}
        />
      </SearchListing>
    </Container>
  );
};
export default ArticleListingBody;
