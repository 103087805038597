import React, { useContext } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useLocation } from '@reach/router';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { navigate } from 'gatsby';

import { SearchListingContext } from '../search_listing/SearchListingContext';

interface SearchPagerProps {
  pager?: SearchPager;
}

const Pager: React.FC<SearchPagerProps> = function ({ pager }) {
  const isSSR = typeof window === 'undefined';
  const location = useLocation();
  const context = useContext(SearchListingContext);
  const { t } = useTranslation();

  if (!pager || pager?.total_pages === 1) {
    return null;
  }

  const active = pager.current_page;
  const activeDisplay = active + 1;
  const totalPages = pager.total_pages;
  let start = activeDisplay;
  const max = totalPages <= 4 ? totalPages - 1 : 4;
  if (activeDisplay <= max) {
    start = 1;
  }
  if (activeDisplay >= totalPages - max) {
    start = totalPages - max;
  }

  const items = [];

  const showFirst = totalPages > max && activeDisplay > max && totalPages > 4;
  const showLast = totalPages > max && active < totalPages - max && totalPages > 4;

  const onClick = async (e: React.MouseEvent, number: number) => {
    e.preventDefault();
    handleAnalyticData(number);
    await navigate(generateHref(number), { replace: true });

    if (pager.container_ref) {
      pager.container_ref.current?.focus({ preventScroll: true });
      pager.container_ref.current?.scrollIntoView();
    } else {
      window.scrollTo(0, 0);
    }
  };

  const handleAnalyticData = (page: number) => {
    context?.setLoaded(false);

    const analyticsData = {
      event: 'pagination',
      eventParams: {
        pagination_value: String(page),
      },
    };
    sendIt(analyticsData);
  };

  const generateHref = (page: number) => {
    if (isSSR) {
      // Return query string with only page number for crawlers.
      const queryParams = new URLSearchParams();
      queryParams.set('page', `${page}`);
      return `?${queryParams.toString()}`;
    }

    const url = new URL(location.href);
    url.searchParams.set('page', `${page}`);
    return url.pathname + url.search + url.hash;
  };

  for (let number = start; number <= start + max; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === activeDisplay}
        disabled={number === activeDisplay}
        data-page={number}
        href={generateHref(number)}
        linkClassName="!pds-text-[#2B2B2B]"
        onClick={e => onClick(e, number)}
        aria-label={`${t('Page')} ${number}`}
      >
        {number}
      </Pagination.Item>,
    );
  }

  if (items.length > 1) {
    return (
      <nav aria-label={t('Pagination')} className="pager">
        <Pagination size="lg" className="justify-content-center justify-content-sm-end">
          {showFirst && (
            <Pagination.First
              onClick={e => onClick(e, 1)}
              href={generateHref(1)}
              className="d-none d-md-block"
              linkClassName="!pds-text-[#2B2B2B]"
              aria-label={t('First Page')}
            />
          )}
          {activeDisplay > 1 && totalPages > 4 && (
            <Pagination.Prev
              onClick={e => onClick(e, active)}
              href={generateHref(active)}
              linkClassName="!pds-text-[#2B2B2B]"
              aria-label={t('Previous Page')}
            />
          )}
          {showFirst && (
            <Pagination.Ellipsis
              className="d-none d-md-block"
              linkClassName="!pds-text-[#2B2B2B]"
              disabled
            />
          )}
          {items}
          {showLast && (
            <Pagination.Ellipsis
              className="d-none d-md-block"
              linkClassName="!pds-text-[#2B2B2B]"
              disabled
            />
          )}
          {totalPages > activeDisplay && totalPages > 4 && (
            <Pagination.Next
              onClick={e => onClick(e, activeDisplay + 1)}
              href={generateHref(activeDisplay + 1)}
              linkClassName="!pds-text-[#2B2B2B]"
              aria-label={t('Next Page')}
            />
          )}
          {showLast && (
            <Pagination.Last
              onClick={e => onClick(e, totalPages)}
              href={generateHref(totalPages)}
              className="d-none d-md-block"
              linkClassName="!pds-text-[#2B2B2B]"
              aria-label={t('Last Page')}
            />
          )}
        </Pagination>
      </nav>
    );
  }
  return null;
};

export default Pager;
