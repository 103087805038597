import { PageProps, graphql } from 'gatsby';
import React from 'react';
import PurinaPerksLayout from 'src/components/purina_perks/PurinaPerksLayout';
import RewardCatalog from 'src/components/purina_perks/reward_catalog/RewardCatalog';

interface DataProps {
  node: NodeContent;
  translations: Translations;
}

const RewardCatalogTemplate: React.FC<PageProps<DataProps>> = ({
  data: { node, translations },
}) => {
  return (
    <PurinaPerksLayout
      node={node}
      language={node.langcode}
      metaData={node.metatag}
      translations={translations}
    >
      <RewardCatalog />
    </PurinaPerksLayout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeComplex(id: { eq: $id }, langcode: { eq: $language }) {
      id
      body {
        processed
      }
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      behaviors {
        page_layout {
          elements_to_hide {
            header
            footer
            chat
          }
        }
      }
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
    }
    translations: allNodeComplex(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default RewardCatalogTemplate;
