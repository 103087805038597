import Icon from '@mdi/react';
import { Col, Row } from '@purinanbm/pds-ui';
import React, { FC, PropsWithChildren, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiInformationOutline } from 'src/assets/icons/mdiIcons';
import { PROFILE_CONFIG as config } from 'src/common/constants';
import { SST_QUERIES, useSstQuery } from 'src/hooks/useSstService';
import { Pet } from 'src/services/sst/data-contracts';
import { PUP_ROUTES } from 'src/utils/helpers';
import Link from '../Link';
import Alert from '../alert/Alert';
import PetList from './PetList';
import { PetListSkeleton } from './PetListSkeleton';

interface MyPetViewProps {
  pets: Partial<Pet>[];
  isLoading: boolean;
  error: unknown;
  ansiraId: string;
  hasInvalidPetsProfile?: boolean;
}

const MyPetView: FC<PropsWithChildren<MyPetViewProps>> = memo(
  ({ pets, isLoading, error, ansiraId, hasInvalidPetsProfile = false }) => {
    const { t } = useTranslation();
    const { data: media } = useSstQuery(
      SST_QUERIES.GET_ALL_MEDIA,
      {
        userId: ansiraId,
      },
      { enabled: Boolean(ansiraId) },
    );

    return (
      <div className="pds-flex pds-w-full pds-flex-col pds-gap-4.5 pds-pt-5.5">
        <Row>
          <Col className="pds-w-5/6">
            <h3 className="pds-text-title-md pds-font-normal">{t('My Pets')}</h3>
          </Col>
        </Row>
        {!!error && (
          <div className="pds-w-full pds-text-center pds-text-error" role="alert">
            {t('There was an error retrieving Pets')}...
          </div>
        )}
        {hasInvalidPetsProfile && (
          <Alert variant="error" role="alert">
            <Row className="pds-font-semibold">
              <Col className="pds-w-1/12 pds-justify-start">
                <Icon
                  aria-hidden="true"
                  path={mdiInformationOutline}
                  size={1}
                  className="pds-mr-2 pds-self-center"
                />
                <span className="pds-sr-only">{t('Error')}</span>
              </Col>
              <Col>
                <span className="pds-self-center pds-text-body-md">
                  {t(
                    'Looks like one or more of your pet profiles is incomplete. Please let us know all about each of your furry friends to redeem an offer.',
                  )}
                </span>
              </Col>
            </Row>
          </Alert>
        )}
        {isLoading && !error && <PetListSkeleton />}
        {pets?.length > 0 ? (
          <>
            <Row className="pds-px-4">
              <PetList data={pets} media={media?.data} />
            </Row>
            <Row>
              <Link
                to={`${config.pup.url}${PUP_ROUTES.ADD_PET}?origin=${window.location.href}`}
                className="pds-flex pds-items-center pds-font-semibold pds-text-surface-text pds-underline-offset-2"
              >
                <div className="pds-mr-4 pds-flex pds-h-[45px] pds-w-[45px] pds-items-center pds-justify-center pds-rounded-circle pds-bg-surface-text">
                  <span aria-hidden className="pds-text-[24px] pds-leading-7 pds-text-surface">
                    +
                  </span>
                </div>
                <span className="pds-text-surface-text">{t('Add More Pets')}</span>
              </Link>
            </Row>
          </>
        ) : null}
      </div>
    );
  },
);

export default MyPetView;
