import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../../../utils/helpers';
import RichText from '../../../rich_text/RichText';
// eslint-disable-next-line import/no-cycle
import { IContactUsFormData } from '../../ContactUsForm';
// eslint-disable-next-line import/no-cycle
import { requiredClass } from '../InputField';
import RecaptchaDisclaimer from '../RecaptchaDisclaimer';

interface IConsentSectionFormProps {
  optin?: string;
  legalText?: string;
}

const ConsentSection = ({ optin, legalText }: IConsentSectionFormProps) => {
  const {
    register,
    watch,
    control,
    formState: { errors },
  } = useFormContext<IContactUsFormData>();
  const { t } = useTranslation();

  const showPhoneNumber = (watch('callFeedbackConsent') as unknown as string) === '1'; // watch comes as string

  return (
    <>
      <Col md={10}>
        <Form.Group controlId="emailFeedbackConsent">
          <fieldset>
            <legend className={`form-label ${requiredClass}`}>
              {t('May we email you to request feedback on our products or services?')}
            </legend>
            <div className="gap-2 d-flex">
              <Form.Check
                {...register('emailFeedbackConsent')}
                value={1}
                id="emailFeedbackConsent-yes"
                type="radio"
                label={t('Yes')}
                aria-describedby="emailFeedbackConsent-message"
              />
              <Form.Check
                {...register('emailFeedbackConsent')}
                value={0}
                id="emailFeedbackConsent-no"
                type="radio"
                label={t('No')}
                aria-describedby="emailFeedbackConsent-message"
              />
            </div>
            {errors.emailFeedbackConsent && (
              <Form.Control.Feedback
                className="d-block"
                type="invalid"
                id="emailFeedbackConsent-message"
              >
                {errors.emailFeedbackConsent.message}
              </Form.Control.Feedback>
            )}
          </fieldset>
        </Form.Group>
      </Col>
      <Col md={10}>
        <Form.Group controlId="callFeedbackConsent">
          <fieldset>
            <legend className={`form-label ${requiredClass}`}>
              {t('May we call you to request feedback on our products or services?')}
            </legend>
            <div className="gap-2 d-flex">
              <Form.Check
                {...register('callFeedbackConsent')}
                value={1}
                type="radio"
                label={t('Yes')}
                id="callFeedbackConsent-yes"
                aria-describedby="callFeedbackConsent-message"
              />
              <Form.Check
                {...register('callFeedbackConsent')}
                value={0}
                type="radio"
                label={t('No')}
                id="callFeedbackConsent-no"
                aria-describedby="callFeedbackConsent-message"
              />
            </div>
            {errors.callFeedbackConsent && (
              <Form.Control.Feedback
                className="d-block"
                type="invalid"
                id="callFeedbackConsent-message"
              >
                {errors.callFeedbackConsent.message}
              </Form.Control.Feedback>
            )}
          </fieldset>
        </Form.Group>
      </Col>
      {showPhoneNumber && (
        <Col xs={12} md={10}>
          <Form.Group controlId="phoneNumber">
            <Form.Label className={requiredClass}>{t('Phone Number')}</Form.Label>
            <Controller
              control={control}
              name="phoneNumber"
              render={({ field: { onChange, ...rest }, fieldState }) => (
                <>
                  <Form.Control
                    onChange={e => {
                      const formattedPhone = {
                        ...e,
                        target: { ...e.target, value: formatPhoneNumber(e.target.value) },
                      };
                      onChange(formattedPhone);
                    }}
                    className={requiredClass}
                    isInvalid={!!fieldState.error}
                    aria-invalid={!!fieldState.error}
                    {...rest}
                    type="text"
                    autoComplete="tel-national"
                    placeholder={t('e.g. (123) 456-7890')}
                    aria-describedby="phoneNumber-message"
                  />
                  {fieldState.error && (
                    <Form.Control.Feedback
                      className="d-block"
                      type="invalid"
                      id="phoneNumber-message"
                    >
                      {fieldState.error.message}
                    </Form.Control.Feedback>
                  )}
                </>
              )}
            />
          </Form.Group>
        </Col>
      )}
      <h4>{t('My pets')}</h4>
      <Col md={4} xs={12}>
        <Form.Group controlId="ownedDogs">
          <Form.Label>{t('Dogs')}</Form.Label>
          <Form.Select {...register('ownedDogs')} aria-describedby="ownedDogs-message">
            <option key="nd-default" value={undefined}>
              {t('number of dogs')}
            </option>
            {[1, 2, 3, 4, 5].map(number => (
              <option key={`${number} owned dogs`} value={number}>
                {number}
              </option>
            ))}
          </Form.Select>
          {errors?.ownedDogs && (
            <Form.Control.Feedback className="d-block" type="invalid" id="ownedDogs-message">
              {errors.ownedDogs.message}
            </Form.Control.Feedback>
          )}
        </Form.Group>
      </Col>
      <Col md={4} xs={12}>
        <Form.Group controlId="ownedCats">
          <Form.Label>{t('Cats')}</Form.Label>
          <Form.Select {...register('ownedCats')}>
            <option key="nc-default" value={undefined}>
              {t('number of cats')}
            </option>
            {[1, 2, 3, 4, 5].map(number => (
              <option key={`${number} owned cats`} value={number}>
                {number}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Col>
      <Col md={12}>
        <Form.Group controlId="emailSubscribingConsent" className="gap-2 d-flex">
          <Form.Check
            type="checkbox"
            label={optin ? <RichText body={optin} /> : null}
            {...register('emailSubscribingConsent')}
          />
          {errors.emailSubscribingConsent && 'error'}
        </Form.Group>
      </Col>
      {legalText && <RichText body={legalText} />}
      <Col xs={12}>
        <RecaptchaDisclaimer />
      </Col>
    </>
  );
};

export default ConsentSection;
