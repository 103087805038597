import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NewArticleCard from '../article_card/NewArticleCard';
import ProductCardSkeleton from '../product_card/ProductCardSkeleton';
import NoResults from './NoResults';
import { SearchListingContext } from './SearchListingContext';

interface Props {
  serverData?: DrupalSearch;
}

const ArticleAuthorSearchResults: React.FC<Props> = function ({ serverData }) {
  const context = useContext(SearchListingContext);

  if (!context && !serverData) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const { data, loaded } = context || {};
  const search_results = serverData?.search_results || data?.search_results;
  if (!loaded) {
    return (
      <Row
        className="gy-3 gy-md-4 pds-mb-5.5"
        style={{
          // @ts-ignore - --bs-gutter-x is a bootstrap variable
          '--bs-gutter-x': 'clamp(1rem, 3%, 48px)',
        }}
      >
        {[...Array(12)].map((_, i) => {
          return (
            <Col key={`loading-card-${i}`} xs={12} sm={6} md={3} className="pds-flex">
              <ProductCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }

  if (!search_results) {
    return <NoResults />;
  }

  return (
    <Row
      id="search-results"
      className="gy-3 gy-md-4 pds-mb-5.5"
      style={{
        // @ts-ignore - --bs-gutter-x is a bootstrap variable
        '--bs-gutter-x': 'clamp(1rem, 3%, 48px)',
      }}
    >
      {Object.values(search_results).map((node: ArticleSearchResult) => {
        const image = node.field_media_image
          ? process.env.GATSBY_DRUPAL_ENDPOINT + node.field_media_image.substring(1)
          : '';

        return (
          <Col key={node.nid} xs={12} sm={6} lg={3}>
            <NewArticleCard
              image={{
                images: {
                  fallback: {
                    src: image,
                  },
                },
                layout: 'fullWidth',
                width: 300,
                height: 200,
              }}
              link={node.url}
              title={node.title}
              topics={node.topics}
              readTime={node.read_time}
            />
          </Col>
        );
      })}
    </Row>
  );
};

ArticleAuthorSearchResults.displayName = 'SearchListing.ArticleAuthorSearchResults';

export default ArticleAuthorSearchResults;
