import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormErrors } from '../../../common/types';
import { getAddressStates } from '../../../utils/formHelpers';
import InputField, { requiredClass } from './InputField';

interface Props {
  register: UseFormRegister<any>;
  errors: FormErrors;
  isLongAddress?: boolean;
}

const AddressSection: React.FC<Props> = ({ register, errors, isLongAddress = false }) => {
  const { t } = useTranslation();
  const addressStates = Object.entries(getAddressStates());
  const stateOptions = [['', t('Select a State')], ...addressStates];

  return (
    <>
      {isLongAddress && (
        <>
          <Col xs={12}>
            <InputField
              name="address1"
              label={t('Address 1')}
              placeholder={t('Address Line 1')}
              required
              autoComplete="address-line1"
              maxLength={255}
            />
          </Col>
          <Col xs={12}>
            <InputField
              name="address2"
              label={t('Address 2')}
              placeholder={t('Address Line 2')}
              autoComplete="address-line2"
              maxLength={255}
            />
          </Col>
          <Col xs={12} md={4} className="md-bt:pds-pr-3">
            <InputField
              name="city"
              label={t('City')}
              placeholder={t('City')}
              required
              autoComplete="address-level2"
              maxLength={255}
            />
          </Col>
          <Col xs={12} md={4} className="md-bt:pds-px-3">
            <Form.Group controlId="state">
              <Form.Label className={requiredClass}>{t('State')}</Form.Label>
              <Form.Select
                {...register('state')}
                isInvalid={!!errors?.state}
                aria-invalid={!!errors?.state}
                required
                autoComplete="address-level1"
                aria-describedby="state-message"
              >
                {stateOptions.map(([key, value]) => {
                  return (
                    <option value={key} key={key}>
                      {value}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Control.Feedback type="invalid" id="state-message">
                {errors.state && errors.state.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </>
      )}
      <Col xs={12} md={isLongAddress ? 4 : 12} className={isLongAddress ? 'md-bt:pds-pl-3' : ''}>
        <InputField
          name="postalCode"
          label={t('Zip Code')}
          placeholder={t('Enter your zip code')}
          maxLength={5}
          required
          autoComplete="postal-code"
        />
      </Col>
    </>
  );
};

export default AddressSection;
