import React, { FC } from 'react';

export const PetListSkeleton: FC = () => (
  <div className="pds-shadow pds-rounded-md pds-mx-auto pds-flex pds-w-full pds-justify-center pds-border pds-p-4">
    <div className="pds-flex pds-w-full pds-max-w-4xl pds-animate-pulse pds-flex-wrap pds-items-center pds-justify-center">
      <div className="pds-flex pds-w-1/3 pds-flex-grow pds-flex-col pds-items-center pds-justify-center pds-overflow-hidden pds-text-ellipsis pds-py-4">
        <div className="pds-h-[94px] pds-w-[94px] pds-self-center pds-rounded-circle pds-bg-gray-300 pds-text-center sm:pds-h-[120px] sm:pds-w-[120px]"></div>
        <span className="pds-mt-3 pds-line-clamp-1 pds-h-[30px] pds-w-[49px] pds-rounded pds-bg-gray-300 pds-text-body-lg pds-font-bold"></span>
      </div>
      <div className="pds-flex pds-w-1/3 pds-flex-grow pds-flex-col pds-items-center pds-justify-center pds-overflow-hidden pds-text-ellipsis pds-py-4">
        <div className="pds-h-[94px] pds-w-[94px] pds-self-center pds-rounded-circle pds-bg-gray-300 pds-text-center sm:pds-h-[120px] sm:pds-w-[120px]"></div>
        <span className="pds-mt-3 pds-line-clamp-1 pds-h-[30px] pds-w-[49px] pds-rounded pds-bg-gray-300 pds-text-body-lg pds-font-bold"></span>
      </div>
      <div className="pds-flex pds-w-1/3 pds-flex-grow pds-flex-col pds-items-center pds-justify-center pds-overflow-hidden pds-text-ellipsis pds-py-4">
        <div className="pds-h-[94px] pds-w-[94px] pds-self-center pds-rounded-circle pds-bg-gray-300 pds-text-center sm:pds-h-[120px] sm:pds-w-[120px]"></div>
        <span className="pds-mt-3 pds-line-clamp-1 pds-h-[30px] pds-w-[49px] pds-rounded pds-bg-gray-300 pds-text-body-lg pds-font-bold"></span>
      </div>
    </div>
  </div>
);
