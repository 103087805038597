import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';

export type SearchPageResult = {
  node: {
    path: { alias: string };
    id: string;
    api_slug: PageType;
    title: string;
    langcode: string;
    default_langcode: boolean;
  };
};

export enum PageType {
  Products = 'products',
  Articles = 'articles',
  Pages = 'pages',
  Breeds = 'breeds',
}

export const PageTypeLabels = {
  [PageType.Products]: 'Products',
  [PageType.Articles]: 'Articles',
  [PageType.Pages]: 'Pages',
  [PageType.Breeds]: 'Breeds',
};

export interface FacetCountValue {
  value: 'article' | 'breed' | 'page' | 'product';
  count: number;
}

/*
 * This a service to request the data from the server. The difference is that,
 * in order to call this as a function, we must wrap it inside a hook.
 * Hooks have the specific rule of needing to be called at the beginning of the component
 * This must not be confused with a normal service, where you just call a function to fetch from a url
 */
function useQuerySearchPages() {
  return useStaticQuery<{
    allNodeSearchPage: {
      edges: SearchPageResult[];
    };
  }>(graphql`
    query SearchPageQuery {
      allNodeSearchPage(sort: { field_node_weight: ASC }) {
        edges {
          node {
            id
            api_slug: field_api_slug
            title
            path {
              alias
            }
            langcode
            default_langcode
          }
        }
      }
    }
  `);
}

export default {
  useGetSearchPages: (language = 'en') => {
    const data = useQuerySearchPages();

    return data.allNodeSearchPage.edges.filter(page => page.node.langcode === language);
  },
  useGetActiveSearchPage: (language = 'en') => {
    const data = useQuerySearchPages();
    const location = useLocation();
    let searchPage = data.allNodeSearchPage.edges[0].node;
    const match = data.allNodeSearchPage.edges.find(page => {
      const langcode = page.node?.langcode || 'en';
      return (
        langcode === language &&
        decodeURIComponent(location.pathname).indexOf(page.node.path.alias) > -1 // used the decodeURIComponent to avoid encoding issues
      );
    });

    if (match) searchPage = match.node;
    return searchPage;
  },
  getSearchPageTypeFromFacet: (facet: FacetCountValue['value']) => {
    switch (facet) {
      case 'product':
        return PageType.Products;
      case 'article':
        return PageType.Articles;
      case 'page':
        return PageType.Pages;
      case 'breed':
        return PageType.Breeds;
      default:
        return undefined;
    }
  },
};
