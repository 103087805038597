import { Button, Heading } from '@purinanbm/pds-ui';
import { CognitoHostedUIIdentityProvider, profileAuth } from '@purinanbm/pup-script';
import PetsIcon from '@purinanbm/pup-script/dist/react/icons/Pets.svg';
import RewardsIcon from '@purinanbm/pup-script/dist/react/icons/Rewards.svg';
import SSOLogo from '@purinanbm/pup-script/dist/react/icons/SSO.svg';
import AppleLogo from '@purinanbm/pup-script/dist/react/icons/apple.svg';
import FacebookLogo from '@purinanbm/pup-script/dist/react/icons/facebook.svg';
import GoogleLogo from '@purinanbm/pup-script/dist/react/icons/google.svg';
import { StaticImage } from 'gatsby-plugin-image';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { debounce } from 'lodash';
import React from 'react';
import { PROFILE_CONFIG as config } from 'src/common/constants';

interface SSOWidgetProps {
  brand?: string;
}

const Auth = profileAuth(config);

const onSocialLogin = debounce((provider: CognitoHostedUIIdentityProvider, brand: string) => {
  sendIt({
    event: 'form_start',
    eventParams: {
      form_brand: brand,
      form_id: config?.analytics?.loginSourceCode ?? '[not set]',
      form_name: `pup_social: ${provider}`,
      form_parent: config.analytics?.formParent,
      form_type: 'social_login_sign_up',
      form_version: 'v1',
    },
  });

  // Delay to ensure dataLayer push is sent before redirect.
  return setTimeout(() => {
    Auth.federatedSignIn({
      provider,
      customState: JSON.stringify({
        prevHref: window.location.href,
      }),
    });
  }, 100);
}, 100);

interface ISocialLoginButtonConfig {
  socialProvider: CognitoHostedUIIdentityProvider;
  socialProviderText: string;
  showProviderText?: boolean;
  brand: string;
}

const getSocialImage = (provider: CognitoHostedUIIdentityProvider) => {
  switch (provider) {
    case 'SignInWithApple':
      return <AppleLogo width={24} />;
    case 'Google':
      return <GoogleLogo width={24} />;
    case 'Facebook':
      return <FacebookLogo width={24} />;
    default:
      return <SSOLogo width={24} />;
  }
};

const SocialLoginButton: React.FC<ISocialLoginButtonConfig> = ({
  socialProvider,
  socialProviderText,
  showProviderText = true,
  brand,
}) => {
  return (
    <button
      type="submit"
      className="pds-flex pds-cursor-pointer pds-items-center pds-gap-2 pds-rounded pds-border-solid pds-border-surface-text pds-bg-utils-transparent pds-p-3 hover:pds-bg-surface-line md-bt:pds-justify-center"
      onClick={() => onSocialLogin(socialProvider, brand)}
    >
      <div className="pds-flex pds-items-center pds-justify-center">
        {getSocialImage(socialProvider)}
      </div>
      {showProviderText && (
        <div className="pds-text-body-md pds-font-semibold pds-text-surface-text">
          <span className="md-bt:pds-hidden">Continue with </span>
          {socialProviderText}
        </div>
      )}
    </button>
  );
};

const items = [
  {
    id: 'rewards',
    icon: <RewardsIcon aria-hidden="true" width="48" height="48" viewBox="0 0 48 48" />,
    text: 'Earn points for signing up, buying & reviewing Purina products, then redeem points for treats and toys.',
  },
  {
    id: 'sso',
    icon: <SSOLogo aria-hidden="true" width="48" height="48" viewBox="0 0 48 48" />,
    text: 'Use a single account to access Purina.com, Petfinder, and more.',
  },
  {
    id: 'pets',
    icon: <PetsIcon aria-hidden="true" width="48" height="48" viewBox="0 0 48 48" />,
    text: 'Keep all your pet info handy in one place for easier access.',
  },
];

const BenefitList = () => (
  <ul className="pds-m-0 pds-space-y-4 pds-p-0">
    {items.map(item => (
      <li key={item.id} className="pds-flex pds-items-center pds-gap-3">
        <div className="pds-flex pds-items-center pds-justify-center">{item.icon}</div>
        <div className="pds-flex-1">
          <span className="pds-text-neutral-layer">{item.text}</span>
        </div>
      </li>
    ))}
  </ul>
);

export const SSOWidget = (form: SSOWidgetProps) => {
  const onSignUp = debounce(() => {
    const searchParams = new URLSearchParams();
    searchParams.set('property', config?.pup?.property ?? '');
    searchParams.set('origin', window.location.href);
    searchParams.set('client', config.cognito.userPoolWebClientId);
    const params = `?${searchParams.toString()}`;
    sendIt({
      event: 'create_account_click',
      eventParams: {
        content_name: `create account ${config.analytics?.formParent ?? '[not set]'}`,
        cta_location: 'create account offers (offers 2.0)',
        form_brand: form?.brand ?? 'purina',
        internal_campaign_id: 'offers (offers 2.0)' ?? '[not set]',
        origin_site_id: config.analytics?.formBrand ?? 'purina',
        form_id: config.analytics?.signUpSourceCode ?? '[not set]',
        form_version: 'v1',
      },
    });

    // Delay to ensure dataLayer push is sent before redirect.
    return setTimeout(() => {
      window.location.href = `${config.pup.url}/sign-up${params}`;
    }, 100);
  }, 100);

  const onSignIn = debounce(() => {
    sendIt({
      event: 'form_start',
      eventParams: {
        form_brand: form?.brand ?? 'purina',
        form_id: config?.analytics?.loginSourceCode ?? '[not set]',
        form_name: 'login offers (offers 2.0)',
        form_parent: config.analytics?.formParent,
        form_type: 'login',
        form_version: 'v1',
      },
    });

    // Delay to ensure dataLayer push is sent before redirect.
    return setTimeout(() => {
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Cognito,
        customState: JSON.stringify({
          prevHref: window.location.href,
        }),
      });
    }, 100);
  }, 100);

  return (
    <div className="!pds-p-4.5 md-bt:!pds-p-4">
      <div className="pds-overflow-clip pds-rounded !pds-p-0 pds-shadow-weak">
        <div className="pds-hidden pds-h-3 pds-bg-primary md-bt:pds-block" />
        <div className="pds-grid md-bt:pds-grid-cols-2 lg-bt:pds-grid-cols-3">
          <div className="pds-hidden md-bt:pds-inline md-bt:pds-max-w-[336px] lg-bt:pds-w-auto lg-bt:pds-max-w-[464px]">
            <StaticImage
              className="pds-h-full pds-w-full pds-object-cover"
              src="https://www.purina.com/sites/default/files/2023-06/mypurina_promo_module.jpg"
              width={960}
              height={1260}
              placeholder="blurred"
              alt=""
            />
          </div>
          <div className="pds-space-y-4.5 pds-p-4.5 md-bt:pds-pl-0 lg-bt:pds-col-span-2 lg-bt:pds-p-5">
            <div className="pds-flex pds-h-auto pds-justify-center pds-p-4">
              <StaticImage src="../../assets/media/images/logo.png" alt="purina logo" width={150} />
            </div>
            <Heading
              as="h2"
              size="titleSmall"
              weight="extraBold"
              className="pds-mb-3 pds-mt-3 pds-text-center"
            >
              Sign in to redeem offers plus more benefits:
            </Heading>
            <div className="pds-mx-auto pds-max-w-xl pds-text-surface-textSubdued">
              <BenefitList />
            </div>
            <div className="pds-space-y-3 pds-pt-3">
              <div className="pds-mx-auto pds-flex pds-flex-col pds-gap-3 md-bt:pds-max-w-sm">
                <Button
                  buttonStyle="solid"
                  width="full"
                  className="pds-border-solid pds-border-primary"
                  onClick={onSignUp}
                >
                  Create Purina Account
                </Button>
                <Button
                  buttonStyle="outlined"
                  width="full"
                  className="pds-border-solid"
                  onClick={onSignIn}
                >
                  Log in with Purina
                </Button>
              </div>
              <div className="pds-flex pds-items-center">
                <div
                  aria-hidden="true"
                  className="pds-pointer-events-none pds-h-[1px] pds-flex-grow pds-bg-surface-line"
                />
                <span className="pds-mx-4 pds-flex-shrink pds-text-disabled-text">
                  or continue with
                </span>
                <div
                  aria-hidden="true"
                  className="pds-pointer-events-none pds-h-[1px] pds-flex-grow pds-bg-surface-line"
                />
              </div>
              <div className="pds-grid pds-gap-2 md-bt:pds-grid-cols-3">
                <SocialLoginButton
                  socialProvider={CognitoHostedUIIdentityProvider.Google}
                  socialProviderText="Google"
                  brand={form.brand ?? 'purina'}
                />
                <SocialLoginButton
                  socialProvider={CognitoHostedUIIdentityProvider.Apple}
                  socialProviderText="Apple"
                  brand={form.brand ?? 'purina'}
                />
                <SocialLoginButton
                  socialProvider={CognitoHostedUIIdentityProvider.Facebook}
                  socialProviderText="Facebook"
                  brand={form.brand ?? 'purina'}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
