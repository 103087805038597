import { styled } from '@linaria/react';
import React, { useMemo } from 'react';
import { Container } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { remCalc } from '../../theme/theme';
import { MetatagProps, getBreadcrumbsFromMeta } from '../../utils/metatagHelpers';
import Link from '../Link';

interface Props {
  metaData?: Array<MetatagProps>;
}

const StyledBreadcrumb = styled(Breadcrumb)`
  font-size: ${() => remCalc(12)};

  .breadcrumb {
    margin: 0;
  }
`;

const Breadcrumbs: React.FC<Props> = ({ metaData }) => {
  const crumbsMeta = useMemo(() => {
    if (metaData) {
      return getBreadcrumbsFromMeta(metaData);
    }

    return null;
  }, [metaData]);
  // No breadcrumbs to render
  if (!crumbsMeta || crumbsMeta?.length === 0) return null;

  return (
    <Container fluid className="bg-white py-3">
      <Container>
        <StyledBreadcrumb>
          {crumbsMeta.map(crumb => (
            <li className="breadcrumb-item" key={crumb['@id']}>
              {crumb.item ? (
                <Link to={crumb.item} className="link-dark">
                  {crumb.name}
                </Link>
              ) : (
                <span>{crumb.name}</span>
              )}
            </li>
          ))}
        </StyledBreadcrumb>
      </Container>
    </Container>
  );
};
export default Breadcrumbs;
