import { common } from './common';
import { ITheme } from './types';

export const dark: ITheme = {
  mode: 'dark',
  primary: {
    main: '#FFF',
    withAlpha: (alpha: number) => `rgba(255, 255, 255, ${alpha})`,
    // dark: '',
    // light: '',
  },
  text: {
    invert: '#2B2B2B',
    withAlpha: (alpha: number) => `rgba(255, 255, 255, ${alpha})`,
    default: '#FFF',
  },
  background: {
    default: '#1E1E1E',
    alternate: '#2B2B2B',
    paper: '#464646',
    imageMatte: '#EDF1FA',
    iconContainer: '#FFF',
  },
  action: '#2596F8',
  ...common,
};
