import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FieldErrors, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NewOfferFormProps } from '../../newOfferFormTypes';
import { OfferFormTypes } from '../../utils/offer';
import DynamicPetArrayField from '../DynamicPetField';
import { requiredClass } from '../InputField';

const petCountOptions = [
  { value: '0', label: '0' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

interface Props {
  errors: FieldErrors<OfferFormTypes>;
  register: UseFormRegister<OfferFormTypes>;
  watch: UseFormWatch<OfferFormTypes>;
  formType: NewOfferFormProps['formType'];
}

const PetCountSection: React.FC<Props> = ({ errors, formType, register, watch }) => {
  const { t } = useTranslation();
  const dogCount = watch('dogCount');
  const catCount = watch('catCount');
  return (
    <fieldset className="pds-flex pds-flex-col pds-gap-3">
      <legend className="pds-mb-2 pds-mt-4 pds-text-title-md">{t('My Pets')}</legend>
      <div className="flex-md-row pds-flex pds-flex-col pds-gap-4">
        <Col xs={12} md={4}>
          <Form.Group controlId="dogCount">
            <Form.Label className={requiredClass}>{t('Dogs')}</Form.Label>
            <Form.Select
              {...register('dogCount')}
              isInvalid={!!errors?.dogCount}
              aria-invalid={!!errors?.dogCount}
              placeholder={t('Dog count')}
              defaultValue={0}
              required
              aria-describedby="dogCount-message"
            >
              {petCountOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" id="dogCount-message">
              {errors.dogCount && errors.dogCount.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col xs={12} md={4}>
          <Form.Group controlId="catCount">
            <Form.Label className={requiredClass}>{t('Cats')}</Form.Label>
            <Form.Select
              {...register('catCount')}
              isInvalid={!!errors?.catCount}
              aria-invalid={!!errors?.catCount}
              placeholder={t('Cat count')}
              defaultValue={0}
              required
              aria-describedby="catCount-message"
            >
              {petCountOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" id="catCount-message">
              {errors.catCount && errors.catCount.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </div>
      {(formType === 'long_address' || formType === 'long') && (
        <DynamicPetArrayField key="pets" catCount={Number(catCount)} dogCount={Number(dogCount)} />
      )}
    </fieldset>
  );
};

export default PetCountSection;
