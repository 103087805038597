export const validateRequired = (label: string) => {
  return `${label} field is required.`;
};

export const validateMaxLength = (label: string, length: number) => {
  return {
    value: length,
    message: `${label} field is limited to ${length} characters.`,
  };
};

export const validateDropdownRequired = (
  userInput: string | undefined,
  unchangedOption: string,
  label: string
) => {
  if (userInput === unchangedOption) {
    return `${label} field is required.`;
  }
  return true;
};

export const validateMinLength = (label: string, length: number) => {
  return {
    value: length,
    message: `${label} field must be at least ${length} characters.`,
  };
};

export const validateEmailPattern = () => {
  return {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: 'A valid E-mail address is required.',
  };
};

export const validateTextPattern = (label: string) => {
  return {
    value: /^[a-zA-Z_-]+\s?([a-zA-Z_-]+\s?){0,3}$/,
    message: `${label} field must be formatted correctly.`,
  };
};

export const validatePostalCodePattern = (label: string) => {
  return {
    value: /(\d{5})/,
    message: `${label} must be 5 digits.`,
  };
};

export const validateBirthDayPattern = (label: string) => {
  return {
    value: /^(0?[1-9]|1[0-2])\/(0?[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/,
    message: `${label} must be in the format mm/dd/yyyy`,
  };
};

export const getAddressStates = () => {
  return {
    AK: 'Alaska',
    AL: 'Alabama',
    AR: 'Arkansas',
    AZ: 'Arizona',
    AS: 'American Samoa',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    DC: 'District of Columbia',
    FL: 'Florida',
    GA: 'Georgia',
    GU: 'Guam',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    PR: 'Puerto Rico',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VI: 'Virgin Islands',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
  };
};

export const getRecaptchaToken = async (): Promise<string> => {
  const tokenPromise: Promise<string> = new Promise((res, rej) => {
    window.grecaptcha.ready(async () => {
      const token = await window.grecaptcha.execute(process.env.GATSBY_RECAPTCHA_SITE_KEY, {
        action: 'submit',
      });

      if (token) {
        res(token);
      } else {
        rej(Error('grecaptcha rejected'));
      }
    });
  });

  return tokenPromise;
};
