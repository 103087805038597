import {
  CopyLinkItem,
  DropdownSocialShareContainer,
  EmailLinkItem,
  dropdownStyles,
  socialPlatforms,
} from '@purinanbm/pds-ui';
import SocialPlatformItem from '@purinanbm/pds-ui/dist/components/DropdownSocialShare/components/SocialPlatformItem';
import { ShareEverywhereProps } from '@purinanbm/pds-ui/dist/components/DropdownSocialShare/socialShare/socialShareTypes';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { ISendIt } from 'gatsby-plugin-purina-analytics/types';
import React from 'react';

type ArticleShareProps = ShareEverywhereProps & { articleType: string; analytics?: ISendIt };

export const DropdownSocialShare: React.FC<ArticleShareProps> = ({
  shareUrl,
  shareTitle,
  bodyText,
  emailSubjectLine,
  iconSize = 24,
  label = 'Share this Article',
  onPlatformChange,
  classNames,
  articleType,
  analytics,
  ...props
}: ArticleShareProps) => {
  const { menuItem } = dropdownStyles();
  const menuItemStyle = menuItem({
    className: classNames?.menuItem,
  });
  return (
    <DropdownSocialShareContainer
      classNames={classNames}
      iconSize={iconSize}
      label={label}
      shareTitle={shareTitle}
      shareUrl={shareUrl}
      {...props}
    >
      <CopyLinkItem shareUrl={shareUrl} className={menuItemStyle} />
      <div className="pds-mx-5 pds-my-2 pds-h-px pds-bg-gray-200" /> {/* <-- horizontal rule */}
      {socialPlatforms.map(platform => (
        <SocialPlatformItem
          key={platform.name}
          shareUrl={shareUrl}
          className={menuItemStyle}
          shareTitle={shareTitle}
          name={platform.name}
          icon={platform.icon}
          url={platform.url}
          onPlatformChange={(name: string) =>
            sendIt({
              event: 'share',
              eventLabel: `Share to ${name}`,
              eventCategory: 'social share',
              ...analytics,
              eventParams: {
                social_network: platform.name,
                article_type: articleType,
                item_name: shareTitle,
                item_id: 'share this article',
                content_type: 'article_share',
                ...analytics?.eventParams,
              },
            })
          }
        />
      ))}
      <EmailLinkItem
        bodyText={bodyText}
        emailSubjectLine={emailSubjectLine}
        className={menuItemStyle}
      />
    </DropdownSocialShareContainer>
  );
};
