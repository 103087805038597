import Icon from '@mdi/react';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { CMS_THEME } from '../common/enums';
import Link from '../components/Link';
import { getMedia } from '../utils/mediaHelpers';
import { pdsTv } from '@purinanbm/pds-ui';
import { mdiOpenInNew } from 'src/assets/icons/mdiIcons';

type Props = {
  node: IStorageContactCards;
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const styles = pdsTv({
  slots: {
    wrapper: 'pds-px-0 pds-py-4 lg:pds-py-5.5',
    logoCard: [
      'pds-no-underline',
      'pds-flex',
      'pds-flex-col',
      'pds-text-center',
      'pds-items-center',
      'pds-gap-4',
      'pds-px-4',
      'hover:pds-underline',
      'focus:pds-underline',
      'active:pds-underline',
      'hover:pds-cursor-pointer',
      'focus:pds-cursor-pointer',
      'active:pds-cursor-pointer',
    ],
    row: '!pds-mt-4 pds-justify-center after:pds-grow-[2] after:pds-content-none md:!pds-mt-5.5 md:pds-grow-0 ',
  },
  variants: {
    theme: {
      light: {
        wrapper: 'pds-bg-surface-foreground pds-text-paper-text',
        logoCard:
          'pds-text-paper-text hover:pds-text-paper-text focus:pds-text-paper-text active:pds-text-paper-text',
      },
      dark: {
        wrapper: 'pds-bg-default pds-text-paper-text',
        logoCard:
          'pds-text-paper-text  hover:pds-text-paper-text focus:pds-text-paper-text active:pds-text-paper-text',
      },
      neutral: {
        wrapper: 'pds-bg-surface-foreground pds-text-paper-text',
        logoCard:
          'pds-text-paper-text  hover:pds-text-paper-text focus:pds-text-paper-text active:pds-text-paper-text',
      },
    },
  },
});

const StorageContactCards: React.FC<Props> = ({ node: contactCards }) => {
  const settings = Object.assign(DefaultSettings, contactCards?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { wrapper, logoCard, row } = styles({
    theme: cmsTheme,
  });

  // If all cards are draft, don't render.
  if (!contactCards?.relationships?.cards || contactCards.relationships.cards.length === 0) {
    return null;
  }

  return (
    <Container
      id={contactCards.component_id}
      data-id={contactCards.drupal_id}
      fluid
      className={wrapper()}
    >
      <Container
        className={`${contactCards?.title ? 'pds-pb-4 md:pds-pb-5.5' : ''} pds-text-center`}
      >
        {contactCards?.title && (
          <HeadingElement className={headingStyle}>{contactCards.title}</HeadingElement>
        )}
      </Container>
      <Container>
        <Row className={row()}>
          {contactCards.relationships?.cards.map((card, index) => {
            const fieldMedia = card.relationships.field_media;
            const icon = card.link.options?.attributes?.icon ?? true;
            const attributes = { ...card.link.options?.attributes, icon };
            const openInNew = attributes?.target === '_blank';
            return (
              <AnalyticsPoint
                type="component"
                node={card}
                as={Col}
                data-id={card.drupal_id}
                key={index}
                xs={6}
                lg={3}
                className="pds-mb-4 md:pds-mb-5.5"
              >
                <Link to={card.link.url} className={logoCard()} {...attributes}>
                  {getMedia({ media: fieldMedia, style: { width: '150px' } })}
                  {card.link?.title && (
                    <div className="pds-inline-flex pds-items-center pds-gap-3">
                      {openInNew && <Icon path={mdiOpenInNew} size={0.6} />}
                      <span className="pds-text-body-xl">{card.link.title}</span>
                    </div>
                  )}
                </Link>
              </AnalyticsPoint>
            );
          })}
        </Row>
      </Container>
    </Container>
  );
};

export default StorageContactCards;
