import React from 'react';
import { Col, Form } from 'react-bootstrap';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import RichText from 'src/components/rich_text/RichText';
import { NewOfferFormProps } from '../../newOfferFormTypes';
import { OfferFormTypes } from '../../utils/offer';

interface Props {
  optinLabel: string;
  optinError: NewOfferFormProps['optinError'];
  errors: FieldErrors<OfferFormTypes>;
  register: UseFormRegister<OfferFormTypes>;
}

const OptIn: React.FC<Props> = ({ optinLabel, errors, register, optinError }) => {
  const { t } = useTranslation();
  return (
    <Col xs={12}>
      <Form.Group controlId="optIn">
        <Form.Check
          typography-h2
          type="checkbox"
          label={t(
            'I agree to receiving email and other market communications from Purina and its brand.',
          )}
        >
          <Form.Check.Input
            isInvalid={!!errors?.optIn}
            aria-invalid={!!errors?.optIn}
            type="checkbox"
            {...register('optIn')}
            required
            aria-describedby="optIn-message"
            className="!pds-border-gray-700"
          />
          <Form.Check.Label>{optinLabel && <RichText body={optinLabel} />}</Form.Check.Label>
          <Form.Control.Feedback type="invalid" id="optIn-message">
            {errors.optIn && ((optinError as string) || errors.optIn.message)}
          </Form.Control.Feedback>
        </Form.Check>
      </Form.Group>
    </Col>
  );
};

export default OptIn;
