import { PageProps, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Combobox, { ComboBoxItem } from '../components/autocomplete/Combobox';
import ProductIngredients from '../components/product/ProductIngredients';
import RichText from '../components/rich_text/RichText';
import { getMedia } from '../utils/mediaHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface IIngredientsPage extends NodeContent {
  relationships: {
    field_image: MediaImage;
  };
}
interface DataProps {
  node: IIngredientsPage;
  translations: Translations;
  ingredients: {
    edges: {
      node: TaxonomyTermIngredient;
    }[];
  };
  ingredientGroups: {
    edges: {
      node: TaxonomyTerm;
    }[];
  };
}

const IngredientsPageTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, ingredients, ingredientGroups, translations },
}) {
  const [selectedItem, setSelectedItem] = useState<ComboBoxItem | null>(null);
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const image = getMedia({
    media: node.relationships.field_image,
    loading: 'eager',
  });
  const comboboxItems: ComboBoxItem[] = ingredients.edges.map(ingredient => {
    return {
      value: ingredient.node.name,
      category: ingredientGroups?.edges.find(
        group =>
          group.node.drupal_internal__tid ===
          ingredient.node.field_ingredient_group.drupal_internal__target_id,
      )?.node.name,
    };
  });

  // @ts-ignore TS not recognizing undefined values are filtered out.
  const comboboxCategories: string[] = Array.from(
    new Set(comboboxItems.map(option => option?.category)),
  ).filter(val => typeof val !== undefined);
  const { node: searchedIngredient } = ingredients.edges.find(
    ingredient => ingredient.node.name === selectedItem?.value,
  ) || { node: null };
  const searchedImage = searchedIngredient?.relationships?.field_image?.gatsbyImage;
  const searchedBenefits = searchedIngredient?.relationships?.field_benefits;

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Container className="p-5 text-center">
        <Row className="justify-content-center">
          <Col md={9}>
            <h1 className="h1">{node.title}</h1>
            {node?.body?.processed && <RichText body={node.body.processed} className="my-4" />}
          </Col>
        </Row>
      </Container>
      <Container>{image}</Container>
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col md={9}>
            <Combobox
              items={comboboxItems}
              categories={comboboxCategories.length > 0 ? comboboxCategories : undefined}
              onSelect={(selectedItem, stateAndHelpers) => {
                setSelectedItem(selectedItem);
              }}
            />
          </Col>
        </Row>
        {searchedIngredient && (
          <Row className="justify-content-center py-5">
            <Col md={9} className="justify-content-start">
              <Row>
                <span className="h3">{searchedIngredient.name}</span>
              </Row>
              {searchedIngredient.description && (
                <Row className="py-3">
                  <Col
                    dangerouslySetInnerHTML={{ __html: searchedIngredient.description?.processed }}
                  />
                </Row>
              )}
              {searchedImage && (
                <Row>
                  <Col xs={5} sm={6} className="ps-0">
                    <GatsbyImage
                      alt={searchedIngredient.name}
                      image={searchedImage}
                      objectFit="contain"
                    />
                  </Col>
                  {searchedBenefits && searchedBenefits.length >= 1 && (
                    <Col>
                      <h3 className="h6 mb-3">Benefits</h3>
                      {searchedBenefits.map(searchedBenefits => {
                        const benefitIcon =
                          searchedBenefits.relationships?.icon?.relationships?.svg?.publicUrl;
                        return (
                          <Row key={searchedBenefits.id} className="my-2 align-items-center">
                            {benefitIcon && (
                              <Col xs="auto" className="pe-0">
                                <img
                                  src={benefitIcon}
                                  alt={searchedIngredient.name}
                                  style={{ height: '24px', width: '24px' }}
                                />
                              </Col>
                            )}
                            <Col>{searchedBenefits.name}</Col>
                          </Row>
                        );
                      })}
                    </Col>
                  )}
                </Row>
              )}
            </Col>
          </Row>
        )}
      </Container>

      {ingredientGroups && ingredients && (
        <Container>
          {ingredientGroups.edges.map(group => {
            const filteredIngredients = ingredients.edges.filter(
              ingredient =>
                group.node.drupal_internal__tid ===
                ingredient.node.field_ingredient_group.drupal_internal__target_id,
            );
            if (filteredIngredients.length) {
              return (
                <Row key={group.node.drupal_internal__tid} className="py-5">
                  <Col className="text-center">
                    <h2 className="h2">{group.node.name}</h2>
                    {group.node.description ? (
                      <RichText body={group.node.description.processed} />
                    ) : null}
                    <ProductIngredients
                      bgColor="white"
                      ingredients={filteredIngredients.map(ingredient => ingredient.node)}
                      maxHeight="auto"
                    />
                  </Col>
                </Row>
              );
            }
            return null;
          })}
        </Container>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeIngredientsPage(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      title
      body {
        processed
      }
      langcode
      ...Metatag
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
      relationships {
        field_image {
          ...MediaImage
          relationships {
            field_media_image {
              gatsbyImage(aspectRatio: 3, width: 1600, placeholder: NONE)
            }
          }
        }
      }
    }
    translations: allNodeIngredientsPage(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    ingredients: allTaxonomyTermIngredients(filter: { langcode: { eq: $language } }) {
      edges {
        node {
          ...TaxonomyTermIngredients
          field_ingredient_group {
            drupal_internal__target_id
          }
        }
      }
    }
    ingredientGroups: allTaxonomyTermIngredientGroup(filter: { langcode: { eq: $language } }) {
      edges {
        node {
          name
          description {
            processed
          }
          drupal_internal__tid
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default IngredientsPageTemplate;
