import React, { useEffect, useMemo, useState } from 'react';
import { BrazeCardPayload } from 'src/hooks/useBraze';
import { useUniqueId } from '../../hooks/useUniqueId';
import ArticleAuthorSearchResults from './ArticleAuthorSearchResults';
import ArticleSearchResults from './ArticleSearchResults';
import BreedSearchResults from './BreedSearchResults';
import FacetModal from './FacetModal';
import OfferSearchResults from './OfferSearchResults';
import PageSearchResults from './PageSearchResults';
import Pagination from './Pagination';
import ProductSearchResults from './ProductSearchResults';
import SearchFacets from './SearchFacets';
import SearchHeader from './SearchHeader';
import { SearchListingContext } from './SearchListingContext';

interface SearchListingComposition {
  SearchFacets: typeof SearchFacets;
  ProductSearchResults: typeof ProductSearchResults;
  BreedSearchResults: typeof BreedSearchResults;
  PageSearchResults: typeof PageSearchResults;
  OfferSearchResults: typeof OfferSearchResults;
  ArticleSearchResults: typeof ArticleSearchResults;
  ArticleAuthorSearchResults: typeof ArticleAuthorSearchResults;
  Pagination: typeof Pagination;
  SearchHeader: typeof SearchHeader;
  FacetModal: typeof FacetModal;
}

const SearchListing: React.FC<
  DrupalSearchResponse & { children: React.ReactElement; brazeCardPayloads: BrazeCardPayload[] }
> &
  SearchListingComposition = function ({
  children,
  defaultLoadedState = false,
  brazeCardPayloads = [],
  ...props
}) {
  const id = useUniqueId({ prefix: 'SearchListing' });
  const [data, setData] = useState<DrupalSearch | undefined>();
  const [facetModalActive, setFacetModalActive] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [loaded, setLoaded] = useState(defaultLoadedState);

  const { data: d, error: e, loaded: l } = props || {};

  useEffect(() => {
    setData(d);
    setError(e);
    setLoaded(l);
  }, [d, e, l]);

  const searchListingContextValue = useMemo(
    () => ({
      id,
      data,
      error,
      loaded,
      facetModalActive,
      brazeCards: brazeCardPayloads,
      setFacetModalActive,
      setLoaded,
    }),
    [data, error, facetModalActive, id, loaded, brazeCardPayloads],
  );

  return (
    <SearchListingContext.Provider value={searchListingContextValue}>
      {children}
    </SearchListingContext.Provider>
  );
};

SearchListing.SearchFacets = SearchFacets;
SearchListing.ProductSearchResults = ProductSearchResults;
SearchListing.BreedSearchResults = BreedSearchResults;
SearchListing.PageSearchResults = PageSearchResults;
SearchListing.ArticleSearchResults = ArticleSearchResults;
SearchListing.ArticleAuthorSearchResults = ArticleAuthorSearchResults;
SearchListing.Pagination = Pagination;
SearchListing.SearchHeader = SearchHeader;
SearchListing.FacetModal = FacetModal;
SearchListing.OfferSearchResults = OfferSearchResults;

export default SearchListing;
