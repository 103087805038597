import { IMAGE_POSITION, SponsorAd } from '@purinanbm/pds-ui';
import { navigate } from 'gatsby';
import React, { useEffect, useRef } from 'react';
import { CMS_THEME } from 'src/common/enums';
import { ButtonLink } from 'src/components/button/Button';
import { BrazeCardPayload, useBraze } from 'src/hooks/useBraze';
import { useAnalyticsHelper } from 'src/utils/useAnalyticsHelper';

export interface StorageBrazeCardProps {
  defaultCard: IStorageBrazeCard;
  brazeCard?: BrazeCardPayload;
}

const cardFormatMap: { [key: string]: IMAGE_POSITION } = {
  web_classic_card: IMAGE_POSITION.TOP,
  web_captioned_card: IMAGE_POSITION.BACKGROUND,
  web_captioned_banner: IMAGE_POSITION.BANNER,
  web_banner: IMAGE_POSITION.BANNER_HALF,
};

const transformBrazeCardData = (
  defaultCard: IStorageBrazeCard,
  brazeData?: BrazeCardPayload,
): BrazeCardPayload => {
  if (!brazeData || brazeData.isControl) {
    const { field_description, title, field_formats, field_link, relationships } = defaultCard;
    const transformedData: BrazeCardPayload = {
      id: defaultCard.id,
      created: new Date(),
      isControl: brazeData?.isControl || false,
      title: title || '',
      description: field_description.processed.replace(/<(\S?)[^>]>?|<.*?\/>/g, ''),
      url: field_link.url,
      linkText: field_link.title,
      imageUrl: relationships.field_media_image.relationships.field_media_image.url || '',
      extras: {
        location: '',
        format: field_formats?.toLowerCase() || '',
        brand: '',
        species: '',
        priority: '',
        campaign: '',
      },
    };

    return transformedData;
  }
  return brazeData;
};

const StorageBrazeCard: React.FC<StorageBrazeCardProps> = ({ defaultCard, brazeCard }) => {
  const card = transformBrazeCardData(defaultCard, brazeCard);
  const cardRef = useRef<HTMLDivElement>(null);
  const { isSdkLoaded } = useBraze({});
  const { sendIt } = useAnalyticsHelper();

  const { format } = card.extras;

  const getImage = (imagePosition: IMAGE_POSITION, url: string) => {
    let image = null;
    switch (imagePosition) {
      case IMAGE_POSITION.BANNER:
        image = (
          <img
            src={url}
            alt=""
            className="pds-max-h-[200px] pds-object-cover pds-object-right md:pds-max-h-full md:!pds-w-full md:pds-max-w-full md:pds-object-center"
          />
        );
        break;
      default:
        image = <img src={url} alt="" className="w-100 pds-object-cover" />;
        break;
    }
    return image;
  };

  useEffect(() => {
    if (!isSdkLoaded || !cardRef.current) return undefined;

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && entry.intersectionRatio > 0.7) {
            const { logContentCardImpressions } = window.braze;
            // Need to pass original payload if it's a control group.
            logContentCardImpressions([brazeCard?.isControl ? brazeCard : card]);
            sendIt({
              event: 'content_card_impression',
              eventLabel: 'content_card_impression',
              eventCategory: 'braze',
              eventAction: 'impression',
              eventParams: {
                braze_format: card.extras.format,
                braze_location: card.extras.location,
                braze_campaign: card.extras.campaign,
                braze_priority: card.extras.priority,
              },
            });
            observer.disconnect();
          }
        });
      },
      { threshold: 0.7, rootMargin: '0px' },
    );

    observer.observe(cardRef.current);
    return () => {
      observer.disconnect();
    };
  }, [card, cardRef, isSdkLoaded, sendIt, brazeCard]);

  const handleCardClick = () => {
    if (!card || !isSdkLoaded || !window.braze) return;
    const { logContentCardClick } = window.braze;
    // Need to pass original payload if it's a control group.
    logContentCardClick(brazeCard?.isControl ? brazeCard : card);
    sendIt({
      event: 'content_card_click',
      eventLabel: 'content_card_click',
      eventCategory: 'braze',
      eventAction: 'click',
      eventParams: {
        braze_format: card.extras?.format,
        braze_location: card.extras?.location,
        braze_campaign: card.extras?.campaign,
        braze_priority: card.extras?.priority,
      },
    });
  };

  return (
    <div ref={cardRef} className="d-flex pds-h-auto pds-max-h-max pds-w-full md:pds-max-h-full">
      <SponsorAd
        className="pds-h-auto pds-max-h-max pds-w-full md:pds-max-h-full"
        image={getImage(cardFormatMap[format] ?? IMAGE_POSITION.NONE, card.imageUrl)}
        body={card?.description}
        title={card?.title}
        imagePosition={format ? cardFormatMap[format] : IMAGE_POSITION.NONE}
        cta={
          <ButtonLink
            to={card.url}
            cmsTheme={CMS_THEME.DARK}
            className="!hover:pds-bg-[#9B1318] !pds-bg-[#E91C24] !pds-text-white"
            onClick={e => {
              e.preventDefault();
              handleCardClick();
              navigate(card.url);
            }}
          >
            {card.linkText}
          </ButtonLink>
        }
        variant="light"
      />
    </div>
  );
};

export default StorageBrazeCard;
