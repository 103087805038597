import { pdsTv } from '@purinanbm/pds-ui';

export const themeVarient = {
  dark: 'pds-bg-[#1E1E1E] pds-text-white',
  light: 'pds-bg-white pds-text-paper-text',
  neutral: 'pds-bg-surface-foreground pds-text-paper-text',
};

export const themeVarientText = {
  dark: 'pds-text-white',
  light: 'pds-text-paper-text',
  neutral: 'pds-text-paper-text',
};

export const themeVarientBg = {
  dark: 'pds-bg-[#1E1E1E]',
  light: 'pds-bg-white',
  neutral: 'pds-bg-surface-foreground',
};

export const themeStyles = pdsTv({
  base: '',
  variants: {
    theme: themeVarient,
  },
  defaultVariants: {
    theme: 'light',
  },
});
