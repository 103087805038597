import { UseMutationOptions, UseQueryOptions, useMutation, useQuery } from '@tanstack/react-query';
import { Customers as LoyaltyService } from 'src/services/loyalty/Customers';
import { AuthLoyaltyService } from 'src/services/services';

// @DEV - Not all queries and mutations are implemented here. Add as needed.
export enum LOYALTY_QUERIES {
  GET_CUSTOMERS_DETAIL = 'customersDetail',
  GET_LEDGER_DETAIL = 'ledgerDetail',
  GET_REVIEWS_DETAIL = 'reviewsDetail',
  GET_PRODUCTS_DETAIL = 'productsDetail',
  GET_PRODUCTS_DETAIL2 = 'productsDetail2',
  GET_RECEIPTS = 'receiptsDetail',
  GET_REWARDS_CATALOG = 'rewardsDetail',
}

export enum LOYALTY_MUTATIONS {
  POST_EVENTS_CREATE = 'eventsCreate',
  POST_RECEIPTS_CREATE = 'receiptsCreate',
  POST_REVIEWS_IMAGES_CREATE = 'reviewsImagesCreate',
  POST_REVIEWS_CREATE = 'reviewsCreate',
  POST_REWARDS_CHECKOUT = 'rewardCheckout',
}

export const useLoyaltyQuery = <T extends LOYALTY_QUERIES>(
  endpoint: T,
  params: Parameters<LoyaltyService[T]>[0],
  options: UseQueryOptions<Awaited<ReturnType<LoyaltyService[T]>>['data']> = {
    enabled: true,
  },
) => {
  type ResponseType = Awaited<ReturnType<LoyaltyService[T]>>['data'];
  const paramQueryKeys = Object.entries(params).map(([key, value]) => `${key}:${value}`);
  return useQuery<ResponseType>(
    [endpoint, ...paramQueryKeys],
    async () => {
      const response = await (AuthLoyaltyService as any)[endpoint](params);
      return response.data;
    },
    options,
  );
};

export const useLoyaltyMutation = <T extends LOYALTY_MUTATIONS>(
  endpoint: T,
  params: Parameters<LoyaltyService[T]>[0],
  options: UseMutationOptions<
    Awaited<ReturnType<LoyaltyService[T]>>['data'],
    unknown,
    Parameters<LoyaltyService[T]>[0]['data']
  > = {},
) => {
  type ResponseType = Awaited<ReturnType<LoyaltyService[T]>>['data'];
  type RequestBody = Parameters<LoyaltyService[T]>[0]['data'];
  const paramQueryKeys = Object.entries(params).map(([key, value]) => `${key}:${value}`);

  return useMutation<ResponseType, unknown, RequestBody>(
    [endpoint, ...paramQueryKeys],
    async data => {
      const response = await (AuthLoyaltyService as any)[endpoint]({ ...params, data });
      return response.data;
    },
    options,
  );
};
