/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  Error,
  GetCustomerResponse,
  GetLedgerResponse,
  GetProductResponse,
  GetProductsResponse,
  GetReceiptResponse,
  GetReviewsResponse,
  GetRewardsResponse,
  PostBehavioralEventRequestBody,
  PostBehavioralEventResponse,
  PostCustomerRequestBody,
  PostCustomerResponse,
  PostReceiptImageRequestBody,
  PostReceiptImageResponse,
  PostReviewImageRequestBody,
  PostReviewImageResponse,
  PostReviewRequestBody,
  PostRewardsCheckoutRequestBody,
  PostRewardsCheckoutResponse,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class Customers<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Retrieves the record associated with the provided 'customerId'; Calls to GET https://apac-apigateway.capillarytech.com/mobile/v2/api/customer/getbyexternalid which is a wrapper around Capillary's /customer/get endpoint
   *
   * @tags Customers
   * @name CustomersDetail
   * @summary Retrieve a customer's information
   * @request GET:/customers/{customerId}
   * @secure
   */
  customersDetail = (args: { customerId: string; params?: RequestParams }) =>
    this.request<GetCustomerResponse, Error>({
      path: `/customers/${args.customerId}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Creates a queued request to create a new customer account in the Loyalty service.
   *
   * @tags Customers
   * @name CustomersCreate
   * @summary Queue a new customer record to be created in Loyalty
   * @request POST:/customers/{customerId}
   * @secure
   */
  customersCreate = (args: {
    customerId: string;
    data: PostCustomerRequestBody;
    params?: RequestParams;
  }) =>
    this.request<PostCustomerResponse, Error | void>({
      path: `/customers/${args.customerId}`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Adds points to the customer's account and creates a new customer behavioral event; Calls to POST https://apac-apigateway.capillarytech.com/mobile/v2/api/behavioral/event; Should this endpoint be /customer/event or something similar?
   *
   * @tags Events
   * @name EventsCreate
   * @summary Create new points for a customer, record a behavioral event record
   * @request POST:/customers/{customerId}/events
   * @secure
   */
  eventsCreate = (args: {
    customerId: string;
    data: PostBehavioralEventRequestBody;
    params?: RequestParams;
  }) =>
    this.request<PostBehavioralEventResponse, Error | void>({
      path: `/customers/${args.customerId}/events`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Retrieves an array of transactions associated with the provided 'customerId'
   *
   * @tags Ledger
   * @name LedgerDetail
   * @summary Retrieve a customer's ledger of transactions
   * @request GET:/customers/{customerId}/ledger
   * @secure
   */
  ledgerDetail = (args: { customerId: string; params?: RequestParams }) =>
    this.request<GetLedgerResponse, Error>({
      path: `/customers/${args.customerId}/ledger`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Retrieves an array of receipt data that is associated with the provided `customerId`
   *
   * @tags Receipts
   * @name ReceiptsDetail
   * @summary Retrieve a customer's receipt history
   * @request GET:/customers/{customerId}/receipts
   * @secure
   */
  receiptsDetail = (args: {
    customerId: string;
    query?: {
      /**
       * filter results for a specific receipt status
       * @default "all"
       */
      status?: 'all' | 'pending' | 'approved' | 'rejected';
    };
    params?: RequestParams;
  }) =>
    this.request<GetReceiptResponse, Error>({
      path: `/customers/${args.customerId}/receipts`,
      method: 'GET',
      query: args.query,
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Posts a receipt image for provided 'customerId' to loyalty provider to accumulate reward points; Calls to Capillary GET imageId, GET S3 URL, and POST Process Images.
   *
   * @tags Receipts
   * @name ReceiptsCreate
   * @summary Post a customer's receipt image
   * @request POST:/customers/{customerId}/receipts
   * @secure
   */
  receiptsCreate = (args: {
    customerId: string;
    data: PostReceiptImageRequestBody;
    params?: RequestParams;
  }) =>
    this.request<PostReceiptImageResponse, Error>({
      path: `/customers/${args.customerId}/receipts`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Retrieves the reviews associated with the provided 'customerId'; Calls to Bazaar Voice GET Reviews by Author ID and GET Product by UPC from Product Data Service
   *
   * @tags Reviews
   * @name ReviewsDetail
   * @summary Retrieve a customer's reviews
   * @request GET:/customers/{customerId}/reviews
   * @secure
   */
  reviewsDetail = (args: {
    customerId: string;
    query?: {
      /**
       * Sort reviews by recency
       * @default "desc"
       */
      sort?: 'desc' | 'asc';
      /**
       * Select reviews based on status, default is all
       * @default "all"
       */
      status?: 'pending' | 'queued' | 'approved' | 'rejected' | 'all';
      /**
       * The number of records to return.
       * @min 10
       * @max 100
       * @default 10
       */
      limit?: number;
      /**
       * The number of records to skip before returning requested data.
       * @min 0
       * @max 300000
       * @default 0
       */
      offset?: number;
    };
    params?: RequestParams;
  }) =>
    this.request<GetReviewsResponse, Error>({
      path: `/customers/${args.customerId}/reviews`,
      method: 'GET',
      query: args.query,
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Image must be uploaded prior to submitting the review
   *
   * @tags Reviews
   * @name ReviewsImagesCreate
   * @summary Submit an image that will be associated with a new review
   * @request POST:/customers/{customerId}/reviews/{upc}/images
   * @secure
   */
  reviewsImagesCreate = (args: {
    customerId: string;
    upc: string;
    data: PostReviewImageRequestBody;
    params?: RequestParams;
  }) =>
    this.request<PostReviewImageResponse, Error | void>({
      path: `/customers/${args.customerId}/reviews/${args.upc}/images`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Adds points to the customer's account and creates a new review.
   *
   * @tags Reviews
   * @name ReviewsCreate
   * @summary Submit a new review
   * @request POST:/customers/{customerId}/reviews/{upc}
   * @secure
   */
  reviewsCreate = (args: {
    customerId: string;
    upc: string;
    data: PostReviewRequestBody;
    params?: RequestParams;
  }) =>
    this.request<PostBehavioralEventResponse, Error | void>({
      path: `/customers/${args.customerId}/reviews/${args.upc}`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Retrieves the full catalago of available rewards.
   *
   * @tags Rewards
   * @name RewardsDetail
   * @summary Retrieve the Reward Catalog.
   * @request GET:/customers/{customerId}/rewards
   * @secure
   */
  rewardsDetail = (args: { customerId: string; params?: RequestParams }) =>
    this.request<GetRewardsResponse, Error>({
      path: `/customers/${args.customerId}/rewards`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Retrieves three products for a customer to review, only returns products that have not been reviewed in the last year.
   *
   * @tags Products
   * @name ProductsDetail
   * @summary Retrieve three products for a customer to review.
   * @request GET:/customers/{customerId}/products
   * @secure
   */
  productsDetail = (args: { customerId: string; params?: RequestParams }) =>
    this.request<GetProductsResponse, Error>({
      path: `/customers/${args.customerId}/products`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });
  /**
   * @description Retrieves product information, intended for use with UPC scanning. Gets information about the product from Product Data Service and review information from Bazaar Voice to return determination about whether a customer can review a product.
   *
   * @tags Products
   * @name ProductsDetail2
   * @summary Retrieve a product for a customer to review
   * @request GET:/customers/{customerId}/products/{upc}
   * @originalName productsDetail
   * @duplicate
   * @secure
   */
  productsDetail2 = (args: { customerId: string; upc: string; params?: RequestParams }) =>
    this.request<GetProductResponse, Error>({
      path: `/customers/${args.customerId}/products/${args.upc}`,
      method: 'GET',
      secure: true,
      format: 'json',
      ...args.params,
    });

  // @DEV - All the below were manually added.

  /**
   * @description Posts a reward redemption request for the provided 'customerId' and 'rewardId' to the loyalty provider.
   *
   * Manually added because the swagger file does not include this endpoint.
   *
   * @tags Rewards
   * @name RewardsCheckout
   * @summary Post a customer's rewards checkout
   * @request POST:/customers/{customerId}/rewards/{rewardId}
   * @secure
   */
  rewardCheckout = (args: {
    customerId: string;
    rewardId: string;
    data: PostRewardsCheckoutRequestBody;
    params: RequestParams;
  }) =>
    this.request<PostRewardsCheckoutResponse, Error>({
      path: `/customers/${args.customerId}/rewards/${args.rewardId}`,
      method: 'POST',
      body: args.data,
      secure: true,
      type: ContentType.Json,
      format: 'json',
      ...args.params,
    });
}
