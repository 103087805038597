import { styled } from '@linaria/react';
import { Link as GatsbyLink } from 'gatsby';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { CMS_THEME } from 'src/common/enums';
import { getPageTypeFromDom } from 'src/common/functions';
import { theme } from 'src/theme/theme';
import Link from '../Link';
import BazaarvoiceWidget from '../bazaarvoice/BazaarvoiceWidget';
import { ButtonDiv } from '../button/Button';
import PricespiderWidget from '../pricespider/PricespiderWidget';

const StyledImageLink = styled(GatsbyLink)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].background.imageMatte};
  box-sizing: border-box;

  img {
    mix-blend-mode: multiply;
  }
`;

const OfferLinkContainer = styled.div<{ cmsTheme: CMS_THEME }>`
  display: flex;
  justify-content: center;

  a {
    width: 100%;
    text-align: center;
    text-decoration: none;
    border: ${({ cmsTheme }) => `1px solid ${theme[cmsTheme].text.default}`};
    border-radius: 999999px;
    padding: 15px 20px;
    color: ${({ cmsTheme }) => `1px solid ${theme[cmsTheme].text.default}`};
    font-weight: 300;
  }
`;

const StyledBrand = styled.div`
  font-size: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-top: 15px;
  margin-bottom: 5px;
`;

const StyledTitleLink = styled(Link)<{ cmsTheme: CMS_THEME }>`
  color: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].text.default};
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    color: ${({ cmsTheme }) => theme[cmsTheme as CMS_THEME].text.default};
    text-decoration: underline;
  }
`;

interface Props {
  image: IGatsbyImageData;
  link: string;
  title: string;
  sku: string;
  bvID: string;
  cmsTheme: CMS_THEME;
  brand?: string;
  onClick?: () => void;
  cardType?: string;
  outlinedCta?: boolean;
  isDirect?: boolean;
  ctaText?: string;
}

const ProductCardBody: React.FC<Props> = ({
  cmsTheme = CMS_THEME.LIGHT,
  link,
  image,
  onClick,
  brand,
  title,
  sku,
  bvID,
  cardType,
  outlinedCta = false,
  isDirect = false,
  ctaText = undefined,
}) => {
  const { t } = useTranslation();
  const isExternalLink = link.includes('http');

  const onPartClick = (type: 'offerButton' | 'directButton' | 'title' | 'image') => {
    const getId = () => {
      if (type === 'directButton') return 'direct_button';
      return title;
    };
    sendIt({
      event: 'module_interaction',
      eventParams: {
        component_id: getId(),
        component_type: 'product_card',
        content_name: `product_card: ${cardType}`,
        page_type: getPageTypeFromDom(),
      },
    });

    if (onClick) {
      onClick();
    }
  };

  const determineCta = () => {
    if (cardType === 'offers') {
      return (
        <OfferLinkContainer cmsTheme={cmsTheme}>
          <Link
            to={link}
            onClick={() => onPartClick('offerButton')}
            aria-label={`${t('Redeem Offer')} ${title}`}
          >
            {t('Redeem Offer')}
          </Link>
        </OfferLinkContainer>
      );
    }

    if (isDirect && ctaText) {
      return (
        <ButtonDiv
          as={Link}
          className="!pds-w-full"
          cmsTheme={cmsTheme}
          onClick={() => onPartClick('directButton')}
          to={link}
          target="_blank"
          aria-label={`${ctaText} ${title}`}
        >
          {ctaText}
        </ButtonDiv>
      );
    }

    return <PricespiderWidget title={title} cmsTheme={cmsTheme} sku={sku} outlined={outlinedCta} />;
  };

  return (
    <>
      <StyledImageLink
        cmsTheme={cmsTheme}
        to={link}
        onClick={() => onPartClick('image')}
        className="p-1 mb-2 text-center rounded-2 d-block p-md-3"
        target={isExternalLink ? '_blank' : undefined}
        aria-label={title}
      >
        <GatsbyImage image={image} objectFit="contain" alt="" style={{ maxHeight: '400px' }} />
      </StyledImageLink>
      {brand && <StyledBrand>{brand}</StyledBrand>}
      <StyledTitleLink
        cmsTheme={cmsTheme}
        to={link}
        onClick={() => onPartClick('title')}
        target={isExternalLink ? '_blank' : undefined}
        analytics={false}
        tabIndex={-1}
        className="typography_body pds-mb-2"
        aria-hidden
        id={sku}
      >
        {title}
      </StyledTitleLink>
      {cardType === 'products' && (
        <div className="pds-mb-3 md:pds-mb-4">
          <BazaarvoiceWidget attributes={{ productID: bvID, widgetID: 'inline_rating' }} />
        </div>
      )}
      <div className="pds-mt-auto">{determineCta()}</div>
    </>
  );
};

export default ProductCardBody;
