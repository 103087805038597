import { minimatch } from 'minimatch';
import React from 'react';
import { getCmsComponent } from 'src/utils/cmsComponentHelpers';

export type BasicBlocksProps = {
  location?: string;
  data: any;
  language?: string;
};

type QueryProps = {
  node: {
    relationships: {
      field_component: any;
    };
  };
};

function matchAnyPattern(location: string, patternsStr: string): boolean {
  const patterns: string[] = patternsStr.split('\r\n');
  return patterns.some(pattern => minimatch(location, pattern)) || false;
}

const BasicBlocks = ({ location = '', data, language = 'en' }: BasicBlocksProps) => {
  const activeBlocks = data.filter(({ node }: any) =>
    node.langcode === language && node.field_visibility === null
      ? true
      : matchAnyPattern(location, node.field_visibility),
  );
  return (
    <div>
      {activeBlocks &&
        activeBlocks.map((block: QueryProps, index: number) => {
          const component = block.node.relationships.field_component;
          return (
            component && (
              <React.Fragment key={component.id}>
                {getCmsComponent({ node: component, index })}
              </React.Fragment>
            )
          );
        })}
    </div>
  );
};

export default BasicBlocks;
