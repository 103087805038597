import { WindowLocation, useLocation } from '@reach/router';
import { PageProps, graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import PurinaPerksLayout, {
  PURINA_PERKS_ROUTES,
} from 'src/components/purina_perks/PurinaPerksLayout';
import RewardCheckout from 'src/components/purina_perks/reward_checkout/RewardCheckout';
import { Reward } from 'src/services/loyalty/types';

interface DataProps {
  node: NodeContent;
  translations: Translations;
}

const RewardRedirectForGatsby = () => {
  useEffect(() => {
    navigate(PURINA_PERKS_ROUTES.Catalog);
  }, []);

  return null;
};

const RewardCheckoutTemplate: React.FC<PageProps<DataProps>> = ({
  data: { node, translations },
}) => {
  const location = useLocation() as WindowLocation<{ reward?: Reward } | undefined>;
  const item = location.state?.reward;

  // TODO: figure out why gatsby pages typescript hate below and why I need to include
  // A component to make it happy.
  // if (!item) return navigate(PURINA_PERKS_ROUTES.Catalog);
  if (!item) return <RewardRedirectForGatsby />;

  return (
    <PurinaPerksLayout
      node={node}
      language={node.langcode}
      metaData={node.metatag}
      translations={translations}
    >
      <RewardCheckout item={item} />
    </PurinaPerksLayout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeComplex(id: { eq: $id }, langcode: { eq: $language }) {
      id
      body {
        processed
      }
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      behaviors {
        page_layout {
          elements_to_hide {
            header
            footer
            chat
          }
        }
      }
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
    }
    translations: allNodeComplex(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default RewardCheckoutTemplate;
