import { pdsTv } from '@purinanbm/pds-ui/dist/common/pdsTv';
import React from 'react';
import { PetType } from 'src/services/sst/data-contracts';
import AdornmentCatIcon from './AdornmentCatIcon';
import AdornmentDogIcon from './AdornmentDogIcon';

export type SpeciesAvatarProps = {
  species: PetType;
  color: 'PURPLE' | 'ORANGE' | 'GREEN' | 'BLUE';
  className?: string;
};

export const ColorPriority = ['PURPLE', 'GREEN', 'BLUE', 'ORANGE'] as SpeciesAvatarProps['color'][];

export const SpeciesAvatar = ({ species, color, className }: SpeciesAvatarProps) => {
  const avatar = pdsTv({
    base: 'pds-flex pds-h-[94px] pds-w-[94px] pds-items-center pds-justify-center pds-rounded-circle pds-bg-[#725ca0] pds-text-surface sm:pds-h-[120px] sm:pds-w-[120px]',
    variants: {
      color: {
        ORANGE: {
          base: 'pds-bg-[#F77C20]',
          icon: 'pds-text-surface',
        },
        PURPLE: {
          base: 'pds-bg-[#725ca0]',
          icon: 'pds-text-surface',
        },
        GREEN: {
          base: 'pds-bg-[#469752]',
          icon: 'pds-text-surface',
        },
        BLUE: {
          base: 'pds-bg-[#80BBD2]',
          icon: 'pds-text-surface',
        },
      },
    },
    slots: {
      icon: 'pds-h-[85%] pds-w-[85%]',
    },
  });

  const { base, icon } = avatar({ color });

  return (
    <div className={base({ className })}>
      {species.keyName === 'CAT' ? (
        <AdornmentCatIcon className={icon()} aria-hidden="true" />
      ) : (
        <AdornmentDogIcon className={icon()} aria-hidden="true" />
      )}
    </div>
  );
};
