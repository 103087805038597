import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Spinner,
} from '@purinanbm/pds-ui';
import React from 'react';
import { mdiShoppingCartOutline } from 'src/assets/icons/mdiIcons';
import { Reward } from '../../../services/loyalty/types';
import {
  RewardItemOrderSummary,
  RewardItemSummaryProduct,
  RewardItemSummaryWrapper,
} from '../reward_catalog/RewardCatalogItemSummary';
import { RewardDivider } from './RewardDivider';

const SubmitButton: React.FC<{ submissionIsLoading: boolean }> = ({ submissionIsLoading }) => {
  return (
    <Button
      disabled={submissionIsLoading}
      className="pds-mt-4 pds-flex pds-justify-center"
      buttonColor="neutral"
      type="submit"
      width="full"
    >
      Place Order
      {submissionIsLoading && <Spinner className="pds-ml-3" color="currentColor" />}
    </Button>
  );
};

const RewardCheckoutSummary: React.FC<{
  submissionIsLoading: boolean;
  item: Reward;
  error: Error | undefined;
}> = ({ item, error, submissionIsLoading }) => {
  return (
    <>
      <div className="pds-sticky pds-bottom-0 pds-col-span-4 pds-mt-4 pds-block pds-rounded pds-bg-surface pds-shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)] 3xl:pds-hidden">
        <Accordion type="single" collapsible>
          <AccordionItem className="pds-border-b-0 pds-p-0" value="1">
            <AccordionTrigger className="pds-p-4 pds-text-body-md pds-font-semibold">
              Order Summary
            </AccordionTrigger>
            <AccordionContent>
              <div className="pds-px-4">
                <RewardDivider title="Your Item" icon={mdiShoppingCartOutline} />
                <RewardItemSummaryProduct item={item} />
                <RewardItemOrderSummary item={item} />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        {error && (
          <div className="pds-m-4 pds-rounded pds-bg-error-tonal pds-p-4 pds-text-error">
            {error.message}
          </div>
        )}
        <div className="pds-px-4 pds-pb-4">
          <SubmitButton submissionIsLoading={submissionIsLoading} />
        </div>
      </div>
      <div className="pds-sticky pds-bottom-0 pds-col-span-4 pds-hidden 3xl:pds-block">
        <RewardItemSummaryWrapper title="Your Item">
          <RewardItemSummaryProduct item={item} />
          <RewardItemOrderSummary item={item} />
          {error && (
            <div className="pds-m-4 pds-rounded pds-bg-error-tonal pds-p-4 pds-text-error">
              {error.message}
            </div>
          )}
          <SubmitButton submissionIsLoading={submissionIsLoading} />
        </RewardItemSummaryWrapper>
      </div>
    </>
  );
};

export default RewardCheckoutSummary;
