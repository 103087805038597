import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React, { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ProductCardSkeleton from '../product_card/ProductCardSkeleton';
import NoResults from './NoResults';
import { SearchListingContext } from './SearchListingContext';
import NewArticleCard from '../article_card/NewArticleCard';

interface Props {
  serverData?: DrupalSearch;
  columns?: 3 | 4;
}

const ArticleSearchResults: React.FC<Props> = function ({ serverData, columns }) {
  const context = useContext(SearchListingContext);

  if (!context && !serverData) {
    throw new Error(
      'Child components of SearchListing cannot be rendered outside the SearchListing component!',
    );
  }

  const xlColumnWidth = columns === 3 ? 4 : 3;
  const lgColumnWidth = columns === 3 ? 4 : 4;
  const mdColumnWidth = columns === 3 ? 6 : 6;

  const { data, loaded } = context || {};
  const search_results = serverData?.search_results || data?.search_results;
  if (!loaded) {
    return (
      <Row className="mb-5 gy-3 gy-md-4">
        {[...Array(12)].map((_, i) => {
          return (
            <Col
              key={`loading-card-${i}`}
              xs={12}
              sm={6}
              md={mdColumnWidth}
              lg={lgColumnWidth}
              xl={xlColumnWidth}
              className="d-flex"
            >
              <ProductCardSkeleton />
            </Col>
          );
        })}
      </Row>
    );
  }

  if (!search_results) {
    return <NoResults />;
  }
  return (
    <Row
      id="search-results"
      className="mb-5 gy-3 gy-md-4"
      style={{
        // @ts-ignore - --bs-gutter-x is a bootstrap variable
        '--bs-gutter-x': 'clamp(1rem, 3%, 48px)',
      }}
    >
      {Object.values(search_results).map(node => {
        const image = node.field_media_image
          ? process.env.GATSBY_DRUPAL_ENDPOINT + node.field_media_image.substring(1)
          : '';

        return (
          <Col
            key={node.nid}
            xs={12}
            sm={6}
            md={mdColumnWidth}
            lg={lgColumnWidth}
            xl={xlColumnWidth}
          >
            <AnalyticsPoint
              type="component"
              category="search_result_selected"
              action={node.title}
              eventLabel="articles"
              node={{ ...node }}
              className="pds-max-w-full"
            >
              <NewArticleCard
                key={node.title}
                image={{
                  images: {
                    fallback: {
                      src: image,
                    },
                  },
                  layout: 'fullWidth',
                  width: 300,
                  height: 200,
                }}
                link={node.url}
                title={node.title}
                topics={node?.topics}
                readTime={node?.read_time}
              />
            </AnalyticsPoint>
          </Col>
        );
      })}
    </Row>
  );
};

ArticleSearchResults.displayName = 'SearchListing.ArticleSearchResults';

export default ArticleSearchResults;
