import { Button } from '@purinanbm/pds-ui';
import { WindowLocation, useLocation } from '@reach/router';
import { Link as GatsbyLink, graphql, navigate } from 'gatsby';
import React from 'react';

import { PURINA_PERKS_ROUTES } from 'src/components/purina_perks/PurinaPerksLayout';

import RewardCatalogGate from 'src/components/purina_perks/reward_catalog/RewardCatalogGate';
import {
  RewardItemSummaryProduct,
  RewardItemSummaryWrapper,
} from 'src/components/purina_perks/reward_catalog/RewardCatalogItemSummary';
import { rewardSchema } from 'src/components/purina_perks/reward_catalog/RewardCatalogList';
import Success from 'src/components/success/Success';
import { Reward } from 'src/services/loyalty/types';

const RewardCheckoutSuccessPage: React.FC = () => {
  const location = useLocation() as WindowLocation<{ reward?: Reward } | undefined>;

  React.useEffect(() => {
    // Prevent coming directly to this route in a new session
    if (
      typeof window !== 'undefined' &&
      !location.state?.reward &&
      !rewardSchema.isValidSync(location.state?.reward)
    ) {
      navigate(PURINA_PERKS_ROUTES.ReceiptUpload, { replace: true });
    }
  }, [location.state?.reward]);

  if (!location.state?.reward) {
    return null;
  }

  return (
    <Success className="pds-max-w-[717px] pds-gap-4.5">
      <RewardCatalogGate>
        <h1 className="pds-text-center pds-text-title-md pds-font-semibold md:pds-text-title-lg">
          Your Order Has Been Placed!
        </h1>
        <p className="pds-m-0 pds-max-w-[47rem] pds-text-center pds-text-body-lg">
          Congratulations! Your reward is on its way. Please check your email for order details.
        </p>
        <RewardItemSummaryWrapper title="Item you've just redeemed">
          <RewardItemSummaryProduct withDelete={false} item={location?.state?.reward} />
        </RewardItemSummaryWrapper>
        <div className="pds-w-full md:pds-w-[360px]">
          <Button
            className="pds-flex pds-w-full pds-justify-center pds-no-underline"
            buttonColor="neutral"
            // TS error is because "GatsbyLink" is not a defined option for the "as" property
            as={GatsbyLink}
            to={PURINA_PERKS_ROUTES.Catalog}
          >
            Return to Catalog
          </Button>
        </div>
      </RewardCatalogGate>
    </Success>
  );
};

export default RewardCheckoutSuccessPage;

// "en" is hardcoded since this page is not currently localized
export const query = graphql`
  query {
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
