import Icon from '@mdi/react';
import { Button } from '@purinanbm/pds-ui';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, { useRef } from 'react';
import { mdiInformationOutline } from 'src/assets/icons/mdiIcons';
import pointsBadgeSvg from 'src/assets/icons/pointsBadge.svg';
import Link from 'src/components/Link';
import Alert from 'src/components/alert/Alert';

import Pager from 'src/components/pager/Pager';
import usePager from 'src/components/pager/usePager';
import { Reward } from 'src/services/loyalty/types';
import * as Yup from 'yup';
import { onRedeemClick, onRewardClick } from '../helpers';

export const rewardSchema = Yup.object().shape({
  id: Yup.number().required(),
  pid: Yup.string().required(),
  points: Yup.number().required(),
  type: Yup.string().oneOf(['PHYSICAL', 'DIGITAL', 'physical', 'digital']).required(),
  name: Yup.string().required(),
  description: Yup.string().required(),
  endTime: Yup.string().required(),
  expired: Yup.boolean().required(),
  imageUrl: Yup.string().required(),
  thumbnailUrl: Yup.string().required(),
});

type Props = {
  rewards: Reward[];
  userLoyaltyPoints: number;
};

const RewardCatalogItem: React.FC<{
  reward: Reward;
  userLoyaltyPoints: number;
}> = ({ reward, userLoyaltyPoints }) => {
  const hasEnoughPointsToRedeem = userLoyaltyPoints >= reward.points;

  // TODO: change 'to' prop of StyledLink to the actual reward PDP when implemented
  return (
    <li className="pds-flex pds-w-full pds-flex-col pds-gap-4 pds-self-start">
      <Link
        to={`/purina-perks/reward-details/${reward.id}`}
        className="pds-flex pds-flex-col pds-gap-4 pds-text-paper-text pds-no-underline [&_.reward-name]:hover:pds-underline"
        analytics={false}
        onClick={e => {
          onRewardClick(e, reward);
        }}
      >
        <div className="pds-flex pds-h-[160px] pds-flex-col pds-rounded pds-bg-surface-foreground pds-px-3 pds-py-4">
          <img
            src={reward.thumbnailUrl}
            alt=""
            className="pds-m-auto pds-h-[130px] pds-w-[130px] pds-object-cover"
          />
        </div>
        <span className="reward-name pds-line-clamp-2 pds-h-[48px] pds-font-semibold">
          {reward.name}
        </span>
        <div className="pds-relative pds-flex pds-w-fit pds-items-center pds-gap-2 pds-rounded-pill pds-border pds-bg-surface-heavy pds-px-[0.75rem] pds-text-body-sm pds-font-semibold">
          <img src={pointsBadgeSvg} className="pds-scale-125" alt="Perks points" />
          {reward.points}&nbsp;pts
        </div>
      </Link>
      <Button
        buttonColor="neutral"
        size="medium"
        buttonStyle="solid"
        disabled={!hasEnoughPointsToRedeem}
        aria-label={
          hasEnoughPointsToRedeem ? `Redeem ${reward.name}` : `Low Balance to Redeem ${reward.name}`
        }
        onClick={() => {
          onRedeemClick(reward, 'catalog');
        }}
        className="pds-flex pds-justify-center pds-gap-3 pds-border-none pds-px-4 pds-font-semibold pds-no-underline"
      >
        {hasEnoughPointsToRedeem ? 'Redeem' : 'Low Balance'}
      </Button>
    </li>
  );
};

const RewardCatalogList: React.FC<Props> = (props: Props) => {
  const containerRef = useRef<HTMLUListElement>(null);

  const { enableCatalogOmitFreeSamples } = useFlags();

  const itemsPerPage = 12;

  const { itemsSlice: items, pager } = usePager(props.rewards, containerRef, itemsPerPage);

  if (items.length === 0) {
    return (
      <>
        <h2 className="pds-mb-4 pds-text-left pds-text-title-md pds-font-semibold lg:pds-text-center">
          Redeem Rewards
        </h2>
        <span className="pds-block pds-rounded pds-bg-surface-layer pds-p-4 pds-text-center pds-font-semibold lg:pds-p-4.5">
          Rewards could not be found.
        </span>
      </>
    );
  }

  return (
    <>
      <span className="pds-block pds-pl-4 pds-pt-4 lg:pds-p-4.5">
        {props.rewards.length} matching results
      </span>
      <h2 className="pds-mb-5 pds-pl-4 pds-pt-4 pds-text-left pds-text-title-md pds-font-semibold lg:pds-pt-4 lg:pds-text-center">
        Redeem Rewards
      </h2>

      <Alert variant="info" className="pds-mb-5 !pds-pr-5" role="region" aria-label="Information">
        <div className="flex-row pds-flex pds-gap-4 pds-font-semibold pds-text-[#2B2B2B]">
          <Icon
            path={mdiInformationOutline}
            size={1}
            className="pds-shrink-0"
            aria-hidden
            // @ts-ignore - A11y Requirement
            role={undefined}
          />
          <div>
            {enableCatalogOmitFreeSamples
              ? 'Looking for more rewards? View the full catalog – including free samples and digital rewards – by '
              : 'Looking for digital rewards? View the full catalog – including digital rewards – by '}

            <Link
              to="https://mypurina.onelink.me/z05v/perkscatalog"
              target="_blank"
              className="!pds-text-[#2B2B2B]"
              rel="noreferrer"
              hideExternalIcon
            >
              downloading the myPurina app
            </Link>
          </div>
        </div>
      </Alert>

      <ul
        className="pds-grid pds-grid-cols-2 pds-place-items-center pds-gap-4 pds-px-5 pds-pb-5 md:pds-grid-cols-3 lg:pds-grid-cols-4 lg:pds-px-6"
        ref={containerRef}
      >
        {items.map(reward => (
          <RewardCatalogItem
            key={reward.id}
            reward={reward}
            userLoyaltyPoints={props.userLoyaltyPoints}
          />
        ))}
      </ul>
      <div className="pds-flex pds-justify-center">
        <Pager pager={pager} />
      </div>
    </>
  );
};

export default RewardCatalogList;
