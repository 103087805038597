import {  PageProps } from 'gatsby';
import React, { useEffect, useState } from 'react';

const FeedingGuide = React.lazy(() => import('@kraken-nerd/feeding-guide'));

const branchToEnvMap = {
  test: 'test',
  main: 'production',
  develop: 'development'
};

const FeedingGuidePage: React.FC<PageProps> = ({ location, pageContext }: any) => {
    const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (typeof window?.drupalSettings?.tesla === 'object') {
      if (pageContext?.env) {
        const env = (pageContext.env as keyof typeof branchToEnvMap) || 'develop';
        window.drupalSettings.tesla.env = env;
      }

      setIsLoaded(true);
    }
  }, []);

  return (
    <React.Suspense>
      <div>
        {typeof window !== 'undefined' && FeedingGuide && (
            <FeedingGuide />
        )}
      </div>
    </React.Suspense>
  );
};

export default FeedingGuidePage;