export enum CMS_THEME {
  DARK = 'dark',
  LIGHT = 'light',
  NEUTRAL = 'neutral',
}

export enum CMS_OVERLAY {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum PRODUCT_STATUS {
  FORMULA_UPDATE = 'Formula Update',
  PACKAGE_UPDATE = 'Package Update',
  SUPPLY_SHORTAGES = 'Supply Shortages',
  PRODUCT_RECALL = 'Product Recall',
  DISCONTINUED = 'Discontinued',
  NEW_PRODUCT = 'New Product',
}

// LaunchDarkly Keys.
export enum LD_KEYS {
  LD_USER_ID = 'ld-user-id',
}

export enum DefaultListingItemsPerPage {
  PRODUCT = 12,
  ARTICLE = 16,
}

export enum ListingPageType {
  PRODUCT = 'product_listing',
  ARTICLE = 'article_listing',
}

export enum PupUrls {
  PERKS = 'https://myprofile.purina.com',
  PETS = 'https://myprofile.purina.com/pets',
  ACCOUNT = 'https://myprofile.purina.com/account',
  PRIVACY_AND_LEGAL = 'https://myprofile.purina.com/privacy',
}

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
export enum MimeType {
  IMAGE_JPEG = 'image/jpeg',
  IMAGE_JPG = 'image/jpg',
  IMAGE_PNG = 'image/png',
}

export const isMimeType = (s: string): s is MimeType => {
  return Object.values(MimeType).includes(s as MimeType);
};
