import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import Icon from '@mdi/react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import { Dispatch, SetStateAction } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { SubmitHandler, useForm } from 'react-hook-form';
import { theme } from '../../theme/theme';
import SearchPageService, { PageTypeLabels } from '../search_listing/SearchPageService';
import { mdiMagnify } from 'src/assets/icons/mdiIcons';

const StyledForm = styled(Form)`
  width: 0;
  overflow: hidden;
  flex-direction: column;
  transition: width 0.3s ease;
  &.show {
    width: 100%;
  }
  @media (min-width: ${theme.media.lg}) {
    width: 100%;
  }
  .search-wrapper {
    height: 48px;
  }
  .form-control {
    color: ${theme.common.gray[600]};
    outline: none;
    border: none;
    box-shadow: none;
    padding-left: 25px;
    margin-bottom: 0;
    background-color: inherit;
    &::placeholder {
      color: ${theme.common.gray[600]};
    }
    &:focus::placeholder {
      color: transparent;
    }
  }
  button:focus-visible {
    outline: auto;
  }
`;

interface ParagraphSearch extends Paragraph {
  relationships: {
    field_search_page: NodeContent;
  };
}

interface SearchFormProps {
  node?: ParagraphSearch;
  id?: string;
  props: {
    showCancel: boolean;
    showSearch: boolean;
    showMenu: boolean;
    setShowSearch: Dispatch<SetStateAction<boolean>>;
    searchRef: React.RefObject<any>;
  };
}

const SearchFilter = styled.select`
  background: #ced4e6;
  border: none;
  padding: 10px 35px 10px 20px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem;
  background-position: right 5rem center;

  /* remove down arrow default */
  appearance: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
  background-position: right 0.75rem center;
`;

const classButtonSearch = css`
  padding: 0;
  padding-right: 15px;
`;

const ParagraphSearchForm: React.FC<SearchFormProps> = function ({ id = 0, node, props }) {
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;
  const { showSearch, setShowSearch, showCancel = true } = props;
  const location = useLocation();
  const classes = node?.behavior_settings?.decoupled_styles?.classes?.join(' ') || '';
  const showSearchRef = React.useRef<HTMLButtonElement | null>(null);
  const queryParams = Object.fromEntries(new URLSearchParams(location.search));

  const { handleSubmit, register, setValue } = useForm<{ keywords: string }>({
    defaultValues: { keywords: queryParams.keywords ?? '' },
  });

  React.useEffect(() => {
    setValue('keywords', queryParams.keywords ?? '');
  }, [queryParams.keywords, setValue]);

  const searchPages = SearchPageService.useGetSearchPages(activeLanguage);
  const activePage = SearchPageService.useGetActiveSearchPage(activeLanguage).api_slug;
  const [index, setIndex] = React.useState(activePage);
  React.useEffect(() => {
    const closeSearch = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowSearch(false);
        showSearchRef?.current?.focus();
      }
    };
    if (showSearch) {
      window.addEventListener('keydown', closeSearch);
    }
    return () => window.removeEventListener('keydown', closeSearch);
  }, [showSearch, setShowSearch]);

  const options = searchPages.map(page => {
    /* i18next-extract-mark-context-next-line ["Products", "Articles", "Breeds", "Pages"] */
    const label = t('PageTypeLabels', {
      context: PageTypeLabels[page.node.api_slug],
    });

    return (
      <option value={page.node.api_slug} key={page.node.id}>
        {label}
      </option>
    );
  });

  const onSearchFocus = () => {
    const newPath = searchPages.filter(page => {
      return page.node.api_slug === index;
    })[0].node.path.alias;
    const analyticsData = {
      event: 'search_initiate',
      eventCategory: 'search_initiate',
      eventAction: newPath,
      eventLabel: node?.type ?? '',
      eventParams: {
        default_search_category: newPath,
        page_type: node?.type ?? '',
      },
    };
    sendIt(analyticsData);
  };
  const onSubmit: SubmitHandler<{ keywords: string }> = data => {
    const keywords = data.keywords !== '' ? `?keywords=${data.keywords}` : '';

    if (location) {
      const newPage = searchPages.filter(page => {
        return page.node.api_slug === index;
      })[0].node;

      const newPath = newPage.path.alias;
      // we are still going to statically look for the keywords query param right?
      navigate(`${newPath}${keywords}`, { state: { didSearch: true } });
    }
  };

  const updateIndex = (activeIndex: string) => {
    setIndex(activeIndex);
  };

  const setFocus = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.setSelectionRange(0, event.currentTarget.value.length);
  };

  const handleShowSearch = () => {
    props?.setShowSearch(true);
    setTimeout(() => props.searchRef.current.focus(), 50);
  };
  const closeSearch = () => props?.setShowSearch(false);
  const updateSearchFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateIndex(e.target.value);
  };

  return (
    <div className={cx(classes, 'd-flex search justify-content-end')}>
      <StyledForm
        action={index}
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        className={cx('d-lg-flex', props?.showSearch ? 'd-flex show' : 'd-none')}
      >
        <Form.Group className="d-flex bg-light rounded-pill w-100 search-wrapper">
          <SearchFilter
            className="fw-light"
            as="select"
            onChange={updateSearchFilter}
            value={index}
            aria-label={t('Search Categories')}
            ref={props.searchRef}
          >
            {options}
          </SearchFilter>
          <Form.Control
            {...register('keywords')}
            placeholder={t('Search')}
            aria-label={t('Search site')}
            className="rounded-pill fw-light"
            onFocus={onSearchFocus}
            type="text"
            onClick={setFocus}
          />
          <Button
            className={classButtonSearch}
            type="submit"
            variant="link"
            aria-label={t('Site search submit')}
          >
            <Icon path={mdiMagnify} size={1} color="inherit" aria-hidden />
          </Button>
        </Form.Group>
        {showCancel && (
          <Button
            variant="link"
            aria-label={t('Cancel search')}
            id={`search-toggle-close-${node?.id || id}`}
            className={cx(
              'text-decoration-none',
              'p-1',
              props?.showSearch ? 'd-block d-lg-none' : 'd-none',
            )}
            onClick={closeSearch}
          >
            {t('Cancel')}
          </Button>
        )}
      </StyledForm>
      <Button
        variant="link"
        aria-label={t('Show search')}
        id={`search-toggle-${node?.id || id}`}
        className={cx(
          'rounded-circle bg-light p-2 d-lg-none justify-content-center',
          props?.showSearch || props?.showMenu ? 'd-none' : 'd-inline-flex',
        )}
        onClick={handleShowSearch}
        ref={showSearchRef}
        style={{ height: '48px', width: '48px' }}
      >
        <Icon path={mdiMagnify} size={1} color="inherit" aria-hidden />
      </Button>
    </div>
  );
};

export default ParagraphSearchForm;
