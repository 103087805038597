import { styled } from '@linaria/react';
import Icon from '@mdi/react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';
import * as React from 'react';
import { mdiOpenInNew } from 'src/assets/icons/mdiIcons';
import { prepareAttribute } from '../utils/helpers';

const StyledLink = styled.a`
  > svg {
    margin-left: 5px;
  }
`;

interface AttributeProps {
  [key: string]: any;
}

interface LinkPropsExtended {
  analytics?: boolean;
  /** Hides the icon for external links that open new tabs */
  hideExternalIcon?: boolean;
}

export type LinkProps = Omit<GatsbyLinkProps<{}>, 'ref'> & LinkPropsExtended;

const Link = React.forwardRef<React.ElementRef<'a'>, LinkProps>(
  ({ children, analytics = true, to, ...props }, ref) => {
    // This example assumes that any internal link (intended for Gatsby)
    // will start with exactly one slash, and that anything else is external.
    const internal = /^\/(?!\/)/.test(to);
    const file = /\.[0-9a-z]+(?=\?|$)/i.test(to);

    const attributes: AttributeProps = {};
    // Rewrite attributes to valid html.
    Object.entries(props).forEach(attribute => {
      const key: string = prepareAttribute(attribute[0]);
      attributes[key] = attribute[1];
    });

    if (analytics) {
      if (attributes.className) {
        attributes.className = `${attributes.className} js-track`;
      } else {
        attributes.className = 'js-track';
      }
    }

    const withIcon = attributes?.icon;

    // Use Gatsby Link for internal links, and <a> for others
    if (internal) {
      const handlePath = () => {
        if (!props.lang || !to.includes('/index')) return to;

        // If we're on the home page
        return props.lang === 'en' ? '/' : `/${props.lang}`;
      };
      if (file) {
        return (
          <StyledLink ref={ref} href={handlePath()} {...attributes}>
            {children}
          </StyledLink>
        );
      }

      if (props?.target !== '_blank') {
        return (
          <GatsbyLink ref={ref} to={handlePath()} {...attributes}>
            {children}
          </GatsbyLink>
        );
      }
    }

    return (
      <StyledLink ref={ref} href={to} {...attributes}>
        {children}
        {props?.target === '_blank' && !props.hideExternalIcon && !withIcon && (
          <Icon title="Open in new window" path={mdiOpenInNew} size={0.6} />
        )}
      </StyledLink>
    );
  },
);

export default Link;
