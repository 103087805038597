import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { css, cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Stack } from 'react-bootstrap';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';
import { getMedia } from '../../utils/mediaHelpers';
import Link from '../Link';

type Props = {
  node: IStorageContactCards;
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled.div<CardThemeProps>`
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

interface CardThemeProps {
  cmsTheme: CMS_THEME;
}

const StyledLogoCard = styled(Link)<CardThemeProps>`
  max-width: 56px;
  max-height: 56px;
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
  border-radius: ${theme.common.shape.roundedPill};
  text-decoration: none;
  font-size: 18px;
  &:hover,
  &:focus,
  &:active {
    color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
    cursor: pointer;
    text-decoration: underline;
  }
`;
const classHeadingElement = css`
  font-size: 1.375rem;
`;
const StorageContactCards: React.FC<Props> = ({ node: contactCards }) => {
  const settings = Object.assign(DefaultSettings, contactCards?.behaviors || {});
  const cmsTheme = settings?.entity_variant?.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;

  // If all cards are draft, don't render.
  if (!contactCards?.relationships?.cards || contactCards.relationships.cards.length === 0) {
    return null;
  }

  return (
    <Wrapper className="gap-4 d-grid" cmsTheme={cmsTheme}>
      {contactCards?.title && (
        <HeadingElement className={cx(headingStyle, classHeadingElement)}>
          {contactCards.title}
        </HeadingElement>
      )}
      <Stack className="flex-row" gap={2}>
        {contactCards.relationships?.cards.map(card => {
          const fieldMedia = card.relationships.field_media;
          return (
            <AnalyticsPoint
              type="component"
              node={{ ...card }}
              as={StyledLogoCard}
              id={card.id}
              key={card.id}
              to={card.link.url}
              cmsTheme={cmsTheme}
              {...card.link.options?.attributes}
            >
              {getMedia({ media: fieldMedia, style: { width: '56px' } })}
            </AnalyticsPoint>
          );
        })}
      </Stack>
    </Wrapper>
  );
};

export default StorageContactCards;
