import Icon from '@mdi/react';
import { pdsTv } from '@purinanbm/pds-ui';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiEdit, mdiInformationOutline } from 'src/assets/icons/mdiIcons';
import { PROFILE_CONFIG as config } from 'src/common/constants';
import { Pet, PetType } from 'src/services/sst/data-contracts';
import { PUP_ROUTES, isValidPetProfile, transformImageUrlToNetlify } from 'src/utils/helpers';
import Link from '../Link';
import { ColorPriority, SpeciesAvatar } from '../species_avatar/SpeciesAvatar';

interface PetMedia {
  fileType: string;
  isPrimary: boolean;
  mediaId: string;
  mediaType: string;
  petId: string;
  url: string;
}

interface PetListProps {
  data: Partial<Pet>[];
  media: PetMedia[];
}

type IconColor = 'PURPLE' | 'ORANGE' | 'GREEN' | 'BLUE';

interface IPetAvatar {
  id: number;
  name: string;
  petType: PetType;
  iconColor: IconColor;
  media?: PetMedia;
  isIncomplete?: boolean;
}

const PetAvatar: FC<IPetAvatar> = ({
  petType,
  name,
  media,
  iconColor,
  isIncomplete = false,
  id,
}) => {
  const { t } = useTranslation();
  const petsUrl = `${config.pup.url}${PUP_ROUTES.PETS}`;

  const item = {
    hidden: { opacity: 0 },
    show: { opacity: 1 },
  };

  const avatar = pdsTv({
    base: 'pds-relative pds-h-[94px] pds-w-[94px] pds-self-center pds-rounded-circle pds-bg-gray-300 pds-text-center sm:pds-h-[120px] sm:pds-w-[120px]',
  });

  const petIcon = () => (
    <SpeciesAvatar species={petType as PetType} color={iconColor} className="pds-self-center" />
  );
  return (
    <motion.li
      variants={item}
      key={name}
      className="pds-flex pds-w-1/2 pds-flex-col pds-items-center pds-justify-center pds-overflow-hidden pds-text-ellipsis pds-py-4 md:pds-w-1/3"
    >
      <div className={avatar()}>
        <Link
          className="pds-absolute pds-right-[-7px] pds-top-[-10px] pds-rounded-circle pds-bg-surface pds-p-3 pds-drop-shadow-[-2px_4px_0px_rgba(0,0,0,0.25)]"
          to={`${petsUrl}/${id}/edit?origin=${window.location.href}${
            isIncomplete && '&incomplete'
          }`}
        >
          <span className="pds-sr-only">Edit {name}</span>
          <Icon aria-hidden path={mdiEdit} className="pds-text-surface-text" size={1} />
        </Link>
        {!media ? (
          petIcon()
        ) : (
          <GatsbyImage
            alt={name}
            image={{
              images: { sources: [{ srcSet: [transformImageUrlToNetlify(media.url)] }] },
            }}
            className="pds-h-full pds-w-full pds-rounded-circle"
          />
        )}
      </div>

      <div className="pds-flex pds-w-full pds-flex-col">
        <div className="pds-flex pds-w-full pds-flex-row pds-items-center pds-justify-center">
          {isIncomplete && (
            <div className="pds-w-4.5">
              <span className="pds-sr-only">{t('Incomplete profile')}</span>
              <Icon
                aria-hidden="true"
                path={mdiInformationOutline}
                size={1}
                className="pds-mr-2 pds-w-4.5 pds-text-error"
                // @ts-ignore - A11y Requirement
                role={undefined}
              />
            </div>
          )}
          <span
            className="pds-max-w-[150px] pds-overflow-hidden pds-overflow-ellipsis pds-whitespace-nowrap pds-text-body-lg pds-font-bold"
            title={name}
          >
            {name}
          </span>
        </div>
        {isIncomplete && (
          <div className="pds-flex pds-w-full pds-flex-col pds-items-center pds-justify-center">
            <Link
              aria-label={`Complete ${name}'s Profile`}
              to={`${config.pup.url}${PUP_ROUTES.EDIT_PET(id)}?origin=${window.location.href}`}
              className="pds-text-body-sm pds-font-extrabold pds-text-neutral"
            >
              {t('Complete Profile')}
            </Link>
          </div>
        )}
      </div>
    </motion.li>
  );
};

const PetList: React.FC<PetListProps> = ({ data, media }) => {
  const petListContainer = pdsTv({
    base: 'pds-flex pds-w-full pds-max-w-4xl pds-flex-wrap pds-items-center pds-pl-0',
    variants: {
      singleView: {
        true: 'pds-items-center pds-justify-center',
      },
    },
  });

  const mappedPets = () => {
    return data.map((pet, idx) => {
      const isIncomplete = !isValidPetProfile(pet);
      const petMedia = media?.find(pMedia => pMedia.petId === `${pet.id}`);
      return (
        <PetAvatar
          key={pet.id}
          name={pet.name as string}
          petType={pet.petType as PetType}
          media={petMedia}
          iconColor={ColorPriority[idx % ColorPriority.length]}
          isIncomplete={isIncomplete}
          id={Number(pet.id)}
        />
      );
    });
  };
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  return (
    <div className="pds-my-5 pds-flex pds-w-full pds-justify-center">
      <motion.ul
        variants={container}
        initial="hidden"
        animate="show"
        className={petListContainer({ singleView: data?.length === 1 })}
      >
        {mappedPets()}
      </motion.ul>
    </div>
  );
};
export default PetList;
