import React from 'react';

type Props = {
  node: IStorageUnfilteredHtml;
};

const StorageUnfilteredHtml = ({ node }: Props) => {
  return (
    <div
      id={node.component_id}
      data-id={node.drupal_id}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: node.field_unfiltered_html.processed }}
    />
  );
};

export default StorageUnfilteredHtml;
