import { useCallback, useEffect, useState } from 'react';

export type BrazeCardPayload = {
  id: string;
  title: string;
  description: string;
  url: string;
  linkText: string;
  created?: Date;
  isControl: boolean;
  imageUrl: string;
  extras: {
    location: string;
    format: string;
    brand: string;
    species: string;
    priority: string;
    campaign: string;
  };
};

type useBrazeProps = {
  brand?: string;
  species?: string;
};

export const FORMAT_BUCKETS = {
  banner: ['web_banner', 'web_captioned_banner'],
  card: ['web_classic_card', 'web_captioned_card'],
};

function testBrandMatch(pattern: string, key: string): boolean {
  if (!pattern) return false;
  try {
    const regex = new RegExp(pattern);
    return regex.test(key);
  } catch (e) {
    return pattern === key;
  }
}

export const useBraze = ({ brand, species }: useBrazeProps) => {
  const [brazeData, setBrazeData] = useState<BrazeCardPayload[]>([]);
  const [isSdkLoaded, setIsSdkLoaded] = useState(false);
  const [contentCards, setContentCards] = useState<any[]>([]);
  const [brazeSDK, setBrazeSDK] = useState<any | null>(null);

  const sortContentCards = useCallback(() => {
    return contentCards.sort((a: BrazeCardPayload, b: BrazeCardPayload) => {
      const aBrandMatch = testBrandMatch(a.extras.brand, brand || '');
      const bBrandMatch = testBrandMatch(b.extras.brand, brand || '');
      const aSpeciesMatch = species === a.extras.species;
      const bSpeciesMatch = species === b.extras.species;

      // Prioritizet match on both brand and species
      if (aBrandMatch && aSpeciesMatch && !(bBrandMatch && bSpeciesMatch)) return -1;
      if (bBrandMatch && bSpeciesMatch && !(aBrandMatch && aSpeciesMatch)) return 1;

      // Prioritize match on brand.
      if (aBrandMatch && !bBrandMatch) return -1;
      if (bBrandMatch && !aBrandMatch) return 1;

      // Prioritize match on species.
      if (aSpeciesMatch && !bSpeciesMatch) return -1;
      if (bSpeciesMatch && !aSpeciesMatch) return 1;

      // Sort by priority and then by created date
      if (a.extras.priority === b.extras.priority && a.created && b.created) {
        return b.created.getTime() - a.created.getTime();
      }

      if (!a.extras.priority) return 1;
      if (!b.extras.priority) return -1;

      return Number(a.extras.priority) - Number(b.extras.priority);
    });
  }, [contentCards, brand, species]);

  useEffect(() => {
    // Poll for Braze SDK and give up after 5 minutes
    let counter = 0;
    const brazePoll = setInterval(() => {
      counter++;
      const sdkLoaded = window?.braze;
      if (sdkLoaded || counter > 300) {
        clearInterval(brazePoll);
      }
      if (sdkLoaded) setIsSdkLoaded(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (isSdkLoaded) {
      const {
        getCachedContentCards,
        openSession,
        removeSubscription,
        requestContentCardsRefresh,
        subscribeToContentCardsUpdates,
      } = window.braze;

      setBrazeSDK(window.braze);

      const contentCardSubscription = subscribeToContentCardsUpdates(
        (updates: BrazeCardPayload[]) => {
          if (!updates.length) return;
          setContentCards(updates);
        },
      );

      const cachedCards = getCachedContentCards();

      // Re-request card updates if first time or cache is a 60 seconds old.
      if (!cachedCards.lastUpdated || Date.now() - cachedCards.lastUpdated > 1000 * 60) {
        requestContentCardsRefresh();
      }

      const initialCards = getCachedContentCards().cards || [];

      if (Array.isArray(initialCards) && initialCards.length) setContentCards(initialCards);

      // Re-open session to ensure we are subscribed to content card updates
      openSession();

      return () => {
        // Cleanup: unsubscribe from content card updates
        removeSubscription(contentCardSubscription);
      };
    }
    return undefined;
  }, [isSdkLoaded, setContentCards]);

  useEffect(() => {
    if (contentCards.length) {
      setBrazeData(sortContentCards());
    }
  }, [contentCards, sortContentCards]);

  return { brazeData, isSdkLoaded, brazeSDK };
};
