import { useEffect } from 'react';
import { pollObjectOnWindow } from 'src/utils/helpers';

export function useBazaarVoiceDCC(jwtToken: string, bvID: string) {
  useEffect(() => {
    if (bvID) {
      pollObjectOnWindow('BV', 50, 1000)
        .then(object => {
          object.pixel.trackEvent('CatalogUpdate', {
            type: 'Product',
            token: jwtToken,
          });
        })
        .catch(error => {
          console.error('BV never loaded on window', error);
        });
    }
  }, [jwtToken, bvID]);
}
