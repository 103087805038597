import React, { PropsWithChildren, useEffect, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import useAppStoreBanner, { AppBannerPlatform } from './useAppStoreBanner';

export interface AppStoreBannerProps {
  appStoreId: string;
  playStoreId: string;
  dismissDuration: number;
  children: (
    platform: AppBannerPlatform,
    viewAppStore: () => string,
    handleDismiss: () => void
  ) => React.ReactNode;
}

const AppStoreBanner = ({
  appStoreId,
  playStoreId,
  dismissDuration,
  children,
}: PropsWithChildren<AppStoreBannerProps>) => {
  const { showCustomBanner, platform } = useAppStoreBanner({ alwaysCustom: true });
  const [isDismissed, setIsDismissed] = useState(false);

  useEffect(() => {
    const dismissed = localStorage.getItem('appStoreBannerDismissed');
    if (dismissed) {
      const dismissTime = parseInt(dismissed, 10);
      const currentTime = Date.now();
      if (currentTime - dismissTime < dismissDuration) {
        setIsDismissed(true);
      }
    }
  }, [dismissDuration]);

  const isInstalled = () => {
    // Check if the app is installed
    // @todo check if the app is installed in future to launch the app.
    return false;
  };

  const viewAppStore = () => {
    // Open the app store page of the respective platform.
    let appStoreURL = '';
    if (!isInstalled()) {
      if (platform === 'ios' && appStoreId) {
        appStoreURL = `https://apps.apple.com/app/id${appStoreId}`;
      } else if (platform === 'android' && playStoreId) {
        appStoreURL = `https://play.google.com/store/apps/details?id=${playStoreId}`;
      }
    }
    return appStoreURL;
  };

  const handleDismiss = () => {
    setIsDismissed(true);
    localStorage.setItem('appStoreBannerDismissed', String(Date.now()));
  };
  if (!showCustomBanner || isDismissed) return null;

  return <>{children(platform, viewAppStore, handleDismiss)}</>;
};

export default AppStoreBanner;
