import { PageProps, graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import RichText from '../../components/rich_text/RichText';
import {
  getHelmetPropsFromMetatagProps,
  getJsonFromMetatagProps,
} from '../../utils/metatagHelpers';

interface DataProps {
  node: NodeContent;
  translations: Translations;
}

const hasOptinGroups = () => {
  const optgroups = decodeURIComponent(
    // @ts-ignore - Purina analytics provided.
    document.cookie
      ?.split('; ')
      ?.find(row => row.startsWith('OptanonConsent='))
      ?.split('&')
      ?.find(row => row.startsWith('groups='))
      ?.split('=')[1],
  )?.split(',');

  return optgroups.includes('C0002:0') && optgroups.includes('C0004:0');
};

const DoNotSellTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, translations },
}) {
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);

  const [rejected, setRejected] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (hasOptinGroups()) {
      setRejected(true);
    }
  }, []);

  const handleOptOut = () => {
    if (rejected) {
      // @dev - Purina doesn't have a way to opt back in.
      return;
    }

    try {
      window.OneTrust.RejectAll();
      setRejected(true);
    } catch (err) {
      // @ts-ignore
      console.error(err);
    }
  };

  return (
    <Layout node={node} language={node.langcode} translations={translations}>
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <Container fluid className="bg-gray-100 py-5 text-center">
        <Container>
          <h1 className="typography_h1">{node.title}</h1>
          {node.body?.processed && <RichText body={node.body.processed} />}
        </Container>
      </Container>
      <Container className="py-5">
        <Row className="justify-content-center">
          <Col xs={12} md="auto" className="justify-center">
            <Form autoComplete="off" noValidate style={{ maxWidth: '500px' }}>
              <Form.Group controlId="CookieOptout_1">
                <Form.Check
                  name="optOut"
                  id="CookieOptout_1"
                  onClick={() => handleOptOut()}
                  data-rejected={rejected}
                  defaultChecked={rejected}
                  checked={rejected}
                  label={t(
                    'Some cookies are used to serve you with targeted content that may involve activities that could be viewed as “selling” or “sharing” under California law. Check the box to turn those cookies off. You do not have to complete and submit the below form to make this choice. Your choice will apply to this browser and device.',
                  )}
                />
              </Form.Group>
            </Form>
            <iframe
              style={{
                height: '900px',
                width: '100%',
              }}
              title={t('Notification to not sell my personal information')}
              src="https://privacyportal.onetrust.com/webform/242e27bf-9452-4c8d-9f7b-5ebde7934198/e9b88612-c1e7-4d58-aa7c-091116e3577b"
            />
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeComplex(id: { eq: $id }, langcode: { eq: $language }) {
      id
      body {
        processed
      }
      path {
        alias
      }
      default_langcode
      langcode
      title
      ...Metatag
      behaviors {
        page_layout {
          elements_to_hide {
            header
            footer
            chat
          }
        }
      }
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
    }
    translations: allNodeComplex(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default DoNotSellTemplate;
