import camelCase from 'lodash/camelCase';
import { HelmetProps } from 'react-helmet';

type MetatagTags = 'link' | 'meta';
type SchemaMetatagAttributes = {
  group?: string;
  schema_metatag?: boolean;
  content?: string;
  [key: string]: any;
};

export interface MetatagProps {
  tag: MetatagTags;
  attributes: SchemaMetatagAttributes;
}

type JsonSchemaType = Record<string, Record<keyof MetatagProps['attributes']['name'], string>>;

export type JsonSchema =
  | {
      '@context': string;
      '@graph': Array<JsonSchemaType>;
    }
  | {};

interface BreadcrumbItem {
  // Url to page
  '@id': string;
  // Also the url to the item
  item: string;
  // Name of the breadcrumb item
  name: string;
}

export const getJsonFromMetatagProps = (data: Array<MetatagProps>): JsonSchema => {
  if (!data) return {};

  const jsonSchema: JsonSchemaType = data.reduce((acc: JsonSchemaType, schema) => {
    if (
      schema.attributes?.schema_metatag &&
      schema.attributes?.group &&
      schema.attributes?.name &&
      schema.attributes?.content
    ) {
      acc[schema.attributes.group] = acc[schema.attributes.group] || {};
      let value = schema.attributes.content;

      try {
        value = JSON.parse(schema.attributes.content);
      } catch (e) {
        // Catch is mandatory.
      } finally {
        acc[schema.attributes.group][schema.attributes.name] = value;
      }
    }

    return acc;
  }, {});

  return {
    '@context': 'https://schema.org',
    '@graph': Object.keys(jsonSchema).map(key => jsonSchema[key]),
  };
};

export const getHelmetPropsFromMetatagProps = (data: MetatagProps[]): HelmetProps => {
  if (!data) return {};

  const helmetProps: HelmetProps = {
    meta: [],
    link: [],
  };

  data.forEach(schema => {
    if (!schema.attributes?.schema_metatag) {
      const formattedAttributes = new Map();

      Object.keys(schema.attributes).forEach(key => {
        const value = schema.attributes[key];

        if (value !== null) {
          const formattedKey = camelCase(key);
          formattedAttributes.set(formattedKey, value);
        }
      });

      switch (schema.tag) {
        case 'meta':
          if (schema.attributes.name === 'title') {
            helmetProps.title = schema.attributes.content;
          }
          helmetProps.meta?.push(Object.fromEntries(formattedAttributes));
          break;
        case 'link':
          helmetProps.link?.push(Object.fromEntries(formattedAttributes));
          break;
        default:
          break;
      }
    }
  });

  return helmetProps;
};

export const getBreadcrumbsFromMeta = (metaData: Array<MetatagProps>): BreadcrumbItem[] | null => {
  // const crumbMeta = metaData.find(data => data.attributes.name === 'breadcrumb');
  const crumbMeta = metaData.find(
    data =>
      data.attributes.group === 'schema_breadcrumb' && data.attributes.name === 'itemListElement',
  );

  if (crumbMeta?.attributes.content) {
    const parsed: BreadcrumbItem[] = Object.values(
      JSON.parse(crumbMeta.attributes.content),
    ) as BreadcrumbItem[];

    return parsed;
  }

  return null;
};
