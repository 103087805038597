import { css } from '@linaria/core';
import { styled } from '@linaria/react';
import { graphql, useStaticQuery } from 'gatsby';
import { IBV } from 'gatsby-plugin-bazaarvoice/types';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useEffect, useState } from 'react';
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import StorageFeedingGuide from 'src/cms-components/StorageFeedingGuide';
import BazaarvoiceWidget from '../../../components/bazaarvoice/BazaarvoiceWidget';
import IconList from '../../../components/common/IconList';
import ProductIngredients from '../../../components/product/ProductIngredients';
import { NodeProductProps } from '../types';

const TAB_FRAGMENT_MAP = {
  '#overview': 'overview',
  '#nutrition': 'nutrition',
  '#feeding': 'feeding',
  '#reviews': 'reviews',
};

interface Props {
  location: any;
  node: NodeProductProps;
  availableSizes: {
    text: string;
    icon: {
      url: string;
      alt: string;
    };
  }[];
  labelDeck?: string | undefined;
  showFeedingTab?:
    | {
        processed: string;
      }
    | undefined;
  bvID: string;
}

const reviewsStop = css`
  scroll-margin-top: 95px;
  height: 1px;

  @media (min-width: 992px) {
    scroll-margin-top: 143px;
  }
`;
const StyledTabs = styled(Tabs)`
  overflow: auto hidden;
  overscroll-behavior-x: contain;
  scroll-snap-type: x mandatory;
  padding: 0 2px 0 0;
  > li > button {
    height: 100%;
  }
  @media (hover: none) {
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }
`;

const useGetFeedingGuideQuery = (langcode: string) =>
  useStaticQuery<{ allStorageFeedingGuide: { edges: { node: IStorageFeedingGuide }[] } }>(graphql`
    query {
      allStorageFeedingGuide {
        edges {
          node {
            ...StorageFeedingGuide
          }
        }
      }
    }
  `).allStorageFeedingGuide.edges.filter(({ node }) => node.langcode === langcode);

const DEFAULT_TAB = 'overview';
// eslint-disable-next-line
const ProductTabs: React.FC<Props> = ({
  location,
  node,
  availableSizes,
  labelDeck,
  showFeedingTab,
  bvID,
}) => {
  const [activeTab, setActiveTab] = useState(DEFAULT_TAB);
  const { t } = useTranslation();
  const [feedingGuide = null] = useGetFeedingGuideQuery(node.langcode);

  useEffect(() => {
    const regex = new RegExp(`^(${Object.keys(TAB_FRAGMENT_MAP).join('|')})$`);
    if (location.hash && regex.test(location.hash)) {
      setActiveTab(TAB_FRAGMENT_MAP[location.hash]);
    }
  }, [location.hash]);

  const onTabSelect = (key: string | null) => {
    setActiveTab(key || DEFAULT_TAB);
    if (key) {
      sendIt({
        event: 'select_content',
        eventCategory: 'product details',
        eventAction: 'tabbed navigation',
        eventLabel: key,
        eventParams: {
          cta_location: 'product details',
          content_type: 'tabbed navigation',
          item_id: key,
          link_url: '(not set)',
        },
      });
    }
  };

  useEffect(() => {
    window.bvCallback = (bv: IBV) => {
      bv.swat_reviews.on('show', () => {
        setActiveTab(() => 'reviews');
        window.location.href = '#reviews';
      });
    };
  }, [location.hash]);
  return (
    <>
      <div id="reviews" className={reviewsStop} />
      <StyledTabs
        // defaultActiveKey={
        //   (TAB_FRAGMENT_MAP[location?.hash] as any) ? TAB_FRAGMENT_MAP[location?.hash] : 'overview'
        // }
        className="my-5 flex-nowrap h-100"
        id="product-tabs"
        activeKey={activeTab}
        onSelect={key => onTabSelect(key)}
      >
        <Tab eventKey="overview" title={t('Overview')}>
          <Row>
            {node?.highlights.length > 0 && (
              <Col>
                <h2 className="h4">{t('Highlights')}</h2>
                <ul className="pds-list-disc">
                  {node.highlights.map((highlight, index) => {
                    return <li key={index}>{highlight}</li>;
                  })}
                </ul>
              </Col>
            )}
            {availableSizes.length >= 1 && (
              <Col>
                <h2 className="h4">{t('Available Sizes')}</h2>
                <IconList items={availableSizes} size="small" />
              </Col>
            )}
            {node.body?.processed && (
              <Col sm={12}>
                <h2 className="h4">{t('Product Description')}</h2>
                {/* eslint-disable-next-line react/no-danger */}
                <div dangerouslySetInnerHTML={{ __html: node.body.processed }} />
              </Col>
            )}
          </Row>
        </Tab>
        {node?.relationships?.ingredients && node.relationships.ingredients.length > 1 && (
          <Tab eventKey="nutrition" title={t('Nutrition')}>
            <ProductIngredients
              title={t('Ingredients')}
              ingredients={node.relationships.ingredients}
            />
            {labelDeck && (
              <div className="mt-4">
                <a
                  href={labelDeck}
                  target="_blank"
                  className="text-decoration-none"
                  rel="noreferrer"
                >
                  {t('Download the full ingredient list (PDF)')}
                </a>
              </div>
            )}
          </Tab>
        )}
        {showFeedingTab && (
          <Tab eventKey="feeding" title={t('Feeding')}>
            <h2 className="h4">{t('Feeding Guide')}</h2>
            {feedingGuide?.node && <StorageFeedingGuide node={feedingGuide.node} />}
            {node.feeding_instructions && (
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: node.feeding_instructions.processed,
                }}
              />
            )}
            {labelDeck && (
              <p>
                {t('For a list of all feeding recommendations')},{' '}
                <a href={labelDeck} target="_blank" rel="noreferrer">
                  {t('Download the full recommended feeding table')} (PDF)
                </a>
                .
              </p>
            )}
          </Tab>
        )}
        <Tab eventKey="reviews" title={t('Reviews')}>
          <BazaarvoiceWidget
            attributes={{
              productID: bvID,
              widgetID: 'reviews',
            }}
          />
        </Tab>
      </StyledTabs>
    </>
  );
};

export default ProductTabs;
