import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';
import { Stack } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';
import MediaRemoteVideo from '../components/media/MediaRemoteVideo';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';

type Props = {
  node: IStorageVideo;
};

const DefaultSettings = {
  entity_heading: {
    element: 'h2',
    style: 'typography_h2',
  },
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const Wrapper = styled(Container)<{ cmsTheme: CMS_THEME }>`
  background-color: ${({ cmsTheme }) => theme[cmsTheme].background.default};
  color: ${({ cmsTheme }) => theme[cmsTheme].text.default};
`;

const StorageVideo = ({ node: video }: Props) => {
  const settings = Object.assign(DefaultSettings, video?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const hasLinks = video?.links && video.links.length > 0;
  const videoMedia = video.relationships.video;
  return (
    <Wrapper id={video.component_id} data-id={video.drupal_id} className="py-3 py-lg-5 px-0" cmsTheme={cmsTheme}>
      <Stack className="py-5 text-center container-xxl" gap={5}>
        <Stack gap={3} className="align-items-center">
          {video?.title ? (
            <HeadingElement className={cx(headingStyle)}>{video.title}</HeadingElement>
          ) : null}
          {video?.body?.processed ? (
            <RichText cmsTheme={cmsTheme} className={cx('mt-4')} body={video.body.processed} />
          ) : null}
          {hasLinks && (
            <ButtonDiadWrapper>
              {video.links?.map((link, index) => (
                <AnalyticsPoint
                  type="component"
                  node={{ name: link.title, title: link.title, type: 'video_link', drupal_id: '' }}
                  as={ButtonLink}
                  key={index}
                  cmsTheme={cmsTheme}
                  to={link.url}
                  {...link.options?.attributes}
                >
                  {link.title}
                </AnalyticsPoint>
              ))}
            </ButtonDiadWrapper>
          )}
        </Stack>
        <MediaRemoteVideo
          cmsTheme={cmsTheme}
          field_media_oembed_video={videoMedia?.field_media_oembed_video}
          field_media_video_transcript={videoMedia?.field_media_video_transcript}
          id={video.id}
          internal={videoMedia?.internal}
        />
      </Stack>
    </Wrapper>
  );
};

export default StorageVideo;
