import { pdsTv } from '@purinanbm/pds-ui';
import * as React from 'react';

const styles = pdsTv({
  base: 'pds-m-0 pds-mt-4.5 pds-flex pds-w-fit pds-max-w-full pds-flex-wrap pds-gap-4 *:pds-grow only:*:pds-grow-0',
});

const ButtonDiadWrapper = ({ className, ...props }: React.ComponentPropsWithRef<'div'>) => {
  return <div className={styles({ className })} {...props} />;
};

export default ButtonDiadWrapper;
