import { pdsTv } from '@purinanbm/pds-ui';
import { GatsbyImage } from 'gatsby-plugin-image';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonDiadWrapper from 'src/components/button/ButtonDiadWrapper';
import { CMS_THEME } from '../common/enums';
import { ButtonLink } from '../components/button/Button';

interface StorageImageContentProps {
  node: IStorageImageContent;
}

interface IDefaultSettings {
  entity_buttons: {
    primary_button: string;
  };
  entity_heading: {
    element: keyof JSX.IntrinsicElements;
    style: string;
  };
  entity_subheading: {
    element: keyof JSX.IntrinsicElements;
    style: string;
  };
  entity_variant: {
    content_position: 'right' | 'left' | undefined;
    vertical_alignment: 'center' | 'top' | 'bottom';
    theme: CMS_THEME;
  };
}

const DefaultSettings: IDefaultSettings = {
  entity_buttons: {
    primary_button: 'btn',
  },
  entity_heading: {
    element: 'h2' as keyof JSX.IntrinsicElements,
    style: 'pds-text-title-md-legacy pds-font-normal pds-tracking-[initial]',
  },
  entity_subheading: {
    element: 'h3' as keyof JSX.IntrinsicElements,
    style: 'pds-text-title-sm-legacy pds-font-normal pds-tracking-[initial]',
  },
  entity_variant: {
    content_position: 'right',
    vertical_alignment: 'center',
    theme: CMS_THEME.LIGHT,
  },
};

const styles = pdsTv({
  slots: {
    imgContainer: 'pds-px-0 pds-py-4 md:pds-py-5.5 [&_img]:pds-max-w-full',
    richText: 'pds-prose pds-mt-4',
    wrapper: '',
    thumbnail: 'pds-mb-4 pds-rounded md:pds-mb-0',
    heading: 'pds-flex-column pds-justify-center lg:pds-pl-5.5',
  },
  variants: {
    verticalAlignment: {
      top: { wrapper: 'pds-items-start' },
      center: { wrapper: 'pds-items-center' },
      bottom: { wrapper: 'pds-items-end' },
    },
    // horizontal justification
    contentPosition: {
      left: { wrapper: '' },
      right: { wrapper: 'pds-flex-row-reverse' },
    },
    color: {
      dark: {
        imgContainer: 'pds-bg-[#1E1E1E] pds-text-white',
        richText: 'pds-prose-invert',
      },
      light: {
        imgContainer: 'pds-bg-white pds-text-[#2B2B2B]',
        richText: '',
      },
      neutral: { imgContainer: 'pds-bg-[#EDF0F8] pds-text-[#2B2B2B]', richText: '' },
    },
  },
  defaultVariants: {
    color: 'light',
    contentPosition: 'left',
    verticalAlignment: 'center',
  },
});

const StorageImageContent: React.FC<StorageImageContentProps> = function ({ node: imageContent }) {
  const settings = Object.assign(DefaultSettings, imageContent?.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const primaryButtonVariation = settings.entity_buttons?.primary_button;
  const { element: HeadingElement, style: headingStyle } = settings.entity_heading;
  const { element: SubheadingElement, style: subheadingStyle } = settings.entity_subheading;
  const media_image = imageContent?.relationships?.image;

  const { wrapper, richText, imgContainer, thumbnail, heading } = styles({
    color: cmsTheme,
    contentPosition: settings.entity_variant.content_position,
    verticalAlignment: settings.entity_variant.vertical_alignment,
  });
  return (
    <Container
      id={imageContent.component_id}
      data-id={imageContent.drupal_id}
      className={imgContainer()}
      fluid
    >
      <Container>
        <Row className={wrapper()}>
          <Col>
            <GatsbyImage
              alt={media_image?.field_media_image?.alt}
              image={media_image?.relationships?.field_media_image?.gatsbyImage}
              className={thumbnail()}
            />
          </Col>
          <Col xs={12} md={6} className={heading()}>
            <HeadingElement className={headingStyle}>{imageContent.title}</HeadingElement>
            {imageContent.subtitle && (
              <SubheadingElement className={subheadingStyle}>
                {imageContent.subtitle}
              </SubheadingElement>
            )}
            {imageContent.body?.processed && (
              <div
                className={richText()}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: imageContent.body.processed }}
              />
            )}
            {imageContent.links && imageContent.links?.length > 0 && (
              <ButtonDiadWrapper>
                {imageContent.links.map((linkObject, index) => {
                  const handleGA = () => {
                    const hasSecondaryButton = imageContent.links?.length > 1;
                    const primaryTitle = imageContent.links[0]?.title ?? '[not set]';
                    const secondaryTitle = imageContent?.links[1]?.title ?? '[not set]';
                    sendIt({
                      event: 'module_interaction',
                      eventParams: {
                        component_id: hasSecondaryButton
                          ? `primary_cta: ${primaryTitle},  secondary_cta: ${secondaryTitle}`
                          : `primary_cta: ${primaryTitle}`,
                        component_type: 'image_content_link',
                        content_name: `${HeadingElement} / ${imageContent?.title}`,
                      },
                    });
                  };
                  return (
                    <ButtonLink
                      onClick={handleGA}
                      analytics={false}
                      key={index}
                      variant={index === 0 ? primaryButtonVariation : 'btn-outline'}
                      cmsTheme={cmsTheme}
                      to={linkObject.url}
                      {...linkObject.options?.attributes}
                    >
                      {linkObject.title}
                    </ButtonLink>
                  );
                })}
              </ButtonDiadWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default StorageImageContent;
