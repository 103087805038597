import { yupResolver } from '@hookform/resolvers/yup';
import { styled } from '@linaria/react';
import Icon from '@mdi/react';
import { useMutation } from '@tanstack/react-query';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import * as React from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import {
  FieldError,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { mdiAlertCircleOutline } from 'src/assets/icons/mdiIcons';
import { ansiraOfferPost } from '../../utils/clientRequests';
import { getRecaptchaToken } from '../../utils/formHelpers';
import RichText from '../rich_text/RichText';
import AddressSection from './components/AddressSection';
import DynamicPetArrayField from './components/DynamicPetField';
import InputField, { requiredClass } from './components/InputField';
import RecaptchaDisclaimer from './components/RecaptchaDisclaimer';
import {
  OfferFormTypes,
  createOfferSchema,
  submitAnalyticsForIncompleteResponse,
} from './utils/offer';

interface FormProps {
  referrer: string;
  offerId: number;
  formType?: 'short' | 'long' | 'long_address';
  successMessage?: string;
  successTitle?: string;
  legalText?: string;
  optinLabel?: string;
  optinError?: string;
  ctaLabel?: string;
  title: string;
  brand?: string;
}

const SubmittedText = styled.p`
  font-weight: 300;
`;

const OfferForm: React.FC<FormProps> = function ({
  title,
  brand,
  referrer,
  offerId,
  formType = 'short',
  successMessage,
  successTitle,
  legalText,
  optinLabel,
  optinError,
  ctaLabel,
}: FormProps) {
  const { t } = useTranslation();

  const schema = createOfferSchema(t, formType);
  const methods = useForm<OfferFormTypes>({
    defaultValues: {
      optIn: false,
      catCount: '0',
      dogCount: '0',
    },
    resolver: yupResolver(schema),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = methods;

  const dogCount = watch('dogCount');
  const catCount = watch('catCount');

  const { mutate, data, isLoading } = useMutation({
    mutationKey: ['ansiraForm'],
    mutationFn: ansiraOfferPost,
    onSuccess: response => {
      if (!response?.response.success) {
        // eslint-disable-next-line no-console
        console.error('Error: ', response);
        return;
      }

      // The API can return a 200 without actually completing the request for the coupon
      if (!response.response.coupon_request) {
        submitAnalyticsForIncompleteResponse({ response });

        return;
      }

      // !IMPORTANT: This event is responsible for triggering down streams
      // that grant users loyalty points
      const uuid = response?.response?.user?.uuid;
      const submitAnalyticsForm = {
        event: 'form_complete',
        eventParams: {
          form_id: referrer,
          form_name: `offers ${title}`,
          form_brand: brand ?? '',
          form_type: 'offer_form',
          form_version: 'v2',
          form_parent: 'purina',
        },
        up: uuid ? { user_id: uuid } : undefined,
      };
      sendIt(submitAnalyticsForm);
      reset();
    },
  });

  const petCountOptions = [
    { value: '0', label: '0' },
    { value: '1', label: '1' },
    { value: '2', label: '2' },
    { value: '3', label: '3' },
    { value: '4', label: '4' },
    { value: '5', label: '5' },
  ];

  const onSubmit: SubmitHandler<OfferFormTypes> = async formData => {
    const RecaptchaToken = await getRecaptchaToken();
    const newData = { ...formData, RecaptchaToken };
    mutate({ data: newData, offerId });

    //  GA Event ---------------------------------
    if (navigator.onLine) {
      const submitAnalyticsForm = {
        event: 'sign_up',
        eventCategory: 'ansira_coupon_form',
        eventParams: {
          form_type: 'ansira_coupon_form',
          form_id: referrer,
          form_name: referrer,
          method: 'on_page',
          email_optin: newData.optIn.toString(),
        },
      };
      sendIt(submitAnalyticsForm);
    }
  };

  //  GA Error Event ---------------------------------
  const onError: SubmitErrorHandler<OfferFormTypes> = validationErrors => {
    const errorsArray = Object.entries(validationErrors);
    (errorsArray as [string, FieldError][]).forEach(([key, error]) => {
      const sendErrorToGA = ([errorKey, fieldError]: [string, FieldError]) => {
        const { message, type } = fieldError;
        const submitAnalyticsErrorForm = {
          event: 'error_occured',
          eventCategory: 'ansira_coupon_form_error',
          eventParams: {
            form_type: 'ansira_coupon_form',
            form_id: referrer,
            form_name: referrer,
            error_code: type?.toString() ?? 'ERROR',
            error_name: errorKey,
            module_name: errorKey,
            error_feature: message as string,
          },
        };
        sendIt(submitAnalyticsErrorForm);
      };
      sendErrorToGA([key, error]);
    });
  };

  if (isLoading) {
    return (
      <Row className="justify-content-center">
        <Col className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Submitting')}...</span>
          </Spinner>
          <span className="ms-3">{t('Please Wait')}...</span>
        </Col>
      </Row>
    );
  }

  if (data?.response.success && !data.response.coupon_request) {
    return (
      <Row>
        <Col className="text-center ml-4">
          <Icon path={mdiAlertCircleOutline} size={5} color="#2553F8" aria-hidden />
          <h2 className="typography-h2 mt-5 mb-5">{t('Oops!... Something went wrong.')}</h2>

          <SubmittedText>
            {t("Looks like our wires got crossed. Please check your pets' profiles and try again.")}
          </SubmittedText>
        </Col>
      </Row>
    );
  }

  if (data?.response.success) {
    return (
      <Row>
        <Col className="text-center">
          <h2>{successTitle}</h2>
          <SubmittedText>{successMessage && <RichText body={successMessage} />}</SubmittedText>
        </Col>
      </Row>
    );
  }

  if (data && !data?.response.success) {
    const getMessage = () => {
      if (data?.response?.error_code === '1007') {
        return {
          heading: t('Duplicate Email'),
          text: t(
            "This email is already registered for this offer. Look for the coupon in your inbox, and don't forget to check your spam or junk folders.",
          ),
        };
      }
      if (data?.response?.error_code === '1012') {
        return {
          heading: t('No Offers'),
          text: t(
            'There are currently no offers available for this product. Please check back later for new offers.',
          ),
        };
      }

      if (!data?.response?.error_code) {
        return {
          heading: t('Error'),
          text: t(
            "Something went wrong. Please resubmit your information, and we'll try again. If you continue to experience issues, please contact us.",
          ),
        };
      }

      return {
        heading: t('Error'),
        text: data?.response.message,
      };
    };
    return (
      <Row>
        <Col className="text-center ml-4">
          <Icon path={mdiAlertCircleOutline} size={5} color="#2553F8" aria-hidden />
          <h2 className="typography-h2 mt-5 mb-5">{getMessage().heading}</h2>
          <SubmittedText>{getMessage().text}</SubmittedText>
        </Col>
      </Row>
    );
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit, onError)} autoComplete="off" noValidate>
        <Row className="gx-3 gy-3">
          <span className="text-danger mb-4 mt-0 font-weight-light">*{t('Required Field')}</span>
          <Col xs={12} md={6}>
            <input type="hidden" aria-hidden value={referrer} {...register('referrer')} />
            <InputField
              name="firstName"
              label={t('First Name')}
              placeholder={t('First Name')}
              maxLength={60}
              required
              autoComplete="given-name"
            />
          </Col>
          <Col xs={12} md={6}>
            <InputField
              name="lastName"
              label={t('Last Name')}
              placeholder={t('Last Name')}
              maxLength={60}
              required
              autoComplete="family-name"
            />
          </Col>
          <Col xs={12} md={formType === 'long_address' ? 12 : 8}>
            <InputField
              name="email"
              type="email"
              label={t('E-mail Address')}
              placeholder={t('example@mail.com')}
              maxLength={128}
              required
              autoComplete="email"
            />
          </Col>
          <AddressSection
            register={register}
            errors={errors}
            isLongAddress={formType === 'long_address'}
          />
          <fieldset className="d-flex flex-column gap-3">
            <legend className="typeography_h4 mt-4 mb-2">{t('My Pets')}</legend>
            <div className="d-flex flex-column flex-md-row gap-4">
              <Col xs={12} md={4}>
                <Form.Group controlId="dogCount">
                  <Form.Label className={requiredClass}>{t('Dogs')}</Form.Label>
                  <Form.Select
                    {...register('dogCount')}
                    isInvalid={!!errors?.dogCount}
                    aria-invalid={!!errors?.dogCount}
                    placeholder={t('Dog count')}
                    defaultValue={0}
                    required
                    aria-describedby="dogCount-message"
                  >
                    {petCountOptions.map(option => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" id="dogCount-message">
                    {errors.dogCount && errors.dogCount.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="catCount">
                  <Form.Label className={requiredClass}>{t('Cats')}</Form.Label>
                  <Form.Select
                    {...register('catCount')}
                    isInvalid={!!errors?.catCount}
                    aria-invalid={!!errors?.catCount}
                    placeholder={t('Cat count')}
                    defaultValue={0}
                    required
                    aria-describedby="catCount-message"
                  >
                    {petCountOptions.map(option => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid" id="catCount-message">
                    {errors.catCount && errors.catCount.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </div>
            {(formType === 'long_address' || formType === 'long') && (
              <DynamicPetArrayField
                key="pets"
                catCount={Number(catCount)}
                dogCount={Number(dogCount)}
              />
            )}
          </fieldset>
          {optinLabel ? (
            <Col xs={12}>
              <Form.Group controlId="optIn">
                <Form.Check
                  typography-h2
                  type="checkbox"
                  label={t(
                    'I agree to receiving email and other market communications from Purina and its brand.',
                  )}
                >
                  <Form.Check.Input
                    isInvalid={!!errors?.optIn}
                    aria-invalid={!!errors?.optIn}
                    type="checkbox"
                    {...register('optIn')}
                    required
                    aria-describedby="optIn-message"
                  />
                  <Form.Check.Label>
                    {optinLabel && <RichText body={optinLabel} />}
                  </Form.Check.Label>
                  <Form.Control.Feedback type="invalid" id="optIn-message">
                    {errors.optIn && (optinError || errors.optIn)}
                  </Form.Control.Feedback>
                </Form.Check>
              </Form.Group>
            </Col>
          ) : null}
          <Col xs={12}>
            {legalText && <RichText body={legalText} />}
            <RecaptchaDisclaimer />
          </Col>
          <Col xs={12} lg={4}>
            <Button
              className="rounded-pill justify-content-center mt-3 w-100"
              type="submit"
              size="sm"
              style={{ padding: '.5rem 3rem' }}
              disabled={isLoading}
            >
              {isLoading ? `${t('Please wait')}...` : ctaLabel ?? t('Get Rebate')}
            </Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default OfferForm;
