import { css } from '@linaria/core';
import Icon from '@mdi/react';
import { intlFormat } from 'date-fns';
import { PageProps, graphql } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ProductCard from 'src/components/product_card/ProductCard';
import { formatBazaarVoiceId, formatSku } from 'src/utils/helpers';
import Layout from '../components/Layout';
import Link from '../components/Link';
import Seo from '../components/Seo';
import ArticleOverlayCard from '../components/article_card/ArticleOverlayCard';
import RichText from '../components/rich_text/RichText';
import { theme } from '../theme/theme';
import { getFocalPoint, getMedia } from '../utils/mediaHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';
import { DropdownSocialShare } from './components/DropdownSocialShare';
import { mdiCalendar } from 'src/assets/icons/mdiIcons';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';

interface IRelatedArticleCards {
  edges: {
    node: IArticleCard;
  }[];
}

interface DataProps {
  node: IArticleContent;
  relatedArticles: IRelatedArticleCards;
  backfillArticles: IRelatedArticleCards;
  translations: Translations;
}

const relatedLayout = css`
  display: grid;
  gap: 1rem;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media (min-width: ${theme.media.md}) {
    grid-template-columns: 1fr 1fr;

    & > :first-child {
      grid-column: 1 / 3;
      grid-row: 1;
    }
  }

  @media (min-width: 960px) {
    grid-template-columns: 66% 1fr;
    grid-template-rows: auto;
    grid-auto-flow: column;

    & > :first-child {
      grid-column: 1;
      grid-row: 1 / 3;

      & > * {
        height: 100%;
      }
    }
  }
`;

const DotSeparator = () => {
  return (
    <span
      className="pds-text-display-sm-legacy pds-mx-3 pds-hidden pds-leading-3 md:pds-block"
      aria-hidden="true"
    >
      &#183;
    </span>
  );
};

const locales = { en: 'en-us', es: 'es' };

const domain = process.env.GATSBY_SITE_URL;

const getArticleCardProps = (node: IArticleCard) => {
  const { title, drupal_id } = node;
  const summary = node.body.summary ?? undefined;
  const url = node.path?.alias;

  const image = node.relationships.field_image?.relationships.field_media_image?.gatsbyImage;
  const imageField = node.relationships.field_image;
  const imageAlt = node.relationships.field_image?.field_media_image.alt ?? '';
  const promoted = node.sticky;

  return {
    title,
    type: 'article_card',
    name: '',
    drupal_id,
    url,
    summary,
    imageComponent: image ? (
      <GatsbyImage
        alt={imageAlt}
        image={image}
        imgStyle={{
          objectFit: 'cover',
        }}
        objectPosition={getFocalPoint(imageField)}
      />
    ) : (
      <StaticImage
        aspectRatio={1.5}
        src="../assets/media/images/cat-dog-article-placeholder-2.jpg"
        alt="A cute dog & a cute cat."
      />
    ),
    promoted,
  };
};

const getArticleMedia = (node: IArticleContent, mediaType: string) => {
  let media = null;
  switch (mediaType) {
    case 'media__remote_video':
    case 'media__infographic':
      media = {
        media: node.relationships.field_media,
      };
      break;
    default:
      media = {
        media: node.relationships.field_image,
      };
      break;
  }

  return getMedia(media);
};

interface Tag {
  href: string;
  label: string;
}

function getTagLinks(tags: IFieldTag[]): Tag[] {
  return tags
    .map(tag => {
      const pageDelegatePath = tag.relationships?.field_page_delegate?.path?.alias ?? null;
      if (!pageDelegatePath) {
        return null;
      }

      return {
        href: pageDelegatePath,
        label: tag?.name ?? '',
      };
    })
    .filter(tag => tag !== null) as Tag[];
}

const ArticleTemplate: React.FC<PageProps<DataProps>> = function ({
  data: { node, relatedArticles, backfillArticles, translations },
}) {
  const { t } = useTranslation();
  const { field_media, field_tags = [], relatedProducts = [] } = node.relationships;
  const tags = getTagLinks(field_tags);
  const body = node.body?.processed ?? '';
  const date = intlFormat(
    new Date(node.changed),
    { day: 'numeric', year: 'numeric', month: 'numeric' },
    { locale: locales[node.langcode] },
  );
  const mediaType: string = field_media?.internal.type || 'media__image';
  const attachedMedia = getArticleMedia(node, mediaType);
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const absoluteUrl = `${domain}${node.path?.alias}`;
  let { edges: related } = relatedArticles;
  const { edges: backfill } = backfillArticles;
  if (related.length < 3) {
    related = [...related, ...backfill].slice(0, 3);
  }
  const hasRelatedProducts = relatedProducts?.length > 0;

  const author = node.relationships.field_author;
  const readTime = node.relationships?.field_read_time;
  const bodyText = t(
    'I just read this on Purina.com and I thought you might be interested:\n {{ title }}, {{ absoluteUrl }}',
    { title: node.title, absoluteUrl },
  );

  const RenderDropdownSocialShare = (
    <DropdownSocialShare
      label={t('Share this article')}
      shareUrl={absoluteUrl}
      shareTitle={node.title}
      emailSubjectLine={t('Thought you might want to check this out...')}
      bodyText={bodyText}
      classNames={{
        menu: 'pds-bg-surface pds-shadow-stronger',
      }}
      articleType={node?.relationships?.field_tags?.[0]?.name || '(not set)'}
    />
  );

  /** Add Listener for inline link analytics on articles.
   * TODO: This can probably be removed once all analytics points are removed and new listener logic is added to the
   * analytics plugin
   */
  const linkAnalytics = (e: any) => {
    const target = e.target as any;
    const anchor = target?.matches(['a[href]']) ? target : target.closest('.rich-article a[href]');
    if (anchor) {
      sendIt({
        event: 'select_content',
        eventParams: {
          content_name: `article: ${anchor?.innerText}`,
          content_type: 'inline_link',
          cta_location: 'article',
          link_url: anchor.href.replace('https://', ''),
        },
      });
    }
  };

  React.useEffect(() => {
    window.addEventListener('click', linkAnalytics);
    return () => window.removeEventListener('click', linkAnalytics);
  }, []);

  const renderRelatedProducts = () => {
    return (
      <Col
        md={3}
        sm={12}
        className="pds-flex pds-h-fit pds-flex-col pds-justify-center pds-gap-5 pds-pb-4 max-sm:!pds-mt-5"
      >
        {relatedProducts.map(product => {
          const url = product.field_alternate_url?.uri
            ? product.field_alternate_url.uri
            : product.path.alias;
          const productImage = product.relationships?.image?.gatsbyImage;
          productImage.width = 334;
          productImage.height = 304;
          const bvId = formatBazaarVoiceId(product?.field_bazaarvoice_id);
          return (
            <ProductCard
              drupalId={`${product.drupal_id}`}
              title={product.title}
              bvID={bvId}
              image={productImage}
              link={url}
              sku={formatSku(product.relationships.skus?.[0]?.upc ?? '')}
              type="products"
              outlinedCta
            />
          );
        })}
      </Col>
    );
  };

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      <AnalyticsPoint type="module" label="Article title section" typeLabel="article_title_section">
        <Container
          fluid
          className="pds-py-5.5"
          style={{
            background: 'linear-gradient(180deg, #EDF0F8 0%, #EDF0F8 75%, rgba(0,0,0,0) 75%)',
          }}
        >
          <Row className="pds-text-center md:pds-justify-center">
            <Col>
              <div className="pds-mb-5 pds-flex pds-flex-wrap pds-justify-center pds-gap-2 md:pds-gap-4">
                {tags.map((tag, index: number) => (
                  <AnalyticsPoint
                    key={`tag-${index}`}
                    type="component"
                    label="article category"
                    typeLabel="article_category"
                    className="pds-flex"
                  >
                    <Link
                      className="pds-text-body-sm-legacy pds-min-w-[50px] pds-rounded pds-border pds-border-solid pds-border-[#b6bed2] pds-bg-white pds-px-4 pds-py-3 pds-font-medium pds-uppercase pds-tracking-widest pds-text-black pds-no-underline hover:pds-border-black hover:pds-text-black md:pds-text-body-md"
                      to={tag?.href || '/'}
                    >
                      {tag.label}
                    </Link>
                  </AnalyticsPoint>
                ))}
              </div>
            </Col>
          </Row>
          <Row className="pds-mb-5.5 pds-text-center md:pds-justify-center">
            <Col xs={12} md={8}>
              <h1 className="pds-font-light" style={{ textWrap: 'balance' }}>
                {node.title}
              </h1>
              <div className="pds-mt-4.5 pds-flex pds-justify-center pds-gap-3">
                {author && (
                  <GatsbyImage
                    imgClassName="pds-rounded-circle"
                    className="pds-h-[50px] pds-w-[50px] md:pds-h-[70px] md:pds-w-[70px]"
                    image={author.relationships.image.gatsbyImage}
                    alt={author.image?.alt ?? ''}
                    objectFit="contain"
                  />
                )}
                <div className="pds-text-body-[14px] pds-space-y-3 pds-text-left md:pds-text-body-md">
                  {author ? (
                    <Link to={author.path.alias}>
                      <span className="pds-text-body-[14px] pds-text-left md:pds-text-body-md">
                        {t('By')} {author.name}
                      </span>
                    </Link>
                  ) : null}
                  <div className="pds-flex pds-flex-col pds-gap-2 md:pds-flex-row md:pds-items-center">
                    <span className="pds-flex pds-max-w-fit pds-items-center pds-justify-center pds-gap-3">
                      <Icon className="pds-hidden sm:pds-block" path={mdiCalendar} size={0.8} />
                      {t('Updated')}: {date}
                    </span>
                    <DotSeparator />
                    {readTime && (
                      <>
                        <span>{readTime.label}</span>
                        <DotSeparator />
                      </>
                    )}
                    <span className="-pds-ml-[10px]">{RenderDropdownSocialShare}</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="pds-justify-center">
            <Col xs={12} md={10} lg={6} className="pds-text-center">
              {attachedMedia}
            </Col>
          </Row>
        </Container>
      </AnalyticsPoint>
      <Container fluid="lg" className="pds-mb-5.5">
        <Container fluid className="pds-mt-5.5">
          <Row className="md:pds-justify-around">
            <Col md={hasRelatedProducts ? 8 : 12} sm={12} className="pds-pb-4">
              <RichText isArticle body={body} />
              <div className="-pds-ml-[10px]">{RenderDropdownSocialShare}</div>
            </Col>
            {hasRelatedProducts ? renderRelatedProducts() : null}
          </Row>
        </Container>
      </Container>
      {related.length === 3 && (
        <AnalyticsPoint
          type="module"
          typeLabel="article_related_articles"
          label="related articles"
          as={Container}
          fluid
          style={{ background: '#F3F7FF', paddingBlock: '7rem' }}
        >
          <Container>
            <h2 className="h3 fw-light pds-mb-5.5">{t('Related articles')}</h2>
            <div className={relatedLayout}>
              {related.map(({ node: articleNode }, index: number) => {
                return (
                  <div key={`${index}-${articleNode.id}`}>
                    <ArticleOverlayCard
                      {...getArticleCardProps(articleNode)}
                      condensed={index !== 0}
                    />
                  </div>
                );
              })}
            </div>
          </Container>
        </AnalyticsPoint>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!, $species: Int, $tags: [Int]) {
    node: nodeArticle(id: { eq: $id }, langcode: { eq: $language }) {
      ...ArticlePage
    }
    translations: allNodeArticle(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    relatedArticles: allNodeArticle(
      limit: 3
      sort: [{ sticky: DESC }, { created: DESC }]
      filter: {
        field_tags: { elemMatch: { drupal_internal__target_id: { in: $tags } } }
        id: { ne: $id }
        langcode: { eq: $language }
      }
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
    backfillArticles: allNodeArticle(
      limit: 3
      filter: {
        field_species_term: { drupal_internal__target_id: { eq: $species } }
        id: { ne: $id }
        langcode: { eq: $language }
      }
    ) {
      edges {
        node {
          ...ArticleCard
        }
      }
    }
  }
`;

export default ArticleTemplate;
