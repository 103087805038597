import Icon from '@mdi/react';
import { IInput, IMaskInput, Input, InputWithMask } from '@purinanbm/pds-ui';
import React from 'react';
import { Control, useController } from 'react-hook-form';
import { mdiInformationOutline } from 'src/assets/icons/mdiIcons';

type Props = Omit<IInput, 'name'> & {
  required?: boolean;
  name: string;
  control?: Control<any>;
  // !TODO: Add Tooltip tooltip?: React.ReactElement;
  maxLength?: number;
  autoComplete?: string;
};
type MaskProps = Omit<IMaskInput, 'name'> & {
  required?: boolean;
  name: string;
  control?: Control<any>;
  // !TODO: Add Tooltip tooltip?: React.ReactElement;
  maxLength?: number;
  autoComplete?: string;
};

export const InputFieldWithMask = ({
  required = false,
  control,
  label,
  name,
  ...rest
}: MaskProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div className="pds-flex pds-flex-col">
      <InputWithMask
        label={label}
        id={name}
        labelProps={{ className: 'has-[~_input[aria-required=true]]:after:pds-content-required' }}
        className="pds-shadow-surface-text"
        aria-invalid={!!error}
        data-error={!!error}
        aria-required={required}
        {...field}
        type="text"
        aria-describedby={`${name}-message`}
        {...rest}
      />
      {error && (
        <span className="pds-mt-2 pds-block pds-text-error" id={`${name}-message`}>
          <Icon className="pds-rotate-180" path={mdiInformationOutline} size={1} />{' '}
          {error?.message?.toString()}
        </span>
      )}
    </div>
  );
};

const InputField = ({ required = false, control, label, name, ...rest }: Props) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });

  return (
    <div className="pds-flex pds-flex-col">
      <Input
        label={label}
        id={name}
        labelProps={{ className: 'has-[~_input[aria-required=true]]:after:pds-content-required' }}
        className="pds-shadow-surface-text"
        aria-invalid={!!error}
        data-error={!!error}
        aria-required={required}
        {...field}
        type="text"
        aria-describedby={`${name}-message`}
        {...rest}
      />
      {error && (
        <span className="pds-mt-2 pds-block pds-text-error" id={`${name}-message`}>
          <Icon className="pds-rotate-180" path={mdiInformationOutline} size={1} />{' '}
          {error?.message?.toString()}
        </span>
      )}
    </div>
  );
};

export default InputField;
