import { createContext } from 'react';
import { BrazeCardPayload } from 'src/hooks/useBraze';

export interface ISearchListingContext {
  id: string;
  data?: DrupalSearch;
  loaded: boolean;
  error: Error | null;
  facetModalActive: boolean;
  brazeCards: BrazeCardPayload[];
  setFacetModalActive: React.Dispatch<React.SetStateAction<boolean>>;
  setLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SearchListingContext = createContext<ISearchListingContext | undefined>(undefined);
