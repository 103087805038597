import { profileAuth } from '@purinanbm/pup-script';
import { PROFILE_CONFIG } from 'src/common/constants';

export const ServicesAuthWrapper = <
  T extends { new (...args: any[]): { setSecurityData: (data: { token: string }) => void } },
>(
  BaseClass: T,
) => {
  return class extends BaseClass {
    private token: string | null = null;

    private refreshTokenInterval: NodeJS.Timeout | null = null;

    constructor(...args: any[]) {
      super(...args);
      this.refreshTokenInterval = null;
      // Setting token during build was throwing error since there was no user
      // this.setToken();
    }

    async setToken() {
      try {
        if (!this.token) {
          const session = (await profileAuth(PROFILE_CONFIG).getSession()).rawSession;
          this.token = session.getAccessToken().getJwtToken();
          this.setSecurityData({ token: this.token });

          this.startTokenRefresh(session.getAccessToken().getExpiration());
        } else {
          await this.refreshToken();
        }
      } catch (error) {
        console.error('Error setting token:', error);
        throw error;
      }
    }

    async refreshToken() {
      try {
        const session = (await profileAuth(PROFILE_CONFIG).getSession()).rawSession;
        this.token = session.getAccessToken().getJwtToken();
        this.setSecurityData({ token: this.token });
        this.stopTokenRefresh();
        this.startTokenRefresh(session.getAccessToken().getExpiration());
        // console.log('Token refreshed:', this.token);
      } catch (error) {
        console.error('Error refreshing token:', error);
        throw error;
      }
    }

    startTokenRefresh(expiration: number) {
      const expiryTime = expiration - Date.now() / 1000;
      this.refreshTokenInterval = setTimeout(
        async () => {
          await this.refreshToken();
        },
        (expiryTime - 60) * 1000,
      );
    }

    stopTokenRefresh() {
      if (this.refreshTokenInterval) {
        clearTimeout(this.refreshTokenInterval);
        this.refreshTokenInterval = null;
      }
    }
  };
};
