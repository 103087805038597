import Icon from '@mdi/react';
import { Button } from '@purinanbm/pds-ui';
import { useLocation } from '@reach/router';
import { differenceInMonths, startOfDay } from 'date-fns';
import { Link as GatsbyLink } from 'gatsby';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { mdiPlusOutline } from 'src/assets/icons/mdiIcons';
import pointsBadgeSvg from 'src/assets/icons/pointsBadge.svg';
import squareCheckSvg from 'src/assets/icons/squareCheck.svg';
import squareXSvg from 'src/assets/icons/squareX.svg';

import Pager from 'src/components/pager/Pager';
import { PURINA_PERKS_ROUTES } from '../PurinaPerksLayout';
import { Receipt } from './Receipt';

type Props = {
  receipts: Receipt[];
  type: 'approved' | 'rejected';
};

const ITEMS_PER_PAGE = 10;

const formatSubmittedTime = (submittedTime: string, onlyDate?: boolean): string => {
  const date = new Date(submittedTime);

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  };

  const optionsOnlyDate: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return date.toLocaleString('en-US', onlyDate ? optionsOnlyDate : options);
};

const calculatePageIndex = (queryParams: string, receipts: Receipt[]): number => {
  const page = new URLSearchParams(queryParams).get('page');
  const pageIndex = page ? parseInt(page, 10) - 1 : 0;
  const isIndexOutOfBounds = pageIndex * ITEMS_PER_PAGE > receipts.length;
  return isIndexOutOfBounds ? 0 : pageIndex;
};

const sliceReceipts = (queryParams: string, receipts: Receipt[]) => {
  const pageIndex = calculatePageIndex(queryParams, receipts);
  return receipts.slice(pageIndex * ITEMS_PER_PAGE, (pageIndex + 1) * ITEMS_PER_PAGE);
};

const ReceiptHistoryPoints = (props: Receipt) => {
  return (
    <div className="pds-relative pds-flex pds-items-center pds-gap-2 pds-rounded-pill pds-bg-white pds-px-[0.75rem] pds-text-body-sm pds-font-semibold md:pds-w-max">
      <img src={pointsBadgeSvg} className="pds-scale-125" alt="Perks points" />
      +&nbsp;{props.totalPoints}&nbsp;pts
    </div>
  );
};

const ReceiptEmptyStateCard: React.FC<{ type: 'approved' | 'rejected' }> = ({ type }) => {
  return (
    <div className="pds-flex pds-w-full pds-flex-col pds-items-center pds-gap-4.5 pds-rounded pds-bg-surface-layer pds-px-3 pds-py-5 pds-text-center">
      <span className="pds-d-block">You have not uploaded any {type} receipts yet.</span>
      <Button
        buttonColor="neutral"
        size="medium"
        buttonStyle="solid"
        className="pds-flex pds-w-[200px] pds-justify-center pds-gap-3 pds-border-none pds-px-4 pds-font-semibold pds-no-underline"
        // TS error is because "GatsbyLink" is not a defined option for the the "as" property
        // @ts-ignore
        as={GatsbyLink}
        to={PURINA_PERKS_ROUTES.ReceiptUpload}
      >
        <Icon path={mdiPlusOutline} size={1} aria-hidden />
        Submit a Receipt
      </Button>
    </div>
  );
};

const ReceiptHistoryRejectedCard: React.FC<{ receipt: Receipt }> = ({ receipt }) => {
  const time = formatSubmittedTime(receipt.submittedTime);
  return (
    <li className="pds-flex pds-list-none pds-gap-4 pds-rounded pds-bg-surface-layer pds-p-4">
      <div className="pds-flex pds-shrink-0 pds-items-center pds-justify-between lg:pds-w-fit">
        <img
          src={squareXSvg}
          alt=""
          className="pds-h-[28px] pds-w-[28px] lg:pds-h-[40px] lg:pds-w-[40px]"
        />
      </div>

      <div className="pds-flex pds-flex-col pds-gap-2">
        <div>
          <span>Uploaded on &#32;</span>
          <span className="pds-inline-block pds-font-semibold">{time}</span>
        </div>

        <div>{receipt.note}</div>
      </div>
    </li>
  );
};

const ReceiptHistoryApprovedCard: React.FC<{ receipt: Receipt }> = ({ receipt }) => {
  const { enableReceiptPurchaseLanguage } = useFlags();
  const time = formatSubmittedTime(receipt.submittedTime, enableReceiptPurchaseLanguage);
  return (
    <li className="pds-flex pds-list-none pds-flex-col pds-items-center pds-gap-4 pds-rounded pds-bg-surface-layer pds-p-4 lg:pds-flex-row">
      <div className="pds-flex pds-w-full pds-shrink-0 pds-items-center pds-justify-between lg:pds-w-fit">
        <img
          src={squareCheckSvg}
          alt=""
          className="pds-h-[28px] pds-w-[28px] lg:pds-h-[40px] lg:pds-w-[40px]"
        />

        <div className="lg:pds-hidden">
          <ReceiptHistoryPoints {...receipt} />
        </div>
      </div>

      <div className="pds-flex pds-w-full pds-flex-col pds-gap-2">
        <div>
          {enableReceiptPurchaseLanguage ? (
            <span>Purchased &#32;</span>
          ) : (
            <span>Uploaded &#32;</span>
          )}
          <span>on &#32;</span>
          <span className="pds-inline-block pds-font-semibold">{time}</span>
        </div>

        <div>Receipt approved.</div>
      </div>

      <div className="pds-hidden lg:pds-block ">
        <ReceiptHistoryPoints {...receipt} />
      </div>
    </li>
  );
};

const ReceiptList: React.FC<Props> = props => {
  const filteredReceipts = useMemo(
    () =>
      props.receipts
        ? props.receipts
            .filter(r => r.status === props.type)
            .filter(r => differenceInMonths(startOfDay(new Date()), new Date(r.submittedTime)) < 12)
            .sort(
              (a, b) => new Date(b.submittedTime).getTime() - new Date(a.submittedTime).getTime(),
            )
        : [],
    [props.receipts, props.type],
  );

  const containerRef = useRef<HTMLUListElement>(null);

  const location = useLocation();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [receiptsSlice, setReceiptSlice] = useState<Receipt[]>(
    sliceReceipts(location.search, filteredReceipts),
  );

  useEffect(() => {
    const pageIndex = calculatePageIndex(location.search, filteredReceipts);
    const receiptSlice = sliceReceipts(location.search, filteredReceipts);
    setCurrentPageIndex(pageIndex);
    setReceiptSlice(receiptSlice);
  }, [location.search, filteredReceipts]);

  const pager: SearchPager = {
    current_page: currentPageIndex,
    total_items: filteredReceipts.length,
    total_pages: Math.ceil(filteredReceipts.length / ITEMS_PER_PAGE),
    items_per_page: ITEMS_PER_PAGE,
    container_ref: containerRef,
  };

  if (filteredReceipts.length === 0) {
    return <ReceiptEmptyStateCard type={props.type} />;
  }

  return (
    <>
      <ul
        className="pds-mb-5 pds-flex pds-flex-col pds-gap-3 pds-p-0"
        ref={containerRef}
        tabIndex={-1}
        aria-label={`${props.type} receipts`}
      >
        {receiptsSlice.map(receipt => {
          switch (props.type) {
            case 'approved':
              return <ReceiptHistoryApprovedCard receipt={receipt} key={receipt.receiptId} />;
            case 'rejected':
              return <ReceiptHistoryRejectedCard receipt={receipt} key={receipt.receiptId} />;
            default:
              return null;
          }
        })}
      </ul>

      <div className="pds-flex pds-justify-center">
        <Pager pager={pager} />
      </div>
    </>
  );
};

export default ReceiptList;
