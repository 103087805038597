import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { OfferFormTypes } from '../utils/offer';
import { requiredClass } from './InputField';

interface Props {
  dogCount: number;
  catCount: number;
}

type PetFieldProps = {
  petType: 'cat' | 'dog';
  index: number;
};

const PetField = ({ petType, index }: PetFieldProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<OfferFormTypes>();
  const { t } = useTranslation();
  const [genderOptions] = React.useState([
    { value: '', label: t('- Select a gender -') },
    { value: 'male', label: t('Male') },
    { value: 'female', label: t('Female') },
  ]);

  const [sizeOptions] = React.useState([
    { value: '', label: t('- Select a size -') },
    { value: 'toy', label: t('Toy: 0-11 lbs') },
    { value: 'small', label: t('Small: 12-22 lbs') },
    { value: 'medium', label: t('Medium: 23-55 lbs') },
    { value: 'large', label: t('Large: 56-99 lbs') },
    { value: 'giant', label: t('Giant: 100+ lbs') },
  ]);
  const idName = `${petType}Pets.${index}.name` as const;
  const idGender = `${petType}Pets.${index}.gender` as const;
  const idBirthDate = `${petType}Pets.${index}.birthDate` as const;
  const idSize = `dogPets.${index}.size` as const;
  const errorsObject = errors?.[`${petType}Pets`]?.[index];
  const hasSize = petType === 'dog';
  const columns = {
    xs: 12,
    md: 4,
    lg: 6,
  };
  return (
    <Col as="fieldset" xs={12}>
      <legend className="typography_h5">
        {/* i18next-extract-mark-context-next-line ["cat", "dog"] */}
        {t('petType', {
          context: petType,
        })}
        &nbsp;#{index + 1}
      </legend>
      <Row className="gy-3">
        <Col {...columns}>
          <Form.Group controlId={idName}>
            <Form.Label className={requiredClass}>{t('Name')}</Form.Label>
            <Form.Control
              {...register(idName, {
                shouldUnregister: true,
              })}
              isInvalid={!!errorsObject?.name}
              aria-invalid={!!errorsObject?.name}
              // i18next-extract-mark-context-next-line ["cat", "dog"]
              placeholder={t('petTypeName', { context: petType })}
              required
              aria-describedby={`${idName}-message`}
            />
            <Form.Control.Feedback type="invalid" id={`${idName}-message`}>
              {errorsObject?.name && errorsObject.name.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col {...columns}>
          <Form.Group controlId={idGender}>
            <Form.Label className={requiredClass}>{t('Gender')}</Form.Label>
            <Form.Select
              {...register(idGender, {
                shouldUnregister: true,
              })}
              isInvalid={!!errorsObject?.gender}
              aria-invalid={!!errorsObject?.gender}
              required
              aria-describedby={`${idGender}-message`}
            >
              {genderOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
            <Form.Control.Feedback type="invalid" id={`${idGender}-message`}>
              {errorsObject?.gender && errorsObject?.gender.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        {hasSize && (
          <Col {...columns}>
            <Form.Group controlId={idSize}>
              <Form.Label className={requiredClass}>{t('Size')}</Form.Label>
              <Form.Select
                {...register(idSize, {
                  shouldUnregister: true,
                })}
                isInvalid={!!errorsObject?.size}
                aria-invalid={!!errorsObject?.size}
                required
                aria-describedby={`${idSize}-message`}
              >
                {sizeOptions.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Form.Select>
              <Form.Control.Feedback type="invalid" id={`${idSize}-message`}>
                {errorsObject?.size && errorsObject?.size.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        )}
        <Col {...columns}>
          <Form.Group controlId={idBirthDate}>
            <Form.Label className={requiredClass}>{t('Birthday')}</Form.Label>
            <Form.Control
              {...register(idBirthDate, { shouldUnregister: true })}
              isInvalid={!!errorsObject?.birthDate}
              aria-invalid={!!errorsObject?.birthDate}
              required
              type="date"
              aria-describedby={`${idBirthDate}-message`}
            />
            <Form.Control.Feedback type="invalid" id={`${idBirthDate}-message`}>
              {errorsObject?.birthDate && errorsObject?.birthDate.message}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </Col>
  );
};

const DynamicPetArrayField = ({ dogCount, catCount }: Props) => {
  const { control } = useFormContext<OfferFormTypes>();
  useFieldArray({ control, name: 'dogPets', shouldUnregister: true });
  useFieldArray({ control, name: 'catPets', shouldUnregister: true });

  const dogFields = Array.from({ length: dogCount }).map((_, index) => (
    <PetField key={`dogPets${index}`} petType="dog" index={index} />
  ));
  const catFields = Array.from({ length: catCount }).map((_, index) => (
    <PetField key={`catPets${index}`} petType="cat" index={index} />
  ));

  return (
    <>
      {dogFields}
      {catFields}
    </>
  );
};

export default DynamicPetArrayField;
