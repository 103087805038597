import { styled } from '@linaria/react';
import React, { useState } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { TextButton } from '../common/TextButton';
import OfferForm from '../forms/OfferForm';

const BannerContainer = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  background: #bed0f8;
`;

interface Props {
  offer: Offer[];
}

const ProductOfferBanner: React.FC<Props> = ({ offer }) => {
  const [offerIsOpen, setOfferIsOpen] = useState(false);
  const currentOffer = offer[0];

  const formProps = {
    referrer: currentOffer.referrerCode,
    offerId: currentOffer.offerId,
    formType: currentOffer.field_form_type,
    successMessage: currentOffer.settings.success_response,
    successTitle: currentOffer.settings.success_title,
    optinLabel: currentOffer.settings.optin_label,
    optinError: currentOffer.settings.optin_error,
    ctaLabel: currentOffer.field_offer_cta,
  };

  if (typeof currentOffer === 'undefined') {
    return null;
  }
  return (
    <>
      <BannerContainer>
        <TextButton textColor="#1D3873" type="button" onClick={() => setOfferIsOpen(true)}>
          {'Offer or rebate for this product ->'}
        </TextButton>
      </BannerContainer>
      <Modal
        size="lg"
        fullscreen="lg-down"
        contentClassName="overflow-auto"
        show={offerIsOpen}
        onHide={() => setOfferIsOpen(false)}
      >
        <div className="p-3 p-md-5">
          <Modal.Header closeButton className="border-0">
            <h2 className="me-2" id="offerBannerDialogBanner">
              {currentOffer.title}
            </h2>
          </Modal.Header>
          <ModalBody>
            <OfferForm {...formProps} />
          </ModalBody>
        </div>
      </Modal>
    </>
  );
};
export default ProductOfferBanner;
