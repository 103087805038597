import differenceInDays from 'date-fns/differenceInDays';
import { PRODUCT_STATUS } from '../../common/enums';

export const getProductStatus = (
  status: TaxonomyTermStatus,
  statusLink: LinkProps,
  createdDate: Date
) => {
  const todayDate = new Date();
  const publishedDate = new Date(createdDate);
  const isNew = differenceInDays(todayDate, publishedDate) <= 180;
  const newAlert = { name: PRODUCT_STATUS.NEW_PRODUCT, variant: 'success' } as TaxonomyTermStatus;

  const productStatus = {
    name: status.name,
    variant: status.variant,
    link: statusLink,
  };

  return isNew ? [newAlert, productStatus] : [productStatus];
};
