import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import Icon from '@mdi/react';
import FocusTrap from 'focus-trap-react';
import { graphql, useStaticQuery } from 'gatsby';
import { useFlags, useLDClient } from 'gatsby-plugin-launchdarkly';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import * as React from 'react';
import { Navbar } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { Helmet } from 'react-helmet';
import { mdiClose, mdiMenu } from 'src/assets/icons/mdiIcons';
import { IGeoMenu } from 'src/utils/helpers';

import { CMS_THEME, LD_KEYS } from '../../common/enums';
import { defaultColors } from '../../theme/colors';
import { theme } from '../../theme/theme';
import { getParagraph } from '../../utils/paragraphHelpers';
import SkipLink from '../screen_reader/SkipLink';
import SmartAppBanner from '../smart_app_banner/SmartAppBanner';
import PupProfile from './components/PupProfile';

const StyledHeader = styled.header`
  width: 100%;
  background-color: ${props => props.color || '#FFFFFF'};
  border-bottom: 2px solid ${defaultColors.border};
  .navbar-expand .navbar-nav .dropdown-menu {
    position: relative;
  }
  .col-nav {
    position: fixed;
    top: 5.9375rem; // 95px
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    border-top: 1px solid ${defaultColors.border};
    transition: height 0.3s ease;
    > div {
      height: 100%;
      padding-bottom: 1rem;
    }
  }

  .col-nav.show {
    display: block !important;
  }

  @media (min-width: ${theme.media.lg}) {
    .col-nav {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      border-top: none;
      > div {
        padding-bottom: 0;
      }
    }

    background-color: ${props => props.color || '#FFFFFF'};
    border-bottom: 2px solid ${defaultColors.border};
    .header-top {
      background-color: ${defaultColors.primary};
      .row {
        height: 3rem; // 48px
      }
      ul.menu {
        a,
        a:hover,
        a:active,
        a:focus {
          color: #fff;
        }
        li {
          display: flex;
          align-items: center;
          ::after {
            content: '';
            color: rgba(255, 255, 255, 0.3);
            height: 0.875rem; // 14px;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.5);
          }

          &:last-child::after {
            display: none;
          }
        }
      }
    }

    .search-visible {
      > div > div:not(.search) {
        display: none;
      }
    }

    .logo {
      max-width: 100%;
      max-height: 2.8125rem; // 45px
      min-height: 2.8125rem; // 45px
    }
  }
`;

interface HeaderProps {
  language: Languages;
  translations?: Translations;
  linkgroup: IGeoMenu;
}
const Header: React.FC<HeaderProps> = function ({ language, translations, linkgroup }) {
  const [showSearch, setShowSearch] = React.useState(false);
  const [showMenu, setShowMenu] = React.useState(false);
  const searchRef = React.useRef(null);
  const { enableUniversalProfileMenu = false } = useFlags();
  const ldClient = useLDClient();

  React.useEffect(() => {
    // Check localstorage first for user id
    let userId = localStorage.getItem(LD_KEYS.LD_USER_ID);
    if (!userId) {
      const urlParams = new URLSearchParams(window.location.search);
      userId = urlParams.get(LD_KEYS.LD_USER_ID);
      localStorage.setItem(LD_KEYS.LD_USER_ID, userId || '');
    }

    if (userId && ldClient) {
      ldClient?.identify({ kind: 'user', key: userId, anonymous: false });
    }
  }, [ldClient]);

  React.useEffect(() => {
    const closeMenu: React.EventHandler<any> = (e: React.KeyboardEvent) => {
      if (e.key === 'Escape') {
        setShowMenu(false);
      }
    };
    if (showMenu) {
      window.addEventListener('keydown', closeMenu);
    }
    return () => window.removeEventListener('keydown', closeMenu);
  }, [showMenu, showSearch]);

  const data = useStaticQuery(graphql`
    {
      allStorageHeader {
        edges {
          node {
            langcode
            relationships {
              theme: field_theme {
                ...TaxonomyTermThemes
              }
              headerTop: field_header_top {
                type: __typename
                ...ParagraphMenu
              }
              headerBottom: field_header_bottom {
                type: __typename
                ...ParagraphLinkedLogo
                ...ParagraphNavigation
                ...ParagraphSearchForm
              }
            }
          }
        }
      }
    }
  `);

  const header = data.allStorageHeader.edges.filter(
    ({ node }: { node: { langcode: string } }) => node.langcode === language,
  )[0];
  const headerTopParagraphs: [Paragraph] = header?.node.relationships?.headerTop;
  const headerBottomParagraphs: [Paragraph] = header?.node.relationships?.headerBottom;
  const headerTheme: TaxonomyTermTheme = header?.node.relationships?.theme;
  return (
    <>
      <Helmet bodyAttributes={{ class: showMenu && 'overflow-hidden' }} />
      <StyledHeader
        role="banner"
        className="pds-sticky pds-top-0 pds-z-[990]"
        color={headerTheme?.field_color.color}
      >
        <SkipLink
          style={{ top: '0.5rem', left: '0.5rem' }}
          href="#mainContent"
          className="position-absolute"
        >
          Skip to Main Content
        </SkipLink>
        <FocusTrap active={showMenu} focusTrapOptions={{ returnFocusOnDeactivate: true }}>
          <div role={showMenu ? 'dialog' : undefined} aria-modal={showMenu || undefined}>
            {headerTopParagraphs && (
              <Container className="header-top d-none d-lg-block" fluid>
                <Container fluid="lg" className="justify-content-between">
                  <Row className="align-items-center justify-content-lg-end">
                    {headerTopParagraphs.map(component => {
                      return (
                        <React.Fragment key={component.id}>
                          {getParagraph({
                            node: component,
                            props: { location: 'utility', translations, linkgroup },
                          })}
                        </React.Fragment>
                      );
                    })}
                  </Row>
                </Container>
              </Container>
            )}
            {headerBottomParagraphs && (
              <Navbar
                className={cx('p-0', showSearch ? 'search-visible' : '')}
                style={{ height: '5.9375rem', zIndex: '10' }}
              >
                <Container fluid="lg">
                  {headerBottomParagraphs.map(component => {
                    return (
                      <React.Fragment key={component.id}>
                        {getParagraph({
                          node: component,
                          props: {
                            showSearch,
                            setShowSearch,
                            showMenu,
                            setShowMenu,
                            searchRef,
                            translations,
                            linkgroup,
                          },
                        })}
                      </React.Fragment>
                    );
                  })}
                  <span
                    className="pds-m"
                    style={{
                      transform: 'scale(0.85)',
                    }}
                  >
                    {language === 'en' && ldClient && enableUniversalProfileMenu && !showMenu && (
                      <PupProfile />
                    )}
                  </span>
                  <AnalyticsPoint
                    as={Button}
                    type="component"
                    node={{
                      id: '',
                      name: 'hamburger',
                      type: 'hamburger',
                      drupal_id: '',
                    }}
                    additionalParams={{
                      item_id: !showMenu ? 'open' : 'close',
                    }}
                    eventLabel={!showMenu ? 'open' : 'close'}
                    category="hamburger"
                    action="mobile_menu_toggle"
                    variant="link"
                    aria-label={!showMenu ? 'Show menu' : 'Close menu'}
                    aria-expanded={showMenu}
                    aria-controls="mainMenu"
                    onClick={() => setShowMenu(!showMenu)}
                    className={cx(
                      'rounded-pill bg-light p-2 d-lg-none text-decoration-none justify-content-center order-2',
                      showMenu && 'px-3',
                      showSearch ? 'd-none' : 'd-inline-flex',
                      'js-track',
                    )}
                    style={{
                      height: '3rem',
                      width: showMenu ? 'auto' : '3rem',
                      color: theme[CMS_THEME.NEUTRAL].text.default,
                    }}
                  >
                    {showMenu && <span className="d-inline-block me-2">Close</span>}
                    <Icon
                      path={!showMenu ? mdiMenu : mdiClose}
                      size={1}
                      color="inherit"
                      aria-hidden
                    />
                  </AnalyticsPoint>
                </Container>
              </Navbar>
            )}
          </div>
        </FocusTrap>
        {!showMenu && <SmartAppBanner />}
      </StyledHeader>
    </>
  );
};

export default Header;
