import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

export const SideBarLoader: React.FC<React.SVGAttributes<SVGElement> & IContentLoaderProps> =
  function (props) {
    return (
      <ContentLoader
        speed={1.5}
        width="100%"
        viewBox="0 0 400 617"
        backgroundColor="#EDF0F8"
        foregroundColor="#e2e7f3"
        {...props}
      >
        <rect x="0" y="0" rx="15" width="299" height="30rem" />
      </ContentLoader>
    );
  };

const MainLoader: React.FC<React.SVGAttributes<SVGElement> & IContentLoaderProps> = function (
  props,
) {
  return (
    <ContentLoader
      speed={1.5}
      width="100%"
      viewBox="0 0 783 786"
      backgroundColor="#EDF0F8"
      foregroundColor="#e2e7f3"
      {...props}
    >
      <rect x="0" y="0" width="100%" height="6rem" rx="15" />
      <rect x="0" y="7rem" width="100%" height="6rem" rx="15" />
      <rect x="0" y="14rem" width="100%" height="6rem" rx="15" />
      <rect x="0" y="21rem" width="100%" height="6rem" rx="15" />
      <rect x="0" y="28rem" width="100%" height="6rem" rx="15" />
      <rect x="0" y="35rem" width="100%" height="6rem" rx="15" />
      <rect x="0" y="42rem" width="100%" height="6rem" rx="15" />
    </ContentLoader>
  );
};

export default MainLoader;
