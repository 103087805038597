import { ServicesAuthWrapper } from './ServicesAuthWrapper/ServicesAuthWrapper';
import { Customers } from './loyalty/Customers';
import { SstServices } from './sst/SstServices';

export const SstServiceWithAuth = ServicesAuthWrapper(SstServices);
export const LoyaltyServiceWithAuth = ServicesAuthWrapper(Customers);

type SecurityDataType = { token: string };

export enum BASE_API_PATH {
  SST = '/purina-api/sst',
  LOYALTY = '/purina-api/loyalty/v1',
}

export const AuthSstService = new SstServiceWithAuth({
  baseUrl: BASE_API_PATH.SST,
  baseApiParams: {
    secure: true,
    credentials: 'omit',
  },
  securityWorker: (securityData: SecurityDataType | null) => {
    if (securityData) {
      return {
        headers: {
          Authorization: securityData.token,
        },
      };
    }
    return {};
  },
});

export const AuthLoyaltyService = new LoyaltyServiceWithAuth({
  baseUrl: BASE_API_PATH.LOYALTY,
  baseApiParams: {
    credentials: 'omit',
  },
});
