/* eslint-disable import/no-cycle */
import { yupResolver } from '@hookform/resolvers/yup';
import Icon from '@mdi/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { css } from '@linaria/core';
import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import Row from 'react-bootstrap/Row';
import {
  FieldError,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme/theme';
import { AnsiraContactUs, ansiraContactUsPost } from '../../utils/clientRequests';
import { getRecaptchaToken } from '../../utils/formHelpers';
import RichText from '../rich_text/RichText';
import Typography from '../typography/Typography';
import ConsentSection from './components/contactus/ConsentSection';
import ContactReason from './components/contactus/ContactReasonSection';
import PersonalInformationSection from './components/contactus/PersonalInformationSection';
import { ContactUsInputsType, createContactUsSchema, getBase64 } from './utils/contactUs';
import { mdiCheckCircleOutline } from 'src/assets/icons/mdiIcons';

const submitButtonClass = css`
  min-width: 260px;
  margin-top: 2rem;
  @media (max-width: 540px) {
    width: 100%;
  }
`;

const rowClass = css`
  row-gap: 1rem;
`;

export interface IContactUsFormData extends ContactUsInputsType {
  file: File;
  recaptchaToken: string;
}

interface IContactUsFormProps {
  manufacturerTooltip?: string;
  upcTooltip?: string;
  successMessage?: string;
  successTitle?: string;
  errorMessage?: string;
  errorTitle?: string;
  optin?: string;
  legalText?: string;
  contactReasonLabel?: string;
  messageLabel?: string;
  sourceCodeText?: string;
  moreReasonLabel?: string;
}

type CustomFieldError = Partial<{ type: string | number; message: string }> &
  Record<string, unknown>;

const adaptContactUsInputShape = async (data: IContactUsFormData): Promise<AnsiraContactUs> => {
  const base64File = data?.file ? await getBase64(data?.file) : undefined;
  const shape: AnsiraContactUs = {
    ContactReason: data.contactReason,
    ReasonSpecifications: data.moreSpecificReason,
    Email: data.email,
    Phone: data.phoneNumber ?? '',
    ManufacturerCode: data.manufactureCode ?? '',
    Message: data.message,
    City: data.city ?? '',
    State: data.state ?? '',
    Zip: data.zip ?? '',
    FirstName: data.firstName ?? '',
    LastName: data.lastName ?? '',
    UPCCode: data.upcCode ?? '',
    Address1: data.address1 ?? '',
    Address2: data.address2 ?? '',
    NumberOfDogs: data.ownedDogs || 0,
    NumberOfCats: data.ownedCats || 0,
    ContactViaEmail: data.emailFeedbackConsent as number,
    ContactViaPhone: data.callFeedbackConsent as number,
    Terms: 1,
    PurinaOptin: data.emailSubscribingConsent ? 1 : 0,
    brand: 'PU',
    sourceCode: 'PUContactUsForm062020',
    Attachment: data?.file?.name,
    AttachmentContentType: data.file?.type,
    AttachmentData: base64File as string,
    RecaptchaToken: data.recaptchaToken ?? '',
  };

  return shape;
};

// using hook as a separation of concern
const useAddContact = () =>
  useMutation({
    mutationFn: ansiraContactUsPost,
  });

const ContactUsForm = ({
  manufacturerTooltip,
  optin,
  successMessage,
  successTitle,
  upcTooltip,
  errorMessage,
  errorTitle,
  legalText,
}: IContactUsFormProps) => {
  const { t } = useTranslation();

  const schema = createContactUsSchema(t);
  const methods = useForm<IContactUsFormData>({
    shouldUnregister: true,
    resolver: yupResolver(schema),
    mode: 'all',
  });

  const {
    formState: { isSubmitSuccessful },
    handleSubmit,
    trigger,
  } = methods;

  const { mutate, isError } = useAddContact();

  const onSubmit: SubmitHandler<IContactUsFormData> = async data => {
    if (navigator.onLine) {
      const submitAnalyticsForm = {
        event: 'form_submit',
        eventCategory: 'form_submit',
        eventAction: data.contactReason,
        eventLabel: data.moreSpecificReason,
        eventParams: {
          contact_reason: data.contactReason,
          contact_about: data.moreSpecificReason,
          form_id: 'contact us',
          form_name: 'contact us',
          form_type: 'contact us',
        },
      };
      sendIt(submitAnalyticsForm);
    }

    const recaptchaToken = await getRecaptchaToken();
    const newData = { ...data, recaptchaToken };
    const adaptedData = await adaptContactUsInputShape(newData);

    mutate(adaptedData, {
      onError(error) {
        // if it's an error here it absolutely comes from the server response.
        if (error instanceof AxiosError || error instanceof Error) {
          const submitAnalyticsErrorForm = {
            event: 'contactus_submit_error',
            eventCategory: 'contactus_submit_error',
            eventAction: error.name,
            eventLabel: error.name,
            eventParams: {
              form_id: 'contact us',
              form_name: error.message ?? 'Something went wrong!',
              form_type: 'contact us',
              error_code: 'INTERNAL_SERVER_ERROR',
              error_name: error.name,
              module_name: error.name,
              error_feature: error.message ?? 'Something went wrong!',
            },
          };
          sendIt(submitAnalyticsErrorForm);
        }
      },
    });
  };

  const onError: SubmitErrorHandler<IContactUsFormData> = validationErrors => {
    const errorsArray = Object.entries(validationErrors);

    const sendErrorToGA = ([key, error]: [string, FieldError | CustomFieldError]) => {
      const { message, type } = error as FieldError;

      // client side error validations consists on
      // the key (field name), the type of error and the message
      const submitAnalyticsErrorForm = {
        event: 'contactus_submit_error',
        eventCategory: 'contactus_submit_error',
        eventAction: key,
        eventLabel: key,
        eventParams: {
          form_id: 'contact us',
          form_name: 'contact us',
          form_type: 'contact us',
          error_code: type?.toString() ?? 'ERROR',
          error_name: key,
          module_name: key,
          error_feature: message as string,
        },
      };

      sendIt(submitAnalyticsErrorForm);
    };

    // should send each valid error to the analytics team
    errorsArray.forEach(sendErrorToGA);
  };

  // we make sure here if the form submission is successful
  // we control what to show whether it's an error or not
  if (isSubmitSuccessful) {
    return (
      <div className="bg-gray-100 p-3 p-lg-5 d-grid gap-3 text-center">
        {!isError && (
          <Icon
            path={mdiCheckCircleOutline}
            size="100px"
            className="mx-auto"
            color={theme.neutral.action}
          />
        )}
        <Typography variant="h4" role="status" aria-live="polite">
          {!isError && (successTitle || `${t('Submitted')}!`)}
          {isError && (errorTitle || t('Something went wrong'))}
        </Typography>
        {successMessage && !isError && <RichText body={successMessage} />}
        {isError && errorMessage && <RichText body={errorMessage} />}
      </div>
    );
  }

  const manuallyTriggerValidations = () => {
    trigger();
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit, onError)}>
        <Row className={rowClass}>
          <ContactReason manufacturerTooltip={manufacturerTooltip} upcTooltip={upcTooltip} />
          <PersonalInformationSection />
          <ConsentSection legalText={legalText} optin={optin} />{' '}
        </Row>
        <Button
          variant="dark"
          className={`rounded-pill justify-content-center ${submitButtonClass}`}
          type="submit"
          onClick={manuallyTriggerValidations}
          aria-label={t(
            'By clicking submit I certify that I am at least 18 years of age and have read and agree with the Terms & Conditions, Privacy Policy and About Our Ads.',
          )}
        >
          {t('Submit')}
        </Button>
      </Form>
    </FormProvider>
  );
};

export default ContactUsForm;
