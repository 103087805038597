import { styled } from '@linaria/react';
import React, { Suspense } from 'react';
import { Col } from 'react-bootstrap';
import { usePageStore } from 'src/stores/page';
import { CMS_THEME } from '../../common/enums';
import { theme } from '../../theme/theme';

type ComponentTypes = IStorageContactCards | IStorageHighlights;

enum ContactUsSidebarComponents {
  STORAGE_HIGHLIGHTS = 'storage__highlights',
  STORAGE_CONTACT_CARDS = 'storage__contact_cards',
}

const StorageHighlights = React.lazy(() => import('./HighlightList'));
const StorageContactCards = React.lazy(() => import('./ContactCards'));

interface SidebarProps {
  components: ComponentTypes[];
  cmsTheme?: CMS_THEME;
}

const StyledStack = styled.div<{ cmsTheme: CMS_THEME }>`
  padding: 2.5rem 1.3rem;
  background: ${({ cmsTheme }) => theme[cmsTheme].background.paper};
`;

const ContactUsSidebar = ({ components, cmsTheme = CMS_THEME.LIGHT }: SidebarProps) => {
  const highlightProps = components.find(
    storage => storage.type === ContactUsSidebarComponents.STORAGE_HIGHLIGHTS
  );
  const contactCardProps = components.find(
    storage => storage.type === ContactUsSidebarComponents.STORAGE_CONTACT_CARDS
  );

  const pageContext = usePageStore(state => state.pageContext);
  const getIndex = (moduleType: string, node: any) => {
    const index = pageContext.modules.findIndex(i => i.moduleType === moduleType);
    if (index !== -1) {
      const newNode = { ...node };
      newNode.indexOnPage = index;
      return newNode;
    }

    return node;
  };

  return (
    <StyledStack className="rounded-3 d-flex flex-column gap-5" cmsTheme={cmsTheme}>
      {highlightProps && (
        <Col className="d-flex flex-column gap-5">
          <Suspense fallback="loading...">
            <StorageHighlights node={highlightProps as IStorageHighlights} />
          </Suspense>
        </Col>
      )}
      {contactCardProps && (
        <Suspense fallback="loading...">
          <StorageContactCards
            node={getIndex('contact_cards', contactCardProps) as IStorageContactCards}
          />
        </Suspense>
      )}
    </StyledStack>
  );
};

export default ContactUsSidebar;
