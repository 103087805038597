import React from 'react';
import StorageHeroCard from './StorageHeroCard';
import StorageQRHeroCard from './StorageQRHeroCard';

const StorageHeroCardHelper = (props: { node: IStorageHeroCard }) => {
  const { node } = props;
  const isQRHero = node.relationships?.secondary_image?.field_category.some(
    category => category === 'qr',
  );

  if (isQRHero) {
    return <StorageQRHeroCard {...props} />;
  }

  return <StorageHeroCard {...props} />;
};

export default StorageHeroCardHelper;
