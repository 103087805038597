import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import Icon from '@mdi/react';
import {
  Accordion,
  AccordionContent,
  AccordionHeader,
  AccordionItem,
  AccordionTrigger,
  pdsTv,
} from '@purinanbm/pds-ui';

import { GatsbyImage } from 'gatsby-plugin-image';
import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Nav from 'react-bootstrap/Nav';
import NavLink from 'react-bootstrap/NavLink';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { mdiChevronDown, mdiEarth } from 'src/assets/icons/mdiIcons';
import { CMS_THEME } from 'src/common/enums';
import { IGeoMenu } from 'src/utils/helpers';
import { defaultColors } from '../../theme/colors';
import { theme } from '../../theme/theme';
import Link, { LinkProps } from '../Link';
import { ButtonLink } from '../button/Button';
import RichText from '../rich_text/RichText';

const StyledNav = styled.div<{ offset?: number }>`
  a {
    color: ${defaultColors.dark};
  }
  .dropdown {
    border-bottom: 1px solid ${defaultColors.border};

    .dropdown-toggle {
      display: flex;
      justify-content: space-between;
      font-size: 24px;
      color: ${defaultColors.dark};

      .icon {
        height: 36px;
        width: 36px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: ${defaultColors.light};

        svg {
          width: 24px;
          height: 24px;
        }
      }

      ::after {
        display: none;

        svg {
          display: inline-block;
          height: 1.75rem;
          width: 1.75rem;
        }
      }
    }

    &.show {
      border: none;
      & > a svg {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown-menu {
    border: none;
  }

  @media (min-width: ${theme.media.lg}) {
    .dropdown {
      border-bottom: none;
      position: static;

      .dropdown-toggle {
        justify-content: start;
        font-size: 22px;

        .icon {
          height: auto;
          width: auto;
          background-color: transparent;
        }

        svg {
          height: 1.125rem;
          width: 1.125rem;
          margin-left: 0.25rem;
        }
      }
    }

    .dropdown-menu {
      background: none;
      position: fixed !important;
      top: ${props => (props?.offset ? `${props.offset}px` : 'auto')};
      left: 0 !important;
      right: 0 !important;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      margin: 0;
      padding: 0;
      .mega-content {
        border-bottom-left-radius: 0.375rem;
        border-bottom-right-radius: 0.375rem;
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
        > *:last-child {
          border-bottom-right-radius: 0.375rem;
        }
      }

      &.show {
        .mega-content {
          border-top: 1px solid ${defaultColors.border} !important;
        }
      }
    }
  }
`;

const SubtitleNav = styled.span`
  color: #e81c24;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2.5px;
  text-transform: uppercase;
`;
const StyledPromo = styled.div`
  @media (min-width: ${theme.media.lg}) {
    margin-right: 2rem;
  }
`;

interface MegaMenuProps {
  node: IStorageMegaMenu;
  offset?: number | undefined;
  className?: string;
  translations?: Translations;
  linkgroup?: IGeoMenu;
}

type SecondaryMenuProps = {
  menuSecondary: IStorageMenu;
  translationInfo: { path: { alias: string }; langcode: Languages } | undefined;
  linkgroup: IGeoMenu;
};

const PurinaListItem = React.forwardRef<React.ElementRef<'a'>, LinkProps>(
  ({ className, children, ...props }, ref) => {
    const styles = pdsTv({
      base: 'pds-block pds-space-y-1 pds-py-4 pds-no-underline pds-transition-colors hover:pds-text-surface-text hover:pds-underline focus:pds-text-surface-text',
    });
    const handleAnalytics = () => {
      sendIt({
        event: 'nav_click',
        eventParams: {
          nav_item: props.title,
          nav_type: 'header',
        },
      });
    };

    return (
      <li>
        <Link ref={ref} className={styles({ className })} onClick={handleAnalytics} {...props}>
          <span className="pds-text-body-md-legacy pds-font-normal pds-leading-none">
            {children}
          </span>
        </Link>
      </li>
    );
  },
);

interface GeoMenuProps {
  linkgroup: IGeoMenu;
}

const GeoMenu = (props: GeoMenuProps) => {
  const { linkgroup } = props;

  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const currentCountry = new Intl.DisplayNames(currentLanguage, { type: 'region' }).of('US');
  return (
    <AccordionItem
      key="countries"
      className="pds-border-[0px] pds-border-b pds-border-solid pds-border-b-surface [&_>_h3]:pds-mb-0"
      value="countries"
    >
      <AccordionTrigger className="pds-border-none pds-bg-transparent pds-px-0 !pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-text-paper-text">
        {currentCountry}
      </AccordionTrigger>
      <AccordionContent>
        <ul className="pds-list-none pds-px-0">
          {linkgroup.map(item => (
            <PurinaListItem key={item.id} title={item.title} to={item.url}>
              {item.title}
            </PurinaListItem>
          ))}
        </ul>
      </AccordionContent>
    </AccordionItem>
  );
};

const SecondaryMenu = (props: SecondaryMenuProps): React.ReactNode => {
  const { menuSecondary, translationInfo, linkgroup } = props;
  const {
    relationships: { menus = [] },
  } = menuSecondary;
  const hasGeoTargeting = linkgroup?.length > 0;
  if (menus && menus.length > 0 && menus.every(menu => menu?.link?.title)) {
    return (
      <div className="d-lg-none pds-flex pds-h-full pds-w-auto pds-justify-center pds-bg-[#CED4E6] pds-p-[20px]">
        <Accordion type="single" collapsible className="pds-w-full">
          {menus.map((menu, index) => {
            const hasSubLinks = menu.links.length > 0;
            return hasSubLinks ? (
              <AccordionItem
                key={menu.id}
                className="pds-border-[0px] pds-border-b pds-border-solid pds-border-b-surface [&_>_h3]:pds-mb-0"
                value={String(index)}
              >
                <AccordionTrigger className="pds-border-none pds-bg-transparent pds-px-0 !pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-text-paper-text">
                  {menu.link.title}
                </AccordionTrigger>
                <AccordionContent>
                  <ul className="pds-list-none pds-px-0">
                    {menu.links.map(submenu => (
                      <PurinaListItem
                        key={submenu.title}
                        to={submenu.url}
                        title={submenu.title}
                        {...submenu.options?.attributes}
                      >
                        {submenu.title}
                      </PurinaListItem>
                    ))}
                  </ul>
                </AccordionContent>
              </AccordionItem>
            ) : (
              <AccordionItem
                value={menu.id}
                key={menu.id}
                className="pds-group pds-border-[0px] pds-border-b pds-border-solid pds-border-b-surface"
              >
                <AccordionHeader className="pds-mb-0">
                  <Link
                    onClick={() => {
                      sendIt({
                        event: 'nav_click',
                        eventParams: {
                          nav_item: menu.link.title,
                          nav_type: 'header',
                        },
                      });
                    }}
                    to={menu.link.url}
                    {...menu.link.options?.attributes}
                    className="pds-flex pds-py-4 pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-no-underline group-hover:pds-cursor-pointer group-hover:pds-underline"
                  >
                    {menu.link.title}
                  </Link>
                </AccordionHeader>
              </AccordionItem>
            );
          })}
          {hasGeoTargeting && <GeoMenu linkgroup={linkgroup} />}
          {translationInfo && (
            <AccordionItem
              value="translation-value"
              key="translation-key"
              className="pds-group pds-border-[0px] pds-border-b pds-border-solid pds-border-b-surface pds-py-0"
            >
              <AccordionHeader className="pds-mb-0">
                <Link
                  to={translationInfo.path.alias}
                  lang={translationInfo.langcode}
                  onClick={() => {
                    sendIt({
                      event: 'nav_click',
                      eventParams: {
                        nav_item: translationInfo.langcode,
                        nav_type: 'header',
                      },
                    });
                  }}
                  className="pds-flex pds-py-4 pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-no-underline group-hover:pds-cursor-pointer group-hover:pds-underline"
                >
                  <span className="pds-flex pds-items-center pds-text-body-md pds-font-medium pds-uppercase pds-tracking-[2.5px] pds-no-underline group-hover:pds-cursor-pointer group-hover:pds-underline">
                    {translationInfo.langcode}
                    <Icon path={mdiEarth} className="pds-ml-2" size={0.7} />
                  </span>
                </Link>
              </AccordionHeader>
            </AccordionItem>
          )}
        </Accordion>
      </div>
    );
  }
  return (
    <ul className="d-block d-lg-none pds-list-none pds-px-4" style={{ columnCount: '2' }}>
      {menuSecondary.links.map((link, index) => {
        return (
          <li key={`secondary-${index}`}>
            <Link
              onClick={() => {
                sendIt({
                  event: 'nav_click',
                  eventParams: {
                    nav_item: link.title,
                    nav_type: 'header',
                  },
                });
              }}
              to={link.url}
              {...link.options?.attributes}
              className="text-body pds-block pds-py-3 pds-text-body-2xs pds-uppercase pds-tracking-widest pds-no-underline"
            >
              {link.title}
            </Link>
          </li>
        );
      })}
      {translationInfo && (
        <li key="translation-key">
          <Link
            to={translationInfo.path.alias}
            lang={translationInfo.langcode}
            onClick={() => {
              sendIt({
                event: 'nav_click',
                eventParams: {
                  nav_item: translationInfo.langcode,
                  nav_type: 'header',
                },
              });
            }}
            className="pds-no-underline"
          >
            <span className="text-body pds-block pds-py-3 pds-text-body-2xs pds-uppercase pds-tracking-widest">
              {translationInfo.langcode}
              <Icon path={mdiEarth} className="pds-ml-2" size={0.7} />
            </span>
          </Link>
        </li>
      )}
    </ul>
  );
};

const MegaMenu: React.FC<MegaMenuProps> = function ({
  node,
  offset,
  className,
  translations,
  linkgroup,
}) {
  const menuLinks = node.relationships?.menu_links;
  const menuSecondary = node.relationships?.secondary_menu;
  const [show, setShow] = useState<string | null>(null);

  const translationInfo = translations?.edges[0]?.node;
  return (
    <AnalyticsPoint
      type="module"
      typeLabel="mega_menu"
      label="Mega menu"
      as={StyledNav}
      id="mainMenu"
      offset={offset}
      className={cx(className, 'overflow-auto')}
    >
      <StyledNav>
        <Nav
          as="ul"
          aria-label="Main Navigation"
          className="flex-column flex-lg-row w-100 p-3 p-lg-0 gap-xl-5 gap-3"
        >
          {menuLinks &&
            menuLinks.map(menuLink => {
              const subMenus = menuLink?.relationships?.menus || [];
              const card = menuLink?.relationships?.promo?.[0];
              const media = card?.relationships.image;
              if (!menuLink.link?.title) {
                return null;
              }
              if (subMenus.length >= 1) {
                return (
                  <Dropdown
                    id={`nav-${menuLink.id}`}
                    as="li"
                    key={menuLink.id}
                    className="nav-item"
                    onToggle={e => {
                      if (e) {
                        sendIt({
                          event: 'nav_click',
                          eventParams: {
                            nav_item: menuLink.link.title,
                            nav_type: 'header',
                          },
                        });
                        setShow(`nav-${menuLink.id}`);
                      }
                    }}
                  >
                    <Dropdown.Toggle className="fw-light" as={NavLink} id={`menu-${menuLink.id}`}>
                      {menuLink.link.title}
                      <span className="icon rounded-circle">
                        <Icon path={mdiChevronDown} />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      renderOnMount
                      className="border-0"
                      show={show === `nav-${menuLink.id}`}
                    >
                      <Container fluid className="p-0">
                        <Container fluid="lg">
                          <Row className="gx-lg-0 bg-white mega-content">
                            <Col lg={card ? 8 : 12} className="p-3 px-lg-5">
                              <Link
                                className="h4 mb-2 mb-lg-3 d-none d-lg-inline-block text-decoration-none text-body"
                                to={menuLink.link.url}
                              >
                                {menuLink.link.title}
                              </Link>
                              <Row>
                                {subMenus.map(subMenu => {
                                  const { links } = subMenu;
                                  return (
                                    <AnalyticsPoint
                                      type="component"
                                      node={{
                                        id: subMenu.id,
                                        type: 'submenu_header_item',
                                        name: subMenu.link.title,
                                      }}
                                      as={Col}
                                      key={subMenu.id}
                                      lg="auto"
                                      className="mb-3 mb-lg-0"
                                    >
                                      <SubtitleNav className="mb-2">
                                        {subMenu.link.title}
                                      </SubtitleNav>
                                      {links.length >= 1 && (
                                        <Nav
                                          as="ul"
                                          className="flex-column rounded-2 p-3 bg-gray-100"
                                        >
                                          {links.map((link, index) => {
                                            return (
                                              <li key={`${subMenu.id}${index}`}>
                                                <Link
                                                  to={link.url}
                                                  {...link.options?.attributes}
                                                  className="text-decoration-none text-body py-1 d-block fw-light"
                                                  onClick={() => {
                                                    sendIt({
                                                      event: 'nav_click',
                                                      eventParams: {
                                                        nav_item: link.title,
                                                        nav_type: 'header',
                                                      },
                                                    });
                                                  }}
                                                >
                                                  {link.title}
                                                </Link>
                                              </li>
                                            );
                                          })}
                                        </Nav>
                                      )}
                                    </AnalyticsPoint>
                                  );
                                })}
                              </Row>
                            </Col>
                            {media && (
                              <Col className="p-3 bg-light">
                                <StyledPromo>
                                  <GatsbyImage
                                    alt=""
                                    image={media.relationships.field_media_image.gatsbyImage}
                                    className="rounded-2 mb-3"
                                    loading="lazy"
                                  />
                                  {card.body && (
                                    <RichText
                                      className="text-center text-lg-start"
                                      body={card.body.processed}
                                    />
                                  )}
                                  {card.links &&
                                    card.links.map(link => {
                                      return (
                                        <AnalyticsPoint
                                          key={link.uri}
                                          type="component"
                                          node={{
                                            name: link.title,
                                            type: 'submenu_item',
                                            id: '',
                                          }}
                                        >
                                          <ButtonLink
                                            cmsTheme={CMS_THEME.LIGHT}
                                            to={link.url}
                                            variant="btn-outline"
                                            className="mx-auto d-block mx-lg-0 d-lg-inline-block"
                                            {...link?.options?.attributes}
                                          >
                                            {link.title}
                                          </ButtonLink>
                                        </AnalyticsPoint>
                                      );
                                    })}
                                </StyledPromo>
                              </Col>
                            )}
                          </Row>
                        </Container>
                      </Container>
                    </Dropdown.Menu>
                  </Dropdown>
                );
              }

              return (
                <AnalyticsPoint
                  type="component"
                  node={{ name: menuLink.link.title, type: 'submenu_item', id: '' }}
                >
                  <Link
                    to={menuLink.link.url}
                    key={menuLink.id}
                    {...menuLink.link.options?.attributes}
                  >
                    {menuLink.link.title}
                  </Link>
                </AnalyticsPoint>
              );
            })}
        </Nav>
        {menuSecondary && (
          <SecondaryMenu
            menuSecondary={menuSecondary}
            translationInfo={translationInfo}
            linkgroup={linkgroup}
          />
        )}
      </StyledNav>
    </AnalyticsPoint>
  );
};

export default MegaMenu;
