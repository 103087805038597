import Icon from '@mdi/react';
import React from 'react';

interface IRewardItemWrapper extends Partial<Omit<HTMLDivElement, 'title' | 'children'>> {
  title?: string;
  children: React.ReactNode;
  iconPath?: string;
}

/**
 * Reward Card
 * This creates a rounded card used on reward pages such as checkout
 * And in the RewardItemSummary
 **/
export const RewardCard: React.FC<IRewardItemWrapper> = (
  { title, iconPath, children },
  ...props
) => {
  return (
    <div
      className="pds-overflow-hidden pds-rounded pds-border pds-border-surface-line pds-bg-surface"
      {...props}
    >
      <div className="pds-flex pds-border-b pds-border-b-surface-line pds-p-4 pds-font-semibold">
        {iconPath && (
          <Icon
            aria-hidden="true"
            className="pds-mr-3 pds-h-4.5 pds-w-4.5 pds-text-primary"
            path={iconPath}
            // @ts-ignore - A11y Requirement
            role={undefined}
          />
        )}
        {title && <h2 className="pds-unset">{title}</h2>}
      </div>
      <div className="pds-p-4">{children}</div>
    </div>
  );
};
