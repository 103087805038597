import Icon from '@mdi/react';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React, { MouseEvent, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { mdiClose } from 'src/assets/icons/mdiIcons';
import Link from 'src/components/Link';
import { themeStyles } from 'src/theme/basic-styles';
import { CMS_THEME } from '../common/enums';
import { theme } from '../theme/theme';

interface StorageBannerProps {
  node: IStorageBanner;
  behaviors?: {
    entity_variant?: {
      theme: CMS_THEME;
    };
    entity_buttons?: {
      dismiss_button: boolean;
      dismiss_duration: number;
    };
  };
}
const DefaultSettings = {
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
  entity_buttons: {
    dismiss_duration: 3,
    dismiss_button: true,
  },
};

const StorageBanner: React.FC<StorageBannerProps> = ({ node: banner }) => {
  const { t } = useTranslation();
  const [showBanner, setShowBanner] = useState(true);
  const storageKey = 'dismissTime';
  const settings = Object.assign(DefaultSettings, banner?.behaviors || {});
  const expireInSeconds = Number(settings.entity_buttons.dismiss_duration * 60);
  const showDismissBtn = Boolean(settings.entity_buttons.dismiss_button);
  const cmsTheme = settings.entity_variant.theme;

  const wrapper = themeStyles({ theme: cmsTheme, className: 'pds-flex pds-relative pds-py-4' });
  useEffect(() => {
    const dismissedAt = Number(localStorage.getItem(storageKey));
    const currentTime = Number(new Date().getTime());
    const shouldShowBanner = !dismissedAt || currentTime - dismissedAt >= expireInSeconds * 1000;
    setShowBanner(shouldShowBanner);
  }, [expireInSeconds]);

  const handleDismiss = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowBanner(false);
    localStorage.setItem(storageKey, String(new Date().getTime()));
    sendIt({
      event: 'module_interaction',
      eventParams: {
        component_id: 'Dismiss banner',
        component_type: 'notification_banner',
        content_name: 'secondary_cta',
      },
    });
  };

  const handleGA = () => {
    sendIt({
      event: 'module_interaction',
      eventParams: {
        component_id: banner?.link?.title ?? '[not set]',
        component_type: 'notification_banner',
        content_name: 'primary_cta',
      },
    });
  };

  return showBanner ? (
    <Container id={banner.component_id} fluid data-id={banner.drupal_id} className={wrapper}>
      <Container className="position-relative">
        <div className="pds-m-auto pds-text-balance pds-px-[48px] pds-text-center lg:pds-px-[7.5rem]">
          {banner?.title ? (
            <span className="d-block d-lg-inline me-lg-3 fs-4 mb-0 fw-normal">{banner.title}</span>
          ) : null}
          {banner?.link ? (
            <Link
              className={`d-inline-block fs-4 fw-bold ${
                cmsTheme === 'dark' ? 'link-light' : 'link-dark'
              }`}
              analytics={false}
              onClick={handleGA}
              to={banner.link.url}
              aria-describedby={banner.title}
              {...banner.link.options?.attributes}
            >
              {banner.link.title ? banner.link.title : null}
            </Link>
          ) : null}
        </div>

        {showDismissBtn && (
          <button
            className="pds-absolute pds-right-0 pds-top-0 pds-border-none pds-bg-[transparent]"
            type="button"
            aria-label={t('Dismiss banner')}
            onClick={handleDismiss}
          >
            <Icon
              path={mdiClose}
              size={1}
              color={cmsTheme === 'dark' ? theme.common.gray[300] : theme.common.gray[500]}
            />
          </button>
        )}
      </Container>
    </Container>
  ) : null;
};

export default StorageBanner;
