import Icon from '@mdi/react';
import FocusTrap from 'focus-trap-react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import { styled } from '@linaria/react';
import * as React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import { Trans, useTranslation } from 'react-i18next';
import { CMS_THEME } from 'src/common/enums';
import { useDidMountEffect } from 'src/hooks/useDidMountEffect';
import { Button } from '../button/Button';
import { mdiArrowLeft, mdiArrowRight } from 'src/assets/icons/mdiIcons';

const Wrapper = styled.div`
  .image-wrap {
    img {
      max-width: 100%;
      max-height: 400px;
      display: inline-block;
    }
  }

  .instruct {
    font-size: 0.875rem;
    margin: 1rem 0;
  }
`;

const StyledModal = styled(Modal)`
  .btn-close-special {
    color: #0147b7;
  }
  .btn-close:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }
  .image-wrap {
    max-height: 90vh;

    img {
      max-height: 90vh !important;
    }
  }
`;

const StyledCarousel = styled.div`
  position: relative;

  &#product-carousel {
    img {
      cursor: pointer;
    }
  }

  .carousel {
    display: flex;

    .carousel-inner {
      order: 2;
    }

    .carousel-control-prev {
      order: 1;
    }

    .carousel-control-next {
      order: 3;
    }

    .carousel-control-prev:focus,
    .carousel-control-next:focus {
      svg {
        outline: 2px solid #2553f8;
      }
    }

    .carousel-control-prev,
    .carousel-control-next {
      width: 5rem;
      position: relative;
      left: initial;
      right: initial;

      svg {
        height: 2.5rem;
        width: 2.5rem;
        color: #888888;
      }
    }
  }

  .instruct {
    font-size: 0.875rem;
    margin: 1rem 0;
  }

  .carousel-item {
    mix-blend-mode: multiply;
  }

  img {
    max-width: 100%;
    max-height: 400px;
    display: inline-block;
    mix-blend-mode: multiply !important;
  }

  .thumbs {
    list-style: none;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding: 0.5rem;
    overflow-x: auto;
    margin-top: 1rem;
    align-items: center;

    button > span {
      height: 1rem;
      width: 1rem;
      background: #888888;
      border-radius: 50%;
    }

    li {
      margin-right: 1rem;
      flex: none;

      &:first-child {
        margin-left: auto;
      }

      &:last-child {
        margin-right: auto;
      }

      img {
        cursor: pointer;
        opacity: 0.7;
        background-color: #ffffff;
      }

      &.active {
        img {
          opacity: 1;
          border: 2px solid #2553f8;
        }

        button > span {
          scale: 1.25;
          transition-property: all;
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-duration: 200ms;
          background: #767676;
        }
      }
    }

    button {
      border: transparent;
      background-color: transparent;

      img {
        width: 50px;
      }
    }
  }
`;

interface Image {
  gatsbyImage: IGatsbyImageData;
}
interface Props {
  productTitle: string;
  images?: Image[];
  thumbs?: Image[];
  imageMeta?: ImageMetadata[];
}

const ProductCarousel: React.FC<Props> = function ({ images, thumbs, imageMeta, productTitle }) {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [show, setShow] = React.useState(false);
  const { t } = useTranslation();

  const sendGA = () => {
    sendIt({
      event: 'select_content',
      eventCategory: 'product details',
      eventAction: 'product carousel',
      eventLabel: `image ${activeIndex + 1} of ${images?.length}: ${imageMeta?.[activeIndex].alt}`,
      eventParams: {
        cta_location: 'product details',
        content_type: 'image carousel',
        image_active: `image ${activeIndex + 1} of ${images?.length}: ${imageMeta?.[activeIndex]
          .alt}`,
        internal_path: `${imageMeta?.[activeIndex].alt}`,
        item_id: `${productTitle}`,
      },
    });
  };

  useDidMountEffect(() => {
    if (Number.isInteger(activeIndex)) {
      sendGA();
    }
  }, [activeIndex]);

  const expandImageText = t('Enlarge View');

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };
  const handleButtonShow = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Enter' || e.key === ' ') handleShow();
  };
  const handleSelect = (selectedIndex: number) => {
    setActiveIndex(selectedIndex);
  };
  const goToIndex = (newIndex: number) => {
    setActiveIndex(newIndex);
  };
  if (images && images.length > 1) {
    return (
      <section aria-label={t('Product image carousel')}>
        <Trans>
          <span aria-live="polite">
            {{ activeIndex: activeIndex + 1 }} of {{ imageLength: images.length }}
          </span>
        </Trans>
        <StyledCarousel id="product-carousel">
          <Button
            variant="btn-link"
            cmsTheme={CMS_THEME.LIGHT}
            type="button"
            onClick={handleShow}
            className="text-center text-uppercase instruct d-none d-md-inline-flex"
          >
            {expandImageText}
          </Button>
          <Carousel
            activeIndex={activeIndex}
            onSelect={handleSelect}
            indicators={false}
            controls
            interval={null}
            variant="dark"
            prevIcon={<Icon path={mdiArrowLeft} aria-label={t('Prev')} />}
            nextIcon={<Icon path={mdiArrowRight} aria-label={t('Next')} />}
          >
            {images.map((image, index: number) => {
              const meta = imageMeta ? imageMeta[index] : null;
              const loading = index === 0 ? 'eager' : 'lazy';
              return (
                <Carousel.Item key={index} onClick={handleShow}>
                  <GatsbyImage
                    imgStyle={{ objectFit: 'contain' }}
                    image={image.gatsbyImage}
                    alt={meta?.alt || ''}
                    loading={loading}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
          {thumbs && thumbs.length > 1 && (
            <ol className="thumbs">
              {thumbs.map((thumb, index: number) => {
                const active = index === activeIndex ? 'active' : '';
                const meta = imageMeta ? imageMeta[index] : null;
                return (
                  <li key={index} className={active}>
                    <button
                      type="button"
                      aria-current={index === activeIndex}
                      className="p-0 d-block"
                      onClick={() => goToIndex(index)}
                      aria-label={`Slide ${index + 1}`}
                    >
                      <span className="d-block d-md-none" />
                      <GatsbyImage
                        imgStyle={{ objectFit: 'contain' }}
                        image={thumb.gatsbyImage}
                        alt={meta?.alt || ''}
                        className="d-none d-md-block"
                        style={{ height: '50px', width: '50px' }}
                      />
                    </button>
                  </li>
                );
              })}
            </ol>
          )}
        </StyledCarousel>
        <StyledModal show={show} onHide={handleClose} size="lg" centered fullscreen="sm-down">
          <FocusTrap>
            <div>
              <Modal.Header closeButton />
              <StyledCarousel>
                <Carousel
                  activeIndex={activeIndex}
                  onSelect={handleSelect}
                  indicators={false}
                  interval={null}
                  variant="dark"
                  prevIcon={<Icon path={mdiArrowLeft} aria-label={t('Prev')} />}
                  nextIcon={<Icon path={mdiArrowRight} aria-label={t('Next')} />}
                >
                  {images.map((image, index: number) => {
                    const meta = imageMeta ? imageMeta[index] : null;
                    return (
                      <Carousel.Item key={index}>
                        <div className="d-flex justify-content-center image-wrap">
                          <GatsbyImage
                            imgStyle={{ objectFit: 'contain' }}
                            image={image.gatsbyImage}
                            alt={meta?.alt || ''}
                          />
                        </div>
                      </Carousel.Item>
                    );
                  })}
                </Carousel>
              </StyledCarousel>
            </div>
          </FocusTrap>
        </StyledModal>
      </section>
    );
  }
  const image = images ? images[0] : null;
  const meta = imageMeta ? imageMeta[0] : null;
  if (image) {
    return (
      <Wrapper>
        <Button
          variant="btn-link"
          cmsTheme={CMS_THEME.LIGHT}
          onClick={handleShow}
          className="instruct d-none d-md-block text-center text-uppercase"
        >
          {expandImageText}
        </Button>
        <div
          className="d-flex justify-content-center image-wrap"
          onClick={handleShow}
          role="button"
          onKeyDown={handleButtonShow}
          tabIndex={0}
        >
          <GatsbyImage
            imgStyle={{ objectFit: 'contain' }}
            image={image.gatsbyImage}
            alt={meta?.alt || ''}
            width={400}
            height={400}
          />
        </div>
        <StyledModal
          show={show}
          onHide={handleClose}
          size="lg"
          centered
          fullscreen="sm-down"
          role="dialog"
          aria-label={
            meta?.alt
              ? t('{{metaAlt}} expanded view', { metaAlt: meta?.alt })
              : t('{{productTitle}} expanded view', { productTitle })
          }
        >
          <Modal.Header closeButton />
          <div className="d-flex justify-content-center image-wrap">
            <GatsbyImage
              imgStyle={{ objectFit: 'contain' }}
              image={image.gatsbyImage}
              alt={meta?.alt || ''}
            />
          </div>
        </StyledModal>
      </Wrapper>
    );
  }
  return null;
};
export default ProductCarousel;
