import React, { Suspense } from 'react';
import ParagraphFooterRow from '../components/paragraphs/ParagraphFooterRow';
import ParagraphLinkedLogo from '../components/paragraphs/ParagraphLinkedLogo';
import ParagraphMenu from '../components/paragraphs/ParagraphMenu';
import ParagraphNavigation from '../components/paragraphs/ParagraphNavigation';
import ParagraphSearchForm from '../components/paragraphs/ParagraphSearchForm';
import ParagraphSocialLinks from '../components/paragraphs/ParagraphSocialLinks';
import ParagraphText from 'src/components/paragraphs/ParagraphText';

interface ComponentsProps {
  [index: string]: React.FC<any>;
}

const components: ComponentsProps = {
  paragraph__navigation: ParagraphNavigation,
  paragraph__search_form: ParagraphSearchForm,
  paragraph__menu: ParagraphMenu,
  paragraph__linked_logo: ParagraphLinkedLogo,
  paragraph__social_links: ParagraphSocialLinks,
  paragraph__footer_row: ParagraphFooterRow,
  paragraph__text: ParagraphText,
};

interface Props {
  [key: string]: any;
}
interface GetParagraphProps {
  node: Paragraph;
  props?: Props;
}

export const getParagraph = ({ node, props }: GetParagraphProps): React.ReactElement | null => {
  if (components.hasOwnProperty(node.type)) {
    const ParagraphComponent = components[node.type];

    return (
      <Suspense fallback={<>Loading ${node.type}...</>}>
        <ParagraphComponent node={node} props={props} />
      </Suspense>
    );
  }
  return null;
};
