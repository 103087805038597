import { Spinner } from '@purinanbm/pds-ui';
import { PageProps, graphql } from 'gatsby';
import { useFlags } from 'gatsby-plugin-launchdarkly';
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import NewOfferForm from 'src/components/forms/NewOfferForm';
import { SSOWidget } from 'src/components/sso_widget/SSOWidget';
import { useAuth } from 'src/hooks/useAuth';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import OfferForm from '../components/forms/OfferForm';
import { getCmsComponent } from '../utils/cmsComponentHelpers';
import { getHelmetPropsFromMetatagProps, getJsonFromMetatagProps } from '../utils/metatagHelpers';

interface NodeProps extends NodeContent {
  referrerCode: string;
  offerId: number;
  field_form_type: 'short' | 'long' | 'long_address';
  field_offer_cta?: string;
  relationships: {
    field_image: MediaImage;
    components?: TCmsComponents[];
    brand?: {
      name: string;
    };
  };
  settings: {
    success_title: string;
    success_response: string;
    optin_label: string;
    optin_error: string;
    error_title: string;
    error_description: string;
  };
}

interface DataProps {
  node: NodeProps;
  translations: Translations;
}

interface FormProps {
  referrer: string;
  offerId: number;
  formType?: 'short' | 'long' | 'long_address';
  successMessage?: string;
  successTitle?: string;
  legalText?: string;
  optinLabel?: string;
  optinError?: string;
  errorTitle?: string;
  errorDescription?: string;
  ctaLabel?: string;
  brand?: string;
  title?: string;
}

const OfferGate = (props: FormProps) => {
  const { user, isLoading } = useAuth();

  if (isLoading) return <Spinner />;

  if (!user) {
    return <SSOWidget brand={props?.brand} />;
  }

  return (
    <div
      id="offer-form-gate-feature-flag"
      className="pds-relative !pds-max-w-full md-bt:!pds-max-w-[812px]"
    >
      <NewOfferForm {...props} />
    </div>
  );
};

const OfferTemplate: React.FC<PageProps<DataProps>> = function ({ data: { node, translations } }) {
  const helmetProps = getHelmetPropsFromMetatagProps(node.metatag);
  const schemaMarkup = getJsonFromMetatagProps(node.metatag);
  const formProps = {
    referrer: node.referrerCode,
    offerId: node.offerId,
    formType: node.field_form_type,
    successMessage: node.settings.success_response,
    successTitle: node.settings.success_title,
    optinLabel: node.settings.optin_label,
    optinError: node.settings.optin_error,
    errorTitle: node.settings.error_title,
    errorDescription: node.settings.error_description,
    ctaLabel: node.field_offer_cta,
    brand: node?.relationships?.brand?.name,
    title: node?.title,
  };
  const { enableOfferPageGate = undefined } = useFlags();
  const isLoaded = enableOfferPageGate !== undefined;

  return (
    <Layout
      node={node}
      metaData={node.metatag}
      language={node.langcode}
      translations={translations}
    >
      <Seo {...helmetProps} schemaMarkup={schemaMarkup} />
      {!!node.relationships?.components?.length &&
        node.relationships?.components?.map((component, index) => {
          // eslint-disable-next-line
          return (
            <React.Fragment key={component.id}>
              {getCmsComponent({ node: component, index })}
            </React.Fragment>
          );
        })}
      <Container>
        <Row
          className="pds-relative pds-overflow-hidden !pds-py-5.5 md-bt:pds-justify-center"
          id="offer-container"
        >
          {!isLoaded && <Spinner />}
          {isLoaded &&
            (enableOfferPageGate ? (
              <OfferGate {...formProps} />
            ) : (
              <div id="offerForm" className="!pds-max-w-full md-bt:!pds-max-w-[812px]">
                <OfferForm {...formProps} />
              </div>
            ))}
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ($id: String!, $language: String!, $nid: Int!) {
    node: nodeOffer(id: { eq: $id }, langcode: { eq: $language }) {
      internal {
        type
      }
      id
      title
      path {
        alias
      }
      body {
        processed
      }
      field_form_type
      referrerCode: field_ansira_referrer_code
      offerId: field_ansira_id
      ansiraData: field_ansira_data {
        expires_at
        is_active
      }
      settings {
        success_title
        success_response
        optin_label
        optin_error
        error_title
        error_description
      }
      country_links: other_country_external_links {
        text
        url
        hreflang
      }
      relationships {
        brand: field_brand {
          drupal_internal__tid
          name
        }
        field_image {
          ...MediaImage
          relationships {
            field_media_image {
              gatsbyImage(aspectRatio: 2, width: 1600, placeholder: NONE)
            }
          }
        }
        components: field_storage {
          type: __typename
          ...StorageArticleCards
          ...StorageImageContent
          ...StorageHeroCard
          ...StorageManualCards
          ...StorageHighlights
          ...StorageProductCards
          ...StorageRichText
          ...StoragePageTitle
          ...StorageBanner
          ...StorageAnsiraProfileForm
          ...StorageIngredientsMap
          ...StorageBrandGrid
          ...StorageFaqSection
          ...StorageUnfilteredHtml
          ...StorageSubnav
          ...StorageCardGrid
          ...StorageReviews
          ...StorageCategoryCards
          ...StorageContactCards
          ...StorageVideo
        }
      }
      field_long_form
      field_offer_cta
      langcode
      ...Metatag
    }
    translations: allNodeOffer(
      filter: { drupal_internal__nid: { eq: $nid }, langcode: { ne: $language } }
    ) {
      edges {
        node {
          langcode
          path {
            alias
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default OfferTemplate;
