import AnalyticsPoint from 'gatsby-plugin-purina-analytics/AnalyticsPoint';
import React from 'react';
import { CMS_THEME } from 'src/common/enums';
import { ButtonLink } from 'src/components/button/Button';
import { getMedia } from 'src/utils/mediaHelpers';

type Props = {
  node: IStorageFeedingGuide;
};

const StorageFeedingGuide = ({ node: feedingGuide }: Props) => {
  const { link, title, copy, relationships } = feedingGuide;
  const image = getMedia({ media: relationships.image, loading: 'eager' });

  return (
    <div className="pds-my-4 pds-flex pds-max-w-xl pds-flex-col pds-items-center pds-justify-center pds-gap-3 pds-rounded-[12px] pds-border pds-bg-surface-foreground pds-p-4.5">
      <div className="pds-flex pds-max-w-md pds-flex-col pds-place-items-center pds-space-y-4">
        {image}
        <h5 className="pds-text-center pds-capitalize">{title}</h5>
        <p className="pds-text-center">{copy}</p>
        <AnalyticsPoint
          key={link.url}
          type="component"
          label="feeding guide CTA"
          typeLabel="cta"
          additionalParams={{
            cta_location: 'product feeding tab',
            content_type: 'feeding guide CTA',
            item_id: link.title,
            link_url: link.url,
          }}
        >
          <ButtonLink variant="btn-outline" cmsTheme={CMS_THEME.LIGHT} to={link.url} {...link.options?.attributes}>
            {link.title}
          </ButtonLink>
        </AnalyticsPoint>
      </div>
    </div>
  );
};

export default StorageFeedingGuide;
