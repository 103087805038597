/**
 * Global functions that are used in both /src and /plugins
 **/
// Eslint does not understand cross-plugin packages.
// eslint-disable-next-line
import { profileAuth } from '@purinanbm/pup-script';
import { PROFILE_CONFIG } from 'src/common/constants';
import { ISendIt, IStopPoint } from '../types';
import { pointTypes } from './constants';

/**
 * Runs a list of regex strings against an item and tells you if that item matches anything in the regex items
 **/
export const hasRouteException = (expressions: string[], item: string) => {
  const mergedExpressions = new RegExp(expressions.join('|'), 'i');
  let matchesAnyExpression = false;

  if (mergedExpressions.test(item)) {
    matchesAnyExpression = true;
  }

  return matchesAnyExpression;
};

/**
 * returns as string index of total
 **/
const getPositionFromSiblings = (totalSiblings?: number, index?: number) => {
  if (!totalSiblings || !index) return '1 of 1';

  return `${index + 1} of ${totalSiblings}`;
};

/**
 * Replaces any parts of an item that match expressions with the replaceWith arg
 **/
export const replaceRouteExceptions = (
  expressions: string[],
  item: string,
  replaceWith: string,
) => {
  const mergedExpressions = new RegExp(expressions.join('|'), 'i');
  return item.replace(mergedExpressions, replaceWith);
};

/**
 * Lower cases values in an object
 **/
export const toLowerParams = (params?: { [key: string]: string | undefined }) => {
  if (!params) return {};

  const entries = Object.entries(params);
  const newObj: { [key: string]: string } = {};

  entries.forEach(entry => {
    if (typeof entry[1] === 'string') {
      newObj[entry[0]] = entry[1].toLowerCase();
    } else {
      newObj[entry[0]] = '';
    }
  });

  return newObj;
};

export function isSameOrigin(url: string) {
  const regex = new RegExp(`^${window.location.origin}`);
  return regex.test(url);
}

export const fromCamelCaseToSnakeCase = (str: string) => {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase ? letter.toLowerCase() : letter}`);
};

export const objectKeysToSnakeCase = (obj?: Record<string, unknown>) => {
  if (!obj) return undefined;
  const objMap = new Map();
  Object.entries(obj).forEach(([key, value]) => {
    objMap.set(fromCamelCaseToSnakeCase(key), value);
  });

  return Object.fromEntries(objMap);
};

/**
 * Sends google analytics data via purData
 **/
export const sendIt = ({
  event,
  eventCategory,
  eventAction,
  eventLabel,
  eventParams,
  eventCallback,
  eventTimeout,
  up,
}: ISendIt) => {
  const dataLayerName = process.env.GATSBY_DATA_LAYER_NAME || 'dataLayer';
  // Log for testing sendIt events as they come through
  if (process.env.GATSBY_GA_LOGGING === 'true') {
    // eslint-disable-next-line
    console.groupCollapsed(`%cGA Event: ${eventCategory}`, 'color: #ffb300');
    // eslint-disable-next-line
    console.log({
      dataLayerName,
      event: {
        event,
        eventCategory,
        eventAction,
        eventLabel,
        event_params: objectKeysToSnakeCase(toLowerParams(eventParams)),
      },
      up,
    });
    // eslint-disable-next-line
    console.groupEnd();
  }
  return window[dataLayerName].push(
    {
      event: event?.toLowerCase(),
      eventCategory: eventCategory?.toLowerCase(),
      eventAction: eventAction?.toLowerCase(),
      eventLabel: eventLabel?.toLowerCase(),
      eventCallback,
      eventTimeout,
      event_params: objectKeysToSnakeCase(toLowerParams(eventParams)),
      up,
    },
    { event_params: undefined },
  );
};

// eslint-disable-next-line complexity
export const generateGaEvent = (stopPoints: IStopPoint[], keyTarget: any) => {
  const pointComponent = stopPoints.find(point => point.type === pointTypes.component);
  const pointModule = stopPoints.find(point => point.type === pointTypes.module);
  const pointPage = stopPoints.find(point => point.type === pointTypes.page);

  sendIt({
    event: pointComponent?.eventOverride ?? 'select_content',
    eventCategory:
      pointComponent?.category ??
      (stopPoints.find(i => i.type === pointTypes.module)?.typeLabel.replace('storage__', '') ||
        '[not set]'),
    eventAction: pointComponent?.action ?? keyTarget?.innerText ?? stopPoints[0].label,
    eventLabel: pointComponent?.eventLabel ?? keyTarget?.innerText ?? stopPoints[0].label,
    eventParams: {
      pageType: pointPage?.pageType.replace('node__', ''),
      moduleId: pointModule?.label || '[not set]',
      moduleTitle: pointModule?.title || '[not set]',
      modulePosition: getPositionFromSiblings(pointModule?.totalSiblings, pointModule?.index),
      moduleType: pointModule?.typeLabel.replace('storage__', '') || '[not set]',
      componentId:
        pointComponent?.eventLabel ?? pointComponent?.label ?? pointModule?.label ?? '[not set]',
      componentTitle: pointComponent?.title || '[not set]',
      componentPosition: getPositionFromSiblings(
        pointComponent?.totalSiblings,
        pointComponent?.index,
      ),
      componentType:
        pointComponent?.typeLabel.replace('storage__', '') ??
        pointModule?.typeLabel.replace('storage__', '') ??
        '[not set]',
      link_title: keyTarget?.innerText || '[not set]',
      linkUrl: keyTarget?.href || '[not set]',
      ...JSON.parse(pointModule?.additionalParams || '{}'),
      ...JSON.parse(pointComponent?.additionalParams || '{}'),
    },
    eventCallback: undefined,
    eventTimeout: keyTarget?.href && !isSameOrigin(keyTarget?.href) ? 2000 : undefined,
  });
};

export function pollObjectOnWindow(
  objectName: string,
  interval: number,
  maxAttempts: number,
  attempts = 0,
) {
  return new Promise((resolve, reject) => {
    if (window[objectName]) {
      resolve(window[objectName]);
    } else if (maxAttempts && attempts >= maxAttempts) {
      reject(new Error(`Failed to find object '${objectName}' on the window.`));
    } else {
      setTimeout(() => {
        pollObjectOnWindow(objectName, interval, maxAttempts, attempts + 1)
          .then(resolve)
          .catch(reject);
      }, interval);
    }
  });
}

/**
 * sends login analytics
 * deprecate after autologin is turned off
 */
export const sendLoginAnalytics = async () => {
  const Auth = profileAuth(PROFILE_CONFIG);
  sendIt({
    event: 'form_complete',
    eventParams: {
      form_brand: 'purina',
      form_id: 'PUPurinaSiteLogin',
      form_name: 'login',
      form_type: 'login',
      form_parent: 'purina',
      form_version: 'v1',
    },
  });
  const id: string = await Auth.currentSession()
    .then(r => r.getIdToken().payload['custom:ansiraUuid'])
    .catch(() => null);

  sendIt({
    event: 'login',
    eventParams: {
      method: 'PUPurinaSiteRegistration',
    },
    up: {
      user_id: id,
    },
  });
};

/**
 * Triggers the sending of login analytics and removes initialSignIn from url afterwards
 * deprecate after autologin is turned off
 */
export function sendLoginOnIntialSignIn(location: {
  pathname: string;
  search: string;
  href: string;
}) {
  const queryParams = new URLSearchParams(location.search);
  const initialSignIn = queryParams.get('initialSignIn');

  if (initialSignIn === 'true') {
    sendLoginAnalytics();

    const newUrl = location.href.replace(/\?initialSignIn=[^&$]*/i, '');

    // eslint-disable-next-line
    history.replaceState(null, '', newUrl);
  }
}
