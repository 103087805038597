import * as Yup from 'yup';
import { TranslateFunctionType } from '../../../utils/TranslateFunctionType';
import { textPattern } from './contactUs';

export const createProfileSchema = (t: TranslateFunctionType) =>
  Yup.object({
    firstName: Yup.string()
      .matches(textPattern, t('First name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .required(t('Please fill out a First Name.')),
    lastName: Yup.string()
      .matches(textPattern, t('Last name must be formatted correctly'))
      .nullable()
      .transform((o, c) => (c === '' ? null : o))
      .required(t('Please fill out a Last Name.')),
    postalCode: Yup.string()
      .min(5, t('Provide a 5 digit zip code.'))
      .max(5, t('Provide a 5 digit zip code.'))
      .transform((o, c) => (o === '' ? null : c))
      .nullable()
      .required(t('Please fill out a Zip Code.')),
    email: Yup.string()
      .email(t('Provide a valid email address.'))
      .required(t('Please fill out an Email.')),

    language: Yup.object({
      keyName: Yup.string().oneOf(['en', 'es', '']),
    }),
    sourceCode: Yup.object({
      keyName: Yup.string().required(),
    }),
    petOwnershipPlan: Yup.object({
      keyName: Yup.string().oneOf(['NEITHER', 'BOTH', 'DOG', 'CAT', '']),
    }),
    dogPets: Yup.array()
      .of(
        Yup.object({
          name: Yup.string().required(t('Please fill out a name for the pet')),
          petType: Yup.object({
            keyName: Yup.string()
              .oneOf(['DOG', 'CAT'], t('Please fill out a valid option'))
              .required(t('Please fill out a valid option')),
          }),
          birthDate: Yup.string().required(t('Please fill out a birth date')),
        })
      )
      .defined(),
    catPets: Yup.array()
      .of(
        Yup.object({
          name: Yup.string().required(t('Please fill out a name for the pet')),
          petType: Yup.object({
            keyName: Yup.string()
              .oneOf(['DOG', 'CAT'], t('Please fill out a valid option'))
              .required(t('Please fill out a valid option')),
          }),
          birthDate: Yup.string().required(t('Please fill out a birth date')),
        })
      )
      .defined(),
    optIn: Yup.bool()
      .oneOf([true], t('Please fill out an Email Consent.'))
      .required(t('Please fill out an Email Consent.')),
  }).required();

export type ProfileFormTypes = Yup.InferType<ReturnType<typeof createProfileSchema>>;
