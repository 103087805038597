import { SelectItem } from '@purinanbm/pds-ui';
import React from 'react';
import { Control } from 'react-hook-form';
import { mdiLocalShippingOutline } from 'src/assets/icons/mdiIcons';
import InputField from 'src/components/forms/new_components/InputField';
import SelectField from 'src/components/forms/new_components/SelectField';
import { getAddressStates } from 'src/utils/formHelpers';
import { Reward } from '../../../services/loyalty/types';
import { RewardDivider } from './RewardDivider';
import { RewardDigitalFormType, RewardPhysicalFormType } from './types';

interface IRewardCheckoutFields {
  control: Control<RewardPhysicalFormType | RewardDigitalFormType>;
  item: Reward;
}
const RewardCheckoutFields: React.FC<IRewardCheckoutFields> = ({ control, item }) => {
  const addressStates = Object.entries(getAddressStates());
  const stateOptions = [['default', 'Select a State'], ...addressStates];
  return (
    <>
      <div className="pds-p-4">
        <span className="pds-col-span-12">All fields are required unless marked as optional.</span>
        <div className="pds-col-span-12 pds-mt-4">
          <InputField
            required
            className="pds-col-span-12"
            control={control}
            label="Email"
            name="email"
            autoComplete="email"
          />
        </div>
      </div>
      {item.type === 'PHYSICAL' && (
        <>
          <RewardDivider title="Shipping Information" icon={mdiLocalShippingOutline} />
          <div className="pds-grid pds-grid-cols-12 pds-gap-4 pds-p-4">
            <div className="pds-col-span-12">
              <InputField
                required
                autoComplete="given-name"
                className="pds-col-span-12"
                control={control}
                name="first"
                label="First Name"
              />
            </div>
            <div className="pds-col-span-12">
              <InputField
                required
                autoComplete="family-name"
                className="pds-col-span-12"
                control={control}
                name="last"
                label="Last Name"
              />
            </div>
            <div className="pds-col-span-12">
              <InputField
                required
                autoComplete="address-line1"
                className="pds-col-span-12"
                control={control}
                name="street1"
                label="Street Address 1"
              />
            </div>
            <div className="pds-col-span-12">
              <InputField
                autoComplete="address-line2"
                className="pds-col-span-12"
                control={control}
                name="street2"
                label="Street Address 2 (Optional)"
              />
            </div>
            <div className="pds-col-span-12">
              <InputField
                required
                className="pds-col-span-12"
                control={control}
                name="city"
                label="City"
                autoComplete="address-level2"
              />
            </div>
            <div className="pds-col-span-12 md:pds-col-span-6">
              <SelectField
                required
                className="pds-col-span-12"
                control={control}
                name="state"
                label="State"
                defaultValue="default"
                autoComplete="address-level1"
              >
                {stateOptions.map(([key, value]) => {
                  return (
                    <SelectItem value={key} key={key}>
                      {value}
                    </SelectItem>
                  );
                })}
              </SelectField>
            </div>
            <div className="pds-col-span-12 md:pds-col-span-6">
              <InputField
                defaultValue=""
                maxLength={5}
                onBeforeInput={i => {
                  if (
                    !/\d/.test((i as React.FormEvent<HTMLInputElement> & { data: string }).data)
                  ) {
                    i.preventDefault();
                  }
                }}
                inputMode="numeric"
                pattern="\d*"
                required
                className="pds-col-span-12"
                control={control}
                name="zip"
                label="ZIP Code"
                autoComplete="postal-code"
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RewardCheckoutFields;
