import { useLocation } from '@reach/router';
import { cx } from '@linaria/core';
import { styled } from '@linaria/react';
import React from 'react';

const StyledSkipLink = styled.a`
  background-color: #edf0f8;
  color: #2b2b2b;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1;
  padding: 0.55rem 0.75rem;
  text-align: center;
  transition: none;
  vertical-align: baseline;
  white-space: nowrap;

  & > * + * {
    margin-left: 0.5rem;
  }

  &:focus,
  &:active {
    text-decoration: none;
    z-index: 999999 !important;
  }
`;

type Props = React.ComponentPropsWithoutRef<'a'> & React.AnchorHTMLAttributes<Props>;

const SkipLink = React.forwardRef<HTMLAnchorElement, Props>(
  ({ children, className, href, ...props }, ref) => {
    if (!href?.startsWith('#')) {
      throw new Error('Skip link href is required and must begin with #.');
    }

    const location = useLocation();

    return (
      <StyledSkipLink
        ref={ref}
        className={cx('visually-hidden-focusable sticky-top rounded-3', className)}
        href={`${location.search}${href}`}
        {...props}
      >
        {children}
      </StyledSkipLink>
    );
  }
);

export default SkipLink;
