import Icon from '@mdi/react';
import {
  Button,
  Dialog,
  DialogClose,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '@purinanbm/pds-ui';
import { navigate } from 'gatsby';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mdiInformationOutline } from 'src/assets/icons/mdiIcons';
import { CMS_THEME } from 'src/common/enums';
import { ButtonLink } from 'src/components/button/Button';
import { getMedia } from 'src/utils/mediaHelpers';

type Props = {
  node: IStorageReward;
  onTriggerClick?: () => void;
  onLinkClick?: () => void;
};

const StorageReward = ({ node, onLinkClick, onTriggerClick }: Props) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const image = getMedia({ media: node.relationships.image, loading: 'eager' });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          buttonStyle="text"
          className="pds-mt-4 pds-flex pds-space-x-4 pds-rounded-[12px] pds-border-transparent pds-bg-surface-foreground pds-p-0 pds-p-4 pds-text-paper-text pds-transition-colors hover:pds-bg-gray-400/DEFAULT hover:pds-text-paper-text [@media(min-width:450px)]:pds-max-w-xs"
          aria-label={t('More Purina Reward Info')}
          onClick={() => {
            setOpen(true);
            if (onTriggerClick) {
              onTriggerClick();
            }
          }}
        >
          {image}
          <span className="pds-inline-block pds-max-w-xs pds-whitespace-normal pds-break-words pds-text-left">
            {node.copy}
            <Icon size={1} path={mdiInformationOutline} color="black" />
            <DialogContent>
              <DialogTitle className="pds-sr-only">{t('MyPurina Rewards')}</DialogTitle>
              <DialogClose
                aria-label={t('Close')}
                className="pds-border-none pds-bg-utils-transparent pds-text-surface-textSubdued hover:pds-text-surface-text"
              />
              <div className="pds-space-y-2 pds-p-4">
                <p>{node.tooltip}</p>
                <ButtonLink
                  analytics={false}
                  className="!pds-w-full"
                  cmsTheme={CMS_THEME.LIGHT}
                  variant="btn-outline"
                  to={node.link.uri}
                  onClick={e => {
                    if (!onLinkClick) return;
                    e.preventDefault();
                    onLinkClick();
                    setTimeout(() => {
                      navigate(node.link.uri);
                    }, 300);
                  }}
                >
                  {node.link.title}
                </ButtonLink>
              </div>
            </DialogContent>
          </span>
        </Button>
      </DialogTrigger>
    </Dialog>
  );
};

export default StorageReward;
