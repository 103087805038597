import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import Card from 'react-bootstrap/Card';
import { getPageTypeFromDom } from 'src/common/functions';
import Link from '../Link';
import { CategoryTag } from '../category_tag/CategoryTag';
import { navigate } from 'gatsby';

interface Props {
  image: IGatsbyImageData;
  link: string;
  title: string;
  topics: { name: string; page_delegate: string }[];
  readTime?: string;
}

const NewArticleCard: React.FC<Props> = function ({ title, topics, link, image, readTime }: Props) {
  const topic = topics.length > 0 && topics[0];
  const pageType = getPageTypeFromDom();

  return (
    <Card as="div" className="d-flex pds-gap-3 !pds-border-none !pds-bg-[inherit]">
      <Card.Body className="pds-flex pds-flex-col pds-gap-4 !pds-p-0">
        <div className="pds-relative pds-box-border pds-rounded-[20px] pds-bg-[#E4E9F4]">
          <GatsbyImage image={image} className="pds-w-full pds-rounded-xl" alt="" />
        </div>
        <div className="pds-inline-flex pds-flex-wrap pds-gap-3">
          {topic ? (
            <CategoryTag
              onClick={() =>
                sendIt({
                  event: 'select_content',
                  eventParams: {
                    content_name: `${pageType}: ${topics[0].name}`,
                    cta_location: `${pageType}: article listing`,
                    content_type: `${pageType}: article_category_button`,
                    link_url: topics[0].page_delegate,
                  },
                })
              }
              url={topic.page_delegate}
              name={topic.name}
              className={`!pds-line-clamp-2 ${
                topic.name?.length > 21 ? '!md:pds-line-clamp-1' : ''
              }`}
            />
          ) : null}
        </div>
        <Link
          onClick={() =>
            sendIt({
              event: 'select_content',
              eventParams: {
                cta_location: `${pageType}: article listing`,
                content_type: `${pageType}: article_card`,
                content_name: `${pageType}: ${title}`,
                link_url: link,
              },
            })
          }
          // TODO: Remove once converted to the new system
          to={link}
          className="!pds-line-clamp-2 pds-block pds-text-body-md !pds-font-semibold pds-text-paper-text pds-no-underline hover:pds-cursor-pointer hover:pds-text-paper-text hover:pds-underline"
          analytics={false}
        >
          {title}
        </Link>
        <span>{readTime}</span>
      </Card.Body>
    </Card>
  );
};

export default NewArticleCard;
