/* eslint-disable no-param-reassign */
import { Loader } from '@googlemaps/js-api-loader';
import { MarkerClusterer, Renderer } from '@googlemaps/markerclusterer';
import { IconData, useMapStore } from 'src/stores/mapStore';
import { mapTheme } from './mapTheme';

const pinColor = '#e91c24';
const clusterColor = '#29446a';
const svg = btoa(`
<svg fill="${clusterColor}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
  <circle cx="120" cy="120" opacity=".8" r="70" />
  <circle cx="120" cy="120" opacity=".6" r="90" />
  <circle cx="120" cy="120" opacity=".2" r="110" />
  <circle cx="120" cy="120" opacity=".1" r="130" />
</svg>`);
const pinSvg = btoa(`
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" >
	<path style="fill:${pinColor}" d="M424.269,212.061c0,58.586-23.759,111.638-62.128,150.007L213.684,510.451L212.134,512   L62.275,362.141c-7.231-7.157-13.872-14.905-19.996-23.095C15.715,303.703,0,259.726,0,212.061   c0-51.06,18.077-97.914,48.182-134.512c8.78-10.773,18.668-20.586,29.366-29.367C114.147,18.077,161.074,0,212.134,0   c40.655,0,78.582,11.437,110.826,31.211c28.555,17.487,52.609,41.541,70.097,70.097   C412.832,133.552,424.269,171.478,424.269,212.061z"/>
</svg>`);

interface ILoadMap {
  locations: TaxonomyTermLocation[];
  iconData: IconData;
}

interface ICreateMarkers {
  locations: TaxonomyTermLocation[];
  isInfoWindowOpen: boolean;
  openInfoWindowId: number;
  infoWindow: google.maps.InfoWindow;
  markers: google.maps.Marker[];
  map: google.maps.Map;
  bounds: google.maps.LatLngBounds;
  iconData: IconData;
  focus?: boolean;
  markerCluster?: MarkerClusterer;
}

export const useMap = () => {
  const setMapData = useMapStore(state => state.setMapData);
  const mapData = useMapStore(state => state.mapData);
  const isSSR = typeof window === 'undefined';
  const isDesktop = !isSSR && window.innerWidth > 767;

  const createMarkers = ({
    locations,
    isInfoWindowOpen,
    openInfoWindowId,
    infoWindow,
    markers,
    map,
    bounds,
    focus,
    iconData,
    markerCluster,
  }: ICreateMarkers) => {
    if (locations.length !== 0) {
      locations.forEach((location, i) => {
        const marker = new google.maps.Marker({
          position: { lat: location.field_coordinates.lat, lng: location.field_coordinates.lon },
          map,
          icon: {
            url: `data:image/svg+xml;base64,${pinSvg}`,
            scaledSize: new google.maps.Size(30, 38),
          },
        });

        const infoWindowContent = `
          <div>${location.name}<div>
        `;

        marker.addListener('click', () => {
          if (isInfoWindowOpen && i === openInfoWindowId) {
            infoWindow.close();
            isInfoWindowOpen = false;
            openInfoWindowId = -1;
            return;
          }

          infoWindow.setContent(infoWindowContent);
          infoWindow.open(map, marker);
          isInfoWindowOpen = true;
          openInfoWindowId = i;
        });

        if (marker.getPosition()) {
          bounds.extend(marker.getPosition() as google.maps.LatLng);
          markers.push(marker);
        }
      });

      if (focus) {
        const menu = document.getElementById('map-menu');
        const options = isDesktop
          ? { left: menu?.clientWidth || 0 }
          : { bottom: menu?.clientHeight || 0 };
        map.fitBounds(bounds, options);
      }
    }

    const renderer: Renderer = {
      render: ({ count, position }) =>
        new google.maps.Marker({
          label: { text: String(count), color: 'white', fontSize: '14px' },
          position,
          icon: {
            url: `data:image/svg+xml;base64,${svg}`,
            scaledSize: new google.maps.Size(60, 68),
          },
          // adjust zIndex to be above other markers
          zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
        }),
    };
    markerCluster = new MarkerClusterer({ markers, map, renderer });
    setMapData({
      map,
      bounds,
      markers,
      infoWindow,
      isInfoWindowOpen,
      openInfoWindowId,
      iconData,
      markerCluster,
    });
  };

  const loadMap = async ({ locations, iconData }: ILoadMap) => {
    const isInfoWindowOpen = false;
    let openInfoWindowId = -1;
    const key = process.env.GATSBY_MAP_API_KEY;

    const loader = new Loader({
      apiKey: key || '',
    });

    loader.load().then(google => {
      const entry = document.getElementById('ing-map');
      if (!entry) return;

      const map = new google.maps.Map(entry, {
        zoom: 5,
        // minZoom: 5,
        maxZoom: 6,
        zoomControl: true,
        zoomControlOptions: { position: google.maps.ControlPosition.RIGHT_TOP },
        center: { lat: 38.403163, lng: -92.568913 },
        styles: mapTheme,
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
      });

      const bounds = new google.maps.LatLngBounds();

      const infoWindow = new google.maps.InfoWindow({ content: null });

      map.addListener('click', () => {
        if (isInfoWindowOpen) {
          openInfoWindowId = -1;
          infoWindow.close();
        }
      });

      const markers: google.maps.Marker[] = [];

      createMarkers({
        markers,
        infoWindow,
        openInfoWindowId,
        isInfoWindowOpen,
        locations,
        map,
        bounds,
        iconData,
      });
    });
  };

  const updateMapMarkers = (locations: TaxonomyTermLocation[]) => {
    if (!mapData) return;
    mapData?.markers.forEach(marker => marker.setMap(null));
    setMapData({ ...mapData, markers: [] });

    createMarkers({
      ...mapData,
      locations,
      focus: true,
    });
  };

  const focusMapLocations = () => {
    if (!mapData) return;
    const { map, bounds, markers } = mapData;
    if (markers.length === 0) return;

    markers.forEach(marker => bounds.extend(marker.getPosition()!));

    const menu = document.getElementById('map-menu');

    const options = isDesktop
      ? { left: menu?.clientWidth || 0 }
      : { bottom: menu?.clientHeight || 0 };
    map.fitBounds(bounds, options);
  };

  const focusMapLocation = (locationIndex: number) => {
    if (!mapData || mapData.markers.length === 0) return;
    const { map, markers } = mapData;
    const menu = document.getElementById('map-menu');
    let location = markers[locationIndex].getPosition();

    if (!location) return;

    if (menu) {
      const pixelLocation = map.getProjection()?.fromLatLngToPoint(location);
      const offset = !isSSR && window.innerWidth > 767 ? 7 : 5;

      if (pixelLocation) {
        const newPoint = isDesktop
          ? new google.maps.Point(pixelLocation.x - offset, pixelLocation.y || 0)
          : new google.maps.Point(pixelLocation.x, (pixelLocation.y || 0) + offset);
        const newLocation = map.getProjection()?.fromPointToLatLng(newPoint);

        location = newLocation || location;
      }
    }
    map.panTo(location);
    map.setZoom(5);
  };

  const removeMarkers = () => {
    if (!mapData || mapData.markers.length === 0) return;
    mapData.markers.forEach(marker => marker.setMap(null));
    setMapData({ ...mapData, markers: [] });
  };

  return {
    removeMarkers,
    focusMapLocations,
    createMarkers,
    focusMapLocation,
    updateMapMarkers,
    loadMap,
  };
};
