import { pdsTv } from '@purinanbm/pds-ui';
import { useLocation } from '@reach/router';
import { sendIt } from 'gatsby-plugin-purina-analytics/common/functions';
import React from 'react';
import { Accordion, Stack } from 'react-bootstrap';
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext';
import { CMS_THEME } from '../common/enums';
import FaqItem from '../components/faq/FaqItem';
import RichText from '../components/rich_text/RichText';
import Typography from '../components/typography/Typography';

type Props = {
  node: IStorageFaqSection;
};

const defaultSettings = {
  entity_variant: {
    theme: CMS_THEME.LIGHT,
  },
};

const styles = pdsTv({
  base: '',
  variants: {
    theme: {
      dark: 'pds-bg-[#1E1E1E] pds-text-white',
      light: 'pds-bg-white pds-text-paper-text',
      neutral: 'pds-bg-surface-foreground pds-text-paper-text',
    },
  },
  defaultVariants: {
    theme: 'light',
  },
});

interface IEventKey {
  component_id?: string;
  id: string;
}

const useActiveKey = (list?: IEventKey[]) => {
  const location = useLocation();
  const [activeKey, setActiveKey] = React.useState<AccordionEventKey>(null);

  React.useEffect(() => {
    if (!location?.hash || !list || list.length === 0) {
      return;
    }
    const hash = location.hash.replace('#', '');
    const newActiveKey = list.find(item => item?.component_id === hash)?.id;

    if (newActiveKey) setActiveKey(newActiveKey);
  }, [list, location.hash]);

  return { activeKey, setActiveKey };
};

const StorageFaqSection = ({ node }: Props) => {
  const { qa_sets: faqList } = node.relationships;

  const { activeKey, setActiveKey } = useActiveKey(faqList);
  const settings = Object.assign(defaultSettings, node.behaviors || {});
  const cmsTheme = settings.entity_variant.theme;
  const wrapper = styles({ theme: cmsTheme });
  const renderQASets = (faqItem: IStorageFaq) => (
    <FaqItem cmsTheme={cmsTheme} node={faqItem} key={faqItem.id} />
  );

  // If all the accordion sets are draft, don't render.
  if (!faqList || faqList.length === 0) {
    return null;
  }

  const handleActiveKey = (key: AccordionEventKey) => {
    setActiveKey(key);
    if (key) {
      const activeFaqItem = faqList.find(item => item.id === key);

      if (!activeFaqItem) return;

      const faqAnalyticsEvent = {
        event: 'module_interaction',
        eventParams: {
          component_id: activeFaqItem.name,
          component_type: 'faq',
          content_name: node.title,
        },
      };

      window.location.hash = `#${activeFaqItem.component_id}`;
      sendIt(faqAnalyticsEvent);
    } else {
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  };
  return (
    <Stack id={node.component_id} data-id={node.drupal_id} className={wrapper}>
      <Stack className="container-xxl pds-gap-4.5 pds-py-4.5">
        <div>
          {node?.title && <Typography variant="h2">{node.title}</Typography>}
          {node?.body?.processed && <RichText cmsTheme={cmsTheme} body={node.body.processed} />}
        </div>
        <Accordion
          onSelect={handleActiveKey}
          defaultActiveKey={undefined}
          activeKey={activeKey}
          id={node.id}
          flush
        >
          {faqList.map(renderQASets)}
        </Accordion>
      </Stack>
    </Stack>
  );
};

export default StorageFaqSection;
