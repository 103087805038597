import * as React from 'react';
import { SVGProps } from 'react';

const AdornmentCatIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={25}
    height={24}
    fill="none"
    viewBox="0 0 25 24"
    style={{ '--fill-color': 'currentColor' } as React.CSSProperties}
    {...props}
  >
    <path
      fill="var(--fill-color, #000)"
      d="M18.5146 7.55791V3.87841C18.5146 3.54291 18.1119 3.37516 17.8767 3.6122L14.5053 7.01091C13.9434 6.87477 13.3284 6.79575 12.6809 6.79575C11.9683 6.79575 11.293 6.88814 10.6865 7.05224L7.27282 3.61098C7.03768 3.37395 6.63494 3.5417 6.63494 3.87719V7.97971L4.7804 15.8894C4.6562 16.4194 4.92148 16.9639 5.41345 17.1876L12.6797 20.5L20.1051 16.9493C20.5995 16.7123 20.8551 16.1519 20.7092 15.6195L18.5122 7.5567L18.5146 7.55791ZM12.7653 15.188L11.0193 13.9202H14.5101L12.7641 15.188H12.7653Z"
    />
  </svg>
);
export default AdornmentCatIcon;
